import React from 'react';
import { Row } from 'reactstrap';
import DonutContainer from 'components/donut/DonutContainer';
import CarouselContainer from 'components/carousel/CarouselContainer';
import HorizontalBarChartContainer from 'components/horizontal-bar-chart/HorizontalBarChartContainer';
import TrendContainer from 'components/trends/TrendContainer';
import PlaneContainer from 'components/plane/PlaneContainer';

let page = 'audio';
let displayPageName = 'Audio';

class AudioOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let dashboard = (
            <div>
                <CarouselContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    contentType="Audio"
                    title="Audio Rankings"
                    workbenchTitle="titles"
                    page={page}
                    pageName={displayPageName}
                    forceReloadAll={this.props.forceReloadAll}
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />

                <Row>
                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        renderType="row"
                        forceReloadAll={this.props.forceReloadAll}
                        page={page}
                        pageName={displayPageName}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        allowedApps={this.props.allowedApps}
                        app={this.props.app}
                    />
                </Row>

                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    contentType="Audio"
                    forceReloadAll={this.props.forceReloadAll}
                    title="Trends"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="audio_overview_trend_1"
                    id="audio_overview_trend_1"
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />

                <PlaneContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    contentType="Audio"
                    title="Cabin Class"
                    workbenchTitle="cabin_class_viewing"
                    page={page}
                    pageName={displayPageName}
                    forceReloadAll={this.props.forceReloadAll}
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />

                <Row>
                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        contentType="Audio"
                        title="Routes"
                        workbenchTitle="routes"
                        page="audio"
                        pageName={displayPageName}
                        reportType="routes"
                        color="#F7A77F"
                        routes={true}
                        key="audio_overview_routes_1"
                        id="audio_overview_routes_1"
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        contentType="Audio"
                        title="Categories"
                        workbenchTitle="categories"
                        page="audio"
                        pageName={displayPageName}
                        reportType="categories"
                        color="#7FCECD"
                        key="audio_overview_categories_1"
                        id="audio_overview_categories_1"
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>
                <Row>
                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        contentType="Audio"
                        title="Genres"
                        workbenchTitle="genres"
                        page="audio"
                        pageName={displayPageName}
                        reportType="genres"
                        color="#954F95"
                        key="audio_overview_genres_1"
                        id="audio_overview_genres_1"
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default AudioOverview;
