import React from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import FiltersService from 'services/FiltersService';

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faCaretUp : faCaretDown} />
            </components.DropdownIndicator>
        )
    );
};

class RackDataDropdown extends React.Component {
    constructor(props) {
        super(props);
        let options = [
            { label: 'Production Data', value: 'Production' },
            { label: 'Rack Data', value: 'Rack' }
        ];
        this.state = {
            options: options,
            selected: this.props.rackDataEnv ? options[1] : options[0]
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (option) => {
        if (option.value !== this.state.selected.value) {
            window.sessionStorage.setItem('rack_data_env', option.value);
            window.sessionStorage.removeItem('pac_next_insights_selected_filters');
            FiltersService.resetFilterValues();
            let { history } = this.props;
            this.setState({
                selected: option
            });
            history.push('/');
        }
    };

    render() {
        let { selected } = this.state;
        if (selected && selected.label) {
            let selectedLabel = selected.label;
            if (typeof selectedLabel === 'string') {
                selected.label = <div> {selected.label} </div>;
            }
        }

        return (
            <div style={{ width: '270px' }}>
                <Select
                    className="selectBox"
                    style={{ zIndex: '2', marginTop: 5 }}
                    value={this.state.selected}
                    name="tenant"
                    options={this.state.options}
                    classNamePrefix="select"
                    onChange={this.handleChange}
                    isSearchable={false}
                    styles={{
                        dropdownIndicator: (provided, state) => ({
                            ...provided,
                            fontSize: '14px'
                        }),
                        menu: (base) => ({
                            ...base,
                            fontSize: '15px',
                            zIndex: '2'
                        }),
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            return {
                                ...styles,
                                backgroundColor: isSelected ? '#ebebeb' : isFocused ? '#f6f6f6' : null,
                                color: '#333333'
                            };
                        }
                    }}
                    components={{ DropdownIndicator }}
                />
            </div>
        );
    }
}

export default RackDataDropdown;
