import React from 'react';
import { Row } from 'reactstrap';
import _, { debounce } from 'lodash';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import ErrorBoundary from 'util/ErrorBoundary.js';
import PlaneWidget from 'components/plane/PlaneWidget';
import * as AppConstants from 'constants/app/constants';
import PlaneConfig from 'components/plane/PlaneConfig.js';
import FiltersService from '../../services/FiltersService';
import SubscriptionService from 'services/SubscriptionService';

const planeConfig = new PlaneConfig();

class PlaneContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: '',
            loading: true,
            error: false,
            requestedSections: []
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) ||
            prevProps.path !== this.props.path ||
            this.props.forceReloadAll
        ) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        this.setState({ loading: true });

        if (FiltersService.dateRangeFiltersData !== '') {
            const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));

            let reportSubType = '';
            if (this.props.contentType) {
                selectedFiltersDeepClone.content_types = [this.props.contentType];
                reportSubType = this.props.contentType;
            } else if (this.props.usageSource) {
                selectedFiltersDeepClone.usage_source = this.props.usageSource;
                reportSubType = this.props.usageSource;
            }

            let requestedSections = planeConfig.getCabinClassRequestedSections(reportSubType);

            if (!this.props.showWorkbenchLink) {
                requestedSections = requestedSections.filter((requestedSection) => requestedSection.free_tier === 1);
            }

            selectedFiltersDeepClone.requested_sections = requestedSections.map(function (section) {
                return section['value'];
            });

            // https://jira.panasonic.aero/browse/INSI-2264
            if (selectedFiltersDeepClone.seat_classes) {
                delete selectedFiltersDeepClone.seat_classes;
            }

            if (SubscriptionService.subscription() !== '') {
                selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
            }

            if (this.props.app) {
                selectedFiltersDeepClone['app'] = this.props.app;
            }

            if (this.cancelRequestSource) {
                this.cancelRequestSource.cancel('Operation canceled by the user.');
            }

            this.throttleFetch(selectedFiltersDeepClone);
        }
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchNextInsightsMetricsAxios(
                selectedFiltersDeepClone,
                planeConfig.getAPIPath('cabin-class-viewing'),
                this.cancelRequestSource.token
            )
            .then((response) => {
                if (!response && !response.requested_sections) {
                    this.setState({ error: true, dataset: [], loading: false });
                } else {
                    this.setState({ error: false, dataset: response.requested_sections, loading: false });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, loading: false });
                } else {
                    this.setState({ error: true, dataset: [], loading: false });
                }
            });
    }

    renderPlaneView() {
        let { dataset, loading } = this.state;

        let reportSubType = '';
        if (this.props.contentType) {
            reportSubType = this.props.contentType;
        } else if (this.props.usageSource) {
            reportSubType = this.props.usageSource;
        }

        let dropdownList = planeConfig.getCabinClassRequestedSections(reportSubType);

        if (!this.props.showWorkbenchLink) {
            dropdownList = dropdownList.filter((item) => item.free_tier === 1);
        }

        if (!this.props.showChannelOption) {
            for (let i = 0; i < dropdownList.length; i++) {
                if (dropdownList[i].label.includes('Channel')) {
                    dropdownList.splice(i, 1);
                    break;
                }
            }
        }

        let defaultKey = 'total_views';
        if (this.props.page === 'ereader') {
            defaultKey = 'total_title_views';
        }

        return (
            <Row>
                <div className="align-component card">
                    <ErrorBoundary>
                        <PlaneWidget
                            key={'cabin_class_viewing' + reportSubType}
                            type={'cabin_class_viewing' + reportSubType}
                            defaultKey1={defaultKey}
                            defaultKey2={defaultKey}
                            data={dataset ? dataset : []}
                            title="Cabin Class"
                            page={this.props.page}
                            pageName={this.props.pageName}
                            dd1Options={dropdownList}
                            dd2Options={[]}
                            unit="count"
                            showViewMore={false}
                            loading={loading}
                            workbench_type={this.props.workbenchTitle}
                            showWorkbenchLink={this.props.showWorkbenchLink}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            appliedFilters={this.props.appliedFilters.api}
                            app={this.props.app}
                            contentType={reportSubType}
                        />
                    </ErrorBoundary>
                </div>
            </Row>
        );
    }

    render() {
        return <React.Fragment>{this.renderPlaneView()}</React.Fragment>;
    }
}

export default PlaneContainer;
