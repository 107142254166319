import Excel from 'components/excel/ExcelHelper.js';
import * as XLSX from 'xlsx';
import UtilHelper from 'util/UtilHelper.js';

const utilHelper = new UtilHelper();

class MyInsightsTrendsExcelHelper extends Excel {
    exportExcelTrends(
        airlineIcao,
        reportSubType,
        title,
        fileNamePrefix,
        data,
        filters,
        showComparison,
        dropdown1Options,
        page
    ) {
        if (Object.keys(data.allTabDataBySections).length > 1) {
            let fullData = {};
            for (let key in data.allTabDataBySections) {
                if (!key.includes('5')) {
                    fullData[key] = data.allTabDataBySections[key];
                }
            }
            data = fullData;
        } else {
            data = data.tabDataBySections;
        }
        let isDateRangeCurrentYear = filters['date_range_text'] === 'Current Year';

        let wb = XLSX.utils.book_new();

        let formattedData = [];

        let column1 = 'NEXT Insights Export';
        let column2 = ' ';
        let column3 = '  ';
        let column4 = '   ';
        let column5 = '    ';

        this.populateSummaryRowsData(
            formattedData,
            airlineIcao,
            filters,
            page,
            column1,
            column2,
            column3,
            !showComparison
        );

        let row = {};
        if (showComparison) {
            row = {
                [column1]: title,
                [column2]: reportSubType === 'EntertainmentTrends' ? 'Content Type' : 'Application Name',
                [column3]: 'Date Range',
                [column4]: 'Comparison Range',
                [column5]: 'Change (%)'
            };
        } else {
            row = {
                [column1]: title,
                [column2]: reportSubType === 'EntertainmentTrends' ? 'Content Type' : 'Application Name',
                [column3]: 'Date Range'
            };
        }
        formattedData.push(row);

        for (let key in data) {
            let applicationsData = data[key];
            for (let i = 0; i < applicationsData.length; i++) {
                let formattedValue = '';
                if (applicationsData[i]['unit'] === 'count') {
                    formattedValue = { t: 'n', v: applicationsData[i]['value'], z: '#,##0' };
                } else if (applicationsData[i]['unit'] === 'time') {
                    formattedValue = {
                        t: 'n',
                        z: '[hh]:mm:ss.0',
                        v: parseFloat(applicationsData[i]['value']) / 3600 / 24
                    };
                }

                let row = {
                    [column1]: applicationsData[i]['label'],
                    [column2]: applicationsData[i]['title'],
                    [column3]: formattedValue
                };

                if (showComparison) {
                    let comparisonFormattedValue = '';
                    if (applicationsData[i]['unit'] === 'count') {
                        comparisonFormattedValue = {
                            t: 'n',
                            v: applicationsData[i]['comparison_raw_value'],
                            z: '#,##0'
                        };
                    } else if (applicationsData[i]['unit'] === 'time') {
                        comparisonFormattedValue = {
                            t: 'n',
                            z: '[hh]:mm:ss.0',
                            v: parseFloat(applicationsData[i]['comparison_raw_value']) / 3600 / 24
                        };
                    }
                    row[column4] = isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : applicationsData[i]['comparison_raw_value']
                        ? comparisonFormattedValue
                        : 'No Data';
                    row[column5] = isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : applicationsData[i]['comparison_value']
                        ? applicationsData[i]['comparison_value']
                        : 'No Data';
                }

                formattedData.push(row);
            }

            let row = {
                [column1]: '',
                [column2]: ''
            };
            formattedData.push(row);
        }

        let sheet = XLSX.utils.json_to_sheet(formattedData);
        sheet['!cols'] = [{ wch: 58 }, { wch: 45 }, { wch: 45 }, { wch: 45 }, { wch: 45 }];
        sheet = this.addLinksToFooter(formattedData, sheet);
        let sheetName = reportSubType === 'EntertainmentTrends' ? 'Entertainment Trends' : 'Application Trends';
        XLSX.utils.book_append_sheet(wb, sheet, sheetName);

        dropdown1Options.forEach((option) => {
            let applicationsData = data[option.value];
            let dateData = [];
            for (let i = 0; i < applicationsData.length; i++) {
                let appData = this.formatTrendGraphData(
                    applicationsData[i],
                    showComparison,
                    isDateRangeCurrentYear,
                    reportSubType
                );
                dateData = [...dateData, ...appData];
            }
            let sheet = XLSX.utils.json_to_sheet(dateData);
            sheet['!cols'] = [{ wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];
            XLSX.utils.book_append_sheet(wb, sheet, option.label);
        });

        XLSX.writeFile(wb, `${fileNamePrefix}.xlsx`);
    }

    formatTrendGraphData(data, showComparison, isDateRangeCurrentYear, reportSubType) {
        let formattedData = [];

        let graphRowCount = 0;
        let currentDateRangeCount = data.graph.current_date_range ? data.graph.current_date_range.data.length : 0;
        let comparisonDateRangeCount = data.graph.comparison_date_range
            ? data.graph.comparison_date_range.data.length
            : 0;

        if (currentDateRangeCount > comparisonDateRangeCount || currentDateRangeCount === comparisonDateRangeCount) {
            graphRowCount = currentDateRangeCount;
        } else if (comparisonDateRangeCount > currentDateRangeCount) {
            graphRowCount = comparisonDateRangeCount;
        }

        let columnTitle = reportSubType === 'EntertainmentTrends' ? 'Content Type' : 'Application';

        for (let i = 0; i < graphRowCount; i++) {
            let row = {};
            row[columnTitle] = data['title'];

            if (data.graph.current_date_range.data[i]) {
                row['Current Date Range'] = data.graph.current_date_range.data[i].date
                    ? data.graph.current_date_range.data[i].date
                    : '';
                if (data.graph.current_date_range.data[i].value && data.unit) {
                    if (data.unit === 'time') {
                        row['Value'] = {
                            t: 'n',
                            z: '[hh]:mm:ss.0',
                            v: parseFloat(data.graph.current_date_range.data[i].value) / 3600 / 24
                        };
                    } else {
                        row['Value'] = { t: 'n', v: data.graph.current_date_range.data[i].value, z: '#,##0' };
                    }
                } else {
                    row['Value'] = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                }
            } else {
                row['Current Date Range'] = '';
                row['Value'] = '';
            }

            if (showComparison && data.graph.comparison_date_range) {
                if (data.graph.comparison_date_range.data[i] && !isDateRangeCurrentYear) {
                    row['Comparison Date Range'] = data.graph.comparison_date_range.data[i].date
                        ? data.graph.comparison_date_range.data[i].date
                        : '';

                    if (data.graph.comparison_date_range.data[i].value && data.unit) {
                        if (data.unit === 'time') {
                            row['Comparison Value'] = {
                                t: 'n',
                                z: '[hh]:mm:ss.0',
                                v: parseFloat(data.graph.comparison_date_range.data[i].value) / 3600 / 24
                            };
                        } else {
                            row['Comparison Value'] = {
                                t: 'n',
                                v: data.graph.comparison_date_range.data[i].value,
                                z: '#,##0'
                            };
                        }
                    } else {
                        row['Comparison Value'] = 'No Data';
                    }
                } else if (isDateRangeCurrentYear) {
                    row['Comparison Value'] = 'Not Applicable';
                }
            }

            formattedData.push(row);
        }

        let row = {};
        row[columnTitle] = '';
        formattedData.push(row);

        return formattedData;
    }
}

export default MyInsightsTrendsExcelHelper;
