import React, { Component } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { ButtonPrimary, ButtonSecondary } from 'pac-ux-components';
import { DropdownMultiple, Checkbox, CheckboxSelectVariant } from 'pac-ux-components';
import LicenseFilterHelper from 'components/widgets/admin/LicenseFilterHelper.js';
import UtilHelper from 'util/UtilHelper.js';

const utilHelper = new UtilHelper();

class LicenseFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            applyFilterButtonDisabled: true,
            clearFilterButtonDisabled: true,
            allLicenseOptions: [{ label: 'Any', value: 'Any' }],
            licenseOptions: [{ label: 'Any', value: 'Any' }],
            multiSelectFilterLabel: 'Select Airline',
            selectedFilters: {
                airline: [],
                tier: [],
                stage: []
            },
            appliedFilters: {},
            prevAppliedFilters: {},
            allFilters: {
                airline: [],
                tier: [],
                stage: []
            }
        };

        this.testData = {};

        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.clearAirlineFilter = this.clearAirlineFilter.bind(this);
    }

    async componentDidMount() {
        await this.setAllFilterValues();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.filterValues, prevProps.filterValues)) {
            await this.setAllFilterValues();
        }
    }

    componentWillReceiveProps(nextProps) {}

    async setSelectedFilters() {
        this.setState({ selectedFilters: {}, appliedFilters: {} });
    }

    setAllFilterValues() {
        let filterValues = this.props.filterValues;

        if (filterValues) {
            let filterAirlines = utilHelper.resolve(filterValues, `airlines`);
            let airlineOptionData = [];
            if (filterAirlines) {
                filterAirlines.forEach(function (airline) {
                    let airlineData = {};
                    airlineData.label = airline;
                    airlineData.value = airline;
                    airlineOptionData.push(airlineData);
                });
            } else {
                airlineOptionData = [{ label: 'Any', value: 'Any' }];
            }

            let filterTiers = utilHelper.resolve(this.props.filterValues, `tiers`);
            let filterStages = utilHelper.resolve(this.props.filterValues, `stages`);

            this.setState({
                allFilters: {
                    airline: filterAirlines,
                    tier: filterTiers,
                    stage: filterStages
                },
                allLicenseOptions: airlineOptionData,
                licenseOptions: airlineOptionData
            });
        }
    }

    async handleFilterChange() {
        this.setState({ selectedFilters: this.props.appliedFilters, appliedFilters: this.props.appliedFilters }, () => {
            this.toggleApplyFilterButton();
        });
    }

    getSelectedFiltersFromStore() {
        let storeFilterKey = this.props.active
            ? 'pac_next_insights_active_license_selected_filters'
            : 'pac_next_insights_inactive_license_selected_filters';
        let selectedFiltersFromStore = JSON.parse(window.sessionStorage.getItem(storeFilterKey));
        if (selectedFiltersFromStore) {
            this.setState(selectedFiltersFromStore);
            this.sendAppliedFilters(false);
        }
    }

    handleCheckBoxChange(checkBoxType, e) {
        const item = e.target.name;

        let filters = this.state.selectedFilters;
        let checkboxChecked = false;
        for (let i = 0; i < filters[checkBoxType].length; i++) {
            if (filters[checkBoxType][i] === item) {
                checkboxChecked = true;
                break;
            }
        }

        if (!checkboxChecked) {
            filters[checkBoxType].push(item);
        } else {
            for (let i = 0; i < filters[checkBoxType].length; i++) {
                if (filters[checkBoxType][i] === item) {
                    filters[checkBoxType].splice(i, 1);
                }
            }
        }

        this.setState({ selectedFilters: filters }, () => {
            this.setAllFilterValues();
        });

        this.toggleApplyFilterButton();
    }

    handleChipChange(filterType, value, appliedFilters) {
        let selectedFilters = this.state.selectedFilters;
        for (let i = 0; i < selectedFilters[filterType].length; i++) {
            if (selectedFilters[filterType][i] === value) {
                selectedFilters[filterType].splice(i, 1);
            }
        }
        this.setState(
            {
                selectedFilters: selectedFilters,
                appliedFilters: appliedFilters
            },
            () => {
                this.toggleApplyFilterButton();
                LicenseFilterHelper.setSelectedFiltersToStore(this.state, this.props.active);
            }
        );
    }

    handleAirlineChange = (selectedOption) => {
        let filters = this.state.selectedFilters;
        let index = filters.airline.indexOf(selectedOption);
        if (index >= 0) {
            for (let i = 0; i < filters.airline.length; i++) {
                if (filters.airline[i] === selectedOption) {
                    filters.airline.splice(i, 1);
                }
            }
        } else {
            filters.airline.push(selectedOption);
        }
        this.setState({ selectedFilters: filters });
        this.toggleApplyFilterButton();
    };

    handleAirlineFilter = (selectedOption) => {
        if (this.state.allLicenseOptions && Object.keys(this.state.allLicenseOptions).length > 0 && selectedOption) {
            var filteredAirlines = this.state.allLicenseOptions.filter(function (el) {
                return el.value.toLowerCase().includes(selectedOption.toLowerCase());
            });
            this.setState({ licenseOptions: filteredAirlines });
        } else {
            this.setState({ licenseOptions: this.state.allLicenseOptions });
        }
    };

    clearAirlineFilter = () => {
        let filters = this.state.selectedFilters;
        filters.airline = [];
        this.setState({
            licenseOptions: this.state.allLicenseOptions,
            selectedFilters: filters,
            multiSelectFilterLabel: 'Select Airline'
        });
        this.toggleApplyFilterButton();
    };

    toggleApplyFilterButton() {
        if (this.state.selectedFilters) {
            let disableApplyButton = true;
            let disableClearButton = true;
            for (let [key, value] of Object.entries(this.state.selectedFilters)) {
                if (value.length > 0) {
                    disableApplyButton = false;
                    disableClearButton = false;
                    break;
                }
            }

            if (disableClearButton) {
                for (let [key, value] of Object.entries(this.state.appliedFilters)) {
                    if (value.length > 0) {
                        disableClearButton = false;
                        break;
                    }
                }
            }

            this.setState({
                applyFilterButtonDisabled: disableApplyButton,
                clearFilterButtonDisabled: disableClearButton
            });
        }
    }

    handleApplyFilterClick = (e) => {
        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(this.state.selectedFilters));
        let stateToUpdate = { appliedFilters: selectedFiltersDeepClone };

        this.setState(stateToUpdate, () => {
            this.toggleApplyFilterButton();
            this.sendAppliedFilters(true);
        });
    };

    handleClearFilterClick = (e) => {
        let stateToUpdate = {
            appliedFilters: {},
            selectedFilters: { airline: [], tier: [], stage: [] }
        };

        this.setState(stateToUpdate, () => {
            this.toggleApplyFilterButton();
            this.sendAppliedFilters(true);
        });
    };

    sendAppliedFilters(toggleAirlineFilterDropdown) {
        LicenseFilterHelper.setSelectedFiltersToStore(this.state, this.props.active);
        this.props.onFilterSelectionUpdated(this.state.appliedFilters, toggleAirlineFilterDropdown);
    }

    render() {
        return (
            <React.Fragment>
                <Card.Body>
                    <Row>
                        <Col>
                            <ButtonPrimary
                                disabled={this.state.applyFilterButtonDisabled}
                                className="button-primary"
                                onClick={this.handleApplyFilterClick}
                            >
                                Apply Filter
                            </ButtonPrimary>{' '}
                            <ButtonSecondary
                                disabled={this.state.clearFilterButtonDisabled}
                                className="button-secondary"
                                onClick={this.handleClearFilterClick}
                            >
                                Clear All
                            </ButtonSecondary>{' '}
                        </Col>
                    </Row>

                    <Row>
                        {utilHelper.resolve(this.state.allFilters, `airline`) &&
                        this.state.allFilters.airline.length > 0 ? (
                            <Col xs={12} md={4} lg={3}>
                                <div className="margin-top-20">
                                    <Card.Title>Airline</Card.Title>
                                    <div className="admin-airline-select">
                                        <DropdownMultiple
                                            label={'Select Airline'}
                                            options={this.state.licenseOptions}
                                            selected={
                                                this.state.selectedFilters && this.state.selectedFilters.airline
                                                    ? this.state.selectedFilters.airline
                                                    : []
                                            }
                                            onChange={this.handleAirlineChange}
                                            filterLabel={this.state.multiSelectFilterLabel}
                                            onFilter={this.handleAirlineFilter}
                                            onClear={this.clearAirlineFilter}
                                        />
                                    </div>
                                </div>
                            </Col>
                        ) : (
                            ''
                        )}

                        {utilHelper.resolve(this.state.allFilters, `tier`) && this.state.allFilters.tier.length > 0 ? (
                            <Col xs={12} md={4} lg={3}>
                                {this.state.allFilters.tier.length > 0 ? (
                                    <div className="margin-top-20">
                                        <Card.Title>Tier</Card.Title>
                                        <Card>
                                            <ul className="ul-tiers">
                                                {this.state.allFilters.tier.map((item, index) => (
                                                    <li key={index + '_li_tier'} data-item={item}>
                                                        <Checkbox
                                                            name={item}
                                                            key={item}
                                                            style={{ float: 'left' }}
                                                            marked={
                                                                this.state.selectedFilters &&
                                                                this.state.selectedFilters.tier &&
                                                                this.state.selectedFilters.tier.includes(item)
                                                                    ? CheckboxSelectVariant.selected
                                                                    : CheckboxSelectVariant.unselected
                                                            }
                                                            onChange={(e) => this.handleCheckBoxChange('tier', e)}
                                                        />
                                                        <div className="checkbox-title">{item}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Card>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Col>
                        ) : (
                            ''
                        )}

                        {this.props.stage === 'evaluation' &&
                        utilHelper.resolve(this.state.allFilters, `stage`) &&
                        this.state.allFilters.stage.length > 0 ? (
                            <Col xs={12} md={4} lg={3}>
                                {this.state.allFilters.stage.length > 0 ? (
                                    <div className="margin-top-20">
                                        <Card.Title>Stage</Card.Title>
                                        <Card>
                                            <ul className="ul-stages">
                                                {this.state.allFilters.stage.map((item, index) => (
                                                    <li key={index + '_li_stage'} data-item={item}>
                                                        <Checkbox
                                                            name={item}
                                                            key={item}
                                                            style={{ float: 'left' }}
                                                            marked={
                                                                this.state.selectedFilters &&
                                                                this.state.selectedFilters.stage &&
                                                                this.state.selectedFilters.stage.includes(
                                                                    item.toString()
                                                                )
                                                                    ? CheckboxSelectVariant.selected
                                                                    : CheckboxSelectVariant.unselected
                                                            }
                                                            onChange={(e) => this.handleCheckBoxChange('stage', e)}
                                                        />
                                                        <div className="checkbox-title">{item}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Card>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>
                </Card.Body>
            </React.Fragment>
        );
    }
}

export default LicenseFilter;
