import Analytics from 'analytics';
import smartQueue from '@analytics/queue-utils';
import { Auth } from 'aws-amplify';
import AnalyticsService from '../../services/AnalyticsService';
import * as AppConstants from '../../constants/app/constants';

let InsightsAnalytics = {
    interactionsQueue: '',
    pageSessionsQueue: '',
    analytics: '',
    selectedTenantIcao: '',
    homeTenantIcao: '',
    selectedTenantName: '',
    selectedTier: '',
    selectedPage: '',
    analyticsService: new AnalyticsService(),
    sessionStartTime: '',

    interactionsQueueOptions: {
        max: 30, // limit
        interval: 30000, // 30s
        throttle: true // Ensure only max is processed at interval
    },

    lastInteractionTime() {
        return window.localStorage.getItem('lastInteractionTime');
    },

    sessionId() {
        return window.localStorage.getItem('sessionId');
    },

    pageSessionsQueueOptions: {
        max: 30, // limit
        interval: 30000, // 30s
        throttle: true // Ensure only max is processed at interval
    },

    /* 
      This method is called after configured 'interval' in options, or when the queue size reaches the configured 'max' in options. 
      The 'items' are the popped values from the queue.
     */
    interactionsQueueCallback(items, restOfQueue) {
        this.analyticsService.postInteractionsData(items);
    },

    /* 
      This method is called after configured 'interval' in options, or when the queue size reaches the configured 'max' in options. 
      The 'items' are the popped values from the queue.
     */
    pageSessionsQueueCallback(items, restOfQueue) {
        this.analyticsService.postPageSessionsData(items);
    },

    initialize() {
        if (this.sessionId() === null) {
            this.getSessionId();
        } else {
            Auth.currentSession().then((currentSession) => {
                this.homeTenantIcao = currentSession.idToken.payload['pac_icao_code'];
            });
        }

        if (this.analytics === '') {
            this.sessionStartTime = Date.now();

            this.interactionsQueueCallback = this.interactionsQueueCallback.bind(this);

            this.interactionsQueue = smartQueue(this.interactionsQueueCallback, this.interactionsQueueOptions);

            this.pageSessionsQueueCallback = this.pageSessionsQueueCallback.bind(this);

            this.pageSessionsQueue = smartQueue(this.pageSessionsQueueCallback, this.pageSessionsQueueOptions);

            this.analytics = Analytics({
                app: 'insights',
                plugins: [
                    {
                        name: 'insights',
                        //custom methods
                        methods: {
                            pageSession: async (type, pageName = null) => {
                                if (this.sessionId() === null) {
                                    await new Promise((r) => setTimeout(r, 3000));
                                }
                                if (type === 'start') {
                                    if (this.selectedTenantIcao !== AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) {
                                        let pageObject = {
                                            selected_tenant_icao: this.selectedTenantIcao,
                                            selected_tenant_name: this.selectedTenantName,
                                            tier: this.selectedTier,
                                            session_id: this.sessionId(),
                                            tenant_icao: this.homeTenantIcao
                                        };
                                        pageObject['page_name'] = pageName;
                                        pageObject['start_time'] = Date.now();
                                        this.selectedPage = pageObject;
                                    } else {
                                        this.selectedPage = '';
                                    }

                                    window.localStorage.setItem('lastInteractionTime', Date.now());
                                } else if (type === 'end') {
                                    if (this.selectedPage !== '') {
                                        this.selectedPage['page_duration'] =
                                            (Date.now() - this.selectedPage['start_time']) / 1000;
                                        delete this.selectedPage['start_time'];
                                        this.pageSessionsQueue.push(this.selectedPage);
                                    }
                                }
                            },

                            updateTier: (subscriptionLevel) => {
                                this.selectedTier = subscriptionLevel === 'Basic' ? 1 : 2;
                            },

                            updateTenant: (tenantIcao, tenantName) => {
                                this.selectedTenantIcao = tenantIcao;
                                this.selectedTenantName = tenantName;

                                if (this.selectedPage !== '') {
                                    if (
                                        this.selectedPage['selected_tenant_icao'] === '' ||
                                        this.selectedPage['selected_tenant_name'] === ''
                                    ) {
                                        this.selectedPage['selected_tenant_icao'] = tenantIcao;
                                        this.selectedPage['selected_tenant_name'] = tenantName;
                                    }
                                }
                            }
                        },
                        //built-in method
                        track: async ({ payload }) => {
                            console.log('track event payload', payload);

                            if (this.sessionId() === null) {
                                await new Promise((r) => setTimeout(r, 3000));
                            }

                            //queue all interaction events
                            if (this.selectedTenantIcao !== AppConstants.TENANT_ICAO_PANASONIC_AVIONICS) {
                                this.interactionsQueue.push({
                                    session_id: this.sessionId(),
                                    selected_tenant_icao: this.selectedTenantIcao,
                                    selected_tenant_name: this.selectedTenantName,
                                    tier: this.selectedTier,
                                    page_name: this.selectedPage['page_name'],
                                    visual_name: payload.properties.visual_name,
                                    interaction_type: payload.properties.interaction_type,
                                    interaction_value: payload.properties.interaction_value,
                                    tenant_icao: this.homeTenantIcao
                                });
                            }

                            window.localStorage.setItem('lastInteractionTime', Date.now());
                        }
                    }
                ]
            });

            window.addEventListener('beforeunload', (event) => {
                event.preventDefault();
                return this.handleUnloadEvent();
            });

            let sessionsCount = window.localStorage.getItem('sessionsCount');
            if (sessionsCount) {
                sessionsCount = parseInt(sessionsCount, 10) + 1;
            } else {
                sessionsCount = 1;
            }
            window.localStorage.setItem('sessionsCount', sessionsCount);
        }
    },

    handleUnloadEvent(signout = false) {
        //end current page session
        if (this.selectedPage !== '') {
            this.selectedPage['page_duration'] = (Date.now() - this.selectedPage['start_time']) / 1000;
            delete this.selectedPage['start_time'];
            let pageObjects = [this.selectedPage];
            this.analyticsService.postPageSessionsData(pageObjects, true);
        }

        let sessionsCount = window.localStorage.getItem('sessionsCount');
        sessionsCount = parseInt(sessionsCount, 10) - 1;

        if (sessionsCount <= 0 || signout) {
            //end user session
            let sessionObject = {
                method: 'end',
                session_id: this.sessionId()
            };
            this.analyticsService.startEndUserSession(sessionObject, true);

            //remove session ID
            window.localStorage.removeItem('sessionId');
            window.localStorage.setItem('sessionsCount', 0);
        } else if (!isNaN(sessionsCount)) {
            window.localStorage.setItem('sessionsCount', sessionsCount);
        }

        return null;
    },

    getSessionId() {
        Auth.currentSession()
            .then(async (currentSession) => {
                this.selectedTenantName = currentSession.idToken.payload['pac_tenant_name'];
                this.selectedTenantIcao = currentSession.idToken.payload['pac_icao_code'];
                this.homeTenantIcao = currentSession.idToken.payload['pac_icao_code'];
                let tenantId = currentSession.idToken.payload['pac_tenant_id'];
                let userId = currentSession.idToken.payload['email'];

                let sessionObject = {
                    method: 'create',
                    tenant_id: tenantId,
                    tenant_icao: this.selectedTenantIcao,
                    tenant_name: this.selectedTenantName,
                    tenant_user_id: userId
                };

                let sessionResponse = await this.analyticsService.startEndUserSession(
                    sessionObject,
                    false,
                    tenantId,
                    this.selectedTenantIcao
                );
                window.localStorage.setItem('sessionId', sessionResponse.response.data.session_id);
            })
            .catch((err) => {
                console.log(err);
            });
    }
};

export default InsightsAnalytics;
