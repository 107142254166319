import React from 'react';
import { Row } from 'reactstrap';
import _, { debounce } from 'lodash';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import ErrorBoundary from 'util/ErrorBoundary.js';
import CarouselWidget from 'components/carousel/CarouselWidget';
import * as AppConstants from 'constants/app/constants';
import CarouselConfig from 'components/carousel/CarouselConfig.js';
import { filterSectionsByTier } from 'components/widgets/AccessControl';
import UtilHelper from 'util/UtilHelper.js';
import FiltersService from '../../services/FiltersService';
import SubscriptionService from 'services/SubscriptionService';

const carouselConfig = new CarouselConfig();

class CarouselContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: '',
            loading: true,
            error: false,
            requestedSections: []
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) ||
            prevProps.path !== this.props.path ||
            this.props.forceReloadAll
        ) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        this.setState({ loading: true });

        if (FiltersService.dateRangeFiltersData !== '') {
            const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));
            selectedFiltersDeepClone.requested_sections = ['titles'];
            selectedFiltersDeepClone.content_types = [this.props.contentType];

            if (SubscriptionService.subscription() !== '') {
                selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
            }

            if (this.props.app) {
                selectedFiltersDeepClone['app'] = this.props.app;
            }

            if (this.cancelRequestSource) {
                this.cancelRequestSource.cancel('Operation canceled by the user.');
            }

            this.throttleFetch(selectedFiltersDeepClone);
        }
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchNextInsightsMetricsAxios(
                selectedFiltersDeepClone,
                carouselConfig.getAPIPath(),
                this.cancelRequestSource.token
            )
            .then((response) => {
                if (!response && !response.requested_sections) {
                    this.setState({ error: true, dataset: [], loading: false });
                } else {
                    this.setState({ error: false, dataset: response.requested_sections, loading: false });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, loading: false });
                } else {
                    this.setState({ error: true, dataset: [], loading: false });
                }
            });
    }

    renderContentTitles() {
        let { dataset, loading } = this.state;
        let dropdownList = carouselConfig.getDropdownList(this.props.contentType);

        dropdownList = dropdownList.filter((option) => {
            let filteredList = filterSectionsByTier(this.props.page, [option.section]);
            if (filteredList.length > 0) {
                return true;
            } else {
                return false;
            }
        });

        let utilHelper = new UtilHelper();
        dropdownList = utilHelper.updateDropdownListLabels(dropdownList, dataset);

        return (
            <Row>
                <div className="align-component card">
                    <ErrorBoundary>
                        <CarouselWidget
                            key={'content_tiles_' + this.props.contentType}
                            type={'content_tiles_' + this.props.contentType}
                            defaultKey1="most_popular"
                            defaultKey2="total_views"
                            data={dataset ? dataset : []}
                            title={this.props.title}
                            page={this.props.page}
                            pageName={this.props.pageName}
                            dd1Options={dropdownList}
                            loading={loading}
                            workbench_type={this.props.workbenchTitle}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            appliedFilters={this.props.appliedFilters.api}
                            contentType={this.props.contentType}
                            app={this.props.app}
                        />
                    </ErrorBoundary>
                </div>
            </Row>
        );
    }

    render() {
        return <React.Fragment>{this.renderContentTitles()}</React.Fragment>;
    }
}

export default CarouselContainer;
