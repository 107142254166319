
const LicenseFilterHelper = {
    getFilteredLicenses: function (licenses, appliedFilters) {
        let filteredLicenses = JSON.parse(JSON.stringify(licenses));

        if (appliedFilters.airline && appliedFilters.airline.length > 0) {
            filteredLicenses = filteredLicenses.filter((item) => {
                return appliedFilters.airline.includes(item.airline_name);
            });
        }

        if (appliedFilters.tier && appliedFilters.tier.length > 0) {
            filteredLicenses = filteredLicenses.filter((item) => {
                return appliedFilters.tier.includes(item.subscription_tier);
            });
        }

        if (appliedFilters.stage && appliedFilters.stage.length > 0) {
            filteredLicenses = filteredLicenses.filter((item) => {
                return appliedFilters.stage.includes(item.stage.toString());
            });
        }

        return filteredLicenses;
    },

    arrayMatch: function (arr1, arr2) {
        let arr = [];
        for (let i = 0; i < arr1.length; ++i) {
            for (var j = 0; j < arr2.length; ++j) {
                if (arr1[i] === arr2[j]) {
                    arr.push(arr1[i]);
                }
            }
        }

        return arr.length > 0;
    },

    hasFilters: function (filters) {
        return !!(
            (filters.airline && Array.isArray(filters.airline) && filters.airline.length) ||
            (filters.tier && Array.isArray(filters.tier) && filters.tier.length) ||
            (filters.stage && Array.isArray(filters.stage) && filters.stage.length)
        );
    },

    hasFilterValues: function (filter) {
        return !!(
            (filter.airlines && Array.isArray(filter.airlines) && filter.airlines.length) ||
            (filter.tiers && Array.isArray(filter.tiers) && filter.tiers.length)
        );
    },

    hasAppliedFilterValues: function (filter) {
        return !!(
            (filter.airline && Array.isArray(filter.airline) && filter.airline.length) ||
            (filter.tier && Array.isArray(filter.tier) && filter.tier.length) ||
            (filter.stage && Array.isArray(filter.stage) && filter.stage.length)
        );
    },

    getDisplayNames: function () {
        return {
            airline: 'Airline',
            tier: 'Tier',
            stage: 'Stage'
        };
    },

    setSelectedFiltersToStore: function (state, active) {
        let selectedFiltersToStore = {
            //expanded: false,
            applyFilterButtonDisabled: true,
            clearFilterButtonDisabled: true,
            selectedFilters: {
                airline: [],
                tier: [],
                stage: []
            },
            appliedFilters: {}
        };

        if (state) {
            selectedFiltersToStore.applyFilterButtonDisabled = state.applyFilterButtonDisabled;
            selectedFiltersToStore.clearFilterButtonDisabled = state.clearFilterButtonDisabled;
            selectedFiltersToStore.selectedFilters = state.selectedFilters;
            selectedFiltersToStore.appliedFilters = state.appliedFilters;
        }

        let storeFilterKey = active
            ? 'pac_next_insights_active_license_selected_filters'
            : 'pac_next_insights_inactive_license_selected_filters';

        window.sessionStorage.setItem(storeFilterKey, JSON.stringify(selectedFiltersToStore));
    },

    isAppliedFilterNotExist: function (filterType, value, filterValues) {
        let filterNotExists = false;

        if (filterType && value && filterValues) {
            const filterKeys = {
                airline: 'airlines',
                tier: 'tiers',
                stage: 'stages'
            };

            if (
                filterKeys[filterType] &&
                filterValues[filterKeys[filterType]] &&
                filterValues[filterKeys[filterType]].data
            ) {
                let data = filterValues[filterKeys[filterType]].data;

                let itemExists = data.some((item) => {
                    if (item.value === value) {
                        return true;
                    } else {
                        return false;
                    }
                });
                filterNotExists = !itemExists;
            }
        }

        return filterNotExists;
    },

    getCellValue: function (valuesArray) {
        if (Array.isArray(valuesArray)) {
            let values = '';
            for (let i = 0; i < valuesArray.length; i++) {
                if (i > 0) {
                    values += '<br/>';
                }
                values += valuesArray[i];
            }
            return values;
        }
        return '\u0020';
    },

    getValuesByKey: function (key, jsonArray) {
        let values = [];
        for (let i = 0; i < jsonArray.length; i++) {
            values.push(jsonArray[i][key]);
        }
        return values;
    }
};

export default LicenseFilterHelper;
