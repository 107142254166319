export default class PlaneConfig {
    getAPIPath = function () {
        return '/v2/metrics/cabin_class_data';
    };

    getCabinClassRequestedSections = function (reportType) {
        let requestedSections;

        switch (reportType) {
            case 'Movies':
                requestedSections = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Movies Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Avg. Views per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_views_per_flight',
                        text: 'Movies Avg. Views per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'total_time',
                        text: 'Movies Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Viewing Time',
                        unit: 'hr',
                        value: 'avg_time',
                        text: 'Movies Avg. Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'ExcelMovies':
                requestedSections = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Movies Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Avg. Views per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_views_per_flight',
                        text: 'Movies Avg. Views per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'total_time',
                        text: 'Movies Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Viewing Time',
                        unit: 'hr',
                        value: 'avg_time',
                        text: 'Movies Avg. Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'TV Series':
                requestedSections = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'total_views',
                        text: 'TV Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Avg. Views per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_views_per_flight',
                        text: 'TV Avg. Views per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'total_time',
                        text: 'TV Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Viewing Time',
                        unit: 'hr',
                        value: 'avg_time',
                        text: 'TV Avg. Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'ExcelTV Series':
                requestedSections = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Tv Series Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Avg. Views per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_views_per_flight',
                        text: 'Tv Series Avg. Views per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'total_time',
                        text: 'Tv Series Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Viewing Time',
                        unit: 'hr',
                        value: 'avg_time',
                        text: 'Tv Series Avg. Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Audio':
                requestedSections = [
                    {
                        label: 'Total Listens',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Audio Total Listens',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Avg. Listens per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_views_per_flight',
                        text: 'Audio Avg. Listens per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Listening Time',
                        unit: 'hr',
                        value: 'total_time',
                        text: 'Audio Total Listening Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Listening Time',
                        unit: 'hr',
                        value: 'avg_time',
                        text: 'Audio Avg. Listening Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'ExcelAudio':
                requestedSections = [
                    {
                        label: 'Total Listens',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Audio Total Listens',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Avg. Listens per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_views_per_flight',
                        text: 'Audio Avg. Listens per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Listening Time',
                        unit: 'hr',
                        value: 'total_time',
                        text: 'Audio Total Listening Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Listening Time',
                        unit: 'hr',
                        value: 'avg_time',
                        text: 'Audio Avg. Listening Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Games':
                requestedSections = [
                    {
                        label: 'Total Plays',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Games Total Plays',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Avg. Plays per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_views_per_flight',
                        text: 'Games Avg. Plays per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Playing Time',
                        unit: 'hr',
                        value: 'total_time',
                        text: 'Games Total Playing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Playing Time',
                        unit: 'hr',
                        value: 'avg_time',
                        text: 'Games Avg. Playing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'ExcelGames':
                requestedSections = [
                    {
                        label: 'Total Plays',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Games Total Plays',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Avg. Plays per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_views_per_flight',
                        text: 'Games Avg. Plays per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Playing Time',
                        unit: 'hr',
                        value: 'total_time',
                        text: 'Games Total Playing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Playing Time',
                        unit: 'hr',
                        value: 'avg_time',
                        text: 'Games Avg. Playing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Live TV':
                requestedSections = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Live TV Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Top Channel by Views',
                        unit: 'string',
                        value: 'top_titles',
                        text: 'Live TV Top Channel',
                        free_tier: 1
                    },
                    {
                        label: 'Avg. Views per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_views_per_flight',
                        text: 'Live TV Avg. Views per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'total_time',
                        text: 'Live TV Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Viewing Time',
                        unit: 'hr',
                        value: 'avg_time',
                        text: 'Live TV Avg. Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'ExcelLive TV':
                requestedSections = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Live Tv Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Top Channel by Views',
                        unit: 'string',
                        value: 'top_titles',
                        text: 'Live TV Top Channel',
                        free_tier: 1
                    },
                    {
                        label: 'Avg. Views per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_views_per_flight',
                        text: 'Live Tv Avg. Views per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'total_time',
                        text: 'Live Tv Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Viewing Time',
                        unit: 'hr',
                        value: 'avg_time',
                        text: 'Live Tv Avg. Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Electronic Reader':
                requestedSections = [
                    {
                        label: 'Total Title Views',
                        unit: 'count',
                        value: 'total_title_views',
                        text: 'eReader Total Title Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Readers',
                        unit: 'count',
                        value: 'total_readers',
                        text: 'eReader Total Readers',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Readers per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_readers',
                        text: 'eReader Avg. Readers per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Reading Time',
                        unit: 'hr',
                        value: 'total_reading_time',
                        text: 'eReader Total Reading Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Reading Time',
                        unit: 'hr',
                        value: 'avg_reading_time',
                        text: 'eReader Avg. Reading Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Page Reads',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_page_reads',
                        text: 'eReader Avg. Page Reads',
                        free_tier: 0
                    }
                ];
                break;
            case 'ExcelElectronic Reader':
                requestedSections = [
                    {
                        label: 'Total Title Views',
                        unit: 'count',
                        value: 'total_title_views',
                        text: 'eReader Total Title Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Readers',
                        unit: 'count',
                        value: 'total_readers',
                        text: 'eReader Total Readers',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Readers per Flight',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_readers',
                        text: 'eReader Avg. Readers per Flight',
                        free_tier: 0
                    },
                    {
                        label: 'Total Reading Time',
                        unit: 'hr',
                        value: 'total_reading_time',
                        text: 'eReader Total Reading Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Reading Time',
                        unit: 'hr',
                        value: 'avg_reading_time',
                        text: 'Ereader Avg. Viewing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Avg. Page Reads',
                        unit: 'count',
                        precision: '1',
                        value: 'avg_page_reads',
                        text: 'eReader Avg. Page Reads',
                        free_tier: 0
                    }
                ];
                break;
            case 'Bluetooth':
                requestedSections = [
                    {
                        label: 'Paired Devices',
                        unit: 'count',
                        value: 'paired_devices',
                        text: 'Bluetooth Paired Devices',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Connections',
                        unit: 'count',
                        value: 'connections',
                        text: 'Bluetooth Connections',
                        free_tier: 1
                    },
                    {
                        label: 'Disconnections',
                        unit: 'count',
                        precision: '1',
                        value: 'disconnections',
                        text: 'Bluetooth Disconnections',
                        free_tier: 1
                    },
                    {
                        label: 'Unique Passengers Used Bluetooth',
                        unit: 'count',
                        precision: '1',
                        value: 'bt_users',
                        text: 'Unique Passengers Used Bluetooth',
                        free_tier: 1
                    }
                ];
                break;
            case 'ExcelBluetooth':
                requestedSections = [
                    {
                        label: 'Paired Devices',
                        unit: 'count',
                        value: 'paired_devices',
                        text: 'Bluetooth Paired Devices',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Connections',
                        unit: 'count',
                        value: 'connections',
                        text: 'Bluetooth Connections',
                        free_tier: 1
                    },
                    {
                        label: 'Disconnections',
                        unit: 'count',
                        value: 'disconnections',
                        text: 'Bluetooth Disconnections',
                        free_tier: 1
                    },
                    {
                        label: 'Unique Passengers Used Bluetooth',
                        short_label: 'Unique Passengers Used BT',
                        unit: 'count',
                        value: 'bt_users',
                        text: 'Unique Passengers Used Bluetooth',
                        free_tier: 1
                    }
                ];
                break;
            default:
                break;
        }

        return requestedSections;
    };
}
