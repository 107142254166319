import React from 'react';
import { Row } from 'reactstrap';
import _, { debounce } from 'lodash';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import ErrorBoundary from 'util/ErrorBoundary.js';
import TrendConfig from 'components/trends/TrendConfig.js';
import UtilHelper from 'util/UtilHelper.js';
import * as AppConstants from 'constants/app/constants';
import TrendWidget from 'components/trends/TrendWidget';
import FiltersService from '../../services/FiltersService';
import { filterSectionsByTier } from 'components/widgets/AccessControl';
import SubscriptionService from 'services/SubscriptionService';

const trendConfig = new TrendConfig();

class TrendContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: '',
            loading: true,
            requestedSections: []
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) ||
            prevProps.path !== this.props.path ||
            this.props.forceReloadAll
        ) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        this.setState({ loading: true });

        if (FiltersService.dateRangeFiltersData !== '') {
            let selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));
            let reportSubType = '';
            if (this.props.contentType) {
                selectedFiltersDeepClone.content_types = [this.props.contentType];
                reportSubType = this.props.contentType;
            } else if (this.props.usageSource) {
                if (
                    this.props.usageSource !== 'EntertainmentTrends' &&
                    this.props.usageSource !== 'ApplicationTrends'
                ) {
                    selectedFiltersDeepClone.usage_source = this.props.usageSource;
                }
                reportSubType = this.props.usageSource;
            }

            let requestedSections = trendConfig.getTrendChartRequestedSections(reportSubType);
            if (!this.props.showWorkbenchLink) {
                requestedSections = requestedSections.filter((requestedSection) => requestedSection.free_tier === 1);
            }

            if (
                this.props.usageSource &&
                this.props.usageSource === 'EntertainmentTrends' &&
                this.props.allowedApps &&
                this.props.allowedApps.length > 0
            ) {
                requestedSections = requestedSections.filter((requestedSection) =>
                    this.props.allowedApps.includes(requestedSection.title)
                );
            }

            selectedFiltersDeepClone.requested_sections = requestedSections.map(function (section) {
                return section['key'];
            });

            if (this.props.usageSource === 'EntertainmentTrends' || this.props.usageSource === 'ApplicationTrends') {
                selectedFiltersDeepClone.content_types = selectedFiltersDeepClone.requested_sections;
            }

            if (this.props.usageSource === 'Companion App') {
                delete selectedFiltersDeepClone.usage_source;
                selectedFiltersDeepClone.launched_by = ['Companion App'];
            }

            if (SubscriptionService.subscription() !== '') {
                selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
            }

            if (this.props.app) {
                selectedFiltersDeepClone['app'] = this.props.app;
            }

            if (this.cancelRequestSource) {
                this.cancelRequestSource.cancel('Operation canceled by the user.');
            }

            this.throttleFetch(selectedFiltersDeepClone, requestedSections);
        }
    }

    async throttleFetch(selectedFiltersDeepClone, requestedSections) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        if (this.props.usageSource === 'EntertainmentTrends' || this.props.usageSource === 'ApplicationTrends') {
            delete selectedFiltersDeepClone.requested_sections;
            await service
                .fetchMyInsightsTrendsMetricsAxios(
                    selectedFiltersDeepClone,
                    trendConfig.getApplicationsAPIPath(),
                    this.cancelRequestSource.token
                )
                .then((response) => {
                    if (!response && !response.content_types) {
                        this.setState({ dataset: [], loading: false });
                    } else {
                        this.setState({
                            dataset:
                                this.props.usageSource === 'EntertainmentTrends'
                                    ? response.content_types['entertainment']
                                    : response.content_types['applications'],
                            loading: false,
                            requestedSections: requestedSections
                        });
                    }
                })
                .catch((error) => {
                    if (error && error.error && error.error === 'Operation canceled by the user.') {
                        this.setState({ error: false, loading: false });
                    } else {
                        this.setState({ dataset: [], loading: false });
                    }
                });
        } else {
            await service
                .fetchNextInsightsMetricsAxios(
                    selectedFiltersDeepClone,
                    trendConfig.getAPIPath(),
                    this.cancelRequestSource.token
                )
                .then((response) => {
                    if (!response && !response.requested_sections) {
                        this.setState({ dataset: [], loading: false });
                    } else {
                        if (response.requested_sections['applications']) {
                            this.setState({
                                dataset: response.requested_sections['applications'],
                                loading: false,
                                requestedSections: requestedSections
                            });
                        } else {
                            this.setState({
                                dataset: response.requested_sections,
                                loading: false,
                                requestedSections: requestedSections
                            });
                        }
                    }
                })
                .catch((error) => {
                    if (error && error.error && error.error === 'Operation canceled by the user.') {
                        this.setState({ error: false, loading: false });
                    } else {
                        this.setState({ dataset: [], loading: false });
                    }
                });
        }
    }

    getTopBottom5ApplicationTrends(dataset, metric) {
        let top5 = Object.keys(dataset[metric]).slice(0, 5);
        let bottom5 = Object.keys(dataset[metric]).slice(-5).reverse();

        let top5Obj = {};
        let bottom5Obj = {};

        for (let key in top5) {
            top5Obj[top5[key]] = { ...dataset[metric][top5[key]] };
        }
        for (let key in bottom5) {
            bottom5Obj[bottom5[key]] = { ...dataset[metric][bottom5[key]] };
        }

        dataset['top_5_' + metric] = { ...top5Obj };
        dataset['bottom_5_' + metric] = { ...bottom5Obj };
    }

    renderChart() {
        let { dataset, loading, requestedSections } = this.state;

        let reportSubType = '';
        if (this.props.contentType) {
            reportSubType = this.props.contentType;
        } else if (this.props.usageSource) {
            reportSubType = this.props.usageSource;
        }

        let dropdownList = [];
        let standardDropdownList = [];
        let smallCountDropdownList = [];
        let useTop5Views = dataset && dataset['total_views'] && Object.keys(dataset['total_views']).length > 5;
        let useTop5Time =
            dataset && dataset['total_viewing_time'] && Object.keys(dataset['total_viewing_time']).length > 5;
        if (this.props.title === 'Application Trends' || this.props.title === 'Entertainment Trends') {
            dropdownList = trendConfig.getDropdownList(this.props.title);

            dropdownList = dropdownList.filter((option) => {
                let filteredList = filterSectionsByTier(this.props.page, [option.section]);
                if (filteredList.length > 0) {
                    return true;
                } else {
                    return false;
                }
            });

            if (this.props.title === 'Application Trends') {
                if (useTop5Views) {
                    this.getTopBottom5ApplicationTrends(dataset, 'total_views');
                }
                if (useTop5Time) {
                    this.getTopBottom5ApplicationTrends(dataset, 'total_viewing_time');
                }

                if (dataset) {
                    let utilHelper = new UtilHelper();
                    standardDropdownList = utilHelper.updateDropdownListLabels(dropdownList, dataset, '', true);
                    smallCountDropdownList = [...dropdownList];
                    dropdownList.push(...standardDropdownList);
                }
            }
        }

        let tabData = [];
        let tabDataBySections = {};

        if (dropdownList.length > 0) {
            dropdownList.forEach((option) => {
                let tabData = [];
                if (dataset) {
                    let allTabsData = dataset[option.value];
                    for (let key in allTabsData) {
                        let data = allTabsData[key];
                        let tab = JSON.parse(JSON.stringify(option));

                        let trendData = data;
                        tab.value = trendData.hasOwnProperty('value') ? trendData.value : null;
                        tab.comparison_value = trendData.hasOwnProperty('comparison_value')
                            ? trendData.comparison_value
                            : null;
                        tab.graph = trendData.graph;
                        tab.longText = trendData.key;
                        tab.comparison_raw_value = trendData.hasOwnProperty('comparison_raw_value')
                            ? trendData.comparison_raw_value
                            : null;

                        if (this.props.usageSource === 'EntertainmentTrends') {
                            let sectionObj = requestedSections.find((obj) => obj.key === key);
                            tab.title = sectionObj['title'];
                        } else {
                            // Capitalize first letter
                            let keyParts = key.split(' ');
                            if (keyParts.length > 1) {
                                keyParts = keyParts.map((keyStr) => {
                                    return keyStr.charAt(0).toUpperCase() + keyStr.slice(1);
                                });
                                key = keyParts.join(' ');
                            }
                            tab.title = key;
                        }

                        tabData.push(tab);
                    }
                }
                tabDataBySections[option.value] = tabData;
            });
        } else {
            if (dataset) {
                if (requestedSections && requestedSections.length > 0) {
                    let limit = requestedSections.length;

                    for (let i = 0; i < limit; i++) {
                        let tab = JSON.parse(JSON.stringify(requestedSections[i]));
                        tab.value = null;
                        tab.comparison_value = null;
                        if (dataset[requestedSections[i].key]) {
                            let trendData = dataset[requestedSections[i].key];
                            tab.value = trendData.hasOwnProperty('value') ? trendData.value : null;
                            tab.comparison_value = trendData.hasOwnProperty('comparison_value')
                                ? trendData.comparison_value
                                : null;

                            if (reportSubType === 'Live TV' && tab.label === 'Total Viewers') {
                                tab.label = 'Unique Viewers';
                            }
                            tabData.push(tab);
                        }
                    }
                }
            }
        }

        let allTabDataBySections = {};
        if (this.props.title === 'Application Trends') {
            if (useTop5Time || useTop5Views) {
                allTabDataBySections = { ...tabDataBySections };
                let tabSectionData = {};
                for (let key in tabDataBySections) {
                    if (key.includes('5')) {
                        tabSectionData[key] = tabDataBySections[key];
                    }
                }

                tabDataBySections = { ...tabSectionData };

                dropdownList = smallCountDropdownList;
            } else {
                dropdownList = standardDropdownList;
            }
        }

        return (
            <Row className={this.props.className ? this.props.className : ''}>
                <div className="align-component card">
                    <ErrorBoundary>
                        <TrendWidget
                            key={'line_chart_' + reportSubType}
                            data={dataset ? dataset : []}
                            tabData={tabData}
                            tabDataBySections={tabDataBySections}
                            allTabDataBySections={allTabDataBySections}
                            title={this.props.title}
                            page={this.props.page}
                            pageName={this.props.pageName}
                            reportSubType={reportSubType}
                            loading={loading}
                            workbench_type={this.props.workbenchTitle}
                            showWorkbenchLink={this.props.showWorkbenchLink}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            appliedFilters={this.props.appliedFilters.api}
                            app={this.props.app}
                            dropdownOptions={dropdownList}
                        />
                    </ErrorBoundary>
                </div>
            </Row>
        );
    }

    render() {
        return <React.Fragment>{this.renderChart()}</React.Fragment>;
    }
}

export default TrendContainer;
