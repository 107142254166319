export default class DonutChartConfig {
    getAPIPath = function () {
        return '/v2/metrics/engagement_data';
    };

    getDonutRequestedSections = function (path) {
        let sectionList;

        switch (path) {
            case '/':
            case '/app/':
            case '/app':
                sectionList = [
                    {
                        label: 'Entertainment Usage',
                        value: 'passenger_engagement',
                        requested_section: 'passenger_engagement',
                        default_value: 'passenger_engagement.total_views',
                        dropdown: 1,
                        default_show: '',
                        free_tier: 1
                    },
                    {
                        label: 'Passenger Touchpoints',
                        value: 'passenger_touchpoints',
                        requested_section: 'ped_vs_seatback',
                        default_value: 'ped_vs_seatback.total_views',
                        dropdown: 1,
                        default_show: '',
                        free_tier: 1
                    },
                    {
                        label: 'Application Usage',
                        value: 'applications',
                        requested_section: 'applications',
                        default_value: 'applications.total_views',
                        dropdown: 1,
                        default_show: '',
                        free_tier: 1,
                        application: 1
                    }
                ];
                break;
            case '/app/entertainment/movies':
                sectionList = [
                    {
                        label: 'Passenger Touchpoints',
                        value: 'passenger_touchpoints',
                        requested_section: 'ped_vs_seatback',
                        default_value: 'ped_vs_seatback.total_views',
                        dropdown: 1,
                        content_types: ['Movies'],
                        default_show: '',
                        free_tier: 1
                    }
                ];
                break;
            case '/app/entertainment/tv-series':
                sectionList = [
                    {
                        label: 'Passenger Touchpoints',
                        value: 'passenger_touchpoints',
                        requested_section: 'ped_vs_seatback',
                        default_value: 'ped_vs_seatback.total_views',
                        dropdown: 1,
                        content_types: ['TV Series'],
                        default_show: '',
                        free_tier: 1
                    }
                ];
                break;
            case '/app/entertainment/audio':
                sectionList = [
                    {
                        label: 'Passenger Touchpoints',
                        value: 'passenger_touchpoints',
                        requested_section: 'ped_vs_seatback',
                        default_value: 'ped_vs_seatback.total_views',
                        dropdown: 1,
                        content_types: ['Audio'],
                        default_show: '',
                        free_tier: 1
                    }
                ];
                break;
            case '/app/entertainment/games':
                sectionList = [
                    {
                        label: 'Passenger Touchpoints',
                        value: 'passenger_touchpoints',
                        requested_section: 'ped_vs_seatback',
                        default_value: 'ped_vs_seatback.total_views',
                        dropdown: 1,
                        content_types: ['Games'],
                        default_show: '',
                        free_tier: 1
                    }
                ];
                break;
            case '/app/entertainment/live-tv':
                sectionList = [
                    {
                        label: 'Passenger Touchpoints',
                        value: 'passenger_touchpoints',
                        requested_section: 'ped_vs_seatback',
                        default_value: 'ped_vs_seatback.total_views',
                        dropdown: 1,
                        content_types: ['Live TV'],
                        default_show: '',
                        free_tier: 1
                    }
                ];
                break;
            case '/app/entertainment/ereader':
                sectionList = [
                    {
                        label: 'Passenger Touchpoints',
                        value: 'passenger_touchpoints',
                        requested_section: 'ped_vs_seatback',
                        default_value: 'ped_vs_seatback.total_views',
                        dropdown: 1,
                        content_types: ['Electronic Reader'],
                        default_show: '',
                        free_tier: 1
                    }
                ];
                break;
            case '/app/web-portal/seatback':
                sectionList = [
                    {
                        label: 'Entertainment Usage',
                        value: 'passenger_engagement',
                        requested_section: 'passenger_engagement',
                        default_value: 'passenger_engagement.total_views',
                        dropdown: 1,
                        usage_source: 'Seatback',
                        default_show: '',
                        free_tier: 1
                    }
                ];
                break;
            case '/app/web-portal/portal':
                sectionList = [
                    {
                        label: 'Entertainment Usage',
                        value: 'passenger_engagement',
                        requested_section: 'passenger_engagement',
                        default_value: 'passenger_engagement.total_views',
                        dropdown: 1,
                        usage_source: 'Portal',
                        default_show: '',
                        free_tier: 1
                    },
                    {
                        label: 'Device Browsers',
                        value: 'portal_device_browser',
                        requested_section: 'portal_device_browser',
                        default_value: 'portal_device_browser.total_counts',
                        dropdown: 0,
                        usage_source: 'Portal',
                        default_show: '',
                        free_tier: 1
                    },
                    {
                        label: 'Device OS',
                        value: 'portal_device_os',
                        requested_section: 'portal_device_os',
                        default_value: 'portal_device_os.total_counts',
                        dropdown: 0,
                        usage_source: 'Portal',
                        default_show: '',
                        free_tier: 1
                    },
                    {
                        label: 'Device Type',
                        value: 'portal_device_type',
                        requested_section: 'portal_device_type',
                        default_value: 'portal_device_type.total_counts',
                        dropdown: 0,
                        usage_source: 'Portal',
                        default_show: '',
                        free_tier: 1
                    }
                ];
                break;
            case '/app/web-portal/portal-navigation':
                sectionList = [
                    {
                        label: 'Device Browsers',
                        value: 'portal_device_browser',
                        requested_section: 'portal_device_browser',
                        default_value: 'portal_device_browser.total_counts',
                        dropdown: 0,
                        usage_source: 'Portal',
                        default_show: '',
                        free_tier: 1
                    },
                    {
                        label: 'Device OS',
                        value: 'portal_device_os',
                        requested_section: 'portal_device_os',
                        default_value: 'portal_device_os.total_counts',
                        dropdown: 0,
                        usage_source: 'Portal',
                        default_show: '',
                        free_tier: 1
                    },
                    {
                        label: 'Device Type',
                        value: 'portal_device_type',
                        requested_section: 'portal_device_type',
                        default_value: 'portal_device_type.total_counts',
                        dropdown: 0,
                        usage_source: 'Portal',
                        default_show: '',
                        free_tier: 1
                    }
                ];
                break;
            case '/app/web-portal/companion-app':
                sectionList = [
                    {
                        label: 'Media launches by Content Type',
                        value: 'media_launches_by_content_type',
                        requested_section: 'passenger_engagement',
                        default_value: 'passenger_engagement.total_counts',
                        dropdown: 0,
                        usage_source: 'Companion App',
                        default_show: '',
                        free_tier: 1
                    }
                ];
                break;
            default:
                sectionList = [
                    {
                        label: 'Entertainment Usage',
                        value: 'passenger_engagement',
                        requested_section: 'passenger_engagement',
                        default_value: 'passenger_engagement.total_views',
                        dropdown: 0,
                        default_show: '',
                        free_tier: 0
                    }
                ];
                break;
        }

        return sectionList;
    };

    getDonutDropdownList = function (requestedSection) {
        let dropdownList;

        switch (requestedSection) {
            case 'applications':
                dropdownList = [
                    {
                        label: 'Total App Launches',
                        unit: 'pr',
                        value: 'applications.total_views',
                        text: 'Total App Launches',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Time Spent',
                        unit: 'hr',
                        value: 'applications.total_time',
                        text: 'Total Time Spent',
                        free_tier: 0
                    }
                ];
                break;
            case 'passenger_engagement':
                dropdownList = [
                    {
                        label: 'Total Hits',
                        unit: 'count',
                        value: 'passenger_engagement.total_views',
                        text: 'Total Hits',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Usage Time',
                        unit: 'hr',
                        value: 'passenger_engagement.total_time',
                        text: 'Usage Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'ped_vs_seatback':
                dropdownList = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'ped_vs_seatback.total_views',
                        text: 'Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'ped_vs_seatback.total_time',
                        text: 'Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'audio_passenger_engagement':
                dropdownList = [
                    {
                        label: 'Total Hits',
                        unit: 'count',
                        value: 'passenger_engagement.total_views',
                        text: 'Total Hits',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Usage Time',
                        unit: 'hr',
                        value: 'passenger_engagement.total_time',
                        text: 'Usage Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'audio_ped_vs_seatback':
                dropdownList = [
                    {
                        label: 'Total Listens',
                        unit: 'count',
                        value: 'ped_vs_seatback.total_views',
                        text: 'Total Listens',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Listening Time',
                        unit: 'hr',
                        value: 'ped_vs_seatback.total_time',
                        text: 'Listening Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'games_passenger_engagement':
                dropdownList = [
                    {
                        label: 'Total Hits',
                        unit: 'count',
                        value: 'passenger_engagement.total_views',
                        text: 'Total Hits',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Usage Time',
                        unit: 'hr',
                        value: 'passenger_engagement.total_time',
                        text: 'Usage Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'games_ped_vs_seatback':
                dropdownList = [
                    {
                        label: 'Total Plays',
                        unit: 'count',
                        value: 'ped_vs_seatback.total_views',
                        text: 'Total Plays',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Playing Time',
                        unit: 'hr',
                        value: 'ped_vs_seatback.total_time',
                        text: 'Playing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'ereader_passenger_engagement':
                dropdownList = [
                    {
                        label: 'Total Hits',
                        unit: 'count',
                        value: 'passenger_engagement.total_views',
                        text: 'Total Hits',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Usage Time',
                        unit: 'hr',
                        value: 'passenger_engagement.total_time',
                        text: 'Total Usage Time',
                        free_tier: 0
                    }
                    //{ label: "Avg. Reading Time", unit: "hr", value: "passenger_engagement.avg_reading_time", text: "Avg. Reading Time", "free_tier" : 0 },
                ];
                break;
            case 'ereader_ped_vs_seatback':
            case 'ereader_passenger_touchpoints':
                dropdownList = [
                    {
                        label: 'Total Title Views',
                        unit: 'count',
                        value: 'ped_vs_seatback.total_title_views',
                        text: 'Total Title Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Readers',
                        unit: 'count',
                        value: 'ped_vs_seatback.total_readers',
                        text: 'Total Readers',
                        default: 1,
                        free_tier: 0
                    },
                    {
                        label: 'Total Reading Time',
                        unit: 'hr',
                        value: 'ped_vs_seatback.total_reading_time',
                        text: 'Total Reading Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'portal_device_os':
                dropdownList = [
                    {
                        label: 'Device Count',
                        unit: 'pr',
                        value: 'portal_device_os.total_counts',
                        text: 'Total Count',
                        default: 1,
                        free_tier: 1
                    }
                ];
                break;
            case 'portal_device_browser':
                dropdownList = [
                    {
                        label: 'Device Count',
                        unit: 'pr',
                        value: 'portal_device_browser.total_counts',
                        text: 'Total Count',
                        default: 1,
                        free_tier: 1
                    }
                ];
                break;
            case 'portal_device_type':
                dropdownList = [
                    {
                        label: 'Device Count',
                        unit: 'pr',
                        value: 'portal_device_type.total_counts',
                        text: 'Total Count',
                        default: 1,
                        free_tier: 1
                    }
                ];
                break;
            case 'media_launches_by_content_type':
                dropdownList = [
                    {
                        label: 'Media launches by Content Type',
                        unit: 'pr',
                        value: 'passenger_engagement.total_counts',
                        text: 'Media launches by Content Type',
                        default: 1,
                        free_tier: 1
                    }
                ];
                break;
            case 'Excelapplications':
                dropdownList = [
                    {
                        label: 'Total App Launches',
                        unit: 'pr',
                        value: 'applications.total_views',
                        text: 'App Launches',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Time Spent',
                        unit: 'hr',
                        value: 'applications.total_time',
                        text: 'Time Spent',
                        free_tier: 0
                    }
                ];
                break;
            case 'Excelpassenger_engagement':
                dropdownList = [
                    {
                        label: 'Total Hits',
                        unit: 'pr',
                        value: 'passenger_engagement.total_views',
                        text: 'Total Hits',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Usage Time',
                        unit: 'hr',
                        value: 'passenger_engagement.total_time',
                        text: 'Usage Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Excelpassenger_touchpoints':
                dropdownList = [
                    {
                        label: 'Total Views',
                        unit: 'pr',
                        value: 'ped_vs_seatback.total_views',
                        text: 'Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'ped_vs_seatback.total_time',
                        text: 'Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Excel_audio_passenger_engagement':
                dropdownList = [
                    {
                        label: 'Total Hits',
                        unit: 'pr',
                        value: 'passenger_engagement.total_views',
                        text: 'Total Hits',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Usage Time',
                        unit: 'hr',
                        value: 'passenger_engagement.total_time',
                        text: 'Usage Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Excel_audio_passenger_touchpoints':
                dropdownList = [
                    {
                        label: 'Total Listens',
                        unit: 'pr',
                        value: 'ped_vs_seatback.total_views',
                        text: 'Total Listens',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Listening Time',
                        unit: 'hr',
                        value: 'ped_vs_seatback.total_time',
                        text: 'Listening Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Excel_games_passenger_engagement':
                dropdownList = [
                    {
                        label: 'Total Hits',
                        unit: 'pr',
                        value: 'passenger_engagement.total_views',
                        text: 'Total Hits',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Usage Time',
                        unit: 'hr',
                        value: 'passenger_engagement.total_time',
                        text: 'Usage Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Excel_games_passenger_touchpoints':
                dropdownList = [
                    {
                        label: 'Total Plays',
                        unit: 'pr',
                        value: 'ped_vs_seatback.total_views',
                        text: 'Total Plays',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Playing Time',
                        unit: 'hr',
                        value: 'ped_vs_seatback.total_time',
                        text: 'Playing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Excelportal_device_os':
                dropdownList = [
                    {
                        label: 'Device Count by OS',
                        unit: 'pr',
                        value: 'portal_device_os.portal_device_os',
                        text: 'Device Count',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Device Count by OS version',
                        unit: 'pr',
                        value: 'portal_device_os.portal_device_os_version',
                        text: 'Device Count',
                        default: 1,
                        free_tier: 1
                    }
                ];
                break;
            case 'Excelportal_device_browser':
                dropdownList = [
                    {
                        label: 'Device Count',
                        unit: 'pr',
                        value: 'portal_device_browser.total_counts.data',
                        text: 'Device Count',
                        default: 1,
                        free_tier: 1
                    }
                ];
                break;
            case 'Excelportal_device_type':
                dropdownList = [
                    {
                        label: 'Device Count by Device Model',
                        unit: 'pr',
                        value: 'portal_device_type.total_counts',
                        text: 'Device Count',
                        default: 1,
                        free_tier: 1
                    }
                ];
                break;
            case 'Excelmedia_launches_by_content_type':
                dropdownList = [
                    {
                        label: 'Media launches by Content Type',
                        unit: 'pr',
                        value: 'passenger_engagement.total_counts',
                        text: 'Media launches by Content Type',
                        default: 1,
                        free_tier: 1
                    }
                ];
                break;
            default:
                //  throw new Error("Please check the label you are passing")
                break;
        }

        return dropdownList;
    };
}
