module.exports = `
    <div>
        <div class="main-release-content">
            <p>
                NEXT Insights is a business intelligence solution that aims to ease the burden of analyzing data from various data sources to provide relevant and actionable information related to IFEC usage and onboard digital consumption.
            </p>
            <p>
                Serving as the analytics backbone of the Panasonic ecosystem, NEXT Insights creates correlations between multiple products and datasets which helps in identifying the most relevant and desired onboard product offerings. This allows you to focus and respond quickly to market shifts and emerging trends.
            </p>
        </div>
    </div>

<div class="section">
        <h2 class="version">Version 5.2.1</h2>
        <div class="release">Released September 13, 2024</div>
	<div class="section">
        <h2 class="title">NEXT Insights 5.2.1 Bug Fixes</h2>
        <ul>
			<li> Resolved a compatibility issue with the custom date range filter on new version of Chrome browser, addressing the deprecation of previously used functions</li> 
			<li> Corrected an issue where the eReader page view was omitted</li>
			<li> Updated the Calendar UI on the Seat Usage dashboard within the Seatback page to correctly highlight dates that contain data</li>	
			<li> Enhanced the PAC internal license management page by enabling downloadable options and allowing for precise selection of license start and end dates</li>
        </ul>
    </div>

	<div class="section">
        <h2 class="version">Version 5.2</h2>
        <div class="release">Released August 14, 2024</div>
    </div>
	<div class="section">
        <h2 class="title">NEXT Insights 5.2 Standard Features </h2>
        <ul>
			<li> Introduced Month-on-Month reports for Application and Entertainment usage on the Workbench in the MyInsights Page </li> 
			<li> Added the Portal app launch type info into the Excel report of Device OS and Type dashboards on the Portal page</li> 
        </ul>
    </div>
	<div class="section">
        <h2 class="title">NEXT Insights 5.2 Bug Fixes</h2>
        <ul>
			<li> Fixed the order of category info from parents to child category for certain edge cases</li> 
			<li> Enhanced the handling of categories enrichment failures marked as unknown on the Movies and TV series page for particular edge cases</li> 
			<li> Fixed the issue where the Movies Workbench report was incorrectly displayed on the TV series page</li> 
        </ul>
    </div>

	<div class="section">
        <h2 class="version">Version 5.1.1</h2>
        <div class="release">Released June 28, 2024</div>
    </div>
	
	<div class="section">
        <h2 class="title">NEXT Insights 5.1.1 Bug Fixes</h2>
        <ul>
			<li> Added seconds value to the average viewing time on the Cabin Class dashboard </li> 
			<li> Fixed the issue of filters not loading on eReader page </li> 
			<li> Fixed the data discrepancy issue related to Total Viewing time across various dashboards </li>
			<li> Fixed the MMA enrichment for MPU type offload in case of null content type </li>
        </ul>
    </div>
	
	<div class="section">
        <h2 class="version">Version 5.1</h2>
        <div class="release">Released May 23, 2024</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 5.1 Standard Features </h2>
        <ul>
			<li> Added Portal app launch type details on the Excel report of Device Browsers dashboard in Portal page </li> 
			<li> Added Application Trend design with Top/Bottom 5 dropdown option in My Insights page </li> 
			<li> Added Rack data mode for PAC internal testers for verifying rack testing usage </li> 
        </ul>
    </div>
	
	<div class="section">
        <h2 class="title">NEXT Insights 5.1 Bug Fixes</h2>
        <ul>
			<li> Fixed data discrepancy issue between MV and Excel report on the Device Browser report </li> 
			<li> Improved the error handling in device-info-aggregator for 'vacuum reindex' failures </li> 
			<li> Improved the Rack Dashboard filtering by removing flight start/end date filter </li> 
        </ul>
    </div>
	
	<div class="section">
        <h2 class="version">Version 5.0</h2>
        <div class="release">Released Mar 18, 2024</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 5.0 Standard Features </h2>
        <ul>
			<li> Enhanced the My Insights page with the new Application Trends and Usage Dashboard </li>
			<ul class="a">
				<li> Application Trends dashboard </li>
				<ul class="b">
					<li> Added “Total App Launches” metrics </li>
					<li> Added “Total Time Spent” metrics </li>
					<li> Added granular details for Application Trends in Excel report download </li>
				</ul>				
				<li> Application Usage dashboard (Donut chart) </li>
				<ul class="b">
					<li> Added “Total App Launches” metrics </li>
					<li> Added “Total Time Spent” metrics </li>
					<li> Added granular details for Application Usage in Excel report download </li>
				</ul>
			</ul>
			<li> Streamlined dashboards in My Insights page: </li> 
			<ul>
				<li> Renamed Entertainment Trends and Usage </li> 
				<ul>
					<li> The previous “Trends” and “Passenger Engagement” are now called “Entertainment Trends” and “Entertainment Usage” respectively </li>
				</ul>
				<li> Moved Entertainment Trends and Usage below the Application Usage </li> 
			</ul>
			<li> Introduced Media Cycle filter in the Movies, TV Series, and Audio pages </li> 
			<ul>
				<li> Easily switch to a specific Media Cycle for your analysis from the Date Range </li> 
				<li> Access all the usage metrics relevant to the selected Media Cycle </li> 
				<li> Detailed data by Media Cycle is available for download in Excel format and on Workbench </li> 
				<li> No comparison range selection available for Media Cycle filter </li> 
			</ul>
			<li> Miscellaneous </li> 
			<ul>
				<li> Removed “Passenger Engagement” dashboard from Entertainment sub-pages such as Movies, TV Series, Audio, Games, Live TV, and Electronic Reader </li> 
				<li> Renamed Electronic Reader to eReader </li> 
				<li> Updated the custom date range filter to allow selection up to yesterday </li> 
				<li> Added more details on the Excel report of Seat Usage (LOPA) </li> 
				<li> Updated "No Data" to "Not Applicable" for data unavailable cases in Excel report </li> 
				<li> Improved the Excel report size limitation </li>
			</ul>
        </ul>
    </div>
	
	<div class="section">
        <h2 class="title">NEXT Insights 5.0 Bug Fixes</h2>
        <ul>
            <li> Fixed the Trends chart not rendering when user switched between Workbench and Custom UI </li>
			<li> Improved the Subtitle Language Names enrichment logic </li>
			<li> Fixed the Seat LOPA widget not loading on the Seatback page </li>
			<li> Fixed the Filters information in the Excel file download report for Seat Usage </li>
			<li> Fixed issue with user trying to extend their login session </li>
        </ul>
    </div>
		
	<div class="section">
        <h2 class="version">Version 4.2</h2>
        <div class="release">Released Nov 17, 2023</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 4.2 Standard Features </h2>
        <ul>
			<li> Added Seatback language transition dashboard </li>
			<ul>
				<li> Added "Top/Bottom 10 Language By Passengers" metrics </li>
				<li> Added "Top/Bottom 10 Dwell time" metrics </li>
				<li> Added "Top/Bottom 10 Transitions" metrics </li>
				<li> Added granular details for Seatback language transitions in excel report download </li>
			</ul>
			<li> Updated Seat Usage dashboard </li> 
			<ul>
				<li> Added language details per seat in Seat Usage dashboard </li> 
				<li> Added total languages per seat in excel report download </li> 
			</ul>
			<li> Added Release notification capability </li> 
			<ul>
				<li> Added new features modal upon first login after new version is released </li> 
				<li> Added new release notification tooltip display to guide users to the Release Notes hyperlink </li> 
				<li> Added "What's New" pop up with Release Notes when new version hyperlink is clicked </li> 
			</ul>
        </ul>
    </div>
	
	<div class="section">
        <h2 class="version">Version 4.1</h2>
        <div class="release">Released Sep 29, 2023</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 4.1 Standard Features </h2>
        <ul>
            <li> Added login details on Seatback and Companion App </li>
			<ul>
				<li> Added a pillbox metrics within Seatback page for login attempts </li>
				<li> Add 'login attempts' metric in the Seat usage dashbaord in Seatback page </li>
				<li> Added a Seat-level workbench within Seatback page with all login details data columns applicable </li>
				<li> Added a pillbox metric within Companion App page for login attempts </li>
			</ul>
			<li> Updated KPI Info texts for the following </li> 
			<ul>
				<li> Data quality definitions for Media Categories dashboards in Movies, TV Series and Audio pages </li>
				<li> For 'Unique Viewers' metric in the Trends dashboard in Movies, TV Series, Audio, Games, eReader, Seatback and Portal pages. </li>
				<li> Login details in Seatback and Companion App pages </li>
			</ul>
			<li> Renamed 'Total Viewers' metric to 'Unique Viewers' in all Trends dashboards and Excel downloads applicable </li>
        </ul>
    </div>
    
	<div class="section">
        <h2 class="title">NEXT Insights 4.1 Bug Fixes</h2>
        <ul>
            <li> Fixed the inconsistency in Live TV Metrics between Custom UI reports and Quicksights reports </li>
			<li> Fixed the Popularity metrics API timeout issue </li>
			<li> Fixed device metrics donut charts to show the devices in "Not specified" category that cannot be mapped to Laptop or Mobile </li>
        </ul>
    </div>
	
	<div class="section">
        <h2 class="version">Version 4.0</h2>
        <div class="release">Released Aug 14, 2023</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 4.0 Standard Features </h2>
        <ul>
            <li> Implemented Media category dashboards </li>
			<ul>
				<li> Added Top/Bottom 10 Categories dashboard to the Audio page </li>
				<li> Added Top/Bottom 10 Categories dashboard to the Movies page </li>
				<li> Added Top/Bottom 10 Categories dashboard to the TV Series page </li>
				<li> Updated KPI Info for all Media Category dashbords within Audio, Movies and TV Series page </li>
			</ul>
			<li> Added more Live TV usage metrics to existing Live TV page </li> 
			<ul>
				<li> Updated High-level aggregated Pillbox metrics </li>
				<li> Added Top/Bottom 10 Live TV Program Rankings dashboard </li>
				<li> Updated Live TV Program Rankings per Channel title within the channel title popup </li>
				<li> Updated KPI Info for Live TV Program Rankings dashboard metrics </li>
			</ul>
			<li> Some optimizations implemented to all Donut charts </li> 
			<ul>
				<li> Added percentage increase/decrease for all usage metrics within donut charts and excel download reports </li> 
				<li> Updated KPI Info for all donut charts within Insights portal </li> 
			</ul> 
			<li> Updated rounding of all percentage metric that is trivial and less than 0.1% to be displayed as '<0.1%' </li>
        </ul>
    </div>
    
   <div class="section">
        <h2 class="title">NEXT Insights 4.0 Bug Fixes</h2>
        <ul>
            <li> Improved the logic that classifies the passenger device as laptop vs mobile
			<li> Streamlined live TV aggregator job to run after live TV enrichment job using glue workflow
			<li> Fixed issue with the portal displaying a blank screen when the browser is refreshed
			<li> Fixed the left navigation bar to correctly highlight the page name when back and forward navigation buttons in the web browser are used
        </ul>
    </div>
	
	<div class="section">
        <h2 class="version">Version 3.1</h2>
        <div class="release">Released Jun 02, 2023</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 3.1 Standard Features </h2>
        <ul>
            <li> Live TV integration complete between Insights and other internal data sources </li>
			<li> IPS offload ingester implemented </li>
			<li> Expanded granular details for Portal Device OS in excel report download </li>
			<li> Expanded granular details for Portal Device Type in the excel report download </li>
			<li> Improved on reporting the metrics across visuals more consistently </li>
			<li> Increased launch date range from last 3 months to last 6 months </li>
			<li> Cosmetic improvements made in Donut chart </li>
			<li> Added caching (SPICE mode) for few smaller Workbench reports to improve performance </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 3.1 Bug Fixes</h2>
        <ul>
            <li> Fixed a issue with computation of Avg Page View Duration metric in Seatback page </li>
			<li> Fixed Passenger Engagement Donut and Ped Vs Seatback Donut chart to handle divide by zero error when no data in comparison date range </li>
			<li> Fixed the percentage of change shown in donut charts and excel reports to display NA (No Data) when there is no data in comparison date range </li>
			<li> Updated the Top 10 Audio titles dashboard to exclude broadcastAudioSelection usage type </li>
			<li> Improved Passenger Interaction Timeline dashboard to handle the usage data that are not mapped any flight phases </li>
			<li> Reduced the creation time of materialized views in Redshift that are based on the Airline licenses </li>
			<li> Optimized the Redshift SQL queries executed for many visuals </li>
			<li> Cleaned up all the unused datasets created in Quicksights </li>
        </ul>
    </div>
    
	<div class="section">
        <h2 class="version">Version 3.0</h2>
        <div class="release">Released Mar 17, 2023</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 3.0 Standard Features</h2>
        <ul>
            <li> Implemented Bluetooth Analytics </li>
            <ul>
                <li> Added Bluetooth page in the left navigation panel under 'Passenger Touchpoints' </li>
				<li> Added Rack dashboard for PAC internal testers for verifying rack usage of BT </li>
				<li> Added Bluetooth dashboard displaying BT usage metrics </li>
				<li> Added BT KPI Info texts </li>
            </ul>
			<li> Implemented following eReader Rack Offload dashboards </li>
			<ul>
				<li> Generic dashboard - supports legacy eReader offload format </li>
				<li> Integrated dashboard - supports new integrated JSON offload format </li>
			</ul>
			<li> Implemented following updates to Audio and TV Series page </li>
			<ul>
				<li> Added break-down of TV Episodes for each TV Series within Top/Bottom 10 TV Series dashbaord </li>
				<li> Added TV Episodes column in the Excel report </li>
				<li> Renamed the 'TV Series Titles' visual to 'TV Rankings' </li>
				<li> Updated KPI Info texts for TV Episodes views and viewing time breakdown </li>
				<li> Added break-down of Audio Tracks for each Audio Album within Top/Bottom 10 Audio Album dashbaord </li>
				<li> Added Audio Tracks column in the Excel report </li>
				<li> Renamed the 'Audio Titles' visual to 'Audio Rankings' </li>
				<li> Updated KPI Info texts for Audio tracks listens and listening time breakdown </li>
			</ul>
			<li> Implemented NEXT Insights Analytics dashboards capturing PAC internal KPIs for 'NEXT Insights' product, accessible only to the 'Insights PLM' user group </li>
            <ul>
                <li> Airline per visual engagement metrics table </li>
				<li> Airline page metrics table </li>
				<li> Airline interaction element per visual metrics table </li>
				<li> Airline filter count metrics </li>
            </ul>
			<li> Updated usage metrics to be consistent in all the visuals/dashboards adhering to the Global digit business rules </li> 
            <li> Updated Media Rack dashboard to include media version and media part number </li>
			<li> Updated Excel reports to support and apply formula to cells </li>
			<li> Implemented back-end support to query all media titles with no usage for MPU offloads </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 3.0 Bug Fixes</h2>
        <ul>
            <li> Fixed the data discrepancy between donut chart and trends chart </li>
			<li> Fixed the cabin class chart to display the viewing time for PED in a standard format </li>
			<li> Fixed the eReader rack  dashboards to not show the production data </li>
			<li> Fixed the issues with ETL job that clones the data from production to stage environment on daily basis </li>
        </ul>
    </div>
	
    <div class="section">
        <h2 class="version">Version 2.4</h2>
        <div class="release">Released Nov 10, 2022</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 2.4 Standard Features</h2>
        <ul>
            <li> Implemented NEXT Insights Product Analytics dashboards for PAC internal 'Insights PLM' user group </li>
            <ul>
                <li> High-level engagement metrics as a pillbox </li>
                <li> Airline session metrics </li>
                <li> Airline session metrics trend (daily) </li>
                <li> Allow user persona/role 'Insights PLM' only access to Insights Product Analytics dashboards </li>
            </ul>
            <li> Added alerts for production workflow </li>
            <ul>
                <li> Aggregator alerts </li>
                <li> Datahub alerts </li>
            </ul>
            <li> Implemented Bluetooth rack offload dashboard </li>
            <li> Implemented enrichment logic to enrich media with no Media P/N </li>
            <li> Updated Insights APIs to point to the new Datahub APIs </li>
            <li> Added 'Getting Started with NEXT Insights' video to Standard tier </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 2.4 Bug Fixes</h2>
        <ul>
            <li> Fixed various issues related to product analytics dashboards </li>
            <li> Fixed the Flight Aware enrichment in device info ETL to be consistent with the other ETL jobs </li>
            <li> Implemented the fix to address missing poster images for game rankings </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="version">Version 2.3</h2>
        <div class="release">Released Oct 05, 2022</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 2.3 Standard Features</h2>
        <ul>
            <li> Rack offload dashboards implemented </li>
            <ul>
                <li> offload related data dashboard </li>
                <li> usage ingested data - raw data dashboard </li>
                <li> usage enriched data dashboard </li>
                <li> Implemented access control for Rack dashboards for only allowing internal PAC Rack Testers role/group access </li>
            </ul>
            <li> Data pipeline optimization </li>
            <ul>
                <li> Optimized aggregator workflow in the production environment to reduce run time </li>
                <li> Alerts were created for data enrichment and aggregation jobs </li>
            </ul>
            <li> Implemented multi-tenant drop-down in UI and Back-end to support airlines that needs multi-tenant access </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 2.3 Bug Fixes</h2>
        <ul>
            <li> Fixed the issue that disables preset Date filters for Portal Navigation page </li>
            <li> Fixed the issue that prevents Airline users to view Quicksights Dashboards and download excel reports </li>
            <li> Fixed the issue that blocks media art work pop-up when clicked on the media title with no poster image </li>
            <li> Fixed the issue that causes flight metrics in Seatback page shows No Data </li>
            <li> Fixed various issues related to Quicksights dashboards created for test rack paxus offloads </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="version">Version 2.2</h2>
        <div class="release">Released Jul 22, 2022</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 2.2 Standard Features</h2>
        <ul>
            <li> Updated Top/Bottom 10 visuals to remove Top 10 and Bottom 10 options if the number of items are less than or equal to 10 and instead just show as single option for each metrics </li>
            <li> Updated Excel Download to export filter values if selected or show all filter values for every dynamic filters in the excel file downloaded, for all dashboards for all tiers </li>
            <li> Added popups to show disclaimers for airlines in evaluation stage </li>
            <li> Updated more QS Redshift datasets and dashboards to reflect the ACL v2 mechanism </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 2.2 Bug Fixes</h2>
        <ul>
            <li> Fixed the issue that causes discrepancy in the Total Hits and Total Usage Duration metric across different dashboards  </li>
            <li> Fixed various issues related to exporting the filter values in the excel report </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="version">Version 2.1</h2>
        <div class="release">Released Jun 20, 2022</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 2.1 Standard Features</h2>
        <ul>
            <li> Updated Dataloading Dashboard </li>
            <li> Updated the Date Range filter per Subscription tiers and Sales stages </li>
            <li> Standardization of 'System Types' by Datahub and passed to Insights through API </li>
            <li> Addition of system types and aircraft types by Datahub and passed to Insighs through Add-tail API </li>
            <li> Parsed the system type and aircraft type values from Datahub </li>
            <li> Updated filter values list API to include custom Date ranges </li>
            <li> Implemented Custom date range (calendar widget) in the Date Range Filter </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 2.1 Bug Fixes</h2>
        <ul>
            <li> Fixed the issue that blocks admin user from adding some production tails to the Datahub </li>
            <li> Fixed the data discrepancy between summary reports and quicks sights work bench for Portal and Seat back page </li>
            <li> Implemented various fixes to address the issues related to Custom Date Filters and Calendar widget </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="version">Version 2.0</h2>
        <div class="release">Released May 27, 2022</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 2.0 Standard Features</h2>
        <ul>
            <li> Improved the ETL jobs to process the paxus offload files that are received in the landing zone a few days after the flight date </li>
            <li> Implemented access control list (ACL) feature to grant access to metrics and dashboards based on Airline's subscription tiers and Apps selected </li>
            <li> Added dynamic filtering capability to show only the relevant filters for each App page </li>
            <li> Implemented auto refresh of filter values to narrow down the user selections to choose the right filters </li>
            <li> Added donut chart to Portal page to show the device count per device type </li>
            <li> Standardized the representation of time unit in all the excel reports and pill boxes </li>
            <li> Added spinner to provide the visual feedback on the Excel report download progress </li>
            <li> Implemented system alerts to report failures in the daily data processing routine </li>
            <li> Implemented error and warning message scenarios for mismatch in Tails and Licenses </li>
            <li> Handled Error case when adding tails for an airline whose license is expired </li>
            <li> Added message banners to notify the airline user on current onboarding stage </li>
            <li> Updated Getting Started and Standard training descriptions and videos </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 2.0 Bug Fixes</h2>
        <ul>
            <li> Fixed the issue of incorrect flight count for Companion App in Flights per Passenger Touchpoint Quick Sights report </li>
            <li> Fixed the broken excel report download for screen transition dashboard in portal and seatback page </li>
            <li> Improved the display for non-standard sized posters with a black background </li>
            <li> Fixed the issue of mapping usage record to incorrect flight during flight-aware enrichment </li>
            <li> Fixed the issue of polluting metrics with the data from non-production tails </li>
            <li> Fixed the issue of incorrect user session count and page hit metrics for Portal </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="version">Version 1.1</h2>
        <div class="release">Released Feb 25, 2022</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 1.1 Standard Features</h2>
        <ul>
            <li> On-boarded eReader to Panasky demo tenant </li>
            <li> Implemented Flightaware enrichment for eReader Usage </li>
            <li> Implemented ISO language code to Language name mapping for eReader usage </li>
            <li> KPI Info improvements </li>
            <li> Standardized the time-related metrics in the Pillboxes </li>
            <li> Added capability to timeshift Panasky demo data without the need to reprocess </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 1.1 Bug Fixes</h2>
        <ul>
            <li> Fixed the issue which led to incorrect tail count in eReader Page </li>
            <li> Fixed the issue which causes the TV series usage to be classified as Video </li>
            <li> Fixed the issue which causes some Game title to be Null </li>
            <li> Fixed the issue which led to incorrect Avg Viewing Time metric in the Games and Live TV artwork pop-up </li>
            <li> Fixed the issue that causes internal PAC user losing access to Panasky demo data </li>
            <li> Fixed the issue that affected Panasky demo data for Companion App product </li>
            <li> Implemented  various fixes to address the inconsistency in metric names and metric units in all the product pages </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="version">Version 1.0</h2>
        <div class="release">Released Jan 25, 2022</div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights 1.0 Standard Features</h2>
        <ul>
            <li> Comparitive metrics of inflight content consumption on seatback and PED. </li>
            <li> Bug fixes to metrics relating to Companion App, Seatback and Portal. </li>
            <li> Capability to demo using anonymous data on real Insights backend and frontend using the latest Insights codebase. </li>
            <li> Training videos introduced in help section of Insights. </li>
            <li> Added capability for Insights codebase to be aware of the sales/customer engagement stages of Insights product and granting access to Insights to subscribed customer. </li>
            <li> eReader/Electronic Reader integrated to Insights based on initial version 1 of logging standard. Insights backend and frontend has been developed for eReader analytics.  </li>
            <li> MyInsights: added eReader to aggregated comparative metrics across all content by passenger touchpoints. </li>
            <li> eReader : Top and Bottom 10 KPIs by Readers/Hits, Total Reading Time and Avg. Reading Time. </li>
            <li> Passenger Engagement trendline by  Total and Avg. Readers/Hits, Total and Avg. Reading Time, and Avg. Pages Read for eReader. </li>
            <li> Download to CSV capability for each KPI/visual added to eReader analytics in Insights. </li>
            <li> A frontend/visual for understanding passenger usage for content products including eReader by cabin class introduced.  </li>
            <li> Backend improvements for loading data into Insights. </li>
            <li> Improvements to KPI info verbiage. </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="version">Version 0.7.0 - MVP</h2>
        <div class="release">Released Oct 25, 2021</div>
        <div class="release-content">
            <p style="margin-bottom: 0;">
                This MVP (Minimum Viable Product) release is the very first of NEXT Insights Standard Subscription product releases that focuses on understanding inflight passenger usage relating to Media/Entertainment consumption on various passenger touch-points like IFE seat back, Portal, and Companion App.
            </p>
        </div>
    </div>
    
    <div class="section">
        <h2 class="title">NEXT Insights MVP Standard Features</h2>
        <ul>
            <li> Dynamic Filtering for all dashboards: System Type, Aircraft Type/Subtype, Tail, Flight Origin, Flight Destination, Flight Number, Seat Class and Haul Types </li>
            <li> Date Range: last week, curr week, last month, last 3 months, last 6 months, last year, curr year, last 2 years </li>
            <li> Comparison Date Range: preceding 1 week, preceding 1 month, same period a year ago, preceding 3 months, preceding 6 months, preceding year </li>
            <li> Percentage increment and decrement indicators of usage metrics based on comparison date range for all visualizations </li>
            <li> MyInsights: Aggregated comparative metrics across all content by passenger touchpoints </li>
            <li> Top and Bottom 10 KPIs by Views/Hits and Viewing Time for Movies, TV Series, Audio, Games, Live TV, Seatback, Portal and Companion App </li>
            <li> Passenger Engagement trendline  by Views/Hits and Viewing Time for Movies, TV Series, Audio, Games, Live TV, Seatback, Portal and Companion App </li>
            <li> High-level Flight data metrics for Movies, TV Series, Audio, Games, Live TV, Seatback, Portal and Companion App </li>
            <li> High-level aggregated usage metrics for Seatback, Portal and Companion App </li>
            <li> Excel download per visualization </li>
            <li> Integrated help text per visualization </li>
            <li> High level interaction timeline by Total Viewers, Total Time Spent and Total Distinct Views by Flight phases for Seatback, Portal and Companion App </li>
            <li> Three-level Screen transitions with Avg. Dwell Time for Seatback and Portal </li>
            <li> Media launches by content type for Companion App </li>
            <li> Device OS and Browser Counts for Seatback and Portal </li>
            <li> Comparison usage of all applicable passenger touchpoints,  by Views/Hits and Viewing Time  (Seatback and Portal) </li>
            <li> Comparison of Content usage  by Views/Hits and Viewing Time  </li>
            <li> Seat-level anonymized aggregated usage metrics by Total Seat Usage Time and Total Views for Movies, TV Series, Audio, Games and Live TV </li>
        </ul>
    </div>
    
    <div class="section">
        <h2 class="title">Known Issues</h2>
        <ul>
            <li> Genre is null (shown as '===') for some Movies, Audio, TV Series, Games records (due to Genre data not populated in MMA) </li>
            <li> Some Live TV usage records are not enriched with channel rankings </li>
            <li> Time spent metric reported for Companion App is not accurate </li>
            <li> Poster images are not rendered for some media titles </li>
            <li> Album Artist is null in the Quick Sights workbench report for Audio page </li>
            <li> Slowness in loading data to custom UI charts and excel report downloads </li>
            <li> Usage records that not enriched with flight aware data has bad flight number, flight origin and flight destination </li>
            <li> Some of the usage records are not correctly classified with appropriate media type </li>
            <li> In the Seat Usage dashboard, some seats have seat engagement time greater than flight duration </li>
            <li> Some usage records were duplicated during the data enrichment process </li>
			<li> Same language name is displayed for "From Language" and "To Language" in the seatback language transitions dashboard for few transitions. </li>
			<li> "From Language" is unspecified for few transitions in the seatback language transitions dashboard. </li>
        </ul>
    </div>
`;
