import Select, { components } from 'react-select';
import { Row } from 'react-bootstrap';
import WidgetTabs from 'components/widget-frame/WidgetTabsDynamic';
import TooltipModal from 'components/widgets/TooltipModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import _ from 'lodash';
import InfoImage from 'assets/images/icons/popout/info.svg';
import alertIcon from 'assets/images/icons/popout/orange-alert.svg';
import Circular from 'assets/images/icons/circular.gif';
import DynamicFilterHelper from 'data/dynamic-filter-helper';
import UtilHelper from 'util/UtilHelper.js';
import dataStandardJSON from 'content/help/standard/data.json';
import dataBasicJSON from 'content/help/basic/data.json';
import DownloadExcel from 'components/excel/DownloadExcel';
import Analytics from 'components/widgets/Analytics';
import SubscriptionService from 'services/SubscriptionService';

const utilHelper = new UtilHelper();

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faSearch : faCaretDown} />
            </components.DropdownIndicator>
        )
    );
};

class WidgetFrameNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overflowOpen: false,
            helpOpen: false,
            activeTab: 0,
            dropdown1: [],
            dropdown2: [],
            selectedDD1Option: {},
            selectedDD2Option: {},
            tooltip: false,
            uniqueId: Math.round(Math.random() * 100000)
        };
        this.data = this.props.data;

        this.handleDropdown1Change = this.handleDropdown1Change.bind(this);
        this.handleDropdown2Change = this.handleDropdown2Change.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setOverflowState = this.setOverflowState.bind(this);
    }

    componentDidMount() {
        this.setDefaultDropdownValues();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.dd1Options, this.props.dd1Options) && this.props.title == 'Application Trends') {
            this.setDefaultDropdownValues();
        }

        if (prevProps.data !== this.props.data) {
            this.update();
        }
    }

    setDefaultDropdownValues = () => {
        let selectedDD1Option = '';

        if (typeof this.props.dd1Options !== 'undefined') {
            selectedDD1Option = this.props.dd1Options.filter((item) => item.default && item.default === 1)[0];
        }

        let selectedDD2Option = '';
        if (typeof this.props.dd2Options !== 'undefined') {
            if (selectedDD1Option) {
                let options = this.props.dd2Options[selectedDD1Option.value];
                if (options) {
                    selectedDD2Option = options.filter((item) => item.default && item.default === 1)[0];
                }
            }
        }

        this.setState({
            selectedDD1Option: selectedDD1Option,
            selectedDD2Option: selectedDD2Option
        });
    };

    update() {
        let unit;
        let precision;
        let text;
        let longText;
        let data = [];
        let key;

        if (this.props.showTabs) {
            // Selected tab data
            try {
                if (this.props.dd1Options && Object.keys(this.props.dd1Options).length > 0) {
                    if (this.state.selectedDD1Option && this.state.selectedDD1Option.value) {
                        if (this.state.selectedDD1Option.precision) {
                            precision = this.state.selectedDD1Option.precision;
                        }

                        let updatedTabData = this.props.tabDataBySections[this.state.selectedDD1Option.value];

                        if (updatedTabData && updatedTabData[this.state.activeTab]) {
                            data = updatedTabData[this.state.activeTab]['graph'];
                            text = updatedTabData[this.state.activeTab].y_axis_label;
                            unit = updatedTabData[this.state.activeTab].unit;
                            longText = updatedTabData[this.state.activeTab].title;
                            key = updatedTabData[this.state.activeTab].key;
                        }
                    }
                } else {
                    if (this.props.tabData && this.props.tabData[this.state.activeTab]) {
                        data =
                            this.props.data[this.props.tabData[this.state.activeTab].key][
                                this.props.graphObject ? this.props.graphObject : 'graph'
                            ];
                        text = this.props.tabData[this.state.activeTab].y_axis_label
                            ? this.props.tabData[this.state.activeTab].y_axis_label
                            : '';
                        unit = this.props.tabData[this.state.activeTab].unit
                            ? this.props.tabData[this.state.activeTab].unit
                            : 'count';
                        longText = this.props.tabData[this.state.activeTab].title;
                        key = this.props.tabData[this.state.activeTab].key;
                    }
                }
            } catch (error) {
                data = [];
            }
        } else {
            // Selected dropdown data
            if (
                this.props.dd1Options &&
                Object.keys(this.props.dd1Options).length > 0 &&
                this.props.dd2Options &&
                Object.keys(this.props.dd2Options).length > 0
            ) {
                if (this.state.selectedDD1Option.value && this.state.selectedDD2Option.value) {
                    if (this.props.data && Object.keys(this.props.data).length > 0) {
                        try {
                            data = utilHelper.resolve(
                                this.props.data,
                                `${this.state.selectedDD1Option.value}.${this.state.selectedDD2Option.value}.data`
                            );
                        } catch (error) {
                            data = [];
                        }
                    }
                    unit = this.state.selectedDD2Option.unit;
                    if (this.state.selectedDD2Option.text) {
                        text = this.state.selectedDD2Option.text;
                    }
                }
            } else if (this.props.dd1Options && Object.keys(this.props.dd1Options).length > 0) {
                if (this.state.selectedDD1Option && this.state.selectedDD1Option.value) {
                    if (this.props.data && Object.keys(this.props.data).length > 0) {
                        try {
                            data = utilHelper.resolve(this.props.data, `${this.state.selectedDD1Option.value}.data`);
                        } catch (error) {
                            data = [];
                        }
                    }
                    unit = this.state.selectedDD1Option.unit;
                    if (this.state.selectedDD1Option.text) {
                        text = this.state.selectedDD1Option.text;
                    }

                    if (this.state.selectedDD1Option.precision) {
                        precision = this.state.selectedDD1Option.precision;
                    }
                }
            }
        }

        if (this.props.updateData) {
            this.props.updateData({
                data: data,
                unit: unit,
                precision: precision,
                text: text,
                longText: longText,
                key: key
            });
        }
    }

    widgetTabFunction = (i, captureInteraction = true) => {
        if (captureInteraction) {
            let interactionValue = '';
            if (this.props.tabData[i]) {
                interactionValue = this.props.tabData[i]['title'];
            } else if (this.props.tabDataBySections && this.state.selectedDD1Option.value) {
                interactionValue = this.props.tabDataBySections[this.state.selectedDD1Option.value][i]['title'];
            }

            Analytics.analytics.track('widgetTabFunction', {
                visual_name: this.props.title,
                interaction_type: 'Tab Selection',
                interaction_value: interactionValue
            });
        }

        this.setState({ activeTab: i }, () => this.update());
    };

    handleDropdown1Change(selectedOption) {
        Analytics.analytics.track('handleDropdown1Change', {
            visual_name: this.props.title,
            interaction_type: 'Dropdown Selection',
            interaction_value: selectedOption.label
        });

        this.setState({ selectedDD1Option: selectedOption }, () => this.update());
    }

    handleDropdown2Change(selectedOption) {
        Analytics.analytics.track('handleDropdown2Change', {
            visual_name: this.props.title,
            interaction_type: 'Dropdown Selection',
            interaction_value: selectedOption.label
        });

        this.setState({ selectedDD2Option: selectedOption }, () => this.update());
    }

    setInfoState() {
        this.setState({
            helpOpen: !this.state.helpOpen
        });
    }

    setOverflowState(status) {
        this.setState({
            overflowOpen: status,
            helpOpen: false
        });
    }

    setHelp() {
        Analytics.analytics.track('setHelp', {
            visual_name: this.props.title,
            interaction_type: 'Info Click',
            interaction_value: 'Info Click'
        });

        this.setState({ tooltip: true });
        this.setOverflowState(false);
    }

    handleClickOutside(event) {
        let dotMenu = document.getElementById(`#${this.state.uniqueId}`);
        if (
            this.wrapperRef &&
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(event.target) &&
            dotMenu &&
            !dotMenu.contains(event.target)
        ) {
            if (this.state.overflowOpen) {
                this.setOverflowState(false);
            }
        }
    }

    render() {
        var tabData = '';
        if (this.props.tabData || this.props.tabDataBySections) {
            tabData = (
                <WidgetTabs
                    tabs={this.props.tabData}
                    tabDataBySections={this.props.tabDataBySections}
                    widgetTabFunction={this.widgetTabFunction}
                    showWorkbenchLink={SubscriptionService.subscription() === 'Standard'}
                    page={this.props.page}
                    title={this.props.title}
                    appliedFilters={this.props.appliedFilters}
                    selectedDDOption={this.state.selectedDD1Option}
                />
            );
        }

        var dropdown2 = '';
        var dropdown1Class = 'widget-frame-dropdown';

        if (this.props.type === 'carousel' && (this.props.page === 'tv-series' || this.props.page === 'audio')) {
            dropdown1Class = 'widget-frame-dropdown-3';
        }

        if (
            this.props.dd2Options &&
            Object.keys(this.props.dd2Options).length > 0 &&
            Object.keys(this.state.selectedDD1Option).length > 0
        ) {
            let selectedDD2Value = this.state.selectedDD2Option.value;
            let currentDD2Options = this.props.dd2Options[this.state.selectedDD1Option.value];
            if (
                currentDD2Options.filter((option) => option.value === this.state.selectedDD2Option.value).length === 0
            ) {
                selectedDD2Value = this.props.dd2Options[this.state.selectedDD1Option.value][0].value;
            }

            dropdown2 = (
                <Select
                    className="widget-frame-dropdown-2"
                    options={this.props.dd2Options[this.state.selectedDD1Option.value]}
                    menuPortalTarget={document.body}
                    value={this.props.dd2Options[this.state.selectedDD1Option.value].filter(
                        ({ value }) => value === selectedDD2Value
                    )}
                    onChange={this.handleDropdown2Change}
                    classNamePrefix="select"
                    styles={DynamicFilterHelper.getDropdownStyle()}
                    components={{ DropdownIndicator }}
                    isSearchable={false}
                />
            );
            dropdown1Class = 'widget-frame-dropdown-1';
        }

        var dropdown1 = '';
        if (this.props.dd1Options && Object.keys(this.props.dd1Options).length > 0) {
            let selectedDD1OptionValue;
            if (this.state.selectedDD1Option) {
                selectedDD1OptionValue = this.state.selectedDD1Option.value;
            } else {
                selectedDD1OptionValue = this.props.dd1Options.filter((item) => item.default && item.default === 1)[0]
                    .value;
                this.setState({
                    selectedDD1Option: this.props.dd1Options.filter((item) => item.default && item.default === 1)[0]
                });
            }

            dropdown1 = (
                <Select
                    className={dropdown1Class}
                    options={this.props.dd1Options}
                    menuPortalTarget={document.body}
                    value={this.props.dd1Options.filter(({ value }) => value === selectedDD1OptionValue)}
                    onChange={this.handleDropdown1Change}
                    classNamePrefix="select"
                    styles={DynamicFilterHelper.getDropdownStyle()}
                    maxMenuHeight={370}
                    components={{ DropdownIndicator }}
                    isSearchable={false}
                />
            );
        }

        let tooltipHelper = SubscriptionService.subscription() === 'Standard' ? dataStandardJSON : dataBasicJSON;
        let tooltipDirectory = SubscriptionService.subscription() === 'Standard' ? 'standard' : 'basic';
        const tooltipData =
            this.props.page &&
            tooltipHelper.find(
                (data) => data.page.toLowerCase() === this.props.page.toLowerCase() && data.report === this.props.title
            );

        return (
            <div
                className={
                    'widget-frame ' +
                    this.props.type +
                    (SubscriptionService.subscription() !== 'Standard' ||
                    (this.props.appliedFilters && this.props.appliedFilters['media_cycle'])
                        ? ' no-workbench'
                        : '') +
                    ' ' +
                    this.props.page
                }
                style={{ height: '' }}
            >
                <div
                    className="widget-title"
                    style={{
                        position: ''
                    }}
                >
                    {this.props.type === 'lopa' ? (
                        <div style={{ width: '28px', float: 'left' }}>
                            <img height="18px" className="alertInfo-icon" id="disclaimer-icon" src={alertIcon} alt="" />
                            <div className="alertInfo-popup-container">
                                <div className="alertInfo-popup">
                                    Configuration Data for this aircraft is missing so there may be inaccuracies in the
                                    LOPA displayed.{' '}
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.title}
                </div>
                <div className="widget-info" onClick={() => this.setInfoState()}>
                    ?&#x20DD;
                </div>
                <div className={this.state.helpOpen ? 'widget-info-popup' : 'widget-info-popup closed'}></div>

                {this.state.tooltip && tooltipData && (
                    <TooltipModal
                        type={this.props.type}
                        page={this.props.page}
                        data={tooltipData}
                        directory={tooltipDirectory}
                        close={() => this.setState({ tooltip: false })}
                    />
                )}

                {!this.props.loading && (
                    <div>
                        <Row
                            className={'widget-dropdown-' + this.props.type}
                            style={{
                                position: '',
                                top: '',
                                width: ''
                            }}
                        >
                            {dropdown1}
                            {dropdown2}
                        </Row>
                        {tabData}
                        <div
                            className={'widget-body ' + this.props.type + ' ' + this.props.page}
                            style={{
                                position: '',
                                marginTop: ''
                            }}
                        >
                            {this.props.body}
                        </div>
                    </div>
                )}

                {!this.props.loading && this.props.showOverflow && (
                    <div>
                        <div
                            className={
                                this.state.overflowOpen ? 'widget-overflow-popup' : 'widget-overflow-popup closed'
                            }
                            ref={this.wrapperRef}
                        >
                            <div className="widget-overflow-option" onClick={() => this.setHelp()}>
                                <img className="widget-overflow-img" src={InfoImage} alt="" />
                                <div className="widget-overflow-label">Info</div>
                            </div>
                            <div className="widget-overflow-divider" />
                            {![].includes(this.props.type) && (
                                <DownloadExcel
                                    dataAvailable={this.props.dataAvailable}
                                    tenantIcaoCode={this.props.tenantIcaoCode}
                                    appliedFilters={this.props.appliedFilters}
                                    app={this.props.app}
                                    pageName={this.props.pageName}
                                    title={this.props.title}
                                    workbench_type={this.props.workbench_type}
                                    type={this.props.type}
                                    page={this.props.page}
                                    showWorkbenchLink={SubscriptionService.subscription() === 'Standard'}
                                    reportSubType={this.props.reportSubType}
                                    data={this.props.data}
                                    tabData={this.props.tabData}
                                    tabDataBySections={this.props.tabDataBySections}
                                    allTabDataBySections={this.props.allTabDataBySections}
                                    setOverflowState={this.setOverflowState}
                                    contentType={this.props.contentType}
                                    flightInfo={this.props.flightInfo}
                                    dd1Options={this.props.dd1Options}
                                    dd2Options={this.props.dd2Options}
                                />
                            )}
                        </div>
                        <div
                            id={this.state.uniqueId}
                            className="widget-overflow"
                            onClick={() => this.setOverflowState(!this.state.overflowOpen)}
                        >
                            &#8942;
                        </div>
                    </div>
                )}

                {this.props.loading && (
                    <div className={'data-wrapper custom-loading ' + this.props.type + ' ' + this.props.page}>
                        <img src={Circular} alt="" />
                    </div>
                )}
            </div>
        );
    }
}

export default WidgetFrameNew;
