import { useEffect, useState } from 'react';
import UtilHelper from 'util/UtilHelper.js';
import { filterMetricsByTier } from 'components/widgets/AccessControl';
import 'assets/styles/coverage-token-tooltip.css';
import PillboxConfig from 'components/pillbox/PillboxConfig.js';

const pillboxConfig = new PillboxConfig();
const utilHelper = new UtilHelper();

function formatNumber(number) {
    if (!isNaN(number)) {
        let numberFormat = new Intl.NumberFormat('en-US');
        return numberFormat.format(number);
    }
    return '';
}

function CoverageToken(props) {
    let tokenData = '';
    let originalData = '';
    let percentage = 0;
    let denominator = '';

    if (props.loading) {
        tokenData = (
            <span className="info">
                <i className="fas fa-cog fa-spin"></i>
            </span>
        );
    } else {
        if (props.dataset != null) {
            if (props.dataset && props.dataset.data && props.dataset.data.length > 0) {
                let values = props.dataset.data[0];
                let value = (values.hasOwnProperty('value') && values.value) || values.value >= 0 ? values.value : null;
                let maxValue =
                    (values.hasOwnProperty('maxValue') && values.maxValue) || values.maxValue >= 0
                        ? values.maxValue
                        : null;

                if (props.percentage) {
                    // TODO: to be implemented when needed for media load rate.
                } else {
                    if (!isNaN(parseFloat(value))) {
                        value = parseFloat(value);
                    }
                    if (value !== null) {
                        if (props.type == 'int') {
                            tokenData = utilHelper.formatToUnitsForPillBox(
                                formatNumber(value).toString().replace(/[',']/g, '')
                            );
                            originalData = formatNumber(value);
                            originalData = originalData < 1000 ? null : originalData;
                        }
                        if (props.type == 'Hrs') {
                            tokenData = utilHelper.secondsToHmsForDynamicTabs(
                                formatNumber(value).toString().replace(/[',']/g, '')
                            );
                            originalData = formatNumber(value);
                        }
                        if (props.type == 'Mins') {
                            value = Number(value);
                            value = Math.floor(value / 60);
                            tokenData = utilHelper.formatToUnitsForPillBox(
                                formatNumber(value).toString().replace(/[',']/g, '')
                            );
                            originalData = formatNumber(value);
                            originalData = originalData < 1000 ? null : originalData;
                        }
                    }

                    if (value !== null && maxValue !== null) {
                        if (!isNaN(parseFloat(maxValue))) {
                            maxValue = parseFloat(maxValue);
                        }
                        maxValue = maxValue.toFixed(0);
                        denominator = <span className="denominator">/{formatNumber(maxValue)}</span>;
                        if (!isNaN(value) && !isNaN(maxValue) && Number(value) <= Number(maxValue)) {
                            percentage = ((value / maxValue) * 100).toFixed(0);
                        }
                    }
                }
            }
        }
    }

    if (tokenData === '') {
        tokenData = <span className="info">Data not available</span>;
        originalData = '';
    }

    if (percentage === 0 && !props.loading) {
        percentage = 100;
    }

    return (
        <div className="coverage-token" style={{ flex: props.isBig && '1 1 ' }}>
            <div className="coverage-token-background-fill" style={{ backgroundColor: props.color, width: '100%' }}>
                <div className="coverage-token-data">
                    <span
                        className="vertical-middle"
                        title={originalData && props.type !== 'Hrs' ? originalData : ''}
                        token-data={originalData}
                    >
                        {tokenData}
                    </span>
                    {denominator}
                </div>
                {false && originalData && props.type !== 'Hrs' ? (
                    <div className="customeTooltip">{originalData}</div>
                ) : (
                    ''
                )}
                {props.tooltip && props.tooltip === 'Avg. Connection Duration by Passenger device' ? (
                    <div className="coverage-token-title tooltip-cursor" data-pillbox-tooltip-2={props.tooltip}>
                        {props.title}
                    </div>
                ) : props.tooltip && props.tooltip !== 'Avg. Connection Duration by Passenger device' ? (
                    <div className="coverage-token-title tooltip-cursor" data-pillbox-tooltip={props.tooltip}>
                        {props.title}
                    </div>
                ) : (
                    <div className="coverage-token-title">{props.title}</div>
                )}
            </div>
        </div>
    );
}

function CoverageTokenWithMetricTooltip(props) {
    let tokenData = '';
    let originalData = '';
    let percentage = 0;
    let denominator = '';
    let tooltipData = Object.values(props.tooltipData);

    function TooltipModal({ tooltipData, randomKey }) {
        return (
            <div className="coverage-tooltip">
                {tooltipData && (
                    <div>
                        {tooltipData.map((data, index) => (
                            <p key={index * randomKey}>{data}</p>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    if (props.loading) {
        tokenData = (
            <span className="info">
                <i className="fas fa-cog fa-spin"></i>
            </span>
        );
    } else {
        if (props.dataset != null) {
            if (props.dataset && props.dataset.data && props.dataset.data.length > 0) {
                let values = props.dataset.data[0];
                let value = (values.hasOwnProperty('value') && values.value) || values.value >= 0 ? values.value : null;
                let maxValue =
                    (values.hasOwnProperty('maxValue') && values.maxValue) || values.maxValue >= 0
                        ? values.maxValue
                        : null;

                if (props.percentage) {
                    // TODO: to be implemented when needed for media load rate.
                } else {
                    if (!isNaN(parseFloat(value))) {
                        value = parseFloat(value);
                    }
                    if (value !== null) {
                        if (props.type == 'int') {
                            tokenData = utilHelper.formatToUnitsForPillBox(
                                formatNumber(value).toString().replace(/[',']/g, '')
                            );
                            originalData = formatNumber(value);
                            originalData = originalData < 1000 ? null : originalData;
                        }
                        if (props.type == 'Hrs') {
                            tokenData = utilHelper.secondsToHmsForDynamicTabs(
                                formatNumber(value).toString().replace(/[',']/g, '')
                            );
                            originalData = formatNumber(value);
                        }
                        if (props.type == 'Mins') {
                            value = Number(value);
                            value = Math.floor(value / 60);
                            tokenData = utilHelper.formatToUnitsForPillBox(
                                formatNumber(value).toString().replace(/[',']/g, '')
                            );
                            originalData = formatNumber(value);
                            originalData = originalData < 1000 ? null : originalData;
                        }
                    }

                    if (value !== null && maxValue !== null) {
                        if (!isNaN(parseFloat(maxValue))) {
                            maxValue = parseFloat(maxValue);
                        }
                        maxValue = maxValue.toFixed(0);
                        denominator = <span className="denominator">/{formatNumber(maxValue)}</span>;
                        if (!isNaN(value) && !isNaN(maxValue) && Number(value) <= Number(maxValue)) {
                            percentage = ((value / maxValue) * 100).toFixed(0);
                        }
                    }
                }
            }
        }
    }

    if (tokenData === '') {
        tokenData = <span className="info">Data not available</span>;
        originalData = '';
    }

    if (percentage === 0 && !props.loading) {
        percentage = 100;
    }

    return (
        <div className="coverage-token" style={{ flex: props.isBig && '1 1 ' }}>
            <div
                className="coverage-token-background-fill tooltipModal"
                style={{ backgroundColor: props.color, width: '100%' }}
            >
                <div className="coverage-token-data">
                    <span
                        className="vertical-middle"
                        title={originalData && props.type !== 'Hrs' ? originalData : ''}
                        token-data={originalData}
                    >
                        {tokenData}
                    </span>
                    {denominator}
                </div>
                {false && originalData && props.type !== 'Hrs' ? (
                    <div className="customeTooltip">{originalData}</div>
                ) : (
                    ''
                )}
                {props.tooltip && props.tooltip === 'Avg. Connection Duration by Passenger device' ? (
                    <div className="coverage-token-title tooltip-cursor" data-pillbox-tooltip-2={props.tooltip}>
                        {props.title}
                    </div>
                ) : props.tooltip && props.tooltip !== 'Avg. Connection Duration by Passenger device' ? (
                    <div className="coverage-token-title tooltip-cursor" data-pillbox-tooltip={props.tooltip}>
                        {props.title}
                    </div>
                ) : (
                    <div className="coverage-token-title">{props.title}</div>
                )}
                {tooltipData.length > 0 && <TooltipModal tooltipData={tooltipData} randomKey={props.randomKey} />}
            </div>
        </div>
    );
}

export default function PillboxTabs(props) {
    const [totalSections, setTotalSections] = useState([]);

    useEffect(() => {
        let sectionArray = pillboxConfig.getPillBoxSections(props.title);

        if (!props.ipsEnabled && sectionArray.length > 0) {
            sectionArray = sectionArray.filter((section) => !section.includes('login'));
        }

        // remove some metrics from basic tier
        if (props.title.includes('Electronic')) {
            sectionArray = filterMetricsByTier('ereader', sectionArray);
        } else if (props.title === 'Live TV') {
            sectionArray = filterMetricsByTier('live-tv', sectionArray);
        }

        setTotalSections(sectionArray);
    }, [props.title, props.ipsEnabled]);

    let staticData = {
        flights: {
            color: '',
            title: 'Flights Reported',
            type: 'int'
        },
        tails: {
            color: '',
            title: 'Tails Reported',
            type: 'int'
        },
        destination_countries: {
            color: '',
            title: 'Destination Countries',
            type: 'int'
        },
        destination_airports: {
            color: '',
            title: 'Destination Airports',
            type: 'int'
        },
        routes: {
            color: '',
            title: 'Routes',
            type: 'int'
        },
        total_sessions: {
            color: '',
            title: 'Total sessions',
            type: 'int'
        },
        total_page_views: {
            color: '',
            title: 'Total Page Views',
            type: 'int'
        },
        avg_session_duration: {
            color: '',
            title: 'Avg. Session Duration ',
            type: 'Hrs'
        },
        avg_page_view_duration: {
            color: '',
            title: 'Avg. Page View Duration',
            type: 'Hrs'
        },
        login_attempts: {
            color: '',
            title: 'Login Attempts',
            tooltip: 'Login Attempts',
            type: 'int'
        },
        devices: {
            color: '',
            title: 'Devices',
            type: 'int'
        },
        total_devices: {
            color: '',
            title: 'Total Devices',
            type: 'int'
        },
        unique_devices: {
            color: '',
            title: 'Paired Devices',
            type: 'int'
        },
        total_readers: {
            color: '',
            title: 'Total Readers',
            type: 'int'
        },
        total_reading_time: {
            color: '',
            title: 'Total Reading Time',
            type: 'Hrs'
        },
        avg_reading_time: {
            color: '',
            title: 'Avg. Reading Time',
            type: 'Hrs'
        },
        total_page_reads: {
            color: '',
            title: 'Total Page Reads',
            type: 'int'
        },
        avg_page_reads: {
            color: '',
            title: 'Avg. Page Reads',
            type: 'int'
        },
        total_title_views: {
            color: '',
            title: 'Total Title Views',
            type: 'int'
        },
        unique_title_views: {
            color: '',
            title: 'Unique Title Views',
            type: 'int'
        },
        avg_title_views: {
            color: '',
            title: 'Avg. Title Views',
            type: 'int'
        },
        paired_devices: {
            color: '',
            title: 'Paired Devices',
            type: 'int'
        },
        total_connections: {
            color: '',
            title: 'Total Connections',
            type: 'int'
        },
        total_disconnections: {
            color: '',
            title: 'Total Disconnections',
            type: 'int'
        },
        pairing_attempts: {
            color: '',
            title: 'Pairing Attempts',
            type: 'int'
        },
        bt_users: {
            color: '',
            title: 'Unique Passengers Us...',
            tooltip: 'Unique Passengers used Bluetooth',
            type: 'int'
        },
        avg_connections: {
            color: '',
            title: 'Avg. Connections per Fl...',
            tooltip: 'Avg. Connections per Flight',
            type: 'int'
        },
        avg_disconnections: {
            color: '',
            title: 'Avg. Disconnections pe...',
            tooltip: 'Avg. Disconnections per Flight',
            type: 'int'
        },
        avg_connection_time: {
            color: '',
            title: 'Avg. Connection Durati...',
            tooltip: 'Avg. Connection Duration by Passenger device',
            type: 'Hrs'
        },
        total_channel_hits: {
            color: '',
            title: 'Total Views',
            tooltip: 'Total Channel Hits by all passengers across flights during the selected date range',
            type: 'int'
        },
        total_viewing_time: {
            color: '',
            title: 'Total Viewing Time',
            tooltip: 'Total Viewing Time by all passengers across flights during the selected date range',
            type: 'Hrs'
        },
        total_flight_duration: {
            color: '',
            title: 'Total Flight Time',
            tooltip: 'Total Flight time across flights during the selected date range',
            type: 'Hrs'
        },
        total_unique_passengers: {
            color: '',
            title: 'Unique Viewers',
            tooltip: 'Unique passengers that watched Live Channels across flights during the selected date range',
            type: 'int'
        },
        avg_passengers_per_flight: {
            color: '',
            title: 'Avg. Viewers per Flight',
            tooltip: 'Avg. passengers watched Live TV per Flight',
            type: 'int'
        },
        avg_time_per_flight: {
            color: '',
            title: 'Avg. Viewing Time per Flight',
            tooltip: 'Avg. Viewing time of Live TV per flight',
            type: 'Hrs'
        },
        avg_time_per_passenger: {
            color: '',
            title: 'Avg. Viewing Time per Viewer',
            tooltip: 'Avg. Viewing time of Live TV per Passenger',
            type: 'Hrs'
        },
        avg_content_viewing_time: {
            color: '',
            title: 'Avg. Content Viewing Time',
            tooltip: 'Avg. Content Viewing Time of PED and Seatback per Passenger per Flight',
            type: 'Hrs'
        }
    };

    const randomKey = () => {
        return Math.round(Math.random() * 100000);
    };

    const countRows = (count) => {
        let firstRow = 0,
            secondRow = 0,
            thirdRow = 0;

        if (count < 3) {
            firstRow = 2;
        } else if (count === 3) {
            firstRow = 3;
        } else if (count === 4) {
            firstRow = 4;
        } else if (count === 5) {
            firstRow = 5;
        } else if (count === 6) {
            firstRow = 3;
            secondRow = 3;
        } else if (count === 7) {
            firstRow = 4;
            secondRow = 3;
        } else if (count === 8) {
            firstRow = 4;
            secondRow = 4;
        } else if (count === 9) {
            firstRow = 5;
            secondRow = 4;
        } else if (count === 10) {
            firstRow = 5;
            secondRow = 5;
        } else if (count === 11) {
            firstRow = 5;
            secondRow = 3;
            thirdRow = 3;
        } else if (count === 12) {
            firstRow = 5;
            secondRow = 4;
            thirdRow = 3;
        } else if (count === 13) {
            firstRow = 5;
            secondRow = 4;
            thirdRow = 4;
        }
        return { firstRow, secondRow, thirdRow };
    };

    const renderBoxes = () => {
        let rows,
            count = totalSections.length;

        rows = countRows(count);
        if (props.title === 'Companion App') {
            staticData.devices = staticData.unique_devices;
        }

        return (
            <>
                <div className="coverage-body">
                    {Array.from(Array(rows.firstRow), (a, i) => {
                        if (props.data) {
                            let data = props.data.find((box) => box.key == totalSections[i]);
                            if (data) {
                                return (
                                    <CoverageToken
                                        isBig={count != 5}
                                        key={randomKey()}
                                        color={staticData[totalSections[i]].color}
                                        title={staticData[totalSections[i]].title}
                                        dataset={data}
                                        loading={props.loading}
                                        type={staticData[totalSections[i]].type}
                                    />
                                );
                            } else {
                                return '';
                            }
                        }
                        return (
                            <CoverageToken
                                isBig={count != 5}
                                key={randomKey()}
                                color={staticData[totalSections[i]].color}
                                title={staticData[totalSections[i]].title}
                                dataset={totalSections[i]}
                                loading={props.loading}
                                type={staticData[totalSections[i]].type}
                            />
                        );
                    })}
                </div>
                {rows.secondRow > 0 && (
                    <div
                        className="coverage-body-2"
                        style={{
                            justifyContent: count === 10 && 'space-between'
                        }}
                    >
                        {Array.from(Array(rows.secondRow), (a, i) => {
                            i += rows.firstRow;
                            if (props.data) {
                                let data = props.data.find((box) => box.key === totalSections[i]);
                                if (data) {
                                    return renderSubsequentRowBoxes(
                                        data,
                                        count,
                                        randomKey(),
                                        staticData[data.key].color,
                                        staticData[data.key].title,
                                        staticData[data.key].tooltip,
                                        props.loading,
                                        staticData[data.key].type
                                    );
                                } else {
                                    return '';
                                }
                            }
                            return (
                                <CoverageToken
                                    isBig={count === 6 || count === 8 || count === 9 || count === 7 || count === 13}
                                    key={randomKey()}
                                    color={staticData[totalSections[i]].color}
                                    title={staticData[totalSections[i]].title}
                                    tooltip={staticData[totalSections[i]].tooltip}
                                    dataset={totalSections[i]}
                                    loading={props.loading}
                                    type={staticData[totalSections[i]].type}
                                />
                            );
                        })}
                    </div>
                )}
                {rows.thirdRow > 0 && (
                    <div
                        className="coverage-body-2"
                        style={{
                            justifyContent: count == 10 && 'space-between'
                        }}
                    >
                        {Array.from(Array(rows.thirdRow), (a, i) => {
                            i += rows.firstRow + rows.secondRow;
                            if (props.data) {
                                let data = props.data.find((box) => box.key === totalSections[i]);
                                if (data) {
                                    return renderSubsequentRowBoxes(
                                        data,
                                        count,
                                        randomKey(),
                                        staticData[data.key].color,
                                        staticData[data.key].title,
                                        staticData[data.key].tooltip,
                                        props.loading,
                                        staticData[data.key].type
                                    );
                                } else {
                                    return '';
                                }
                            }
                            return (
                                <CoverageToken
                                    isBig={count === 6 || count === 8 || count === 9 || count === 7 || count === 13}
                                    key={randomKey()}
                                    color={staticData[totalSections[i]].color}
                                    title={staticData[totalSections[i]].title}
                                    tooltip={staticData[totalSections[i]].tooltip}
                                    dataset={totalSections[i]}
                                    loading={props.loading}
                                    type={staticData[totalSections[i]].type}
                                />
                            );
                        })}
                    </div>
                )}
            </>
        );
    };

    const renderSubsequentRowBoxes = (data, count, key, color, title, tooltip, loading, type) => {
        let tooltipData = {};
        if (data.key === 'login_attempts' && !props.loading) {
            let loginSuccess = props.data.find((box) => box.key === 'login_success_rate');

            tooltipData['LoginSuccessRate'] =
                'Login Success Rate: ' + utilHelper.formatToPercentage(loginSuccess.data[0].value, 2);
            return (
                <CoverageTokenWithMetricTooltip
                    isBig={count === 11 || count === 6 || count === 8 || count === 9 || count === 7 || count === 13}
                    randomKey={randomKey()}
                    color={staticData[data.key].color}
                    title={staticData[data.key].title}
                    tooltip={''}
                    tooltipData={tooltipData}
                    dataset={data}
                    loading={props.loading}
                    type={staticData[data.key].type}
                />
            );
        }

        return (
            <CoverageToken
                isBig={count === 11 || count === 6 || count === 8 || count === 9 || count === 7 || count === 13}
                randomKey={randomKey()}
                color={staticData[data.key].color}
                title={staticData[data.key].title}
                tooltip={staticData[data.key].tooltip}
                dataset={data}
                loading={props.loading}
                type={staticData[data.key].type}
                key={randomKey()}
            />
        );
    };

    return totalSections.length > 0 ? renderBoxes() : '';
}
