import React from 'react';
import _, { debounce } from 'lodash';
import { Card } from 'reactstrap';
import ErrorBoundary from 'util/ErrorBoundary.js';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import * as AppConstants from 'constants/app/constants';
import TableWidget from 'components/table/TableWidget.js';
import FiltersService from '../../services/FiltersService';
import SubscriptionService from 'services/SubscriptionService';

class TableContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            tails: [],
            columns: this.props.columns
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.appliedFilters, prevProps.appliedFilters)) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        let requested_sections = ['ips'];
        this.setState({ loading: true });
        if (FiltersService.dateRangeFiltersData !== '') {
            const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));

            selectedFiltersDeepClone.requested_sections = requested_sections;

            if (this.props.pageName === 'Seatback') {
                selectedFiltersDeepClone.usage_source = 'Seatback';
            } else if (this.props.pageName === 'Companion App') {
                delete selectedFiltersDeepClone.usage_source;
                selectedFiltersDeepClone.launched_by = ['Companion App'];
            }

            if (SubscriptionService.subscription() !== '') {
                selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
            }

            if (this.props.app) {
                selectedFiltersDeepClone['app'] = this.props.app;
            }

            if (this.cancelRequestSource) {
                this.cancelRequestSource.cancel('Operation canceled by the user.');
            }

            this.throttleFetch(selectedFiltersDeepClone);
        }
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchWorkbenchData(selectedFiltersDeepClone, this.cancelRequestSource.token)
            .then(async (response) => {
                if (!response || !response.data) {
                    this.setState({
                        error: true,
                        tails: [],
                        loading: false
                    });
                } else {
                    let tails = [];
                    if (response.data.rows) {
                        let preSignedUrl = response.data.rows;

                        try {
                            let rawDataResponse = await axios.get(preSignedUrl);
                            tails = rawDataResponse.data;
                        } catch (error) {
                            console.log(error);
                        }
                    }

                    this.setState({ tails: tails, loading: false });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, loading: false });
                } else {
                    this.setState({ error: true, tails: [], loading: false });
                }
            });
    }

    render() {
        return (
            <Card className="bar-chart-gap">
                <ErrorBoundary>
                    <TableWidget
                        type="table"
                        pageName={this.props.pageName}
                        loading={this.state.loading}
                        title={this.props.title}
                        tailsList={this.state.tails}
                        columns={this.state.columns}
                        appliedFilters={this.props.appliedFilters.api}
                        page={this.props.page}
                        app={this.props.app}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                    />
                </ErrorBoundary>
            </Card>
        );
    }
}

export default TableContainer;
