import Excel from 'components/excel/ExcelHelper.js';
import UtilHelper from 'util/UtilHelper.js';
import * as XLSX from 'xlsx';

const utilHelper = new UtilHelper();

class AdminExcelHelper extends Excel {
    exportExcel(activeLicenses, inactiveLicenses, tailsData, apps, stage, option) {
        let wb = XLSX.utils.book_new();

        let dataFound = false;

        try {
            let reportData;
            let formatReportType;
            let formattedData = [];
            if (option == 'Active Licenses') {
                reportData = activeLicenses;
                formatReportType = this.formatActiveInactive;
            } else if (option == 'Inactive Licenses') {
                reportData = inactiveLicenses;
                formatReportType = this.formatActiveInactive;
            } else if (option == 'Tails') {
                reportData = tailsData;
                formatReportType = this.formatTails;
            }

            if (reportData) {
                let tabTitle = option;

                reportData = this.formatReportData(formatReportType, reportData, option, apps, stage, formattedData);

                let sheet = XLSX.utils.json_to_sheet(reportData);
                sheet['!cols'] = [
                    { wch: 35 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 }
                ];
                sheet = this.addLinksToFooter(reportData, sheet);
                XLSX.utils.book_append_sheet(wb, sheet, tabTitle);
                dataFound = true;
            }
        } catch (e) {
            console.log(e);
        }

        if (!dataFound) {
            XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([]), 'Sheet1');
        }

        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${year}${month}${day}`;
        XLSX.writeFile(wb, option + formattedDate + '.xlsx');
    }

    formatActiveInactive(formattedData, reportData, apps, title) {
        let airlineName = title;
        let tier = ' ';
        let start = '  ';
        let expiration = '   ';
        let lastUpdated = '    ';

        let row = {
            [airlineName]: 'Airline',
            [tier]: 'Tier',
            [start]: 'Start',
            [expiration]: 'Expiration',
            [lastUpdated]: 'Last Updated'
        };
        formattedData.push(row);

        for (let data of reportData) {
            row = {
                [airlineName]: data.airline_name ?? data.airline_code,
                [tier]: data.subscription_tier,
                [start]: data.start_display,
                [expiration]: data.expiration_display,
                [lastUpdated]: data.last_updated_display
            };
            formattedData.push(row);
        }
    }

    formatTails(formattedData, tailsData, apps, title) {
        let airlineIcao = title;
        let tail = ' ';
        let tier = '  ';
        apps = apps.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

        let row = {
            [airlineIcao]: 'Airline',
            [tail]: 'Tail',
            [tier]: 'Tier'
        };
        apps.forEach((value, index) => {
            row[' '.repeat(index + 3)] = value;
        });

        formattedData.push(row);

        for (let data of tailsData) {
            row = {
                [airlineIcao]: data.airline_operator_icao,
                [tail]: data.tail_number,
                [tier]: data.tier
            };

            apps.forEach((value, index) => {
                row[' '.repeat(index + 3)] = data.applications.includes(value) ? 'Yes' : 'No';
            });
            formattedData.push(row);
        }
    }

    formatReportData(formatReportType, reportData, option, apps, stage, formattedData) {
        let originalTitle = stage;

        // Hack to add rows before the Header row in XLSX
        let title = 'NEXT Insights Export';
        let value = ' ';
        let row = {
            [title]: '',
            [value]: ' ',
            '  ': '',
            '   ': '',
            '    ': '',
            '     ': '',
            '      ': ''
        };
        formattedData.push(row);

        row = {
            [title]: 'Report',
            [value]: option
        };
        formattedData.push(row);

        row = {
            [title]: 'Creation Date',
            [value]: this.currentDateTimeForExcelDownload()
        };
        formattedData.push(row);

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: 'Copyright ©2021 Panasonic Avionics Corporation. All rights reserved.',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: option,
            [value]: ''
        };

        formattedData.push(row);

        formatReportType(formattedData, reportData, apps, title);

        return formattedData;
    }
}

export default AdminExcelHelper;
