import { Component } from 'react';
import HorizontalBarChart from 'components/horizontal-bar-chart/HorizontalBarChart';
import WidgetFrame from 'components/widget-frame/WidgetFrameNew';

class HorizontalBarChartWidget extends Component {
    constructor(props) {
        super(props);

        const _unit = props.unit;
        const text = 'Total';

        this.state = {
            data: [],
            unit: _unit,
            text: text
        };
    }

    updateWidgetData = (newData) => {
        this.setState({
            data: newData,
            unit: newData.unit ? newData.unit : this.props.unit,
            text: newData.text ? newData.text : this.state.text
        });
    };

    checkDataAvailable = () => {
        let data;
        let dataAvailable = false;
        let blurred = false;

        if (this.props && this.props.data && this.props.reportType === 'events') {
            data = JSON.parse(JSON.stringify(this.props.data));
            dataAvailable = true;
        } else if (this.state && this.state.data && this.state.data.data && this.state.data.data.length > 0) {
            data = JSON.parse(JSON.stringify(this.state.data.data)); // @todo @important - updating container "data" props on "splice"
            dataAvailable = true;
        }
        if (dataAvailable) {
            let allZeroValues = true;

            for (let i = 0; i < data.length; i++) {
                if (data[i].value !== '0' && data[i].value !== null) {
                    allZeroValues = false;
                    break;
                }
            }

            if (allZeroValues) {
                dataAvailable = true;
                blurred = true;
            }
        } else {
            dataAvailable = true;
            blurred = true;
        }
        return !blurred;
    };

    render() {
        let body = (
            <HorizontalBarChart
                maxRows={10}
                page={this.props.page}
                type={this.props.type}
                loading={this.props.loading}
                data={this.props.reportType === 'events' ? this.props.data : this.state.data}
                unit={this.state.unit || 'count'}
                text={this.state.text || 'total'}
                routes={this.props.routes}
                reportType={this.props.reportType}
                color={this.props.color}
                showWorkbenchLink={this.props.showWorkbenchLink || false}
                appliedFilters={this.props.appliedFilters}
            />
        );

        let dd1Options = this.props.dd1Options;
        return (
            <WidgetFrame
                id={Math.random()}
                type="bar"
                page={this.props.page}
                pageName={this.props.pageName}
                data={this.props.data}
                dataset={this.props.dataset}
                loading={this.props.loading}
                title={this.props.title}
                updateData={this.updateWidgetData}
                showTabs={false}
                showOverflow={true}
                showDropdown={true}
                unit={this.props.unit}
                dataObject={this.props.dataObject}
                dd1Options={dd1Options}
                dd2Options={this.props.dd2Options}
                component_type="bar"
                workbench_type={this.props.workbench_type || ''}
                tenantIcaoCode={this.props.tenantIcaoCode || ''}
                appliedFilters={this.props.appliedFilters}
                app={this.props.app}
                dataAvailable={this.checkDataAvailable()}
                body={body}
            />
        );
    }
}

export default HorizontalBarChartWidget;
