import moment from 'moment';
import * as XLSX from 'xlsx';
import TrendConfig from 'components/trends/TrendConfig.js';
import UtilHelper from 'util/UtilHelper.js';
import SubscriptionService from 'services/SubscriptionService';
import VerticalBarChartConfig from 'components/vertical-bar-chart/VerticalBarChartConfig.js';

const utilHelper = new UtilHelper();
const verticalBarChartConfig = new VerticalBarChartConfig();
const trendConfig = new TrendConfig();

class Excel {
    exportExcel(
        airlineIcao,
        reportType,
        reportSubType,
        title,
        fileNamePrefix,
        data,
        filters,
        showComparison,
        dropdown1Options,
        dropdown2Options,
        page,
        workbenchType
    ) {
        if (filters.media_cycle) {
            showComparison = false;
        }

        if (reportType === 'trends') {
            this.exportExcelTrends(
                airlineIcao,
                reportSubType,
                title,
                fileNamePrefix,
                data,
                filters,
                showComparison,
                page
            );
        } else if (reportType === 'screen-transition') {
            this.exportExcelScreenTransition(airlineIcao, title, fileNamePrefix, data, filters, showComparison, page);
        } else if (reportType === 'vertical-bar') {
            this.exportExcelVerticalBar(
                airlineIcao,
                title,
                fileNamePrefix,
                data,
                filters,
                showComparison,
                reportSubType,
                page
            );
        } else if (reportType === 'lopa') {
            this.exportExcelLopa(airlineIcao, title, fileNamePrefix, data, filters, showComparison, page, reportType);
        } else {
            this.exportExcelCommon(
                airlineIcao,
                title,
                fileNamePrefix,
                data,
                filters,
                showComparison,
                dropdown1Options,
                dropdown2Options,
                page,
                reportType,
                workbenchType
            );
        }
    }

    exportExcelCommon(
        airlineIcao,
        title,
        fileNamePrefix,
        data,
        filters,
        showComparison,
        dropdown1Options,
        dropdown2Options,
        page,
        reportType,
        workbenchType
    ) {
        let wb = XLSX.utils.book_new();

        let dataFound = false;

        if (
            dropdown1Options &&
            Object.keys(dropdown1Options).length > 0 &&
            dropdown2Options &&
            Object.keys(dropdown2Options).length > 0
        ) {
            for (let [dropdown2Option] of Object.entries(dropdown2Options[dropdown1Options[0].value])) {
                let reportData = null;

                try {
                    reportData = this.resolve(data, `${dropdown1Options[0].value}.${dropdown2Option.value}.data`);
                } catch (e) {}

                try {
                    reportData = this.formatReportData(
                        airlineIcao,
                        reportData,
                        filters,
                        title,
                        dropdown2Option.label,
                        'Change (%)',
                        showComparison,
                        dropdown2Option.unit,
                        page
                    );
                    let sheet = XLSX.utils.json_to_sheet(reportData);
                    sheet['!cols'] = [{ wch: 28 }, { wch: 25 }, { wch: 25 }, { wch: 25 }];
                    sheet = this.addLinksToFooter(reportData, sheet);
                    XLSX.utils.book_append_sheet(wb, sheet, dropdown2Option.label);
                    dataFound = true;
                } catch (e) {}
            }
        } else if (dropdown1Options && Object.keys(dropdown1Options).length > 0) {
            dropdown1Options.forEach((dropdown1Option) => {
                try {
                    let reportData = this.resolve(data, `${dropdown1Option.value}.data`);
                    if (reportData) {
                        if (
                            reportType === 'donut' &&
                            ['Entertainment Usage', 'Passenger Touchpoints', 'Application Usage'].includes(title)
                        ) {
                            let comparisonColumnTitle =
                                title === 'Application Usage' ? 'Usage Change (%)' : 'Engagement Change (%)';
                            reportData = this.exportExcelDonut(
                                airlineIcao,
                                reportData,
                                filters,
                                title,
                                dropdown1Option.label,
                                comparisonColumnTitle,
                                showComparison,
                                dropdown1Option.unit,
                                page
                            );
                            let sheet = XLSX.utils.json_to_sheet(reportData);
                            sheet['!cols'] = [
                                { wch: 35 },
                                { wch: 30 },
                                { wch: 31 },
                                { wch: 32 },
                                { wch: 40 },
                                { wch: 24 },
                                { wch: 32 }
                            ];
                            sheet = this.addLinksToFooter(reportData, sheet);
                            XLSX.utils.book_append_sheet(wb, sheet, dropdown1Option.label);
                            dataFound = true;
                        } else {
                            if (title === 'Languages' && page === 'Seatback') {
                                reportData = reportData.map((obj) => {
                                    obj.label = obj.name;
                                    return obj;
                                });
                            }

                            let valueName = dropdown1Option.label;
                            if (valueName === 'Device Browsers' || valueName === 'Device OS') {
                                valueName = 'Percentage';
                            }
                            let tabTitle = dropdown1Option.label;
                            if (dropdown1Option.short_label) {
                                tabTitle = dropdown1Option.short_label;
                            }
                            if (dropdown1Option.title) {
                                title = dropdown1Option.title;
                            }

                            if (workbenchType === 'titles') {
                                let tableColumnTitles = [];

                                if (page !== 'eReader') {
                                    tabTitle = dropdown1Option.tab_title;
                                }

                                tableColumnTitles.push(title);
                                if (dropdown1Option.parent_title) {
                                    tableColumnTitles.push(dropdown1Option.parent_title.title);
                                }

                                if (showComparison) {
                                    if (filters.media_cycle) {
                                        tableColumnTitles.push(
                                            dropdown1Option.label + ' (Media Cycle)',
                                            dropdown1Option.label + ' (Comparison Cycle)',
                                            dropdown1Option.label + ' Change (%)'
                                        );
                                        dropdown1Option.additional_metrics.forEach((addMetric) => {
                                            tableColumnTitles.push(addMetric.title + ' (Media Cycle)');
                                        });
                                    } else {
                                        tableColumnTitles.push(
                                            dropdown1Option.label + ' (Date Range)',
                                            dropdown1Option.label + ' (Comparison Range)',
                                            dropdown1Option.label + ' Change (%)'
                                        );
                                        dropdown1Option.additional_metrics.forEach((addMetric) => {
                                            tableColumnTitles.push(addMetric.title + ' (Date Range)');
                                        });
                                    }

                                    reportData = this.formatReportDataForTitles(
                                        airlineIcao,
                                        reportData,
                                        filters,
                                        tableColumnTitles,
                                        dropdown1Option,
                                        page,
                                        reportType,
                                        false
                                    );
                                } else {
                                    tableColumnTitles.push(valueName);

                                    dropdown1Option.additional_metrics.forEach((addMetric) => {
                                        if (addMetric.free_tier !== undefined) {
                                            tableColumnTitles.push(addMetric.title);
                                        }
                                    });

                                    reportData = this.formatReportDataForTitles(
                                        airlineIcao,
                                        reportData,
                                        filters,
                                        tableColumnTitles,
                                        dropdown1Option,
                                        page,
                                        reportType,
                                        true
                                    );
                                }
                            } else {
                                reportData = this.formatReportData(
                                    airlineIcao,
                                    reportData,
                                    filters,
                                    title,
                                    valueName,
                                    'Change (%)',
                                    showComparison,
                                    dropdown1Option.unit,
                                    page,
                                    dropdown1Option.label,
                                    reportType
                                );
                            }

                            let sheet = XLSX.utils.json_to_sheet(reportData);
                            sheet['!cols'] = [
                                { wch: 35 },
                                { wch: 30 },
                                { wch: 30 },
                                { wch: 30 },
                                { wch: 30 },
                                { wch: 30 },
                                { wch: 30 },
                                { wch: 30 },
                                { wch: 30 },
                                { wch: 30 }
                            ];
                            sheet = this.addLinksToFooter(reportData, sheet);
                            XLSX.utils.book_append_sheet(wb, sheet, tabTitle);
                            dataFound = true;
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            });
        } else {
            data = this.formatReportData(
                airlineIcao,
                data,
                filters,
                title,
                'Value',
                'Change (%)',
                showComparison,
                '',
                page
            );

            let sheet = XLSX.utils.json_to_sheet(data);
            sheet['!cols'] = [{ wch: 35 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];
            sheet = this.addLinksToFooter(data, sheet);
            XLSX.utils.book_append_sheet(wb, sheet, title);
            dataFound = true;
        }

        if (!dataFound) {
            XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([]), 'Sheet1');
        }
        XLSX.writeFile(wb, `${fileNamePrefix}.xlsx`);
    }

    formatReportData(
        airlineIcao,
        data,
        filters,
        title,
        value,
        comparisionValue,
        showComparison,
        unit,
        page,
        label,
        reportType
    ) {
        try {
            let formattedData = [];

            let originalTitle = title;
            let originalValue = value;
            let originalComparisionValue = comparisionValue;
            let isMediaLaunchByContentType = originalTitle === 'Media launches by Content Type';
            let isApplications = originalTitle === 'Application Usage';
            let isDateRangeCurrentYear = filters['date_range_text'] === 'Current Year';

            // Hack to add rows before the Header row in XLSX
            title = 'NEXT Insights Export';
            value = ' ';
            let comparisonRowData = '  ';
            let comparisonMetricValue = '   ';
            comparisionValue = '    ';
            let row = {
                [title]: '',
                [value]: ' ',
                '  ': ''
            };
            formattedData.push(row);

            row = {
                [title]: 'Airline',
                [value]: airlineIcao
            };
            formattedData.push(row);

            row = {
                [title]: 'Report',
                [value]: page
            };
            formattedData.push(row);

            row = {
                [title]: 'Creation Date',
                [value]: this.currentDateTimeForExcelDownload()
            };
            formattedData.push(row);

            if (filters.media_cycle) {
                row = {
                    [title]: 'Media Cycle',
                    [value]: filters.media_cycle
                };
            } else {
                row = {
                    [title]: 'Date Range',
                    [value]: utilHelper.formatDateRange(filters.date_range)
                };
            }
            formattedData.push(row);

            let showComparisonHeader = false;
            data.forEach((dataItem, index) => {
                if (
                    showComparison &&
                    dataItem.hasOwnProperty('comparison_value') &&
                    dataItem.hasOwnProperty('comparison_raw_value')
                ) {
                    showComparisonHeader = true;
                }
            });

            if (showComparisonHeader) {
                if (filters.media_cycle) {
                    row = {
                        [title]: 'Comparison Cycle',
                        [value]: ''
                    };
                } else {
                    row = {
                        [title]: 'Comparison Range',
                        [value]: isDateRangeCurrentYear
                            ? 'Not Applicable'
                            : utilHelper.formatDateRange(filters.comparison_range)
                    };
                }
                formattedData.push(row);
            }
            if (filters) {
                const excludeList = [
                    'date_range',
                    'dateRangeText',
                    'comparison_range',
                    'comparison_range_text',
                    'media_cycle',
                    'date_range_text'
                ];
                const displayNames = {
                    flight_origins: 'Flight Origin',
                    flight_destinations: 'Flight Destination',
                    flight_numbers: 'Flight Number',
                    system_types: 'System',
                    aircraft_types: 'Aircraft Type',
                    flight_haul_types: 'Flight Haul',
                    seat_classes: 'Seat Class',
                    gui_languages: 'GUI Language',
                    tails: 'Tail'
                };
                for (const [key, values] of Object.entries(filters)) {
                    if (!excludeList.includes(key)) {
                        row = {
                            [title]: displayNames[key] ? displayNames[key] : key,
                            [value]: Array.isArray(values) ? values.join(', ') : values
                        };
                        formattedData.push(row);
                    }
                }
            }

            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);

            let setContectUs = '  ';
            row = {
                [title]: 'Terms Of Use',
                [value]: 'Privacy Policy',
                [setContectUs]: 'Contact Us'
            };
            formattedData.push(row);

            row = {
                [title]: 'Copyright ©2021 Panasonic Avionics Corporation. All rights reserved.',
                [value]: ''
            };
            formattedData.push(row);

            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);
            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);

            row = {
                [title]: label,
                [value]: ''
            };
            formattedData.push(row);
            if (isMediaLaunchByContentType) {
                row = {
                    [title]: 'Media Type',
                    [value]: `${utilHelper.formatDateRange(filters.date_range)}`,
                    ['  ']: ` ${utilHelper.formatDateRange(filters.date_range)}`,
                    [comparisonMetricValue]: label + ' Change (%)',
                    ['    ']: 'Percentage Engagement - Date Range',
                    ['     ']: 'Percentage Engagement - Preceding Date Range',
                    ['      ']: 'Engagement ' + originalComparisionValue
                };
            } else if (showComparisonHeader) {
                if (reportType === 'donut') {
                    if (filters.media_cycle) {
                        row = {
                            [title]: originalTitle,
                            [value]: filters.media_cycle,
                            [comparisonRowData]: 'No Cycle',
                            [comparisonMetricValue]: label + ' Change (%)',
                            [comparisionValue]: 'Engagement ' + originalComparisionValue
                        };
                    } else {
                        row = {
                            [title]: originalTitle,
                            [value]: `${utilHelper.formatDateRange(filters.date_range)}`,
                            [comparisonRowData]: `${utilHelper.formatDateRange(filters.comparison_range)}`,
                            [comparisonMetricValue]: label + ' Change (%)',
                            [comparisionValue]: 'Engagement ' + originalComparisionValue
                        };
                    }
                } else {
                    if (filters.media_cycle) {
                        row = {
                            [title]: originalTitle,
                            [value]: filters.media_cycle,
                            [comparisonRowData]: 'No Cycle',
                            [comparisionValue]: originalComparisionValue
                        };
                    } else {
                        row = {
                            [title]: originalTitle,
                            [value]: 'Date Range',
                            [comparisonRowData]: 'Comparison Range',
                            [comparisionValue]: originalComparisionValue
                        };
                    }
                }
            } else {
                row = {
                    [title]: originalTitle,
                    [value]: filters.media_cycle ? 'Media Cycle (' + filters.media_cycle + ')' : originalValue
                };
            }
            formattedData.push(row);

            for (let i = 0; data && i < data.length; i++) {
                if (i === 10) {
                }
                let row = {
                    [title]: '',
                    [value]: ''
                };
                if (data[i].hasOwnProperty('label')) {
                    row[title] = data[i].label;
                }
                if (data[i].hasOwnProperty('value')) {
                    let roundedData;
                    if (unit && data[i].value) {
                        if (unit === 'count') {
                            roundedData = { t: 'n', v: data[i].value, z: '#,##0' };
                        } else if (unit === 'hr') {
                            roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i].value) / 3600 / 24 };
                        }
                    }

                    if (data[i].unit && data[i].value) {
                        if (data[i].unit === 'time') {
                            roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i].value) / 3600 / 24 };
                        } else {
                            roundedData = { t: 'n', v: data[i].value, z: '#,##0' };
                        }
                    }
                    let dataValue = data[i].value;
                    let unit_pr = [
                        'Media launches by Content Type',
                        'Entertainment Usage',
                        'Passenger Touchpoints',
                        'Device Browsers',
                        'Device OS',
                        ''
                    ];
                    if (unit_pr.includes(originalTitle)) {
                        dataValue = { t: 'n', z: '0.00%', v: data[i].value / 100 };
                        if (isMediaLaunchByContentType && dataValue.v === 0) {
                            dataValue = 'No Data';
                        }
                    }

                    if (isMediaLaunchByContentType) {
                        row['    '] = roundedData ? roundedData : data[i].value ? dataValue : 'No Data';
                    } else {
                        row[value] = roundedData ? roundedData : data[i].value ? dataValue : 'No Data';
                    }
                }

                //for rounding Comparison Raw data
                if (data[i].hasOwnProperty('comparison_raw_value') && showComparisonHeader) {
                    let roundedData;
                    if (unit) {
                        if (unit === 'count') {
                            roundedData = data[i].comparison_raw_value
                                ? { t: 'n', v: data[i].comparison_raw_value, z: '#,##0' }
                                : 'No Data';
                        } else if (unit === 'hr') {
                            roundedData = {
                                t: 'n',
                                z: '[hh]:mm:ss.0',
                                v: parseFloat(data[i].comparison_raw_value) / 3600 / 24
                            };
                        }
                    }

                    if (data[i].unit && data[i].comparison_raw_value) {
                        if (data[i].unit === 'time') {
                            roundedData = {
                                t: 'n',
                                z: '[hh]:mm:ss.0',
                                v: parseFloat(data[i].comparison_raw_value) / 3600 / 24
                            };
                        } else {
                            roundedData = { t: 'n', v: data[i].comparison_raw_value, z: '#,##0' };
                        }
                    }
                    let dataValue = data[i].comparison_raw_value;
                    let unit_pr = [
                        'Media launches by Content Type',
                        'Entertainment Usage',
                        'Passenger Touchpoints',
                        'Device Browsers',
                        'Device OS'
                    ];
                    if (unit_pr.includes(originalTitle)) {
                        dataValue = { t: 'n', z: '0.00%', v: data[i].comparison_raw_value / 100 };
                    }

                    roundedData = roundedData
                        ? roundedData
                        : data[i].comparison_raw_value
                        ? dataValue
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                    if (!data[i].comparison_raw_value) {
                        roundedData = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                    }

                    if (isMediaLaunchByContentType) {
                        row['     '] = roundedData;
                    } else {
                        row[comparisonRowData] = roundedData;
                    }
                }

                if (showComparison && data[i].hasOwnProperty('comparison_value')) {
                    let comparisonValue = data[i].comparison_value;
                    if (utilHelper.isValidNumber(comparisonValue)) {
                        if (Math.sign(comparisonValue) === -1) {
                            comparisonValue = { t: 'n', z: '0.00%', v: comparisonValue / 100 };
                        } else if (Math.sign(comparisonValue) === 1) {
                            comparisonValue = { t: 'n', z: '0.00%', v: comparisonValue / 100 };
                        }
                    }
                    if (!comparisonValue) {
                        comparisonValue = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                    }

                    if (isMediaLaunchByContentType) {
                        if (row['    '] === 'No Data' || row['     '] === 'No Data') {
                            comparisonValue = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                        }
                        row['      '] = comparisonValue;
                    } else {
                        row[comparisionValue] = comparisonValue;
                    }
                }

                if (isMediaLaunchByContentType || isApplications) {
                    if (data[i].hasOwnProperty('nominal_value')) {
                        let nominal = data[i].nominal_value ? data[i].nominal_value : 'No Data';
                        let nominalValue = { t: 'n', v: nominal, z: '#,##0' };
                        if (unit === 'hr') {
                            nominalValue = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(nominal) / 3600 / 24 };
                        }

                        row[' '] = nominalValue.v === '0' ? 'No Data' : nominalValue;
                    }

                    if (data[i].hasOwnProperty('comparison_nominal_value')) {
                        let compNominal = data[i].comparison_nominal_value
                            ? data[i].comparison_nominal_value
                            : isDateRangeCurrentYear
                            ? 'Not Applicable'
                            : 'No Data';

                        let compNominalValue = { t: 'n', v: compNominal, z: '#,##0' };
                        if (unit === 'hr') {
                            compNominalValue = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(compNominal) / 3600 / 24 };
                        }

                        row['  '] =
                            compNominalValue.v === '0'
                                ? isDateRangeCurrentYear
                                    ? 'Not Applicable'
                                    : 'No Data'
                                : compNominalValue;
                    }
                }

                if (
                    showComparison &&
                    reportType === 'donut' &&
                    data[i].hasOwnProperty('comparison_value_metric') &&
                    filters.comparison_range
                ) {
                    let comparisonValueMetric = data[i].comparison_value_metric;
                    if (utilHelper.isValidNumber(comparisonValueMetric)) {
                        if (Math.sign(comparisonValueMetric) === -1) {
                            comparisonValueMetric = { t: 'n', z: '0.00%', v: comparisonValueMetric / 100 };
                        } else if (Math.sign(comparisonValueMetric) === 1) {
                            comparisonValueMetric = { t: 'n', z: '0.00%', v: comparisonValueMetric / 100 };
                        }
                    }
                    if (
                        !comparisonValueMetric ||
                        comparisonValueMetric === '0' ||
                        row[' '] === 'No Data' ||
                        row['   '] === 'No Data' ||
                        row['   '] === 'Not Applicable'
                    ) {
                        comparisonValueMetric = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                    }
                    row[comparisonMetricValue] = comparisonValueMetric;
                }

                formattedData.push(row);
            }

            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);
            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);
            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);

            return formattedData;
        } catch (err) {
            console.log(err);
        }
    }

    populateSummaryRowsData(formattedData, airlineIcao, filters, page, column1, column2, column3, isBasicTier) {
        let isDateRangeCurrentYear = filters['date_range_text'] === 'Current Year';
        let row = {
            [column1]: '',
            [column2]: '',
            [column3]: ''
        };
        formattedData.push(row);

        row = {
            [column1]: 'Airline',
            [column2]: airlineIcao,
            [column3]: ''
        };
        formattedData.push(row);

        row = {
            [column1]: 'Report',
            [column2]: page,
            [column3]: ''
        };
        formattedData.push(row);

        row = {
            [column1]: 'Creation Date',
            [column2]: this.currentDateTimeForExcelDownload(),
            [column3]: ''
        };
        formattedData.push(row);

        if (filters.media_cycle) {
            row = {
                [column1]: 'Media Cycle',
                [column2]: filters.media_cycle,
                [column3]: ''
            };
        } else {
            row = {
                [column1]: 'Date Range',
                [column2]: utilHelper.formatDateRange(filters.date_range),
                [column3]: ''
            };
        }

        formattedData.push(row);

        if (!isBasicTier) {
            if (filters.media_cycle) {
                row = {
                    [column1]: 'Comparison Cycle',
                    [column2]: ''
                };
            } else {
                row = {
                    [column1]: 'Comparison Range',
                    [column2]: isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : utilHelper.formatDateRange(filters.comparison_range)
                };
            }
            formattedData.push(row);
        }

        if (filters) {
            const excludeList = ['date_range', 'dateRangeText', 'media_cycle', 'date_range_text'];
            if (!isBasicTier || filters.media_cycle) {
                excludeList.push('comparison_range', 'comparison_range_text');
            }

            const displayNames = {
                flight_origins: 'Flight Origin',
                flight_destinations: 'Flight Destination',
                flight_numbers: 'Flight Number',
                system_types: 'System',
                aircraft_types: 'Aircraft Type',
                flight_haul_types: 'Flight Haul',
                seat_classes: 'Seat Class',
                gui_languages: 'GUI Language',
                tails: 'Tail'
            };
            for (const [key, values] of Object.entries(filters)) {
                if (!excludeList.includes(key)) {
                    row = {
                        [column1]: displayNames[key] ? displayNames[key] : key,
                        [column2]: Array.isArray(values) ? values.join(', ') : values,
                        [column3]: ''
                    };
                    formattedData.push(row);
                }
            }
        }

        //empty row for spacing
        row = {
            [column1]: '',
            [column2]: '',
            [column3]: ''
        };
        formattedData.push(row);

        row = {
            [column1]: 'Terms Of Use',
            [column2]: 'Privacy Policy',
            [column3]: 'Contact Us'
        };
        formattedData.push(row);

        var currentYear = new Date().getFullYear();
        row = {
            [column1]: `Copyright ©${currentYear} Panasonic Avionics Corporation. All rights reserved.`,
            [column2]: '',
            [column3]: ''
        };
        formattedData.push(row);

        //empty rows for spacing
        for (let i = 0; i < 2; i++) {
            row = {
                [column1]: '',
                [column2]: '',
                [column3]: ''
            };
            formattedData.push(row);
        }
    }

    populateTableHeaderRowsData(tableColumnTitles, column1, column2, column3, formattedData, title = null) {
        let row = {
            [column1]: title ? title : tableColumnTitles[0],
            [column2]: '',
            [column3]: ''
        };
        formattedData.push(row);

        //table column headers
        row = {};
        for (let i = 0; i < tableColumnTitles.length; i++) {
            let column;
            if (i === 0) {
                column = 'NEXT Insights Export';
            } else {
                column = ' '.repeat(i);
            }
            row[column] = tableColumnTitles[i];
        }

        formattedData.push(row);
    }

    populateStandardTierTableBodyRowsData(
        column1,
        column2,
        column3,
        dropdown1Option,
        data,
        reportType,
        tableColumnTitles,
        formattedData,
        isDateRangeCurrentYear
    ) {
        let metrics = [];
        let column4 = '   ';

        if (dropdown1Option.parent_title) {
            let column5 = '    ';
            metrics.push(
                {
                    col: column2,
                    metric: dropdown1Option.parent_title.data,
                    unit: dropdown1Option.parent_title.unit
                },
                {
                    col: column3,
                    metric: dropdown1Option.data,
                    unit: dropdown1Option.unit
                },
                {
                    col: column4,
                    metric: dropdown1Option.comparison_data_1,
                    unit: dropdown1Option.unit
                },
                {
                    col: column5,
                    metric: dropdown1Option.comparison_data_2,
                    unit: dropdown1Option.unit
                }
            );
        } else {
            metrics.push(
                {
                    col: column2,
                    metric: dropdown1Option.data,
                    unit: dropdown1Option.unit
                },
                {
                    col: column3,
                    metric: dropdown1Option.comparison_data_1,
                    unit: dropdown1Option.unit
                },
                {
                    col: column4,
                    metric: dropdown1Option.comparison_data_2,
                    unit: dropdown1Option.unit
                }
            );
        }

        for (let i = 0; i < dropdown1Option.additional_metrics.length; i++) {
            let column;
            if (dropdown1Option.parent_title) {
                column = ' '.repeat(i + 5);
            } else {
                column = ' '.repeat(i + 4);
            }
            let addMetric = dropdown1Option.additional_metrics[i];
            metrics.push({ col: column, metric: addMetric.data, unit: addMetric.unit });
        }

        //table rows
        for (let i = 0; data && i < data.length; i++) {
            // Skip TV Episodes rows with same title as parent
            if (dropdown1Option.parent_title) {
                if (dropdown1Option.parent_title.title === 'TV Series') {
                    if (data[i].label === data[i].parent_title) {
                        console.log('excluded same title' + data[i].label);
                        continue;
                    }
                }
            }

            let row = {
                [column1]: '',
                [column2]: '',
                [column3]: '',
                [column4]: ''
            };

            if (data[i].hasOwnProperty('label')) {
                row[column1] = data[i].label;
            }

            metrics.forEach((metricObj) => {
                let { col, metric, unit } = metricObj;

                if (data[i].hasOwnProperty(metric)) {
                    if (metric === 'comparison_value' || metric === 'avg_completion_rate') {
                        let comparisonValue = data[i][metric];
                        if (utilHelper.isValidNumber(comparisonValue)) {
                            if (Math.sign(comparisonValue) === -1) {
                                comparisonValue = { t: 'n', z: '0.00%', v: data[i][metric] / 100 };
                            } else if (Math.sign(comparisonValue) === 1) {
                                comparisonValue = { t: 'n', z: '0.00%', v: data[i][metric] / 100 };
                            }
                        }
                        if (!comparisonValue) {
                            comparisonValue = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                        }
                        row[col] = comparisonValue;
                    } else {
                        let roundedData;
                        if (unit && data[i][metric]) {
                            if (unit === 'count') {
                                roundedData = { t: 'n', v: data[i][metric], z: '#,##0' };
                            } else if (unit === 'hr' || unit === 'hr,min' || unit === 'hr,min,sec') {
                                roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i][metric]) / 3600 / 24 };
                            }
                        }

                        if (data[i].unit && data[i][metric]) {
                            if (data[i].unit === 'time') {
                                roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i][metric]) / 3600 / 24 };
                            } else {
                                roundedData = { t: 'n', z: '#,##0', v: data[i][metric] };
                            }
                        }

                        let dataValue = data[i][metric];
                        let unit_pr = [
                            'Media launches by Content Type',
                            'Entertainment Usage',
                            'Passenger Touchpoints',
                            'Device Browsers',
                            'Device OS',
                            'Device Type',
                            ''
                        ];
                        if (unit_pr.includes(tableColumnTitles[0])) {
                            dataValue = { t: 'n', z: '0.00%', v: data[i][metric] / 100 };
                        }

                        row[col] = roundedData
                            ? roundedData
                            : data[i][metric]
                            ? dataValue
                            : isDateRangeCurrentYear
                            ? 'Not Applicable'
                            : 'No Data';
                    }
                }
            });

            formattedData.push(row);
        }
    }

    populateBasicTierTableBodyRowsData(
        column1,
        column2,
        column3,
        dropdown1Option,
        data,
        reportType,
        tableColumnTitles,
        formattedData
    ) {
        let metrics = [];

        if (dropdown1Option.parent_title) {
            metrics.push(
                {
                    col: column2,
                    metric: dropdown1Option.parent_title.data,
                    unit: dropdown1Option.parent_title.unit
                },
                {
                    col: column3,
                    metric: dropdown1Option.data,
                    unit: dropdown1Option.unit
                }
            );
        } else {
            metrics.push({
                col: column2,
                metric: dropdown1Option.data,
                unit: dropdown1Option.unit
            });
        }

        for (let i = 0; i < dropdown1Option.additional_metrics.length; i++) {
            let addMetric = dropdown1Option.additional_metrics[i];
            if (addMetric.free_tier !== undefined) {
                let column;
                if (dropdown1Option.parent_title) {
                    column = ' '.repeat(i + 3);
                } else {
                    column = ' '.repeat(i + 2);
                }
                metrics.push({ col: column, metric: addMetric.data, unit: addMetric.unit });
            }
        }

        //table rows
        for (let i = 0; data && i < data.length; i++) {
            // Skip TV Episodes rows with same title as parent
            if (dropdown1Option.parent_title) {
                if (dropdown1Option.parent_title.title === 'TV Series') {
                    if (data[i].label === data[i].parent_title) {
                        console.log('excluded same title' + data[i].label);
                        continue;
                    }
                }
            }

            let row = {
                [column1]: '',
                [column2]: '',
                [column3]: ''
            };

            if (data[i].hasOwnProperty('label')) {
                row[column1] = data[i].label;
            }

            metrics.forEach((metricObj) => {
                let { col, metric, unit } = metricObj;

                if (data[i].hasOwnProperty(metric)) {
                    let roundedData;
                    if (unit && data[i][metric]) {
                        if (unit === 'count') {
                            roundedData = { t: 'n', v: data[i][metric], z: '#,##0' };
                        } else if (unit === 'hr') {
                            roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i][metric]) / 3600 / 24 };
                        } else if (unit === 'hr,min,sec') {
                            roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i][metric]) / 3600 / 24 };
                        }
                    }

                    if (data[i].unit && data[i][metric]) {
                        if (data[i].unit === 'time') {
                            roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i][metric]) / 3600 / 24 };
                        } else {
                            roundedData = { t: 'n', v: data[i][metric], z: '#,##0' };
                        }
                    }

                    let dataValue = data[i][metric];
                    let unit_pr = [
                        'Media launches by Content Type',
                        'Entertainment Usage',
                        'Passenger Touchpoints',
                        'Device Browsers',
                        'Device OS',
                        'Device Type',
                        ''
                    ];
                    if (unit_pr.includes(tableColumnTitles[0])) {
                        dataValue = { t: 'n', z: '0.00%', v: data[i][metric] / 100 };
                    }

                    row[col] = roundedData ? roundedData : data[i][metric] ? dataValue : 'No Data';
                }
            });

            formattedData.push(row);
        }
    }

    formatReportDataForTitles(
        airlineIcao,
        data,
        filters,
        tableColumnTitles,
        dropdown1Option,
        page,
        reportType,
        isBasicTier
    ) {
        try {
            let formattedData = [];

            // Hack to add rows before the Header row in XLSX
            let column1 = 'NEXT Insights Export';
            let column2 = ' ';
            let column3 = '  ';

            this.populateSummaryRowsData(
                formattedData,
                airlineIcao,
                filters,
                page,
                column1,
                column2,
                column3,
                isBasicTier
            );

            this.populateTableHeaderRowsData(tableColumnTitles, column1, column2, column3, formattedData);

            if (isBasicTier) {
                this.populateBasicTierTableBodyRowsData(
                    column1,
                    column2,
                    column3,
                    dropdown1Option,
                    data,
                    reportType,
                    tableColumnTitles,
                    formattedData
                );
            } else {
                this.populateStandardTierTableBodyRowsData(
                    column1,
                    column2,
                    column3,
                    dropdown1Option,
                    data,
                    reportType,
                    tableColumnTitles,
                    formattedData,
                    filters['date_range_text'] === 'Current Year'
                );
            }

            //empty rows for spacing
            for (let i = 0; i < 3; i++) {
                let row = {
                    [column1]: '',
                    [column2]: ''
                };
                formattedData.push(row);
            }

            return formattedData;
        } catch (err) {
            console.log(err);
        }
    }

    exportExcelDonut(airlineIcao, data, filters, title, value, comparisionValue, showComparison, unit, page) {
        try {
            let formattedData = [];

            let originalTitle = title;
            let originalValue = value;
            let originalComparisionValue = comparisionValue;
            let isDateRangeCurrentYear = filters['date_range_text'] === 'Current Year';

            // Hack to add rows before the Header row in XLSX
            title = 'NEXT Insights Export';
            value = ' ';
            let comparisonRowData = '  ';

            let row = {
                [title]: '',
                [value]: ' ',
                '  ': ''
            };
            formattedData.push(row);

            row = {
                [title]: 'Airline',
                [value]: airlineIcao
            };
            formattedData.push(row);

            row = {
                [title]: 'Report',
                [value]: page
            };
            formattedData.push(row);

            row = {
                [title]: 'Creation Date',
                [value]: this.currentDateTimeForExcelDownload()
            };
            formattedData.push(row);

            if (filters.media_cycle) {
                row = {
                    [title]: 'Media Cycle',
                    [value]: filters.media_cycle
                };
            } else {
                row = {
                    [title]: 'Date Range',
                    [value]: utilHelper.formatDateRange(filters.date_range)
                };
            }
            formattedData.push(row);

            let showComparisonHeader = false;
            for (let i = 0; data && i < data.length; i++) {
                if (
                    showComparison &&
                    data[i].hasOwnProperty('comparison_value') &&
                    data[i].hasOwnProperty('comparison_raw_value')
                ) {
                    showComparisonHeader = true;
                    break;
                }
            }

            if (showComparisonHeader && (filters.comparison_range || isDateRangeCurrentYear)) {
                row = {
                    [title]: 'Comparison Range',
                    [value]: isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : utilHelper.formatDateRange(filters.comparison_range)
                };
                formattedData.push(row);
            }

            if (filters) {
                const excludeList = [
                    'date_range',
                    'dateRangeText',
                    'comparison_range',
                    'comparison_range_text',
                    'media_cycle',
                    'date_range_text'
                ];
                const displayNames = {
                    flight_origins: 'Flight Origin',
                    flight_destinations: 'Flight Destination',
                    flight_numbers: 'Flight Number',
                    system_types: 'System',
                    aircraft_types: 'Aircraft Type',
                    flight_haul_types: 'Flight Haul',
                    seat_classes: 'Seat Class',
                    gui_languages: 'GUI Language',
                    tails: 'Tail'
                };
                for (const [key, values] of Object.entries(filters)) {
                    if (!excludeList.includes(key)) {
                        row = {
                            [title]: displayNames[key] ? displayNames[key] : key,
                            [value]: Array.isArray(values) ? values.join(', ') : values
                        };
                        formattedData.push(row);
                    }
                }
            }

            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);

            let setContectUs = '  ';
            row = {
                [title]: 'Terms Of Use',
                [value]: 'Privacy Policy',
                [setContectUs]: 'Contact Us'
            };
            formattedData.push(row);

            row = {
                [title]: 'Copyright ©2021 Panasonic Avionics Corporation. All rights reserved.',
                [value]: ''
            };
            formattedData.push(row);

            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);
            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);

            row = {
                [title]: originalValue,
                [value]: ''
            };
            formattedData.push(row);

            if (showComparisonHeader && (filters.comparison_range || isDateRangeCurrentYear)) {
                if (filters.media_cycle) {
                    row = {
                        [title]: originalTitle,
                        [value]: filters.media_cycle,
                        [comparisonRowData]: 'No Cycle',
                        '   ': originalValue + ' Change (%)',
                        '    ': 'Percentage engagement - Media Cycle',
                        '     ': 'Percentage engagement - Preceding Media Cycle',
                        '      ': originalComparisionValue
                    };
                } else {
                    row = {
                        [title]: originalTitle,
                        [value]: 'Date Range',
                        [comparisonRowData]: 'Comparison Range',
                        '   ': originalValue + ' Change (%)',
                        '    ': 'Percentage Usage (Date Range)',
                        '     ': 'Percentage Usage (Comparison Range)',
                        '      ': originalComparisionValue
                    };
                }
            } else {
                if (filters.media_cycle) {
                    row = {
                        [title]: originalTitle,
                        [value]: originalValue,
                        '  ': originalValue + ' (%)'
                    };
                } else {
                    row = {
                        [title]: originalTitle,
                        [value]: 'Date Range',
                        '  ': originalTitle.includes('Application')
                            ? 'Percentage usage (Date Range)'
                            : 'Percentage engagement (Date Range)'
                    };
                }
            }
            formattedData.push(row);

            for (let i = 0; data && i < data.length; i++) {
                let row = {
                    [title]: ''
                };

                if (data[i].hasOwnProperty('label')) {
                    row[title] = data[i].label;

                    if (row[title] === 'Electronic Reader' || row[title] === 'EReader') {
                        row[title] = 'eReader';
                    }
                }

                if (data[i].hasOwnProperty('nominal_value')) {
                    let roundedData;
                    if (unit && data[i].nominal_value) {
                        if (unit === 'hr') {
                            roundedData = {
                                t: 'n',
                                z: '[hh]:mm:ss.0',
                                v: parseFloat(data[i].nominal_value) / 3600 / 24
                            };
                        } else {
                            roundedData = { t: 'n', v: data[i].nominal_value, z: '#,##0' };
                        }
                    }

                    row[' '] = roundedData ? roundedData : data[i].nominal_value ? data[i].nominal_value : 'No Data';
                }

                if (
                    data[i].hasOwnProperty('comparison_nominal_value') &&
                    showComparisonHeader &&
                    (filters.comparison_range || isDateRangeCurrentYear)
                ) {
                    let roundedData;
                    if (unit && data[i].comparison_nominal_value) {
                        if (unit === 'hr') {
                            roundedData = {
                                t: 'n',
                                z: '[hh]:mm:ss.0',
                                v: parseFloat(data[i].comparison_nominal_value) / 3600 / 24
                            };
                        } else {
                            roundedData = { t: 'n', v: data[i].comparison_nominal_value, z: '#,##0' };
                        }
                    }

                    row['  '] = isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : roundedData
                        ? roundedData
                        : data[i].comparison_nominal_value
                        ? data[i].comparison_nominal_value
                        : 'No Data';
                }

                if (
                    showComparison &&
                    data[i].hasOwnProperty('comparison_value_metric') &&
                    (filters.comparison_range || isDateRangeCurrentYear)
                ) {
                    let comparisonValueMetric = data[i].comparison_value_metric;
                    if (utilHelper.isValidNumber(comparisonValueMetric) && !isDateRangeCurrentYear) {
                        if (Math.sign(comparisonValueMetric) === -1) {
                            comparisonValueMetric = { t: 'n', z: '0.00%', v: comparisonValueMetric / 100 };
                        } else if (Math.sign(comparisonValueMetric) === 1) {
                            comparisonValueMetric = { t: 'n', z: '0.00%', v: comparisonValueMetric / 100 };
                        }
                    } else if (isDateRangeCurrentYear) {
                        comparisonValueMetric = 'Not Applicable';
                    }

                    if (!comparisonValueMetric) {
                        comparisonValueMetric = 'No Data';
                    }
                    row['   '] = comparisonValueMetric;
                }

                if (data[i].hasOwnProperty('value')) {
                    let dataValue = data[i].value;
                    let unit_pr = ['Entertainment Usage', 'Passenger Touchpoints', 'Application Usage'];
                    if (unit_pr.includes(originalTitle)) {
                        dataValue = { t: 'n', z: '0.00%', v: data[i].value / 100 };
                    }

                    if (showComparisonHeader && (filters.comparison_range || isDateRangeCurrentYear)) {
                        row['    '] = data[i].value ? dataValue : isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                    } else {
                        row['  '] = data[i].value ? dataValue : isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                    }
                }

                if (
                    data[i].hasOwnProperty('comparison_raw_value') &&
                    showComparisonHeader &&
                    (filters.comparison_range || isDateRangeCurrentYear)
                ) {
                    let dataValue = data[i].comparison_raw_value;
                    let unit_pr = ['Entertainment Usage', 'Passenger Touchpoints', 'Application Usage'];
                    if (unit_pr.includes(originalTitle)) {
                        dataValue = { t: 'n', z: '0.00%', v: data[i].comparison_raw_value / 100 };
                    }
                    row['     '] = isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : data[i].comparison_raw_value
                        ? dataValue
                        : 'No Data';
                }

                if (
                    showComparison &&
                    data[i].hasOwnProperty('comparison_value') &&
                    (filters.comparison_range || isDateRangeCurrentYear)
                ) {
                    let comparisonValue = data[i].comparison_value;
                    if (utilHelper.isValidNumber(comparisonValue) && !isDateRangeCurrentYear) {
                        if (Math.sign(comparisonValue) === -1) {
                            comparisonValue = { t: 'n', z: '0.00%', v: comparisonValue / 100 };
                        } else if (Math.sign(comparisonValue) === 1) {
                            comparisonValue = { t: 'n', z: '0.00%', v: comparisonValue / 100 };
                        }
                    } else if (isDateRangeCurrentYear) {
                        comparisonValue = 'Not Applicable';
                    }
                    if (!comparisonValue) {
                        comparisonValue = 'No Data';
                    }
                    row['      '] = comparisonValue;
                }

                formattedData.push(row);
            }

            return formattedData;
        } catch (err) {
            console.log(err);
        }
    }

    exportExcelTrends(airlineIcao, reportSubType, title, fileNamePrefix, data, filters, showComparison, page) {
        let requestedSections = data.tabData ? data.tabData : trendConfig.getTrendChartRequestedSections(reportSubType);
        let isDateRangeCurrentYear = filters['date_range_text'] === 'Current Year';
        if (title == 'Applications') {
            let clonedData = { ...data };
            delete clonedData['tabData'];
            requestedSections = trendConfig.setTopTrendApplicationConfigs(clonedData);
        }

        let tabData = [];
        if (data) {
            if (requestedSections && requestedSections.length > 0) {
                for (let i = 0; i < requestedSections.length; i++) {
                    let tab = JSON.parse(JSON.stringify(requestedSections[i]));
                    tab.value = null;
                    tab.comparison_value = null;
                    tab.current_date_range_data = null;
                    tab.comparison_date_range_data = null;
                    if (tab.label.length > 31) {
                        tab.short_label = tab.label.substring(0, 28) + '...';
                    }
                    if (data[requestedSections[i].key]) {
                        let trendData = data[requestedSections[i].key];
                        tab.value = trendData.hasOwnProperty('value') ? trendData.value : null;
                        tab.comparison_value = trendData.hasOwnProperty('comparison_value')
                            ? trendData.comparison_value
                            : null;
                        tab.current_date_range_data = this.resolve(trendData, `graph.current_date_range.data`)
                            ? this.resolve(trendData, `graph.current_date_range.data`)
                            : [];
                        tab.comparison_date_range_data = this.resolve(trendData, `graph.comparison_date_range.data`)
                            ? this.resolve(trendData, `graph.comparison_date_range.data`)
                            : [];
                        tab.comparison_raw_value = trendData.hasOwnProperty('comparison_raw_value')
                            ? trendData.comparison_raw_value
                            : null;
                        tabData.push(tab);
                    }
                }
            }
        }

        let wb = XLSX.utils.book_new();

        let headerData = this.formatReportData(
            airlineIcao,
            tabData,
            filters,
            'Trends',
            '',
            'Change (%)',
            showComparison,
            '',
            page
        );
        let sheet = XLSX.utils.json_to_sheet(headerData);
        sheet['!cols'] = [{ wch: 58 }, { wch: 45 }, { wch: 45 }, { wch: 45 }];
        sheet = this.addLinksToFooter(headerData, sheet);
        XLSX.utils.book_append_sheet(wb, sheet, 'Trends');

        for (let i = 0; tabData && i < tabData.length; i++) {
            let dateData = this.formatTrendGraphData(tabData[i], showComparison, isDateRangeCurrentYear);
            let sheet = XLSX.utils.json_to_sheet(dateData);
            sheet['!cols'] = [{ wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];
            XLSX.utils.book_append_sheet(wb, sheet, tabData[i].short_label ? tabData[i].short_label : tabData[i].label);
        }

        XLSX.writeFile(wb, `${fileNamePrefix}.xlsx`);
    }

    formatTrendGraphData(data, showComparison, isDateRangeCurrentYear) {
        let formattedData = [];

        let graphRowCount = 0;
        let currentDateRangeCount = data.current_date_range_data ? data.current_date_range_data.length : 0;
        let comparisonDateRangeCount = data.comparison_date_range_data ? data.comparison_date_range_data.length : 0;

        if (currentDateRangeCount > comparisonDateRangeCount || currentDateRangeCount === comparisonDateRangeCount) {
            graphRowCount = currentDateRangeCount;
        } else if (comparisonDateRangeCount > currentDateRangeCount) {
            graphRowCount = comparisonDateRangeCount;
        }

        for (let i = 0; i < graphRowCount; i++) {
            let row = {};

            if (data.current_date_range_data[i]) {
                row['Current Date Range'] = data.current_date_range_data[i].date
                    ? data.current_date_range_data[i].date
                    : '';
                if (data.current_date_range_data[i].value && data.unit) {
                    if (data.unit === 'time') {
                        row['Value'] = {
                            t: 'n',
                            z: '[hh]:mm:ss.0',
                            v: parseFloat(data.current_date_range_data[i].value) / 3600 / 24
                        };
                    } else {
                        row['Value'] = { t: 'n', v: data.current_date_range_data[i].value, z: '#,##0' };
                    }
                } else {
                    row['Value'] = 'No Data';
                }
            } else {
                row['Current Date Range'] = '';
                row['Value'] = '';
            }

            if (showComparison) {
                if (data.comparison_date_range_data[i]) {
                    row['Comparison Date Range'] = data.comparison_date_range_data[i].date
                        ? data.comparison_date_range_data[i].date
                        : '';

                    if (data.comparison_date_range_data[i].value && data.unit) {
                        if (data.unit === 'time') {
                            row['Comparison Value'] = {
                                t: 'n',
                                z: '[hh]:mm:ss.0',
                                v: parseFloat(data.comparison_date_range_data[i].value) / 3600 / 24
                            };
                        } else {
                            row['Comparison Value'] = {
                                t: 'n',
                                v: data.comparison_date_range_data[i].value,
                                z: '#,##0'
                            };
                        }
                    } else {
                        row['Comparison Value'] = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                    }
                }
            }

            formattedData.push(row);
        }

        return formattedData;
    }

    exportExcelVerticalBar(airlineIcao, title, fileNamePrefix, data, filters, showComparison, reportSubType, page) {
        let requestedSections = verticalBarChartConfig.getVerticalChartRequestedSections(reportSubType);

        if (!showComparison) {
            requestedSections = requestedSections.filter(function (option, index) {
                return option.basic === '0';
            });
        }

        let tabData = [];
        if (data) {
            if (requestedSections && requestedSections.length > 0) {
                for (let i = 0; i < requestedSections.length; i++) {
                    let tab = JSON.parse(JSON.stringify(requestedSections[i]));
                    tab.value = null;
                    tab.comparison_value = null;
                    tab.flight_phases = null;
                    if (data[requestedSections[i].key]) {
                        let timelineData = data[requestedSections[i].key];
                        tab.value = timelineData.hasOwnProperty('value') ? timelineData.value : null;
                        tab.comparison_value = timelineData.hasOwnProperty('comparison_value')
                            ? timelineData.comparison_value
                            : null;
                        tab.flight_phases = this.resolve(timelineData, `flight_phases`)
                            ? this.resolve(timelineData, `flight_phases`)
                            : [];
                        tab.comparison_raw_value = timelineData.hasOwnProperty('comparison_raw_value')
                            ? timelineData.comparison_raw_value
                            : null;
                        tabData.push(tab);
                    }
                }
            }
        }

        let wb = XLSX.utils.book_new();

        let headerData = this.formatReportData(
            airlineIcao,
            tabData,
            filters,
            title,
            'Value',
            'Change (%)',
            showComparison,
            '',
            page
        );
        let sheet = XLSX.utils.json_to_sheet(headerData);
        sheet['!cols'] = [{ wch: 58 }, { wch: 45 }, { wch: 45 }, { wch: 45 }];
        sheet = this.addLinksToFooter(headerData, sheet);
        XLSX.utils.book_append_sheet(wb, sheet, 'Passenger Interaction Timeline');

        for (let i = 0; tabData && i < tabData.length; i++) {
            let formattedData = this.formatVerticalBarData(tabData[i]);
            let sheet = XLSX.utils.json_to_sheet(formattedData);
            sheet['!cols'] = [{ wch: 30 }, { wch: 30 }, { wch: 35 }, { wch: 35 }, { wch: 45 }];
            XLSX.utils.book_append_sheet(wb, sheet, tabData[i].label);
        }

        XLSX.writeFile(wb, `${fileNamePrefix}.xlsx`);
    }

    formatVerticalBarData(data) {
        let formattedData = [];
        let flightPhasesCount = data.flight_phases ? data.flight_phases.length : 0;
        let col1 = '';
        let col2 = ' ';
        let col3 = '   ';
        if (
            data.flight_phases[0] &&
            data.flight_phases[0].start &&
            data.flight_phases[0].end &&
            data.flight_phases[0].current_date_range
        ) {
            let row = {
                'Media Content Types': ''
            };
            formattedData.push(row);
            const contentTypeKeys = Object.keys(data.flight_phases[0].current_date_range);
            if (data.unit === 'time') {
                row = {
                    [data.label]: '         First 20% of flight',
                    [col1]: '          Middle 60% of flight',
                    [col2]: '          Last 20% of flight',
                    [col3]: 'Time spent does not fall in any of three phases'
                };
            } else {
                row = {
                    [data.label]: '         First 20% of flight',
                    [col1]: '          Middle 60% of flight',
                    [col2]: '          Last 20% of flight'
                };
            }

            formattedData.push(row);
            if (contentTypeKeys && contentTypeKeys.length > 0) {
                contentTypeKeys.forEach((key, index) => {
                    let value = [];
                    for (let i = 0; i < flightPhasesCount; i++) {
                        if (data.unit === 'time') {
                            value[i] =
                                data.flight_phases[i].current_date_range[key] !== null
                                    ? {
                                          t: 'n',
                                          z: '[hh]:mm:ss.0',
                                          v: parseFloat(data.flight_phases[i].current_date_range[key]) / 3600 / 24
                                      }
                                    : 'No Data';
                        } else {
                            value[i] =
                                data.flight_phases[i].current_date_range[key] !== null
                                    ? { t: 'n', v: data.flight_phases[i].current_date_range[key], z: '#,##0' }
                                    : 'No Data';
                        }
                    }
                    let row = {};
                    if (data.unit === 'time') {
                        row = {
                            'Media Content Types': key,
                            [data.label]: value[0],
                            [col1]: value[1],
                            [col2]: value[2],
                            [col3]: value[3]
                        };
                    } else {
                        row = {
                            'Media Content Types': key,
                            [data.label]: value[0],
                            [col1]: value[1],
                            [col2]: value[2]
                        };
                    }

                    formattedData.push(row);
                });
                if (data.unit === 'time') {
                } else {
                }
                row = {
                    'Media Content Types': '',
                    [data.label]: ''
                };
                formattedData.push(row);
            } else {
                let row = { 'Media Content Types': '', [data.label]: '' };
                formattedData.push(row);
            }
        } else {
            let row = { 'Media Content Types': '', [data.label]: '' };
            formattedData.push(row);
        }

        return formattedData;
    }

    exportExcelScreenTransition(airlineIcao, title, fileNamePrefix, data, filters, showComparison, page) {
        let formattedData = [];

        let wb = XLSX.utils.book_new();

        // Hack to add rows before the Header row in XLSX
        let column1 = '';
        let column2 = '';
        let column3 = ' ';
        let column4 = '  ';
        let column5 = '   ';
        let column6 = '    ';
        let column7 = '     ';
        let column8 = '      ';
        let column9 = '       ';

        let row;
        column1 = 'NEXT Insights Export';
        row = {
            [column1]: ''
        };
        formattedData.push(row);

        row = {
            [column1]: 'Airline',
            [column2]: airlineIcao
        };
        formattedData.push(row);

        row = {
            [column1]: 'Report',
            [column2]: page
        };
        formattedData.push(row);

        row = {
            [column1]: 'Creation Date',
            [column2]: this.currentDateTimeForExcelDownload()
        };
        formattedData.push(row);

        row = {
            [column1]: 'Date Range',
            [column2]: utilHelper.formatDateRange(filters.date_range)
        };
        formattedData.push(row);

        let showComparisonHeader = false;
        for (let i = 0; data && i < data.length; i++) {
            if (
                showComparison &&
                data[i].hasOwnProperty('comparison_value') &&
                data[i].hasOwnProperty('comparison_raw_value')
            ) {
                showComparisonHeader = true;
                break;
            }
        }
        if (showComparisonHeader) {
            row = {
                [column1]: 'Comparison Range',
                [column2]: utilHelper.formatDateRange(filters.comparison_range)
            };
            formattedData.push(row);
        }

        if (filters) {
            const excludeList = [
                'date_range',
                'dateRangeText',
                'comparison_range',
                'comparison_range_text',
                'date_range_text'
            ];
            const displayNames = {
                flight_origin: 'Flight Origin',
                flight_destination: 'Flight Destination',
                flight_number: 'Flight Number',
                system_types: 'System Type',
                aircraft_types: 'Airframe',
                flight_haul_types: 'Flight Haul',
                seat_classes: 'Seat Class',
                gui_languages: 'GUI Language',
                tails: 'Tail'
            };
            for (const [key, values] of Object.entries(filters)) {
                if (!excludeList.includes(key)) {
                    row = {
                        [column1]: displayNames[key] ? displayNames[key] : key,
                        [column2]: Array.isArray(values) ? values.join(', ') : values
                    };
                    formattedData.push(row);
                }
            }
        }

        row = {
            [column1]: '',
            [column2]: ''
        };
        formattedData.push(row);
        row = {
            [column1]: '',
            [column2]: ''
        };
        formattedData.push(row);

        row = {
            [column1]: 'Terms Of Use',
            [column2]: 'Privacy Policy',
            [column3]: 'Contact Us'
        };
        formattedData.push(row);

        row = {
            [column1]: 'Copyright ©2021 Panasonic Avionics Corporation. All rights reserved.',
            [column2]: ''
        };
        formattedData.push(row);

        row = {
            [column1]: '',
            [column2]: ''
        };
        formattedData.push(row);
        formattedData.push(row);

        if (showComparison) {
            row = {
                [column1]: 'Level 1',
                [column2]: 'Visits',
                [column3]: 'Avg Dwell (in sec)',
                [column4]: 'Level 2',
                [column5]: 'Visits',
                [column6]: 'Avg Dwell (in sec)',
                [column7]: 'Level 3',
                [column8]: 'Visits',
                [column9]: 'Avg Dwell (in sec)'
            };
        } else {
            row = {
                [column1]: 'Level 1',
                [column2]: 'Visits',
                [column3]: 'Avg Dwell (in sec)',
                [column4]: 'Level 2',
                [column5]: 'Visits',
                [column6]: 'Avg Dwell (in sec)'
            };
        }
        formattedData.push(row);

        let denormalizedData = [];
        if (data && Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                let level1 = data[i];
                let level1ScreenName = level1.screen_name ? level1.screen_name : '';
                let level1Hits = level1.hits ? { t: 'n', v: level1.hits, z: '#,##0' } : '';
                let level1AvgDwellTime = level1.avg_dwell_time
                    ? { t: 'n', v: parseFloat(level1.avg_dwell_time) / 3600 / 24, z: '[hh]:mm:ss.0' }
                    : '';

                if (level1.visits && Array.isArray(level1.visits)) {
                    for (let j = 0; j < level1.visits.length; j++) {
                        let level2 = level1.visits[j];
                        let level2ScreenName = level2.screen_name ? level2.screen_name : '';
                        let level2Hits = level2.hits ? { t: 'n', v: level2.hits, z: '#,##0' } : '';
                        let level2AvgDwellTime = level2.avg_dwell_time
                            ? { t: 'n', v: parseFloat(level2.avg_dwell_time) / 3600 / 24, z: '[hh]:mm:ss.0' }
                            : '';

                        if (showComparison) {
                            if (level2.visits && Array.isArray(level2.visits)) {
                                for (let k = 0; k < level2.visits.length; k++) {
                                    let level3 = level2.visits[k];

                                    if (level3 && (level3.screen_name || level3.hits || level3.avg_dwell_time)) {
                                        let level3ScreenName = level3.screen_name ? level3.screen_name : '';
                                        let level3Hits = level3.hits ? { t: 'n', v: level3.hits, z: '#,##0' } : '';
                                        let level3AvgDwellTime = level3.avg_dwell_time
                                            ? {
                                                  t: 'n',
                                                  v: parseFloat(level3.avg_dwell_time) / 3600 / 24,
                                                  z: '[hh]:mm:ss.0'
                                              }
                                            : '';

                                        let visitsRow = {
                                            [column1]: level1ScreenName,
                                            [column2]: level1Hits,
                                            [column3]: level1AvgDwellTime,
                                            [column4]: level2ScreenName,
                                            [column5]: level2Hits,
                                            [column6]: level2AvgDwellTime,
                                            [column7]: level3ScreenName,
                                            [column8]: level3Hits,
                                            [column9]: level3AvgDwellTime
                                        };
                                        denormalizedData.push(visitsRow);
                                    }
                                }
                            } else {
                                let visitsRow = {
                                    [column1]: level1ScreenName,
                                    [column2]: level1Hits,
                                    [column3]: level1AvgDwellTime,
                                    [column4]: level2ScreenName,
                                    [column5]: level2Hits,
                                    [column6]: level2AvgDwellTime
                                };
                                denormalizedData.push(visitsRow);
                            }
                        } else {
                            let visitsRow = {
                                [column1]: level1ScreenName,
                                [column2]: level1Hits,
                                [column3]: level1AvgDwellTime,
                                [column4]: level2ScreenName,
                                [column5]: level2Hits,
                                [column6]: level2AvgDwellTime
                            };
                            denormalizedData.push(visitsRow);
                        }
                    }
                } else {
                    let visitsRow = {
                        [column1]: level1ScreenName,
                        [column2]: level1Hits,
                        [column3]: level1AvgDwellTime
                    };
                    denormalizedData.push(visitsRow);
                }
            }
        }

        if (denormalizedData && denormalizedData.length > 0) {
            formattedData.push(...denormalizedData);
        }

        let sheet = XLSX.utils.json_to_sheet(formattedData);
        sheet['!cols'] = [
            { wch: 58 },
            { wch: 45 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 },
            { wch: 25 }
        ];
        sheet = this.addLinksToFooter(formattedData, sheet);
        XLSX.utils.book_append_sheet(wb, sheet, title);

        XLSX.writeFile(wb, `${fileNamePrefix}.xlsx`);
    }

    async exportExcelLopa(airlineIcao, title, fileNamePrefix, flightData, filters, showComparison, page, reportType) {
        const { info, data } = flightData;
        let wb = XLSX.utils.book_new();

        let exportData = [];

        if (data && data.seatData && Array.isArray(data.seatData)) {
            for (let i = 0; i < data.seatData.length; i++) {
                let row = data.seatData[i];
                if (row && row.row) {
                    let minColumn = this.resolve(row, 'classInfo.columns.min_column');
                    let maxColumn = this.resolve(row, 'classInfo.columns.max_column');
                    if (minColumn && maxColumn) {
                        let minNumber = utilHelper.letterToNumber(minColumn.toUpperCase());
                        let maxNumber = utilHelper.letterToNumber(maxColumn.toUpperCase());
                        let rowData = [];
                        for (let j = minNumber; j <= maxNumber; j++) {
                            let seatNumber = row.row + utilHelper.numberToLetter(j);
                            let seatRow = {
                                seat_number: seatNumber,
                                total_seat_usage_time: '',
                                total_seat_engagement: '',
                                total_views: '',
                                duration_by_content_type: '',
                                views_by_content_type: ''
                            };
                            rowData.push(seatRow);
                        }

                        if (row.seats && Array.isArray(row.seats)) {
                            for (let k = 0; k < row.seats.length; k++) {
                                let seat = row.seats[k];
                                if (seat && seat.letter && seat.seatData) {
                                    let totalUsageTime = seat.seatData.total_usage_time
                                        ? seat.seatData.total_usage_time
                                        : '';
                                    let totalUsageTimePercent = seat.seatData.total_usage_time_percent
                                        ? seat.seatData.total_usage_time_percent
                                        : '';
                                    let totalViews = seat.seatData.total_views ? seat.seatData.total_views : '';

                                    let duration_by_content_type = seat.seatData.duration_by_content_type
                                        ? seat.seatData.duration_by_content_type
                                        : '';
                                    let views_by_content_type = seat.seatData.views_by_content_type
                                        ? seat.seatData.views_by_content_type
                                        : '';

                                    for (let x = 0; x < rowData.length; x++) {
                                        if (rowData[x].seat_number === row.row + seat.letter) {
                                            rowData[x].total_seat_usage_time = totalUsageTime;
                                            rowData[x].total_seat_engagement = totalUsageTimePercent;
                                            rowData[x].total_views = totalViews;
                                            rowData[x].duration_by_content = duration_by_content_type;
                                            rowData[x].views_by_content = views_by_content_type;
                                        }
                                    }
                                }
                            }
                        }

                        if (rowData && rowData.length > 0) {
                            exportData.push(...rowData);
                        }
                    }
                }
            }
        }

        let ipsEnabled = false;
        let subscriptionData = await SubscriptionService.fetchSubscription();
        // Lopa is displayed only for Standard tier
        let tier = subscriptionData.tiers.find((tier) => tier['subscription-level'] === 'Standard');
        if (tier.apps.includes('IPS')) {
            ipsEnabled = true;
        }

        let exportLoginData = [];
        if (ipsEnabled) {
            if (data && data.seatLoginData && Array.isArray(data.seatLoginData)) {
                for (let il = 0; il < data.seatLoginData.length; il++) {
                    let rowLogin = data.seatLoginData[il];
                    if (rowLogin && rowLogin.row) {
                        let minColumn = this.resolve(rowLogin, 'classInfo.columns.min_column');
                        let maxColumn = this.resolve(rowLogin, 'classInfo.columns.max_column');
                        if (minColumn && maxColumn) {
                            let minNumber = utilHelper.letterToNumber(minColumn.toUpperCase());
                            let maxNumber = utilHelper.letterToNumber(maxColumn.toUpperCase());
                            let rowLoginData = [];
                            for (let jl = minNumber; jl <= maxNumber; jl++) {
                                let seatNumber = rowLogin.row + utilHelper.numberToLetter(jl);
                                let seatRow = {
                                    seat_number: seatNumber,
                                    login_attempts: ''
                                };
                                rowLoginData.push(seatRow);
                            }

                            if (rowLogin.seats && Array.isArray(rowLogin.seats)) {
                                for (let kl = 0; kl < rowLogin.seats.length; kl++) {
                                    let seatLogin = rowLogin.seats[kl];
                                    if (seatLogin && seatLogin.letter && seatLogin.seatData) {
                                        let loginAttempts = seatLogin.seatData.login_attempts
                                            ? seatLogin.seatData.login_attempts
                                            : '';

                                        for (let xl = 0; xl < rowLoginData.length; xl++) {
                                            if (rowLoginData[xl].seat_number === rowLogin.row + seatLogin.letter) {
                                                rowLoginData[xl].login_attempts = loginAttempts;
                                            }
                                        }
                                    }
                                }
                            }

                            if (rowLoginData && rowLoginData.length > 0) {
                                exportLoginData.push(...rowLoginData);
                            }
                        }
                    }
                }
            }
        }

        let exportLangData = [];
        if (data && data.seatLangData && Array.isArray(data.seatLangData)) {
            for (let il = 0; il < data.seatLangData.length; il++) {
                let rowLang = data.seatLangData[il];
                if (rowLang && rowLang.row) {
                    let minColumn = this.resolve(rowLang, 'classInfo.columns.min_column');
                    let maxColumn = this.resolve(rowLang, 'classInfo.columns.max_column');
                    if (minColumn && maxColumn) {
                        let minNumber = utilHelper.letterToNumber(minColumn.toUpperCase());
                        let maxNumber = utilHelper.letterToNumber(maxColumn.toUpperCase());
                        let rowLangData = [];
                        for (let jl = minNumber; jl <= maxNumber; jl++) {
                            let seatNumber = rowLang.row + utilHelper.numberToLetter(jl);
                            let seatRow = {
                                seat_number: seatNumber,
                                languages: ''
                            };
                            rowLangData.push(seatRow);
                        }

                        if (rowLang.seats && Array.isArray(rowLang.seats)) {
                            for (let kl = 0; kl < rowLang.seats.length; kl++) {
                                let seatLang = rowLang.seats[kl];
                                if (seatLang && seatLang.letter && seatLang.seatData) {
                                    for (let xl = 0; xl < rowLangData.length; xl++) {
                                        if (rowLangData[xl].seat_number === rowLang.row + seatLang.letter) {
                                            rowLangData[xl].languages = seatLang.seatData;
                                        }
                                    }
                                }
                            }
                        }

                        if (rowLangData && rowLangData.length > 0) {
                            exportLangData.push(...rowLangData);
                        }
                    }
                }
            }
        }

        let formattedUsageTimeData = this.formatLopaData(
            airlineIcao,
            exportData,
            info,
            'total_usage_time',
            filters,
            page,
            reportType
        );
        let sheet = XLSX.utils.json_to_sheet(formattedUsageTimeData);
        sheet['!cols'] = [{ wch: 58 }, { wch: 45 }, { wch: 25 }, { wch: 25 }];
        sheet = this.addLinksToFooter(formattedUsageTimeData, sheet);
        XLSX.utils.book_append_sheet(wb, sheet, 'Total Usage Time');

        let formattedViewsData = this.formatLopaData(
            airlineIcao,
            exportData,
            info,
            'total_views',
            filters,
            page,
            reportType
        );
        sheet = XLSX.utils.json_to_sheet(formattedViewsData);
        sheet['!cols'] = [{ wch: 58 }, { wch: 45 }, { wch: 25 }];
        sheet = this.addLinksToFooter(formattedViewsData, sheet);
        XLSX.utils.book_append_sheet(wb, sheet, 'Total Views');

        if (ipsEnabled) {
            let formattedLoginData = this.formatLopaData(
                airlineIcao,
                exportLoginData,
                info,
                'login_attempts',
                filters,
                page,
                reportType
            );
            sheet = XLSX.utils.json_to_sheet(formattedLoginData);
            sheet['!cols'] = [{ wch: 58 }, { wch: 45 }, { wch: 25 }];
            sheet = this.addLinksToFooter(formattedLoginData, sheet);
            XLSX.utils.book_append_sheet(wb, sheet, 'Login Attempts');
        }

        let formattedLangData = this.formatLopaData(
            airlineIcao,
            exportLangData,
            info,
            'languages',
            filters,
            page,
            reportType
        );
        sheet = XLSX.utils.json_to_sheet(formattedLangData);
        sheet['!cols'] = [{ wch: 58 }, { wch: 45 }, { wch: 25 }, { wch: 25 }];
        sheet = this.addLinksToFooter(formattedLangData, sheet);
        XLSX.utils.book_append_sheet(wb, sheet, 'Seat Languages');

        XLSX.writeFile(wb, `${fileNamePrefix}.xlsx`);
    }

    formatLopaData(airlineIcao, data, flightinfo, sheetName, filters, page, reportType = null) {
        let formattedData = [];

        // Hack to add rows before the Header row in XLSX
        let column1 = '';
        let column2 = ' ';
        let column3 = '  ';
        let column4 = '   ';
        column1 = 'NEXT Insights Export';
        let row;

        row = {
            [column1]: ''
        };
        formattedData.push(row);

        row = {
            [column1]: 'Airline',
            [column2]: airlineIcao
        };
        formattedData.push(row);

        row = {
            [column1]: 'Report',
            [column2]: page
        };
        formattedData.push(row);

        row = {
            [column1]: 'Creation Date',
            [column2]: this.currentDateTimeForExcelDownload()
        };
        formattedData.push(row);

        row = {
            [column1]: 'Flight Number',
            [column2]: flightinfo.flightNumber
        };
        formattedData.push(row);

        row = {
            [column1]: 'Route',
            [column2]: flightinfo.route
        };
        formattedData.push(row);

        row = {
            [column1]: 'Flight Duration',
            [column2]: flightinfo.flightDuration
        };
        formattedData.push(row);

        row = {
            [column1]: 'Flight Date',
            [column2]: flightinfo.date
        };
        formattedData.push(row);

        row = {
            [column1]: 'Flight Departure',
            [column2]: flightinfo.startTime
        };
        formattedData.push(row);

        row = {
            [column1]: 'Aircraft',
            [column2]: flightinfo.aircraftType
        };
        formattedData.push(row);

        if (reportType !== 'lopa') {
            row = {
                [column1]: 'Date Range',
                [column2]: utilHelper.formatDateRange(filters.date_range)
            };
            formattedData.push(row);

            row = {
                [column1]: 'Comparison Range',
                [column2]: utilHelper.formatDateRange(filters.comparison_range)
            };
            formattedData.push(row);

            if (filters) {
                let row = {};

                const excludeList = [
                    'date_range',
                    'dateRangeText',
                    'comparison_range',
                    'comparison_range_text',
                    'date_range_text'
                ];
                const displayNames = {
                    flight_origin: 'Flight Origin',
                    flight_destination: 'Flight Destination',
                    flight_number: 'Flight Number',
                    system_types: 'System Type',
                    aircraft_types: 'Airframe',
                    flight_haul_types: 'Flight Haul',
                    gui_languages: 'GUI Language',
                    tails: 'Tail'
                };
                if (filters.seat_classes) {
                    delete filters.seat_classes;
                }
                for (const [key, values] of Object.entries(filters)) {
                    if (!excludeList.includes(key)) {
                        row = {
                            [column1]: displayNames[key] ? displayNames[key] : key,
                            [column2]: Array.isArray(values) ? values.join(', ') : values
                        };
                        formattedData.push(row);
                    }
                }
            }
        }

        row = {
            [column1]: '',
            [column2]: ''
        };
        formattedData.push(row);

        row = {
            [column1]: 'Terms Of Use',
            [column2]: 'Privacy Policy',
            [column3]: 'Contact Us'
        };
        formattedData.push(row);

        row = {
            [column1]: 'Copyright ©2021 Panasonic Avionics Corporation. All rights reserved.',
            [column2]: ''
        };
        formattedData.push(row);

        row = {
            [column1]: '',
            [column2]: ''
        };
        formattedData.push(row);

        if (sheetName === 'total_usage_time') {
            row = {
                [column1]: 'Seat#',
                [column2]: 'Category',
                [column3]: 'Total Seat Usage Time',
                [column4]: 'Total Seat Engagement'
            };
        } else if (sheetName === 'total_views') {
            row = {
                [column1]: 'Seat#',
                [column2]: 'Category',
                [column3]: 'Total Views'
            };
        } else if (sheetName === 'login_attempts') {
            row = {
                [column1]: 'Seat#',
                [column2]: 'Login Attempts'
            };
        } else if (sheetName === 'languages') {
            row = {
                [column1]: 'Seat#',
                [column2]: 'Languages',
                [column3]: 'Total Seat Engagement'
            };
        }
        formattedData.push(row);

        for (let i = 0; data && i < data.length; i++) {
            let row = {},
                isData = false;
            if (sheetName === 'total_usage_time') {
                if (data[i].hasOwnProperty('duration_by_content') && data[i].duration_by_content) {
                    for (let content_type in data[i].duration_by_content) {
                        row = {};
                        if (data[i].hasOwnProperty('seat_number') && data[i].seat_number) {
                            row[column1] = data[i].seat_number;
                        }
                        row[column2] = content_type;
                        let contentTypeData = data[i].duration_by_content[content_type];
                        row[column3] = {
                            t: 'n',
                            z: '[hh]:mm:ss.0',
                            v: parseFloat(contentTypeData.duration) / 3600 / 24
                        };
                        row[column4] = { t: 'n', z: '0.00%', v: contentTypeData.pct / 100 };
                        formattedData.push(row);
                    }

                    continue;
                }
            } else if (sheetName === 'total_views') {
                if (data[i].hasOwnProperty('views_by_content') && data[i].views_by_content) {
                    for (let content_type in data[i].views_by_content) {
                        row = {};
                        if (data[i].hasOwnProperty('seat_number') && data[i].seat_number) {
                            row[column1] = data[i].seat_number;
                        }
                        row[column2] = content_type;
                        row[column3] = { t: 'n', v: data[i].views_by_content[content_type], z: '#,##0' };

                        formattedData.push(row);
                    }

                    continue;
                }
            } else if (sheetName === 'login_attempts') {
                if (data[i].hasOwnProperty('login_attempts') && data[i].login_attempts) {
                    if (data[i].hasOwnProperty('seat_number') && data[i].seat_number) {
                        row[column1] = data[i].seat_number;
                    }
                    row[column2] = { t: 'n', v: data[i].login_attempts, z: '#,##0' };
                    isData = true;
                }
            } else if (sheetName === 'languages') {
                if (data[i].hasOwnProperty('languages') && data[i].languages) {
                    let usageTime = parseFloat(data[i].total_seat_usage_time) / 3600 / 24;
                    for (let key in data[i].languages) {
                        row = {};
                        if (data[i].hasOwnProperty('seat_number') && data[i].seat_number) {
                            row[column1] = data[i].seat_number;
                        }
                        row[column2] = key;
                        row[column3] = { t: 'n', z: '0.00%', v: data[i].languages[key] / 100 };

                        formattedData.push(row);
                    }

                    continue;
                }
            }
            isData && formattedData.push(row);
        }

        return formattedData;
    }

    addLinksToFooter(data, sheet) {
        try {
            if (data && data.length > 2) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i] && data[i]['NEXT Insights Export'].includes('Terms Of Use')) {
                        let linkRowNumber = i + 1;
                        sheet[XLSX.utils.encode_cell({ c: 0, r: linkRowNumber })].l = {
                            Target: 'https://www.panasonic.aero/terms-of-use/'
                        };
                        sheet[XLSX.utils.encode_cell({ c: 1, r: linkRowNumber })].l = {
                            Target: 'https://www.panasonic.aero/privacy-policy/'
                        };
                        sheet[XLSX.utils.encode_cell({ c: 2, r: linkRowNumber })].l = {
                            Target: 'https://www.panasonic.aero/contact-us/'
                        };
                    }
                }
            }
        } catch (error) {}
        return sheet;
    }

    resolve(obj, path) {
        path = path.split('.');
        var current = obj;
        while (path.length) {
            if (typeof current !== 'object') return undefined;
            current = current[path.shift()];
        }
        return current;
    }

    currentDateTimeForExcelDownload() {
        return moment.utc(new Date()).format('MMM DD, YYYY');
    }
}

export default Excel;
