import React from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import { ButtonPrimary, ButtonSecondary } from 'pac-ux-components';
import 'assets/styles/remove-tail.css';
import AdminService from 'services/AdminService';

const service = new AdminService();

class RemoveTail extends React.Component {
    constructor(props) {
        super(props);

        let airline;
        let tails = [];

        if (this.props.selectedTails.length > 0) {
            airline = this.props.selectedTails[0].airline_code;

            tails = this.props.selectedTails.map((tail) => {
                return tail.tail_no;
            });
        }

        this.state = {
            tails: tails,
            title: this.getTitle(),
            buttonText: this.getButtonText(),
            showSpinner: false,
            airline: airline,
            airlineName: this.getAirlineName(airline)
        };

        this.removeTails = this.removeTails.bind(this);
    }

    getAirlineName(airlineCode) {
        let airlineOption = this.props.tenantOptions.find((option) => {
            if (option.value === airlineCode) {
                return true;
            }
            return false;
        });

        if (airlineOption) {
            return airlineOption.label;
        }

        return '';
    }

    getTitle() {
        if (this.props.selectedTails.length > 1) {
            return `Remove Tails?`;
        } else {
            return `Remove Tail?`;
        }
    }

    async removeTails() {
        let { airline, tails } = this.state;

        this.setState({ showSpinner: true });

        let payload = {
            method: 'delete-tails',
            airline_code: airline,
            data: {
                tails: tails
            }
        };

        let errorTails = null;
        let errorDescription = null;
        try {
            let result = await service.addUpdateDeleteTails(payload);
            if (result.response.error !== undefined) {
                errorTails = result.response.error.tails;
                errorDescription = result.response.error.description;
            }
        } catch (error) {
            console.log('remove tail error ##');
            errorDescription = 'default';
        }

        this.setState({ showSpinner: false });

        if (errorDescription !== null) {
            this.props.displayError('remove', errorDescription, errorTails);
        } else {
            this.props.removeTailsAction(airline, tails);
        }
    }

    getButtonText() {
        if (this.props.selectedTails.length > 1) {
            return `Yes, remove ${this.props.selectedTails.length} tails`;
        } else {
            return `Yes, remove tail`;
        }
    }

    getSelectedTailsLabel() {
        return (
            <div>
                <span>You are about to remove the selected </span>
                <span style={{ fontFamily: 'Inter SemiBold' }}>
                    {this.props.selectedTails.length > 1 ? this.props.selectedTails.length : ''}
                </span>
                <span>{this.props.selectedTails.length > 1 ? ' tails:' : 'tail:'}</span>
            </div>
        );
    }

    render() {
        let { showSpinner, buttonText } = this.state;

        return (
            <div>
                <div className="header">{this.state.title}</div>
                <div className="form-body">
                    <Row>
                        <Col>
                            <div>
                                {this.getSelectedTailsLabel()}
                                <div className="airline">
                                    <span className="edit-label">Airline:</span>
                                    <span className="value">{this.state.airlineName}</span>
                                </div>
                                <div style={{ maxHeight: 206, overflowY: 'auto' }}>
                                    <table className="table table-bordered">
                                        <tbody>
                                            {this.props.selectedTails.map((tail, index) => {
                                                return (
                                                    <tr style={{ height: 41 }} key={index}>
                                                        <td style={{ verticalAlign: 'middle' }}>{tail.tail_no}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="buttons-section">
                    <ButtonSecondary className="button-secondary" onClick={this.props.closeAction}>
                        {this.props.selectedTails.length > 1 ? 'No, keep tails' : 'No, keep tail'}
                    </ButtonSecondary>
                    <ButtonPrimary className="button-primary margin-left-16" onClick={this.removeTails}>
                        {showSpinner ? (
                            <div>
                                <Spinner />
                            </div>
                        ) : (
                            buttonText
                        )}
                    </ButtonPrimary>{' '}
                </div>
            </div>
        );
    }
}

export default RemoveTail;
