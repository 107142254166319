import React from 'react';
import { Row } from 'reactstrap';
import { Accordion, Card } from 'react-bootstrap';
import ErrorBoundary from 'util/ErrorBoundary.js';
import HlsPlayer from 'components/widgets/player/HlsPlayer.js';
import Analytics from 'components/widgets/Analytics';

const tabsBasic = [
    {
        id: 1,
        url: 'pac_mma_images_prod_v2/videos/nextinsi_help_intro.m3u8',
        label: 'What is NEXT Insights',
        description:
            'We recognize airlines must respond to passenger demands and market trends with precision and agility. Learn more about NEXT Insights and how it can help you make smarter business decisions.',
        cardstyle: 'border-bottom-none'
    },
    {
        id: 2,
        url: 'pac_mma_images_prod_v2/videos/nextinsi_help_basic.m3u8',
        label: 'Basic Features in NEXT Insights',
        description:
            'Getting Started with NEXT Insights - Take a tour of the Insights navigation and user interface, and learn more about its features, dashboards and visualizations.',
        cardstyle: ''
    }
];

const tabsStandard = [
    {
        id: 1,
        url: 'pac_mma_images_prod_v2/videos/nextinsi_help_intro.m3u8',
        label: 'What is NEXT Insights',
        description:
            'We recognize airlines must respond to passenger demands and market trends with precision and agility. Learn more about NEXT Insights and how it can help you make smarter business decisions.',
        cardstyle: 'border-bottom-none'
    },
    {
        id: 2,
        url: 'pac_mma_images_prod_v2/videos/nextinsi_help_basic.m3u8',
        label: 'Basic Features in NEXT Insights',
        description:
            'Getting Started with NEXT Insights - Take a tour of the Insights navigation and user interface, and learn more about its features, dashboards and visualizations.',
        cardstyle: 'border-bottom-none'
    },
    {
        id: 3,
        url: 'pac_mma_images_prod_v2/videos/nextinsi_help_standard.m3u8',
        label: 'Standard Features in NEXT Insights',
        description:
            'Getting Started with NEXT Insights - Take a tour of the Insights navigation and user interface, and learn more about its features, dashboards and visualizations.',
        cardstyle: ''
    }
];

class HelpOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabsExpanded: [
                { id: 1, expanded: false },
                { id: 2, expanded: false },
                { id: 3, expanded: false }
            ]
        };
    }

    handleAccordionClick(tabId, tabLabel) {
        Analytics.analytics.track('handleAccordionClick', {
            visual_name: 'Help',
            interaction_type: 'Video Section Expand',
            interaction_value: tabLabel
        });

        let tabsExpanded = this.state.tabsExpanded;

        for (let i = 0; i < tabsExpanded.length; i++) {
            if (tabsExpanded[i].id === tabId) {
                tabsExpanded[i].expanded = !tabsExpanded[i].expanded;
            }
        }

        this.setState({ tabsExpanded: tabsExpanded });
    }

    getToggleIcon(tabId) {
        let tabsExpanded = this.state.tabsExpanded;

        let toggleIcon = '';
        for (let i = 0; i < tabsExpanded.length; i++) {
            if (tabsExpanded[i].id === tabId) {
                toggleIcon = tabsExpanded[i].expanded ? (
                    <div>
                        <i className="fa fa-caret-up fa-custom"></i>
                    </div>
                ) : (
                    <div>
                        <i className="fa fa-caret-down fa-custom"></i>
                    </div>
                );
                break;
            }
        }

        return toggleIcon;
    }

    render() {
        let reactAppHost = process.env.REACT_APP_IMAGE_URL + '/';

        let tabs = this.props.showWorkbench ? tabsStandard : tabsBasic;

        const createMarkup = (htmlContent) => {
            return { __html: htmlContent };
        };

        return (
            <div className="help">
                <Row>
                    <div>
                        <ErrorBoundary>
                            <div className="getting-started">Getting started</div>
                        </ErrorBoundary>
                    </div>
                </Row>

                <Row className="outer-container">
                    {tabs.map((tab) => (
                        <Accordion key={tab.id}>
                            <Card className={tab.cardstyle}>
                                <Accordion.Toggle
                                    eventKey={tab.id}
                                    className="accordion-button"
                                    onClick={() => this.handleAccordionClick(tab.id, tab.label)}
                                >
                                    <Card.Header>
                                        <Card.Title>{tab.label}</Card.Title>
                                        {this.getToggleIcon(tab.id)}
                                    </Card.Header>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey={tab.id}>
                                    <Card.Body>
                                        <div dangerouslySetInnerHTML={createMarkup(tab.description)}></div>
                                        <Row className="video-container">
                                            <HlsPlayer videoUrl={reactAppHost + tab.url} />
                                        </Row>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    ))}
                </Row>
            </div>
        );
    }
}

export default HelpOverview;
