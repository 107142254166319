import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Circular from 'assets/images/icons/circular.gif';
import donutLegendUp from 'assets/images/icons/donutLegendUp.svg';
import donutLegendDown from 'assets/images/icons/donutLegendDown.svg';
import UtilHelper from 'util/UtilHelper.js';
import Analytics from 'components/widgets/Analytics';

const utilHelper = new UtilHelper();

function generateRandomColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let c = (hash & 0x00ffffff).toString(16).toUpperCase();

    return '#' + '00000'.substring(0, 6 - c.length) + c;
}

function formatPercentageValue(change) {
    return Math.abs(change) > 999 ? '999+' : Math.abs(change) > 100 ? Math.round(change) : Math.abs(change);
}

class DonutChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            legendOpen: false
        };

        this.toggleLegend = this.toggleLegend.bind(this);
    }

    toggleLegend() {
        Analytics.analytics.track('toggleLegend', {
            visual_name: this.props.title,
            interaction_type: 'Donut Legend Click',
            interaction_value: !this.state.legendOpen === true ? 'Open' : 'Close'
        });

        this.setState((prevState) => ({
            legendOpen: !prevState.legendOpen
        }));
    }

    dummyDataForBlurredView() {
        return [
            { label: 'Movies', value: '65', comparison_value: null },
            { label: 'Live TV', value: '35', comparison_value: null }
        ];
    }

    getIndicatorJSX(defaultChange) {
        return defaultChange > 0 ? (
            '▲'
        ) : defaultChange < 0 ? (
            '▼'
        ) : (
            <i className="fas fa-sort" style={{ fontSize: '20px' }}></i>
        );
    }

    getIndicatorString(defaultChange) {
        return defaultChange > 0
            ? '▲'
            : defaultChange < 0
            ? '▼'
            : "<i class='fas fa-sort' style='font-size: 20px'></i>";
    }

    getChange(defaultChange, filters) {
        return defaultChange === null && !filters['media_cycle'] ? ' N/A' : ' ' + defaultChange + '%';
    }

    getIndicatorColor(value) {
        return value > 0 ? '#225522' : value < 0 ? '#FF0000' : '#7A7A7A';
    }

    getDonutClassTier(className, isStandardTier, filters) {
        if (!isStandardTier || filters['media_cycle']) {
            className += '-basic';
        }

        return className;
    }

    render() {
        let data;

        let dashboard;
        let dataAvailable = false;
        let chartData;
        let blurred = false;

        if (this.props && this.props.data && this.props.data.length > 0) {
            data = this.props.data;
            dataAvailable = true;
        }

        let defaultColors = [
            { color: '#71137180', hoverColor: '#711371' },
            { color: '#002C6180', hoverColor: '#002C61' },
            { color: '#E4801C80', hoverColor: '#E4801C' },
            { color: '#00461280', hoverColor: '#004612' },
            { color: '#79C2D080', hoverColor: '#79C2D0' },
            { color: '#C6970880', hoverColor: '#C69708' },
            { color: '#B3221780', hoverColor: '#B32217' },
            { color: '#0068B380', hoverColor: '#0068B3' }
        ];

        let passengerEngagementColors = {
            Movies: { color: '#71137180', hoverColor: '#711371' },
            'Live TV': { color: '#002C6180', hoverColor: '#002C61' },
            'TV Series': { color: '#E4801C80', hoverColor: '#E4801C' },
            Audio: { color: '#00461280', hoverColor: '#004612' },
            Games: { color: '#79C2D080', hoverColor: '#79C2D0' },
            'Info Videos': { color: '#C6970880', hoverColor: '#C69708' },
            eReader: { color: '#10304C80', hoverColor: '#10304C' }
        };

        let pedSeatbackColors = {
            PED: { color: '#79C2D080', hoverColor: '#79C2D0' },
            Seatback: { color: '#002C6180', hoverColor: '#002C61' }
        };

        let blurredColors = {
            Movies: { color: '#00461280', hoverColor: '#004612' },
            'Live TV': { color: '#002C6180', hoverColor: '#002C61' }
        };

        var chartId = Math.random();

        try {
            let labels = [];
            let values = [];
            let comparisonValues = [];
            let comparisonValuesMetric = [];
            let nominalValues = [];
            let colors = [];
            let hoverColors = [];
            let legendItems = [];

            let defaultLabel;
            let defaultValue;
            let defaultNominalValue;
            let defaultColor;
            let defaultChange;
            let defaultChangeColor;
            let defaultChangeMetric;
            let defaultChangeMetricColor;

            if (dataAvailable) {
                let allZeroValues = true;

                for (let i = 0; i < data.length; i++) {
                    if (data[i].value !== '0') {
                        allZeroValues = false;
                        break;
                    }
                }

                if (allZeroValues) {
                    data = this.dummyDataForBlurredView();
                    dataAvailable = true;
                    blurred = true;
                }

                // Show data is not available if no data for selected page
                if (this.props.defaultShow) {
                    let defaultLabelExists = false;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].label === this.props.defaultShow) {
                            defaultLabelExists = true;
                            break;
                        }
                    }

                    if (!defaultLabelExists) {
                        //data.push({ label: this.props.defaultShow, value: '0', comparison_value: null });
                        data = this.dummyDataForBlurredView();
                        dataAvailable = true;
                        blurred = true;
                    }
                }
            } else {
                data = this.dummyDataForBlurredView();
                dataAvailable = true;
                blurred = true;
            }

            if (dataAvailable) {
                for (let i = 0; i < data.length; i++) {
                    let color;
                    let hoverColor;

                    data[i].label = data[i].label === 'Electronic Reader' ? 'eReader' : data[i].label;
                    labels.push(data[i].label);
                    values.push(data[i].value);
                    comparisonValues.push(data[i].comparison_value);
                    comparisonValuesMetric.push(data[i].comparison_value_metric);
                    if (data[i].nominal_value) {
                        nominalValues.push(data[i].nominal_value);
                    }
                    if (data[i].label === 'Electronic Reader') {
                        //
                    }
                    if (this.props.donut_type === 'passenger_engagement') {
                        if (blurred) {
                            passengerEngagementColors = blurredColors;
                        }
                        if (passengerEngagementColors.hasOwnProperty(data[i].label)) {
                            color = passengerEngagementColors[data[i].label].color;
                            hoverColor = passengerEngagementColors[data[i].label].hoverColor;
                        }
                    } else if (this.props.donut_type === 'media_launches_by_content_type') {
                        if (blurred) {
                            passengerEngagementColors = blurredColors;
                        }
                        if (passengerEngagementColors.hasOwnProperty(data[i].label)) {
                            color = passengerEngagementColors[data[i].label].color;
                            hoverColor = passengerEngagementColors[data[i].label].hoverColor;
                        }
                    } else if (this.props.donut_type === 'ped_vs_seatback') {
                        if (blurred) {
                            pedSeatbackColors = blurredColors;
                        }
                        if (pedSeatbackColors.hasOwnProperty(data[i].label)) {
                            color = pedSeatbackColors[data[i].label].color;
                            hoverColor = pedSeatbackColors[data[i].label].hoverColor;
                        }
                    } else {
                        if (defaultColors[i]) {
                            color = defaultColors[i].color;
                            hoverColor = defaultColors[i].hoverColor;
                        }
                    }
                    if (color === undefined || hoverColor === undefined) {
                        let randomColor = generateRandomColor(data[i].label);
                        color = randomColor + '80';
                        hoverColor = randomColor;
                    }
                    colors.push(color);
                    hoverColors.push(hoverColor);

                    let labelName = data[i].label;
                    if (labelName === this.props.defaultShow) {
                        let channelTitle = '';
                        if (labelName === 'Live TV') {
                            channelTitle = sessionStorage.getItem('channel_title');
                        }
                        defaultLabel =
                            channelTitle && channelTitle !== 'ALL' ? labelName + ' - ' + channelTitle : labelName;
                        labelName = defaultLabel;
                        defaultValue = data[i].value;
                        defaultColor = color;
                        defaultChange = data[i].comparison_value;
                        defaultChangeMetric = data[i].comparison_value_metric;

                        if (data[i].nominal_value) {
                            defaultNominalValue = data[i].nominal_value;
                        }
                    }

                    legendItems.push({ name: labelName, color: color });
                }

                if (defaultLabel === undefined || defaultLabel === 'undefined') {
                    let maxValueIndex = values.indexOf(Math.max(...values).toString());
                    if (maxValueIndex === -1) {
                        maxValueIndex = 0;
                    }
                    defaultLabel = labels[maxValueIndex];
                    defaultValue = values[maxValueIndex];
                    defaultColor = colors[maxValueIndex];
                    defaultChange = comparisonValues[maxValueIndex];
                    defaultChangeMetric = comparisonValuesMetric[maxValueIndex];

                    if (nominalValues && nominalValues.length > 0 && nominalValues[maxValueIndex]) {
                        defaultNominalValue = nominalValues[maxValueIndex];
                    }
                }

                defaultChangeColor = this.getIndicatorColor(defaultChange);
                defaultChangeMetricColor = this.getIndicatorColor(defaultChangeMetric);
            }

            let innerDefaultText = defaultLabel;
            if (this.props.showMetrics) {
                defaultNominalValue = defaultNominalValue ? defaultNominalValue : 0;
                if (defaultNominalValue || typeof defaultNominalValue === 'number') {
                    innerDefaultText = defaultNominalValue;
                    if (this.props.unit === 'hr') {
                        innerDefaultText = utilHelper.secondsToHmsForDynamicTabs(defaultNominalValue);
                    } else if (this.props.unit === 'count') {
                        if (this.props.text && this.props.text.includes('Readers')) {
                            innerDefaultText = utilHelper.formatToUnitsForPillBox(defaultNominalValue) + ' Readers';
                        } else if (this.props.text && this.props.text.includes('Hits')) {
                            innerDefaultText = utilHelper.formatToUnitsForPillBox(defaultNominalValue) + ' Hits';
                        } else if (this.props.text && this.props.text.includes('Listens')) {
                            innerDefaultText = utilHelper.formatToUnitsForPillBox(defaultNominalValue) + ' Listens';
                        } else if (this.props.text && this.props.text.includes('Plays')) {
                            innerDefaultText = utilHelper.formatToUnitsForPillBox(defaultNominalValue) + ' Plays';
                        } else {
                            innerDefaultText = utilHelper.formatToUnitsForPillBox(defaultNominalValue) + ' Views';
                        }
                    } else {
                        if (this.props.text && this.props.text.toLowerCase().includes('launches')) {
                            innerDefaultText = utilHelper.formatToUnitsForPillBox(defaultNominalValue) + ' Launches';
                        } else {
                            innerDefaultText = utilHelper.formatToUnitsForPillBox(defaultNominalValue) + ' Devices';
                        }
                    }
                }
            }

            chartData = {
                labels: labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: colors,
                        hoverBackgroundColor: hoverColors,
                        hoverShadowOffsetX: 0,
                        hoverShadowOffsetY: 0,
                        hoverShadowBlur: 10,
                        hoverShadowColor: '#00000080',
                        hoverBorderWidth: 0
                    }
                ]
            };

            let { getIndicatorString, getIndicatorColor, getDonutClassTier, props } = this;
            let options = {
                animation: {
                    duration: blurred ? 0 : 1000
                },
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        top: 10,
                        left: 10,
                        right: 10,
                        bottom: 10
                    }
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    enabled: false,
                    custom: function (tooltipModel) {
                        //Building custom tooltip to display in center
                        var tooltipEl = document.getElementById(chartId);
                        var legendOuterEl = document.getElementById(chartId + '_legend_outer');
                        var innerHtml = '';
                        var legendOuterHtml = '';

                        var itemClass = getDonutClassTier(
                            'donut-legend-item',
                            props.showWorkbenchLink,
                            props.appliedFilters
                        );

                        var numberClass = getDonutClassTier(
                            'donut-legend-number',
                            props.showWorkbenchLink,
                            props.appliedFilters
                        );

                        var changeClass = getDonutClassTier(
                            'donut-legend-change',
                            props.showWorkbenchLink,
                            props.appliedFilters
                        );

                        var changeMetricClass = 'donut-legend-change-metric';

                        // Show default if no tooltip
                        if (tooltipModel.opacity === 0) {
                            innerHtml +=
                                '<div id="tooltip_default_label' +
                                props.donut_name +
                                '" class="' +
                                itemClass +
                                '">' +
                                innerDefaultText +
                                '</div>';

                            if (props.showWorkbenchLink && !props.appliedFilters['media_cycle']) {
                                if (defaultChangeMetric !== null) {
                                    let indicator = getIndicatorString(defaultChangeMetric);
                                    let formattedDefaultChangeMetric = formatPercentageValue(defaultChangeMetric);
                                    innerHtml +=
                                        '<div id="tooltip_default_change_metric' +
                                        props.donut_name +
                                        '" class="' +
                                        changeMetricClass +
                                        '" style="color: ' +
                                        defaultChangeMetricColor +
                                        '"; text-align:center;">' +
                                        indicator +
                                        ' ' +
                                        formattedDefaultChangeMetric +
                                        '%</div>';
                                } else {
                                    let indicator = "<i class='fas fa-sort' style='font-size: 20px;'></i>";
                                    innerHtml +=
                                        '<div id="tooltip_default_change_metric' +
                                        props.donut_name +
                                        '" class="' +
                                        changeMetricClass +
                                        '" style="color: ' +
                                        defaultChangeMetricColor +
                                        '"; margin: 0 auto;">' +
                                        indicator +
                                        ' ' +
                                        'N/A</div>';
                                }
                            }

                            //let defaultValueUnit = defaultValue + '%';
                            let defaultValueUnit;
                            if (parseInt(defaultValue, 10) === 0 && defaultNominalValue > 0) {
                                defaultValueUnit = '0.1%';
                            } else {
                                defaultValueUnit = defaultValue + '%';
                            }

                            if (defaultValueUnit === '0.1%') {
                                innerHtml +=
                                    '<div id="tooltip_default_value' +
                                    props.donut_name +
                                    '" class="' +
                                    numberClass +
                                    '" style="color: ' +
                                    defaultColor +
                                    '; left: 5px">' +
                                    defaultValueUnit +
                                    '</div>';

                                let lessThanSignClass = props.showWorkbenchLink
                                    ? 'donut-less-than-sign'
                                    : 'donut-less-than-sign-basic';

                                innerHtml +=
                                    '<div id="tooltip_default_value' +
                                    props.donut_name +
                                    '" class="' +
                                    lessThanSignClass +
                                    '"' +
                                    ' style="color: ' +
                                    defaultColor +
                                    '; display: block;">' +
                                    '<' +
                                    '</div>';
                            } else {
                                innerHtml +=
                                    '<div id="tooltip_default_value' +
                                    props.donut_name +
                                    '" class="' +
                                    numberClass +
                                    '" style="color: ' +
                                    defaultColor +
                                    '">' +
                                    defaultValueUnit +
                                    '</div>';
                            }

                            if (props.showWorkbenchLink && !props.appliedFilters['media_cycle']) {
                                if (defaultChange !== null) {
                                    let indicator =
                                        defaultChange > 0
                                            ? '▲'
                                            : defaultChange < 0
                                            ? '▼'
                                            : "<i class='fas fa-sort' style='font-size: 20px;'></i>";
                                    let formattedDefaultChange = formatPercentageValue(defaultChange);
                                    innerHtml +=
                                        '<div id="tooltip_default_change' +
                                        props.donut_name +
                                        '" class="' +
                                        changeClass +
                                        '" style="color: ' +
                                        defaultChangeColor +
                                        '">' +
                                        indicator +
                                        ' ' +
                                        formattedDefaultChange +
                                        '%</div>';
                                } else {
                                    let indicator = "<i class='fas fa-sort' style='font-size: 20px;'></i>";
                                    innerHtml +=
                                        '<div id="tooltip_default_change' +
                                        props.donut_name +
                                        '" class="' +
                                        changeClass +
                                        '" style="color: ' +
                                        defaultChangeColor +
                                        '">' +
                                        indicator +
                                        ' ' +
                                        'N/A</div>';
                                }
                            }

                            tooltipEl.innerHTML = innerHtml;
                            legendOuterEl.innerHTML = defaultLabel;
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Tooltip Text
                        if (tooltipModel.body) {
                            var bodyLines = tooltipModel.body.map(getBody);
                            // Don't show tool tip text if value is zero.
                            if (bodyLines.length === 1) {
                                innerHtml = '';
                                legendOuterHtml = '';
                                bodyLines.forEach(function (body, i) {
                                    var colors = tooltipModel.labelColors[i];
                                    var dataString = body[0].split(': ');
                                    var name = dataString[0];
                                    var number = dataString[1];

                                    var change = null;
                                    let changeMetric = null;
                                    var innerTextLabel = name;
                                    for (let i = 0; i < data.length; i++) {
                                        if (data[i].label === name) {
                                            change = data[i].comparison_value;
                                            changeMetric = data[i].comparison_value_metric;
                                            if (props.showMetrics && data[i].nominal_value) {
                                                innerTextLabel = data[i].nominal_value;
                                                if (props.unit === 'hr') {
                                                    innerTextLabel = utilHelper.secondsToHmsForDynamicTabs(
                                                        data[i].nominal_value
                                                    );
                                                } else if (props.unit === 'count') {
                                                    if (props.text && props.text.includes('Readers')) {
                                                        innerTextLabel =
                                                            utilHelper.formatToUnitsForPillBox(data[i].nominal_value) +
                                                            ' Readers';
                                                    } else if (props.text && props.text.includes('Hits')) {
                                                        innerTextLabel =
                                                            utilHelper.formatToUnitsForPillBox(data[i].nominal_value) +
                                                            ' Hits';
                                                    } else if (props.text && props.text.includes('Listens')) {
                                                        innerTextLabel =
                                                            utilHelper.formatToUnitsForPillBox(data[i].nominal_value) +
                                                            ' Listens';
                                                    } else if (props.text && props.text.includes('Plays')) {
                                                        innerTextLabel =
                                                            utilHelper.formatToUnitsForPillBox(data[i].nominal_value) +
                                                            ' Plays';
                                                    } else {
                                                        innerTextLabel =
                                                            utilHelper.formatToUnitsForPillBox(data[i].nominal_value) +
                                                            ' Views';
                                                    }
                                                } else {
                                                    if (props.text && props.text.toLowerCase().includes('launches')) {
                                                        innerTextLabel =
                                                            utilHelper.formatToUnitsForPillBox(data[i].nominal_value) +
                                                            ' Launches';
                                                    } else {
                                                        innerTextLabel =
                                                            utilHelper.formatToUnitsForPillBox(data[i].nominal_value) +
                                                            ' Devices';
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    let changeColor;
                                    let changeArrow;
                                    let changeMetricColor;
                                    let changeMetricArrow;
                                    let formattedChange;
                                    let formattedChangeMetric;

                                    if (change !== null) {
                                        changeColor = getIndicatorColor(change);
                                        changeArrow = getIndicatorString(change);
                                        formattedChange = formatPercentageValue(change);
                                    }

                                    if (changeMetric !== null) {
                                        changeMetricColor = getIndicatorColor(changeMetric);
                                        changeMetricArrow = getIndicatorString(changeMetric);
                                        formattedChangeMetric = formatPercentageValue(changeMetric);
                                    }

                                    var numberColor;
                                    if (colors.backgroundColor.startsWith('rgba')) {
                                        numberColor = colors.backgroundColor.replace(/[\d\.]+\)$/g, '1)');
                                    } else {
                                        numberColor = colors.backgroundColor.slice(0, -2);
                                    }

                                    innerHtml += '<div class="' + itemClass + '">' + innerTextLabel + '</div>';

                                    if (props.showWorkbenchLink && !props.appliedFilters['media_cycle']) {
                                        if (changeMetric !== null) {
                                            innerHtml +=
                                                '<div class="' +
                                                changeMetricClass +
                                                '" style="color: ' +
                                                changeMetricColor +
                                                '">' +
                                                changeMetricArrow +
                                                ' ' +
                                                formattedChangeMetric +
                                                '%</div>';
                                        } else {
                                            changeMetricArrow = "<i class='fas fa-sort' style='font-size: 20px;'></i>";
                                            changeMetric = 'N/A';
                                            innerHtml +=
                                                '<div class="' +
                                                changeMetricClass +
                                                '" style="color: ' +
                                                changeMetricColor +
                                                '">' +
                                                changeMetricArrow +
                                                ' ' +
                                                changeMetric +
                                                '</div>';
                                        }
                                    }

                                    innerHtml += innerHtml +=
                                        '<div class="' +
                                        numberClass +
                                        '" style="color: ' +
                                        numberColor +
                                        '">' +
                                        number +
                                        '%</div>';

                                    if (props.showWorkbenchLink && !props.appliedFilters['media_cycle']) {
                                        if (change !== null) {
                                            innerHtml +=
                                                '<div class="' +
                                                changeClass +
                                                '" style="color: ' +
                                                changeColor +
                                                '">' +
                                                changeArrow +
                                                ' ' +
                                                formattedChange +
                                                '%</div>';
                                        } else {
                                            changeArrow = "<i class='fas fa-sort' style='font-size: 20px;'></i>";
                                            change = 'N/A';
                                            innerHtml +=
                                                '<div class="' +
                                                changeClass +
                                                '" style="color: ' +
                                                changeColor +
                                                '">' +
                                                changeArrow +
                                                ' ' +
                                                change +
                                                '</div>';
                                        }
                                    }

                                    if (name === 'Live TV') {
                                        let channelTitle = sessionStorage.getItem('channel_title');
                                        if (channelTitle && channelTitle !== 'ALL') {
                                            name = name + ' - ' + channelTitle;
                                        }
                                    }
                                    legendOuterHtml = name;
                                });

                                tooltipEl.innerHTML = innerHtml;
                                legendOuterEl.innerHTML = legendOuterHtml;
                            }
                        }
                    }
                },
                responsive: true,
                cutoutPercentage: 66
            };

            if (process.env.JEST_WORKER_ID !== undefined) {
                delete options['tooltips'];
            }

            let indicatorInitial = this.getIndicatorJSX(defaultChange);
            let indicatorAfterDropdownChange = this.getIndicatorString(defaultChange);
            let formattedDefaultChange = formatPercentageValue(defaultChange);
            let changeInitial = this.getChange(formattedDefaultChange, this.props.appliedFilters);

            let indicatorMetricInitial = this.getIndicatorJSX(defaultChangeMetric);
            let indicatorMetricAfterDropdownChange = this.getIndicatorString(defaultChangeMetric);
            let formattedDefaultChangeMetric = formatPercentageValue(defaultChangeMetric);
            let changeMetricInitial = this.getChange(formattedDefaultChangeMetric, this.props.appliedFilters);

            let defaultValueUnit;
            if (parseInt(defaultValue, 10) === 0 && defaultNominalValue > 0) {
                defaultValueUnit = '0.1%';
            } else {
                defaultValueUnit = defaultValue + '%';
            }

            if (document.getElementById('tooltip_default_label' + this.props.donut_name)) {
                document.getElementById('tooltip_default_label' + this.props.donut_name).innerText = innerDefaultText;
            }

            if (document.getElementById('tooltip_default_change_metric' + this.props.donut_name)) {
                document.getElementById('tooltip_default_change_metric' + this.props.donut_name).innerHTML =
                    indicatorMetricAfterDropdownChange + ' ' + formattedDefaultChangeMetric + '%';
                document.getElementById('tooltip_default_change_metric' + this.props.donut_name).style.color =
                    defaultChangeMetricColor;
                document.getElementById('tooltip_default_change_metric' + this.props.donut_name).style.display =
                    'block';
            }

            if (document.getElementById('tooltip_default_value' + this.props.donut_name)) {
                document.getElementById('tooltip_default_value' + this.props.donut_name).innerText = defaultValueUnit;
                document.getElementById('tooltip_default_value' + this.props.donut_name).style.color = defaultColor;
            }

            if (document.getElementById('tooltip_default_change' + this.props.donut_name)) {
                document.getElementById('tooltip_default_change' + this.props.donut_name).innerHTML =
                    indicatorAfterDropdownChange + ' ' + formattedDefaultChange + '%';
                document.getElementById('tooltip_default_change' + this.props.donut_name).style.color =
                    defaultChangeColor;
                document.getElementById('tooltip_default_change' + this.props.donut_name).style.display = 'block';
            }

            let legend = '';
            let gridStyle = 'repeat(3, 1fr)';
            if (this.props.showLegend && legendItems && legendItems.length > 0) {
                if (legendItems.some((e) => e.name.length > 13)) {
                    gridStyle = 'repeat(auto-fill,minmax(200px, 3fr))';
                }
                legend = [
                    <div
                        className={'donut-legend-bottom'}
                        key={'donut-legend-bottom' + this.props.donut_name}
                        style={{ bottom: '-42px' }}
                    >
                        <div
                            className={
                                this.state.legendOpen ? 'donut-legend-bottom-tab' : 'donut-legend-bottom-tab closed'
                            }
                            onClick={this.toggleLegend}
                        >
                            Legend
                            <img
                                src={this.state.legendOpen ? donutLegendDown : donutLegendUp}
                                style={{ marginLeft: '7px' }}
                                alt=""
                            />
                        </div>
                        <div
                            className={
                                this.state.legendOpen
                                    ? 'donut-legend-bottom-item-container'
                                    : 'donut-legend-bottom-item-container closed'
                            }
                            style={{ gridTemplateColumns: gridStyle }}
                        >
                            {legendItems.map((legendItem, i) => {
                                return (
                                    <div
                                        className="donut-legend-bottom-item"
                                        key={'donut-legend-bottom-item' + this.props.donut_name + legendItem.name}
                                    >
                                        <div
                                            className="donut-legend-bottom-item-color"
                                            style={{ backgroundColor: legendItem.color }}
                                        ></div>
                                        <div
                                            className="donut-legend-bottom-item-label short-label"
                                            data-tooltip={legendItem.name}
                                        >
                                            {legendItem.name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ];
            }

            let donutLegendOuterClassName =
                defaultLabel.length > 25 ? 'donut-legend-outer short-label' : 'donut-legend-outer';
            dashboard = (
                <div>
                    {' '}
                    <div className={donutLegendOuterClassName} id={chartId + '_legend_outer'}>
                        {defaultLabel}
                    </div>
                    <div className="donut-chart">
                        <div className={'donut-legend'} id={chartId}>
                            <div
                                className={getDonutClassTier(
                                    'donut-legend-item',
                                    this.props.showWorkbenchLink,
                                    this.props.appliedFilters
                                )}
                            >
                                {innerDefaultText}
                            </div>
                            {this.props.showWorkbenchLink && !this.props.appliedFilters['media_cycle'] && (
                                <div
                                    className={'donut-legend-change-metric'}
                                    style={{
                                        color: defaultChangeMetricColor,
                                        display: defaultChangeMetric === null ? 'block' : 'block'
                                    }}
                                >
                                    {indicatorMetricInitial}
                                    {changeMetricInitial}
                                </div>
                            )}
                            {defaultValueUnit === '0.1%' ? (
                                <div>
                                    <div
                                        id="initial_default_value"
                                        className={getDonutClassTier(
                                            'donut-legend-number',
                                            this.props.showWorkbenchLink,
                                            this.props.appliedFilters
                                        )}
                                        style={{ color: defaultColor, left: '5px' }}
                                    >
                                        {defaultValueUnit}
                                    </div>
                                    <div
                                        id="initial_default_value"
                                        className={
                                            this.props.showWorkbenchLink
                                                ? 'donut-less-than-sign'
                                                : 'donut-less-than-sign-basic'
                                        }
                                        style={{
                                            display: defaultValueUnit === '0.1%' ? 'block' : 'none',
                                            color: defaultColor
                                        }}
                                    >
                                        {'<'}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    id="initial_default_value"
                                    className={getDonutClassTier(
                                        'donut-legend-number',
                                        this.props.showWorkbenchLink,
                                        this.props.appliedFilters
                                    )}
                                    style={{ color: defaultColor }}
                                >
                                    {defaultValueUnit}
                                </div>
                            )}

                            {this.props.showWorkbenchLink && !this.props.appliedFilters['media_cycle'] && (
                                <div
                                    className={'donut-legend-change'}
                                    style={{
                                        color: defaultChangeColor,
                                        display: defaultChange === null ? 'block' : 'block'
                                    }}
                                >
                                    {indicatorInitial}
                                    {changeInitial}
                                </div>
                            )}
                        </div>
                        <Doughnut data={chartData} width={330} height={330} options={options} />
                    </div>
                    {legend}
                </div>
            );
        } catch (error) {
            console.log(error);
            dashboard = <div className="widget-title"> Data is not available</div>;
        }

        return (
            <div className="donut-chart-wrapper">
                {this.props.loading && (
                    <div>
                        <div className="data-wrapper custom-loading">
                            <img src={Circular} alt="" />
                        </div>
                    </div>
                )}
                {!this.props.loading && (
                    <div>
                        <div className={blurred ? 'data-wrapper blurred' : 'data-wrapper'}>{dashboard}</div>
                        <div className="no-data" style={{ display: !blurred ? 'none' : 'initial' }}>
                            Data is not available
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default DonutChart;
