import React from 'react';
import ReactHlsPlayer from 'react-hls-player';

class HlsPlayer extends React.Component {
    render() {
        return (
            <ReactHlsPlayer
                src={this.props.videoUrl}
                width="100%"
                height="340px"
                pip={true}
                ref="playerRef"
                autoPlay={false}
                controls={true}
                config={{
                    file: {
                        attributes: {
                            onContextMenu: (e) => e.preventDefault()
                        }
                    }
                }}
            ></ReactHlsPlayer>
        );
    }
}

export default HlsPlayer;
