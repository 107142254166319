import React from 'react';
const querystring = require('querystring');

class LinkHelper extends React.Component {
    getTokenFragment(currentSession, expires_in = 3600, token_type = 'Bearer') {
        if (typeof currentSession !== 'undefined') {
            if (typeof currentSession.idToken !== 'undefined' && typeof currentSession.accessToken !== 'undefined') {
                const params = {
                    id_token: currentSession.idToken.jwtToken,
                    access_token: currentSession.accessToken.jwtToken,
                    expires_in: expires_in,
                    token_type: token_type
                };
                if (this.getCurAuthTenandid !== null) {
                    params.pac_selected_tenant_id = this.getCurAuthTenandid();
                }

                return '#' + querystring.stringify(params);
            }
        }
    }

    getCurAuthTenandid() {
        return window.sessionStorage.getItem('pac_selected_tenant_id');
    }

    getCurAuthTenantIcao() {
        return window.sessionStorage.getItem('pac_selected_tenant_icao_code');
    }
}

export default LinkHelper;
