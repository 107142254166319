export default {
    getGenresDropdownListHelper(reportSubType) {
        let dropdownList;

        switch (reportSubType) {
            case 'Movies':
                dropdownList = [
                    {
                        label: 'Total Views (Top 10)',
                        unit: 'count',
                        value: 'genres.most_popular.total_views',
                        text: 'Movie Total Views',
                        default: 1,
                        free_tier: 1,
                        section: 'top_10_total_views',
                        small_count_label: 'Total Views'
                    },
                    {
                        label: 'Total Viewing Time (Top 10)',
                        unit: 'hr',
                        value: 'genres.most_popular.total_time',
                        text: 'Movie Total Viewing Time',
                        free_tier: 0,
                        section: 'top_10_total_time',
                        small_count_label: 'Total Viewing Time'
                    },
                    {
                        label: 'Total Views (Bottom 10)',
                        unit: 'count',
                        value: 'genres.least_popular.total_views',
                        text: 'Movie Total Views',
                        free_tier: 1,
                        section: 'bottom_10_total_views',
                        small_count_label: 'Total Views'
                    },
                    {
                        label: 'Total Viewing Time (Bottom 10)',
                        unit: 'hr',
                        value: 'genres.least_popular.total_time',
                        text: 'Movie Total Viewing Time',
                        free_tier: 0,
                        section: 'bottom_10_total_time',
                        small_count_label: 'Total Viewing Time'
                    }
                ];
                break;
            case 'Excelmovies':
                dropdownList = [
                    {
                        label: 'Total Views ',
                        unit: 'count',
                        value: 'genres.all_records.total_views',
                        text: 'Movie Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'genres.all_records.total_time',
                        text: 'Movie Total Viewing Time'
                    }
                ];
                break;
            case 'TV Series':
                dropdownList = [
                    {
                        label: 'Total Views (Top 10)',
                        unit: 'count',
                        value: 'genres.most_popular.total_views',
                        text: 'TV Series Total Views',
                        default: 1,
                        free_tier: 1,
                        section: 'top_10_total_views',
                        small_count_label: 'Total Views'
                    },
                    {
                        label: 'Total Viewing Time (Top 10)',
                        unit: 'hr',
                        value: 'genres.most_popular.total_time',
                        text: 'TV Series Total Viewing Time',
                        free_tier: 0,
                        section: 'top_10_total_time',
                        small_count_label: 'Total Viewing Time'
                    },
                    {
                        label: 'Total Views (Bottom 10)',
                        unit: 'count',
                        value: 'genres.least_popular.total_views',
                        text: 'TV Series Total Views',
                        free_tier: 1,
                        section: 'bottom_10_total_views',
                        small_count_label: 'Total Views'
                    },
                    {
                        label: 'Total Viewing Time (Bottom 10)',
                        unit: 'hr',
                        value: 'genres.least_popular.total_time',
                        text: 'TV Series Total Viewing Time',
                        free_tier: 0,
                        section: 'bottom_10_total_time',
                        small_count_label: 'Total Viewing Time'
                    }
                ];
                break;
            case 'Exceltv-series':
                dropdownList = [
                    {
                        label: 'Total Views ',
                        unit: 'count',
                        value: 'genres.all_records.total_views',
                        text: 'TV Series Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'genres.all_records.total_time',
                        text: 'TV Series Total Viewing Time'
                    }
                ];
                break;
            case 'Audio':
                dropdownList = [
                    {
                        label: 'Total Listens (Top 10)',
                        unit: 'count',
                        value: 'genres.most_popular.total_views',
                        text: 'Audio Total Listens',
                        default: 1,
                        free_tier: 1,
                        section: 'top_10_total_views',
                        small_count_label: 'Total Listens'
                    },
                    {
                        label: 'Total Listening Time (Top 10)',
                        unit: 'hr',
                        value: 'genres.most_popular.total_time',
                        text: 'Audio Total Listening Time',
                        free_tier: 0,
                        section: 'top_10_total_time',
                        small_count_label: 'Total Listening Time'
                    },
                    {
                        label: 'Total Listens (Bottom 10)',
                        unit: 'count',
                        value: 'genres.least_popular.total_views',
                        text: 'Audio Total Listens',
                        free_tier: 1,
                        section: 'bottom_10_total_views',
                        small_count_label: 'Total Listens'
                    },
                    {
                        label: 'Total Listening Time (Bottom 10)',
                        unit: 'hr',
                        value: 'genres.least_popular.total_time',
                        text: 'Audio Total Listening Time',
                        free_tier: 0,
                        section: 'bottom_10_total_time',
                        small_count_label: 'Total Listening Time'
                    }
                ];
                break;
            case 'Excelaudio':
                dropdownList = [
                    {
                        label: 'Total Listens',
                        unit: 'count',
                        value: 'genres.all_records.total_views',
                        text: 'Audio Total Listens',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Listening Time',
                        unit: 'hr',
                        value: 'genres.all_records.total_time',
                        text: 'Audio Total Listening Time'
                    }
                ];
                break;
            case 'Games':
                dropdownList = [
                    {
                        label: 'Total Plays (Top 10)',
                        unit: 'count',
                        value: 'genres.most_popular.total_views',
                        text: 'Games Total Plays',
                        default: 1,
                        free_tier: 1,
                        section: 'top_10_total_views',
                        small_count_label: 'Total Plays'
                    },
                    {
                        label: 'Total Playing Time (Top 10)',
                        unit: 'hr',
                        value: 'genres.most_popular.total_time',
                        text: 'Games Total Playing Time',
                        free_tier: 0,
                        section: 'top_10_total_time',
                        small_count_label: 'Total Playing Time'
                    },
                    {
                        label: 'Total Plays (Bottom 10)',
                        unit: 'count',
                        value: 'genres.least_popular.total_views',
                        text: 'Games Total Plays',
                        free_tier: 1,
                        section: 'bottom_10_total_views',
                        small_count_label: 'Total Plays'
                    },
                    {
                        label: 'Total Playing Time (Bottom 10)',
                        unit: 'hr',
                        value: 'genres.least_popular.total_time',
                        text: 'Games Total Playing Time',
                        free_tier: 0,
                        section: 'bottom_10_total_time',
                        small_count_label: 'Total Playing Time'
                    }
                ];
                break;
            case 'Excelgames':
                dropdownList = [
                    {
                        label: 'Total Plays',
                        unit: 'count',
                        value: 'genres.all_records.total_views',
                        text: 'Games Total Plays',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Playing Time',
                        unit: 'hr',
                        value: 'genres.all_records.total_time',
                        text: 'Games Total Playing Time'
                    }
                ];
                break;
            default:
        }

        return dropdownList;
    }
};
