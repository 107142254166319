import React from 'react';
import { Row, Col } from 'reactstrap';
import dashboards from 'data/dashboards.js';

function AddExtraProps(Component, extraProps) {
    return <Component.type {...Component.props} {...extraProps} />;
}

class ChartsDashBoard extends React.Component {
    buildDashboard(title, layout) {
        return (
            <React.Fragment>
                {layout &&
                    layout.rows.map((row, rowIndex) => {
                        return this.buildRow(row, rowIndex);
                    })}
            </React.Fragment>
        );
    }

    buildRow(row, rowIndex) {
        return (
            <Row key={rowIndex}>
                {row.columns.map((col, colIndex) => {
                    return this.buildCol(col, colIndex);
                })}
            </Row>
        );
    }

    buildCol(col, colIndex) {
        const thePath = this.props.location.pathname.split('/');
        const _category = thePath[thePath.length - 1];
        let {
            tenantId,
            tenantIcaoCode,
            app,
            allowedApps,
            appliedFilters,
            dataset1,
            showWorkbench,
            tabFunction,
            updateQuicksightFiltersFunction,
            forceReloadAll,
            match,
            onlyComparisonRangeUpdated,
            title
        } = this.props;

        return (
            <Col key={colIndex}>
                {col.cards.map((card, cardIndex) => {
                    let extra = AddExtraProps(card, {
                        tenantId: tenantId,
                        tenantIcaoCode: tenantIcaoCode,
                        app: app,
                        allowedApps: allowedApps,
                        dataset1: dataset1,
                        category: _category,
                        showWorkbench: showWorkbench,
                        tabFunction: tabFunction,
                        updateQuicksightFiltersFunction: updateQuicksightFiltersFunction,
                        appliedFilters: appliedFilters,
                        forceReloadAll: forceReloadAll,
                        onlyComparisonRangeUpdated: onlyComparisonRangeUpdated,
                        title: title,
                        path: match.path
                    });
                    return (
                        <div key={cardIndex} className="chunky">
                            {extra}
                        </div>
                    );
                })}
            </Col>
        );
    }

    render() {
        let title = '';
        let layout = null;

        let { match } = this.props;

        dashboards.some((item) => {
            if (item.key == match.path) {
                title = item.title;
                layout = item.layout;
            }
        });

        return <React.Fragment>{this.buildDashboard(title, layout)}</React.Fragment>;
    }
}

export default ChartsDashBoard;
