import { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import Analytics from 'components/widgets/Analytics';

//for circular JSON Format
const replacerFunc = () => {
    const visited = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (visited.has(value)) {
                return;
            }
            visited.add(value);
        }
        return value;
    };
};

const LineChart = (props) => {
    const [realTimeData, setRealTimeData] = useState(true);
    const [precedingData, setPrecedingData] = useState(true);
    const [data, setData] = useState(props.data);
    const legendArray = [
        { backgroundColor: '#2264A0', text: props.dateRangeText, type: 'current' },
        { backgroundColor: '#722BCA', text: props.comparisonRangeText, type: 'preceding' }
    ];

    useEffect(() => {
        if (!_.isEqual(data, props.data)) {
            if (props.data !== undefined && props.data !== null && props.data.datasets.length > 0) {
                let copiedData = JSON.parse(JSON.stringify(props.data, replacerFunc()));
                copiedData.datasets[0].labels = copiedData.labels;
                if (realTimeData && precedingData) {
                    //
                } else if (!precedingData) {
                    copiedData.datasets[1].data = [];
                    copiedData.datasets[1].labels = [];
                    copiedData.labels = copiedData.datasets[0].labels;
                } else if (!realTimeData) {
                    copiedData.datasets[0].data = [];
                    copiedData.datasets[0].labels = [];
                    copiedData.labels = copiedData.datasets[1].labels;
                }
                setData(copiedData);
            }
        }
    }, [props.data]);

    let { options } = props;

    function getData(canvas, data, options) {
        let gradientFill;
        if (data !== undefined) {
            let ctx = canvas.getContext('2d');
            gradientFill = ctx.createLinearGradient(0, 0, 0, 300);
            gradientFill.addColorStop(0, 'rgba(17, 118, 220, 0.3)');
            gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0.3)');
            if (data && data.datasets && data.datasets !== undefined && data.datasets[0] !== undefined) {
                data.datasets[0]['backgroundColor'] = gradientFill;
            }
        }

        return data;
    }

    if (props.data === null || props.data === undefined) {
        return null;
    }

    const handleRealtimeChkBox = (event, legendText) => {
        Analytics.analytics.track('handleRealtimeChkBox', {
            visual_name: 'Trends',
            interaction_type: 'Trends Checkbox Click',
            interaction_value: legendText
        });

        setRealTimeData(event);
        let copiedData = JSON.parse(JSON.stringify(props.data));
        if (event) {
            //
        } else {
            copiedData.datasets[0].data = [];
            copiedData.datasets[0].labels = [];
            copiedData.labels = copiedData.datasets[1].labels;
            setPrecedingData(true);
        }
        setData(copiedData);
    };

    const handlePrecedingChkBox = (event, legendText) => {
        Analytics.analytics.track('handlePrecedingChkBox', {
            visual_name: 'Trends',
            interaction_type: 'Trends Checkbox Click',
            interaction_value: legendText
        });

        setPrecedingData(event);
        let copiedData = JSON.parse(JSON.stringify(props.data));
        if (event) {
        } else {
            copiedData.datasets[1].data = [];
            copiedData.datasets[1].labels = [];
            copiedData.labels = copiedData.datasets[0].labels;
            setRealTimeData(true);
        }

        setData(copiedData);
    };

    return (
        <div style={{ paddingLeft: '14px', paddingRight: '5px' }}>
            {props.dataAvailable && (
                <div>
                    {props.yAxisLabel && <div className="chart-y-axis">{props.yAxisLabel}</div>}
                    <div>
                        <Line
                            data={(canvas) => getData(canvas, data, options)}
                            height={props.lineHeight ? props.lineHeight : '270'}
                            options={options}
                            redraw={true}
                        />
                    </div>
                    {props.showWorkbenchLink && props.comparisonRangeText && props.comparisonDataFlag && (
                        <div style={{ display: 'flex', marginLeft: 14, marginTop: props.page ? 5 : 15 }}>
                            <div
                                className="vertical-bar-legend"
                                style={{ width: '50%', gridTemplateColumns: 'repeat(2, 1fr)', left: 55 }}
                            >
                                {legendArray.map((legend, index) => {
                                    return (
                                        <div
                                            key={legend.text}
                                            onClick={() => {
                                                legend.type === 'current'
                                                    ? handleRealtimeChkBox(!realTimeData, legend.text)
                                                    : handlePrecedingChkBox(!precedingData, legend.text);
                                            }}
                                            style={{ height: '24px' }}
                                            className={
                                                index === 0 && !realTimeData
                                                    ? 'unselected'
                                                    : index === 1 && !precedingData
                                                    ? 'unselected'
                                                    : ''
                                            }
                                        >
                                            <div
                                                className="vertical-bar-chart-legend-color"
                                                style={{
                                                    color:
                                                        (index === 0 && realTimeData) || (index === 1 && precedingData)
                                                            ? '#fff'
                                                            : '#000',
                                                    backgroundColor:
                                                        (index === 0 && realTimeData) || (index === 1 && precedingData)
                                                            ? legend.backgroundColor
                                                            : '#E5E5E5'
                                                }}
                                            >
                                                {(index === 0 && realTimeData) || (index === 1 && precedingData)
                                                    ? '\u2713'
                                                    : ''}
                                            </div>
                                            <div className="vertical-bar-chart-legend-label">
                                                <span>{legend.text}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {!props.dataAvailable && (
                <div className="no-data" style={{ marginTop: '18%', fontSize: '32px' }}>
                    Data is not available
                </div>
            )}
        </div>
    );
};

export default LineChart;
