import Excel from 'components/excel/ExcelHelper.js';
import * as XLSX from 'xlsx';
import moment from 'moment';

class TableWidgetExcelHelper extends Excel {
    exportExcel(
        airlineIcao,
        reportType,
        reportSubType,
        title,
        fileNamePrefix,
        data,
        filters,
        showComparison,
        dropdown1Options,
        page,
        workbenchType
    ) {
        let wb = XLSX.utils.book_new();

        let dataFound = false;
        let dropdown1Option = dropdown1Options[0];

        try {
            let reportData = data['rows'];
            if (reportData) {
                let tabTitle = dropdown1Option.label;

                if (dropdown1Option.title) {
                    title = dropdown1Option.title;
                }

                let tableColumnTitles = [
                    { value: 'tail_no', header: 'Tail Number' },
                    { value: 'flight_number', header: 'Flight Number' },
                    { value: 'flight_start_date', header: 'Flight Start Date', type: 'date' },
                    { value: 'flight_end_date', header: 'Flight End Date', type: 'date' },
                    { value: 'flight_duration_secs', header: 'Flight Duration', type: 'time' },
                    { value: 'aircraft_type_subtype', header: 'Aircraft Type/Sub-type' },
                    { value: 'system_type', header: 'System Type' },
                    { value: 'dep_airport_iata', header: 'Flight Origin' },
                    { value: 'arr_airport_iata', header: 'Flight Destination' },
                    { value: 'seat_class', header: 'Seat Class' },
                    { value: 'seatnum', header: 'Seat Number' },
                    { value: 'payload_code', header: 'Payload Code' },
                    { value: 'payload_event', header: 'Payload Event' }
                ];

                reportData = this.formatReportDataForTitles(
                    airlineIcao,
                    reportData,
                    filters,
                    tableColumnTitles,
                    page,
                    reportType,
                    !showComparison,
                    tabTitle
                );

                let sheet = XLSX.utils.json_to_sheet(reportData);
                sheet['!cols'] = [
                    { wch: 35 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 }
                ];
                sheet = this.addLinksToFooter(reportData, sheet);
                XLSX.utils.book_append_sheet(wb, sheet, tabTitle);
                dataFound = true;
            }
        } catch (e) {
            console.log(e);
        }

        if (!dataFound) {
            XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([]), 'Sheet1');
        }

        XLSX.writeFile(wb, `${fileNamePrefix}.xlsx`);
    }

    populateTableBodyRowsData(column1, column2, column3, data, reportType, tableColumnTitles, formattedData) {
        //table rows
        for (let i = 0; data && i < data.length; i++) {
            let row = {};

            for (let j = 0; j < tableColumnTitles.length; j++) {
                let column;
                if (j === 0) {
                    column = 'NEXT Insights Export';
                } else {
                    column = ' '.repeat(j);
                }
                if (tableColumnTitles[j]['type'] === 'time') {
                    let formattedData = {
                        t: 'n',
                        z: '[hh]:mm:ss.0',
                        v: parseFloat(data[i][tableColumnTitles[j]['value']]) / 3600 / 24
                    };
                    row[column] = formattedData;
                } else if (tableColumnTitles[j]['type'] === 'date') {
                    row[column] = moment(data[i][tableColumnTitles[j]['value']]).format('MMM DD, YYYY');
                } else {
                    row[column] = data[i][tableColumnTitles[j]['value']];
                }
            }

            formattedData.push(row);
        }
    }

    formatReportDataForTitles(airlineIcao, data, filters, tableColumnTitles, page, reportType, isBasicTier, tabTitle) {
        try {
            let formattedData = [];

            // Hack to add rows before the Header row in XLSX
            let column1 = 'NEXT Insights Export';
            let column2 = ' ';
            let column3 = '  ';

            this.populateSummaryRowsData(
                formattedData,
                airlineIcao,
                filters,
                page,
                column1,
                column2,
                column3,
                isBasicTier
            );

            let columnTitles = tableColumnTitles.map((obj) => obj.header);
            this.populateTableHeaderRowsData(columnTitles, column1, column2, column3, formattedData, tabTitle);

            this.populateTableBodyRowsData(
                column1,
                column2,
                column3,
                data,
                reportType,
                tableColumnTitles,
                formattedData
            );

            //empty rows for spacing
            for (let i = 0; i < 3; i++) {
                let row = {
                    [column1]: '',
                    [column2]: ''
                };
                formattedData.push(row);
            }

            return formattedData;
        } catch (err) {
            console.log(err);
        }
    }
}

export default TableWidgetExcelHelper;
