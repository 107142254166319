import React from 'react';
import TrendContainer from 'components/trends/TrendContainer';
import PlaneContainer from 'components/plane/PlaneContainer';

let page = 'bluetooth';
let displayPageName = 'Bluetooth';

class BluetoothOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let dashboard = (
            <div>
                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    usageSource={displayPageName}
                    forceReloadAll={this.props.forceReloadAll}
                    title="Trends"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="Bluetooth_overview_trend_1"
                    id="Bluetooth_App_overview_trend_1"
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />

                <PlaneContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    usageSource={displayPageName}
                    title="Cabin Class"
                    workbenchTitle="cabin_class_viewing"
                    page={page}
                    pageName={displayPageName}
                    forceReloadAll={this.props.forceReloadAll}
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default BluetoothOverview;
