export default {
    getLanguagesDropdownListHelper (reportSubType) {
        let dropdownList;

        switch (reportSubType) {
            case 'Movies':
                dropdownList = {
                    dropdown1: [
                        { label: 'Audio Track', unit: 'count', value: 'soundtrack_languages', default: 1 },
                        { label: 'Subtitles', unit: 'count', value: 'subtitle_languages' }
                    ],
                    dropdown2: [
                        {
                            label: 'Total Views (Top 10)',
                            unit: 'count',
                            value: 'most_popular.total_views',
                            text: 'Movies Total Views',
                            xls_label: 'Top10Views',
                            default: 1,
                            free_tier: 1,
                            section: 'top_10_total_views',
                            small_count_label: 'Total Views'
                        },
                        {
                            label: 'Total Viewing Time (Top 10)',
                            unit: 'hr',
                            value: 'most_popular.total_time',
                            text: 'Movies Total Viewing Time',
                            xls_label: 'Top10ViewingTime',
                            free_tier: 0,
                            section: 'top_10_total_time',
                            small_count_label: 'Total Viewing Time'
                        },
                        {
                            label: 'Total Views (Bottom 10)',
                            unit: 'count',
                            value: 'least_popular.total_views',
                            text: 'Movies Total Views',
                            xls_label: 'Bottom10Views',
                            free_tier: 1,
                            section: 'bottom_10_total_views',
                            small_count_label: 'Total Views'
                        },
                        {
                            label: 'Total Viewing Time (Bottom 10)',
                            unit: 'hr',
                            value: 'least_popular.total_time',
                            text: 'Movies Total Viewing Time',
                            xls_label: 'Bottom10ViewingTime',
                            free_tier: 0,
                            section: 'bottom_10_total_time',
                            small_count_label: 'Total Viewing Time'
                        }
                    ]
                };
                break;
            case 'Excelmovies':
                dropdownList = [
                    {
                        label: 'Total Soundtrack Views ',
                        unit: 'count',
                        value: 'soundtrack_languages.all_records.total_views',
                        text: 'Movies Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Soundtrack Viewing Time',
                        unit: 'hr',
                        value: 'soundtrack_languages.all_records.total_time',
                        text: 'Movie Total Viewing Time'
                    },
                    {
                        label: 'Total Subtitles Views ',
                        unit: 'count',
                        value: 'subtitle_languages.all_records.total_views',
                        text: 'Movies Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Subtitles Viewing Time',
                        unit: 'hr',
                        value: 'subtitle_languages.all_records.total_time',
                        text: 'Movie Total Viewing Time'
                    }
                ];
                break;
            case 'TV Series':
                dropdownList = {
                    dropdown1: [
                        { label: 'Audio Track', unit: 'count', value: 'soundtrack_languages', default: 1 },
                        { label: 'Subtitles', unit: 'count', value: 'subtitle_languages' }
                    ],
                    dropdown2: [
                        {
                            label: 'Total Views (Top 10)',
                            unit: 'count',
                            value: 'most_popular.total_views',
                            text: 'TV Series Total Views',
                            xls_label: 'Top10Views',
                            default: 1,
                            free_tier: 1,
                            section: 'top_10_total_views',
                            small_count_label: 'Total Views'
                        },
                        {
                            label: 'Total Viewing Time (Top 10)',
                            unit: 'hr',
                            value: 'most_popular.total_time',
                            text: 'TV Series Total Viewing Time',
                            xls_label: 'Top10ViewingTime',
                            free_tier: 0,
                            section: 'top_10_total_time',
                            small_count_label: 'Total Viewing Time'
                        },
                        {
                            label: 'Total Views (Bottom 10)',
                            unit: 'count',
                            value: 'least_popular.total_views',
                            text: 'TV Series Total Views',
                            xls_label: 'Bottom10Views',
                            free_tier: 1,
                            section: 'bottom_10_total_views',
                            small_count_label: 'Total Views'
                        },
                        {
                            label: 'Total Viewing Time (Bottom 10)',
                            unit: 'hr',
                            value: 'least_popular.total_time',
                            text: 'TV Series Total Viewing Time',
                            xls_label: 'Bottom10ViewingTime',
                            free_tier: 0,
                            section: 'bottom_10_total_time',
                            small_count_label: 'Total Viewing Time'
                        }
                    ]
                };
                break;
            case 'Exceltv-series':
                dropdownList = [
                    {
                        label: 'Total Soundtrack Views ',
                        unit: 'count',
                        value: 'soundtrack_languages.all_records.total_views',
                        text: 'TV Series Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Soundtrack Viewing Time',
                        unit: 'hr',
                        value: 'soundtrack_languages.all_records.total_time',
                        text: 'TV Series Total Viewing Time'
                    },
                    {
                        label: 'Total Subtitles Views ',
                        unit: 'count',
                        value: 'subtitle_languages.all_records.total_views',
                        text: 'TV Series Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Subtitles Viewing Time',
                        unit: 'hr',
                        value: 'subtitle_languages.all_records.total_time',
                        text: 'TV Series Viewing Time'
                    }
                ];
                break;
            case 'Audio':
                dropdownList = {
                    dropdown1: [
                        { label: 'Soundtrack', unit: 'count', value: 'soundtrack_languages', default: 1 },
                        { label: 'Subtitles', unit: 'count', value: 'subtitle_languages' }
                    ],
                    dropdown2: [
                        {
                            label: 'Total Hits (Top 10)',
                            unit: 'count',
                            value: 'most_popular.total_views',
                            text: 'Audio Total Hits',
                            xls_label: 'Top10Views',
                            default: 1,
                            free_tier: 1,
                            section: 'top_10_total_views',
                            small_count_label: 'Total Hits'
                        },
                        {
                            label: 'Total Listening Time (Top 10)',
                            unit: 'hr',
                            value: 'most_popular.total_time',
                            text: 'Audio Total Listening Time',
                            xls_label: 'Top10ViewingTime',
                            free_tier: 0,
                            section: 'top_10_total_time',
                            small_count_label: 'Total Listening Time'
                        },
                        {
                            label: 'Total Hits (Bottom 10)',
                            unit: 'count',
                            value: 'least_popular.total_views',
                            text: 'Audio Total Hits',
                            xls_label: 'Bottom10Views',
                            free_tier: 1,
                            section: 'bottom_10_total_views',
                            small_count_label: 'Total Hits'
                        },
                        {
                            label: 'Total Listening Time (Bottom 10)',
                            unit: 'hr',
                            value: 'least_popular.total_time',
                            text: 'Audio Total Listening Time',
                            xls_label: 'Bottom10ViewingTime',
                            free_tier: 0,
                            section: 'bottom_10_total_views',
                            small_count_label: 'Total Listening Time'
                        }
                    ]
                };
                break;
            case 'Excelaudio':
                dropdownList = [
                    {
                        label: 'Total Soundtrack Views',
                        unit: 'count',
                        value: 'soundtrack_languages.all_records.total_views',
                        text: 'Audio Total Viewers',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Soundtrack Viewing Time',
                        unit: 'hr',
                        value: 'soundtrack_languages.all_records.total_time',
                        text: 'Audio Total Viewing Time'
                    },
                    {
                        label: 'Total Subtitles Views',
                        unit: 'count',
                        value: 'subtitle_languages.all_records.total_views',
                        text: 'Audio Total Viewers',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Subtitles Viewing Time',
                        unit: 'hr',
                        value: 'subtitle_languages.all_records.total_time',
                        text: 'Audio Viewing Time'
                    }
                ];
                break;
            case 'Games':
                dropdownList = {
                    dropdown1: [
                        { label: 'Soundtrack', unit: 'count', value: 'soundtrack_languages', default: 1 },
                        { label: 'Subtitles', unit: 'count', value: 'subtitle_languages' }
                    ],
                    dropdown2: [
                        {
                            label: 'Total Views (Top 10)',
                            unit: 'count',
                            value: 'most_popular.total_views',
                            text: 'Games Total Views',
                            xls_label: 'Top10Views',
                            default: 1,
                            free_tier: 1,
                            section: 'top_10_total_views',
                            small_count_label: 'Total Views'
                        },
                        {
                            label: 'Total Viewing Time (Top 10)',
                            unit: 'hr',
                            value: 'most_popular.total_time',
                            text: 'Games Total Viewing Time',
                            xls_label: 'Top10ViewingTime',
                            free_tier: 0,
                            section: 'top_10_total_time',
                            small_count_label: 'Total Viewing Time'
                        },
                        {
                            label: 'Total Views (Bottom 10)',
                            unit: 'count',
                            value: 'least_popular.total_views',
                            text: 'Games Total Views',
                            xls_label: 'Bottom10Views',
                            free_tier: 1,
                            section: 'bottom_10_total_views',
                            small_count_label: 'Total Views'
                        },
                        {
                            label: 'Total Viewing Time (Bottom 10)',
                            unit: 'hr',
                            value: 'least_popular.total_time',
                            text: 'Games Total Viewing Time',
                            xls_label: 'Bottom10ViewingTime',
                            free_tier: 0,
                            section: 'bottom_10_total_time',
                            small_count_label: 'Total Viewing Time'
                        }
                    ]
                };
                break;
            case 'Excelgames':
                dropdownList = [
                    {
                        label: 'Total Soundtrack Views ',
                        unit: 'count',
                        value: 'soundtrack_languages.all_records.total_views',
                        text: 'Games Total Viewers',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Soundtrack Viewing Time',
                        unit: 'hr',
                        value: 'soundtrack_languages.all_records.total_time',
                        text: 'Games Total Viewing Time'
                    },
                    {
                        label: 'Total Subtitles Views ',
                        unit: 'count',
                        value: 'subtitle_languages.all_records.total_views',
                        text: 'Games Total Viewers',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Subtitles Viewing Time',
                        unit: 'hr',
                        value: 'subtitle_languages.all_records.total_time',
                        text: 'Games Viewing Time'
                    }
                ];
                break;
            case 'Electronic Reader':
                dropdownList = {
                    dropdown1: [
                        { label: 'Book Language', unit: 'count', value: 'ereader_languages', default: 1 }
                        // { label: "Subtitle", unit: "count", value: "subtitle_languages",},
                    ],
                    dropdown2: [
                        {
                            label: 'Total Title Views (Top 10)',
                            unit: 'count',
                            value: 'most_popular.total_title_views',
                            text: 'eReader Total Title Views',
                            default: 1,
                            free_tier: 1,
                            section: 'top_10_total_title_views',
                            small_count_label: 'Total Title Views'
                        },
                        {
                            label: 'Total Readers (Top 10)',
                            unit: 'count',
                            value: 'most_popular.total_readers',
                            text: 'eReader Total Readers',
                            free_tier: 0,
                            section: 'top_10_total_readers',
                            small_count_label: 'Total Readers'
                        },
                        {
                            label: 'Total Reading Time (Top 10)',
                            unit: 'hr',
                            value: 'most_popular.total_time',
                            text: 'eReader Total Reading Time',
                            free_tier: 0,
                            section: 'top_10_total_time',
                            small_count_label: 'Total Reading Time'
                        },
                        {
                            label: 'Avg. Reading Time (Top 10)',
                            unit: 'hr',
                            value: 'most_popular.avg_time',
                            text: 'eReader Avg. Reading Time',
                            free_tier: 0,
                            section: 'top_10_avg_time',
                            small_count_label: 'Avg. Reading Time'
                        },
                        {
                            label: 'Total Title Views (Bottom 10)',
                            unit: 'count',
                            value: 'least_popular.total_title_views',
                            text: 'eReader Total Title Views',
                            free_tier: 1,
                            section: 'bottom_10_total_title_views',
                            small_count_label: 'Total Title Views'
                        },
                        {
                            label: 'Total Readers (Bottom 10)',
                            unit: 'count',
                            value: 'least_popular.total_readers',
                            text: 'eReader Total Readers',
                            free_tier: 0,
                            section: 'bottom_10_total_readers',
                            small_count_label: 'Total Readers'
                        },
                        {
                            label: 'Total Reading Time (Bottom 10)',
                            unit: 'hr',
                            value: 'least_popular.total_time',
                            text: 'eReader Total Reading Time',
                            free_tier: 0,
                            section: 'bottom_10_total_time',
                            small_count_label: 'Total Reading Time'
                        },
                        {
                            label: 'Avg. Reading Time (Bottom 10)',
                            unit: 'hr',
                            value: 'least_popular.avg_time',
                            text: 'eReader Avg. Reading Time',
                            free_tier: 0,
                            section: 'bottom_10_avg_time',
                            small_count_label: 'Avg. Reading Time'
                        }
                    ]
                };
                break;
            case 'Excelereader':
                dropdownList = [
                    {
                        label: 'Total Book Lang Title Views',
                        unit: 'count',
                        value: 'ereader_languages.all_records.total_title_views',
                        text: 'eReader Title Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Book Lang Readers',
                        unit: 'count',
                        value: 'ereader_languages.all_records.total_readers',
                        text: 'eReader Total Readers'
                    },
                    {
                        label: 'Total Book Lang Reading Time',
                        unit: 'hr',
                        value: 'ereader_languages.all_records.total_time',
                        text: 'eReader Total Reading Time'
                    },
                    {
                        label: 'Avg. Book Lang Reading Time',
                        unit: 'hr',
                        value: 'ereader_languages.all_records.avg_time',
                        text: 'eReader Avg. Reading Time'
                    }
                ];
                break;
            case 'Seatback':
                dropdownList = [
                    {
                        label: 'Language By Passengers (Top 10)',
                        unit: 'count',
                        value: 'selected_lang.most_popular.total_users',
                        text: 'Seatback Language By Passengers',
                        xls_label: 'Top10Viewers',
                        default: 1,
                        free_tier: 1,
                        section: 'top_10_total_viewers',
                        small_count_label: 'Language By Passengers'
                    },
                    {
                        label: 'Dwell Time (Top 10)',
                        unit: 'hr',
                        value: 'selected_lang.most_popular.total_time',
                        text: 'Seatback Dwell Time',
                        xls_label: 'Top10Viewers',
                        default: 1,
                        free_tier: 1,
                        section: 'top_10_total_time',
                        small_count_label: 'Dwell Time'
                    },
                    {
                        label: 'Transitions (Top 10)',
                        unit: 'count',
                        value: 'lang_trans.most_popular.total_views',
                        text: 'Seatback Transitions',
                        xls_label: 'Top10Viewers',
                        default: 1,
                        free_tier: 1,
                        section: 'top_10_total_transitions',
                        small_count_label: 'Transitions'
                    },
                    {
                        label: 'Language By Passengers (Bottom 10)',
                        unit: 'count',
                        value: 'selected_lang.least_popular.total_users',
                        text: 'Seatback Language By Passengers',
                        xls_label: 'Bottom10Viewers',
                        default: 1,
                        free_tier: 1,
                        section: 'bottom_10_total_viewers',
                        small_count_label: 'Language By Passengers'
                    },
                    {
                        label: 'Dwell Time (Bottom 10)',
                        unit: 'hr',
                        value: 'selected_lang.least_popular.total_time',
                        text: 'Seatback Dwell Time',
                        xls_label: 'Bottom10Viewers',
                        default: 1,
                        free_tier: 1,
                        section: 'bottom_10_total_time',
                        small_count_label: 'Dwell Time'
                    },
                    {
                        label: 'Transitions (Bottom 10)',
                        unit: 'count',
                        value: 'lang_trans.least_popular.total_views',
                        text: 'Seatback Transitions',
                        xls_label: 'Bottom10Viewers',
                        default: 1,
                        free_tier: 1,
                        section: 'bottom_10_total_transitions',
                        small_count_label: 'Transitions'
                    }
                ];
                break;
            case 'Excelseatback':
                dropdownList = [
                    {
                        label: 'Language By Passengers',
                        unit: 'count',
                        value: 'selected_lang.all_records.total_users',
                        text: 'Language By Passengers',
                        free_tier: 1
                    },
                    {
                        label: 'Dwell Time',
                        unit: 'hr',
                        value: 'selected_lang.all_records.total_time',
                        text: 'Dwell Time'
                    },
                    {
                        label: 'Transitions',
                        unit: 'count',
                        value: 'lang_trans.all_records.total_views',
                        text: 'Transitions',
                        free_tier: 1
                    }
                ];
                break;
            default:
        }

        return dropdownList;
    }
};
