import React from 'react';
import { Card } from 'reactstrap';
import _, { debounce } from 'lodash';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import ErrorBoundary from 'util/ErrorBoundary.js';
import * as AppConstants from 'constants/app/constants';
import HorizontalBarChartWidget from 'components/horizontal-bar-chart/HorizontalBarChartWidget';
import UtilHelper from 'util/UtilHelper.js';
import { filterSectionsByTier } from 'components/widgets/AccessControl';
import HorizontalBarChartConfig from 'components/horizontal-bar-chart/HorizontalBarChartConfig.js';
import FiltersService from '../../services/FiltersService';
import SubscriptionService from 'services/SubscriptionService';

const horizontalBarChartConfig = new HorizontalBarChartConfig();

class HorizontalBarChartContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: '',
            loading: true,
            error: false,
            requestedSections: []
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) ||
            prevProps.path !== this.props.path ||
            this.props.forceReloadAll
        ) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        this.setState({ loading: true });
        if (FiltersService.dateRangeFiltersData !== '') {
            const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));
            let reportSubType = '';
            if (this.props.contentType) {
                selectedFiltersDeepClone.content_types = [this.props.contentType];
                reportSubType = this.props.contentType;
            } else if (this.props.usageSource) {
                if (this.props.usageSource === 'Companion App') {
                    selectedFiltersDeepClone.launched_by = ['Companion App'];
                } else {
                    selectedFiltersDeepClone.usage_source = this.props.usageSource;
                }
                reportSubType = this.props.usageSource;
            }

            selectedFiltersDeepClone.requested_sections = horizontalBarChartConfig.getBarChartRequestedSections(
                this.props.reportType,
                reportSubType
            );

            if (SubscriptionService.subscription() !== '') {
                selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
            }

            if (this.props.app) {
                selectedFiltersDeepClone['app'] = this.props.app;
            }

            const path = horizontalBarChartConfig.getAPIPath();
            if (this.cancelRequestSource) {
                this.cancelRequestSource.cancel('Operation canceled by the user.');
            }

            this.throttleFetch(selectedFiltersDeepClone, path);
        }
    }

    async throttleFetch(selectedFiltersDeepClone, path) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchNextInsightsMetricsAxios(selectedFiltersDeepClone, path, this.cancelRequestSource.token)
            .then((response) => {
                if (!response && !response.requested_sections) {
                    this.setState({ error: true, dataset: [], loading: false });
                } else {
                    if (this.props.reportType === 'events') {
                        this.setState({
                            error: false,
                            dataset: response.requested_sections.companion_app_events.data,
                            loading: false
                        });
                    } else {
                        this.setState({ error: false, dataset: response.requested_sections, loading: false });
                    }
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, loading: false });
                } else {
                    this.setState({ error: true, dataset: [], loading: false });
                }
            });
    }

    renderBarChart() {
        let { dataset, loading } = this.state;

        let dropdownList1 = [];
        let dropdownList2 = [];

        let reportSubType = '';
        if (this.props.contentType) {
            reportSubType = this.props.contentType;
        } else if (this.props.usageSource) {
            reportSubType = this.props.usageSource;
        }

        let dropdownList = horizontalBarChartConfig.getDropdownList(this.props.reportType, reportSubType);
        if (dropdownList) {
            if (Array.isArray(dropdownList)) {
                dropdownList1 = dropdownList.filter((option) => {
                    let filteredList = filterSectionsByTier(this.props.page, [option.section]);
                    if (filteredList.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                });

                let utilHelper = new UtilHelper();
                dropdownList1 = utilHelper.updateDropdownListLabels(dropdownList1, dataset);
            } else {
                dropdownList1 = dropdownList.dropdown1 ? dropdownList.dropdown1 : [];
                dropdownList2 = dropdownList.dropdown2 ? dropdownList.dropdown2 : [];

                if (dropdownList2.length !== 0) {
                    dropdownList2 = dropdownList2.filter((option) => {
                        let filteredList = filterSectionsByTier(this.props.page, [option.section]);
                        if (filteredList.length > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                }

                let dropdownList2Obj = {};
                dropdownList1.forEach((list1Option) => {
                    let utilHelper = new UtilHelper();
                    let clonedList = utilHelper.updateDropdownListLabels(
                        dropdownList2,
                        dataset,
                        list1Option.value,
                        true
                    );

                    dropdownList2Obj[list1Option.value] = clonedList;
                });

                dropdownList2 = dropdownList2Obj;
            }
        }

        return (
            <Card className="bar-chart bar-chart-gap">
                <ErrorBoundary>
                    <HorizontalBarChartWidget
                        key={'horizontal_bar_chart_x_' + this.props.contentType + this.props.reportType}
                        id={'horizontal_bar_chart_y_' + this.props.contentType + this.props.reportType}
                        type={'horizontal_bar_chart_' + this.props.contentType + this.props.reportType}
                        data={dataset ? dataset : []}
                        title={this.props.title}
                        reportType={this.props.reportType}
                        page={this.props.page}
                        pageName={this.props.pageName}
                        dd1Options={dropdownList1}
                        dd2Options={dropdownList2}
                        unit="count"
                        routes={this.props.routes}
                        color={this.props.color}
                        loading={loading}
                        workbench_type={this.props.workbenchTitle}
                        showWorkbenchLink={this.props.showWorkbenchLink}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        appliedFilters={this.props.appliedFilters.api}
                        app={this.props.app}
                    />
                </ErrorBoundary>
            </Card>
        );
    }

    render() {
        return <React.Fragment>{this.renderBarChart()}</React.Fragment>;
    }
}

export default HorizontalBarChartContainer;
