import React from 'react';
import Plane from 'components/plane/Plane';
import WidgetFrame from 'components/widget-frame/WidgetFrameNew';
import PedIcon from 'assets/images/icons/PED-icon.svg';
import UtilHelper from 'util/UtilHelper.js';
import 'assets/styles/plane-with-tooltip.css';

const utilHelper = new UtilHelper();

class PlaneWidget extends React.Component {
    constructor(props) {
        super(props);

        const _unit = props.unit;
        let precision;

        let data = [];
        if (typeof props.data !== 'undefined' && Object.keys(props.data).length > 0) {
            if (typeof props.defaultKey1 !== 'undefined') {
                data = props.data[props.defaultKey1]['data'];
            }
        }

        this.state = {
            data: data,
            unit: _unit,
            precision: precision
        };
    }

    updateWidgetData = (newData) => {
        if (newData.precision) {
            this.setState({
                data: newData.data,
                unit: newData.unit,
                text: newData.text,
                precision: newData.precision
            });
        } else {
            this.setState({
                data: newData.data,
                unit: newData.unit,
                text: newData.text
            });
        }
    };

    createPedSection(pedObject) {
        let value = pedObject.value;
        if (value === null) {
            value = 'N/A';
        } else {
            if (utilHelper.isValidNumber(value)) {
                if (this.state.unit === 'hr') {
                    value =
                        this.state.text && this.state.text.includes('Total')
                            ? utilHelper.secondsToHmsForDynamicTabs(pedObject.value)
                            : utilHelper.secondsToHoursMinutes(pedObject.value);
                } else {
                    value = utilHelper.formatToUnitsForPillBox(pedObject.value);
                }
            }
        }

        let compValue = pedObject.comparison_value;
        let changeArrow =
            compValue > 0 ? (
                <span style={{ fontSize: '12px', paddingRight: '2px' }}>{'\u25B2  '}</span>
            ) : compValue < 0 ? (
                <span style={{ fontSize: '12px', paddingRight: '2px' }}>{'\u25BC  '}</span>
            ) : compValue === 0 ? (
                <i className="fas fa-sort" style={{ fontSize: 15, paddingRight: '2px' }}></i>
            ) : (
                ''
            );
        let changeColor = compValue > 0 ? '#225522' : compValue < 0 ? '#FF0000' : '#9E9E9E';

        if (compValue === null) {
            compValue = 'N/A';
        } else if (Math.abs(compValue) > 999) {
            compValue = '999+%';
        } else if (compValue > 100) {
            compValue = `${Math.abs(Math.round(compValue))}%`;
        } else if (compValue || compValue === 0) {
            compValue = `${Math.abs(compValue)}%`;
        }

        let changeValue = (
            <span
                className="ped-icon text change-value"
                style={{
                    color: changeColor
                }}
            >
                {' '}
                {changeArrow} {compValue}{' '}
            </span>
        );

        let top3Airlines = '';
        if (pedObject.top_3_airlines) {
            top3Airlines = (
                <div class="top">
                    <p>Top 3 Airlines:</p>
                    {pedObject.top_3_airlines.map((airline) => (
                        <p>{airline}</p>
                    ))}
                    <i></i>
                </div>
            );
        }

        return (
            <div
                className="ped-icon"
                style={{
                    position: '',
                    top: ''
                }}
            >
                <img src={PedIcon} alt="" />
                <p className="ped-icon text">
                    <span className="ped-icon text label">PED</span>{' '}
                    {top3Airlines && (
                        <span className="ped-icon text value ped-tooltip">
                            {value}
                            {top3Airlines}
                        </span>
                    )}
                    {this.props.showWorkbenchLink && changeValue}
                </p>
            </div>
        );
    }

    render() {
        let data = [];
        let hasPED = false;
        let pedObject = {};

        if (this.state.data && this.state.data.length) {
            data = this.state.data;

            let result = data.find((element) => {
                return element.label === 'PED';
            });
            if (result !== undefined) {
                hasPED = true;
                pedObject = { ...result };
            }
        }

        let pedSection;

        if (hasPED) {
            pedSection = this.createPedSection(pedObject);
        } else {
            pedSection = <div></div>;
        }

        return (
            <WidgetFrame
                type="plane"
                page={this.props.page}
                pageName={this.props.pageName}
                contentType={this.props.contentType}
                data={this.props.data}
                loading={this.props.loading}
                title={this.props.title}
                updateData={this.updateWidgetData}
                showTabs={false}
                showOverflow={true}
                showDropdown={true}
                unit={this.props.unit}
                showViewMore={this.props.showViewMore}
                dataObject={this.props.dataObject}
                dd1Options={this.props.dd1Options}
                dd2Options={[]}
                component_type="plane"
                workbench_type={this.props.workbench_type || ''}
                tenantIcaoCode={this.props.tenantIcaoCode || ''}
                appliedFilters={this.props.appliedFilters}
                app={this.props.app}
                dataAvailable={data && data.length !== 0 ? true : false}
                body={
                    <div>
                        <Plane
                            page={this.props.page}
                            type={this.props.type}
                            data={data}
                            unit={this.state.unit || this.props.unit}
                            precision={this.state.precision || ''}
                            text={this.state.text || ''}
                            loading={this.props.loading}
                            showWorkbenchLink={this.props.showWorkbenchLink || false}
                            appliedFilters={this.props.appliedFilters}
                            contentType={this.props.contentType}
                            hasPED={hasPED}
                        />{' '}
                        {pedSection}
                    </div>
                }
            />
        );
    }
}

export default PlaneWidget;
