import React from 'react';
import { Spinner } from 'reactstrap';
import { ButtonPrimary, ButtonSecondary } from 'pac-ux-components';
import AdminService from 'services/AdminService';
import moment from 'moment';
import ErrorBoundary from 'util/ErrorBoundary.js';
import 'assets/styles/upgrade-end-license.css';

const service = new AdminService();

class UpgradeEndLicense extends React.Component {
    constructor(props) {
        super(props);

        let startDisplay = moment.unix(this.props.license.start_unix).format('MMM DD, YYYY');
        let expirationDisplay = moment.unix(this.props.license.expiration_unix).format('MMM DD, YYYY');

        this.state = {
            showAddSpinner: false,
            tier: this.props.license.subscription_tier,
            stage: this.props.license.stage,
            startDisplay: startDisplay,
            expirationDisplay: expirationDisplay,
            airlineName: this.props.license.airline_name,
            airlineCode: this.props.license.airline_code,
            startDate: this.props.license.license_start_date,
            endDate: this.props.license.license_end_date
        };

        this.endLicense = this.endLicense.bind(this);
        this.upgradeLicense = this.upgradeLicense.bind(this);
    }

    async endLicense() {
        let { airlineName, airlineCode, tier, stage, startDate } = this.state;

        this.setState({ showAddSpinner: true });

        let exp = new Date();
        const year = exp.getFullYear();
        const month = (exp.getMonth() + 1).toString().padStart(2, '0');
        const day = exp.getDate().toString().padStart(2, '0');

        let payload = {
            method: 'update-license',
            airline_code: airlineCode,
            airline_name: airlineName,
            data: {
                subscription_tier: tier,
                stage: stage,
                license_start_date: startDate,
                expiration: year + month + day
            }
        };

        let errorDescription = null;
        let result = null;
        try {
            result = await service.addUpdateLicense(payload);
            if (result.response.error !== undefined) {
                errorDescription = result.response.error.description;
            }
        } catch (error) {
            console.log('end license error ##');
            errorDescription = 'default';
        }

        this.setState({ showAddSpinner: false });

        if (errorDescription !== null) {
            this.props.displayError('end', errorDescription);
        } else {
            this.props.endLicenseAction(airlineCode, tier, result.response.data.updated_at);
        }
    }

    async upgradeLicense() {
        let { airlineName, airlineCode, tier, startDate, endDate } = this.state;

        this.setState({ showAddSpinner: true });

        let payload = {
            method: 'update-license',
            airline_code: airlineCode,
            airline_name: airlineName,
            data: {
                subscription_tier: tier,
                stage: 3,
                license_start_date: startDate,
                expiration: endDate
            }
        };

        let errorDescription = null;
        try {
            let result = await service.addUpdateLicense(payload);
            if (result.response.error !== undefined) {
                errorDescription = result.response.error.description;
            }
        } catch (error) {
            console.log('end license error ##');
            errorDescription = 'default';
        }

        this.setState({ showAddSpinner: false });

        if (errorDescription !== null) {
            this.props.displayError('upgrade', errorDescription);
        } else {
            this.props.upgradeLicenseAction(airlineCode, tier);
        }
    }

    isUpgradeAction() {
        return this.props.actionType === 'upgrade';
    }

    isDeliveryLicense() {
        return this.props.license.stage === '3';
    }

    render() {
        let { showAddSpinner } = this.state;
        let buttonText = this.isUpgradeAction() ? 'Upgrade license' : 'Yes, end license';

        return (
            <div className="wrapper">
                <div className="header">{this.isUpgradeAction() ? 'Upgrade Evaluation License' : 'End License'}</div>
                <div className="top-info">
                    {this.isUpgradeAction()
                        ? 'You are about to upgrade the selected license from Evaluation to Delivery:'
                        : this.isDeliveryLicense()
                        ? 'End Delivery License?'
                        : 'End Evaluation License?'}
                </div>
                <div className="table-wrapper">
                    <ErrorBoundary>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Airline</th>
                                    <th scope="col">Tier</th>
                                    <th scope="col">Stage</th>
                                    <th scope="col">License Start</th>
                                    <th scope="col">License Expiration</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.state.airlineName}</td>
                                    <td>{this.state.tier}</td>
                                    <td>{this.state.stage}</td>
                                    <td>{this.state.startDisplay}</td>
                                    <td>{this.state.expirationDisplay}</td>
                                </tr>
                            </tbody>
                        </table>
                    </ErrorBoundary>
                </div>
                {this.isUpgradeAction() && (
                    <div className="bottom-info">
                        To access this license, check the Licenses tab on the Deliveries page.
                    </div>
                )}
                {this.isDeliveryLicense() && (
                    <div className="bottom-info">
                        To access this license, check the Licenses tab on the Evaluations page.
                    </div>
                )}
                <div className="buttons-wrapper">
                    <ButtonSecondary className="button-secondary" onClick={this.props.closeAction}>
                        {this.isUpgradeAction() ? 'Cancel' : 'No, keep license'}
                    </ButtonSecondary>
                    <ButtonPrimary
                        className="button-primary margin-left-16"
                        onClick={this.isUpgradeAction() ? this.upgradeLicense : this.endLicense}
                    >
                        {showAddSpinner ? (
                            <div>
                                <Spinner className="spinner" />
                            </div>
                        ) : (
                            buttonText
                        )}
                    </ButtonPrimary>{' '}
                </div>
            </div>
        );
    }
}

export default UpgradeEndLicense;
