import React from 'react';
import Util from 'util/UtilHelper';
import 'react-calendar/dist/Calendar.css';
import 'assets/styles/widgets/lopa.scss';
import 'assets/styles/widgets/lopa.scss';
import Select from 'react-select';
import clsx from 'clsx';
import _ from 'lodash';
import sampleData from 'components/lopa/lopa_sample_data.js';
import Analytics from 'components/widgets/Analytics';

const utilHelper = new Util();

class Lopa extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSeat: '',
            clickedSeat: '',
            activeRow: 0,
            activeScroll: 0,
            seatColors: ['#A1C9EB', '#4D98D9', '#2264A0', '#013253'],
            flightStartDate: null,
            selectedSeatInfo: { label: 'Total Seat Usage Time', value: 'usage' },
            loadingSeats: false,
            flight: '',
            date: '',
            route: '',
            maxScroll: true
        };

        this.handleSeatInfoTypeChange = this.handleSeatInfoTypeChange.bind(this);
    }

    componentDidMount() {
        this.buildDefaultLopa(this.props.data);
    }

    componentWillUpdate(newProps, newState) {
        let icon = document.getElementById('disclaimer-icon');
        if (icon) {
            if (newProps.wizardStep == 4) {
                icon.style.display = 'initial';
            } else {
                icon.style.display = 'none';
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.date && this.props != nextProps) {
            this.setState({
                data: sampleData
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Typical usage (don't forget to compare props):
        let wrapper = document.getElementById('lopa-grid-wrapper');
        let scrollSize = wrapper.scrollWidth - wrapper.clientWidth;
        if (
            !_.isEqual(sampleData, this.state.lopaSeats) &&
            !this.state.loadingSeats &&
            scrollSize !== this.state.maxScroll
        ) {
            this.setState({
                maxScroll: scrollSize
            });
        }

        if (prevProps.data !== this.props.data) {
            this.handleSeatInfoTypeChange({ label: 'Total Seat Usage Time', value: 'usage' });
        }
    }

    randomNumberGenerator = () => {
        return Math.round(Math.random() * 100000);
    };

    openToolTip = (value, e) => {
        try {
            var target = e.target;
            var rect = target.getBoundingClientRect();
            var parent = target.parentNode.parentNode.parentElement;

            var xPosition = parent.getBoundingClientRect().x + parent.clientWidth - rect.x;
            var yPosition = parent.getBoundingClientRect().y + parent.clientHeight - rect.y;
            var xMargin = xPosition > 338 ? 10 : -260;
            var yMargin = yPosition > 310 ? -50 : yPosition > 180 ? -130 : -205;
            target.style.color = target.style.backgroundColor == 'rgb(243, 245, 247)' ? '#000000' : '#FFFFFF';
            if (target.parentNode.childNodes[1]) {
                //
            }
            var tooltip =
                target.parentNode.childNodes[1].data == '' ? <div>No Data</div> : target.parentNode.childNodes[1];
            tooltip.classList.remove('hidden');
            tooltip.style.marginLeft = xMargin + 'px';
            tooltip.style.marginTop = yMargin + 'px';

            if (yMargin == -140 || yMargin == -100) {
            } else {
                tooltip.style.top = -40 + 'px';
            }
            this.setState({
                activeSeat: value
            });
        } catch (error) {
            //console.log(error)
        }
    };

    closeTooltip(e) {
        let tooltip = e.target.parentNode.childNodes[1];
        e.target.style.color = 'transparent';
        if (tooltip) {
            if (tooltip.parentNode.childNodes[1].classList) {
                tooltip.parentNode.childNodes[1].classList.add('hidden');
                this.setState({
                    activeSeat: ''
                });
            }
        }
    }

    formatRoute(route) {
        if (route) {
            return route.split('-').join(' - ');
        }
        return route;
    }

    createLanguageTooltip(rowInfo, letterIndex) {
        let tooltip = '';

        let seatNumber = rowInfo.row + rowInfo.seats[letterIndex].letter;
        let seatContent = [];
        let seatLangData = rowInfo.seats[letterIndex].seatData;
        let totalLanguages = 0;

        for (let [language, percentage] of Object.entries(seatLangData)) {
            if (percentage > 0) {
                totalLanguages++;

                seatContent.push(
                    <li key={seatNumber + language}>
                        <span className="lopa-tool-tip-data-name">{language.toUpperCase()}</span>
                        <span className="lopa-tool-tip-data-value">{percentage + '%'}</span>
                    </li>
                );
            }
        }

        let seatColor = totalLanguages ? this.state.seatColors[1] : '#F3F5F7';
        if (seatContent.length > 0) {
            tooltip = {
                tooltip: (
                    <div className="lopa-tool-tip hidden">
                        <div className="lopa-tool-tip-container">
                            <div className="lopa-tool-tip-seatNumber">{seatNumber}</div>
                            <div className="lopa-tool-tip-header">
                                {`Total Languages`}:{' '}
                                <span className="lopa-tool-tip-header-hours value-small"> {totalLanguages}</span>
                            </div>
                            <ul className="lopa-tool-tip-data">{seatContent}</ul>
                        </div>
                    </div>
                ),
                seatColor: seatColor
            };
        }

        return { seatNumber: seatNumber, tooltip: tooltip, seatColor: seatColor };
    }

    buildDefaultLopa(data) {
        let seatColors = this.state.seatColors;
        let seatLettersArray = [];
        let lopaSeats = [];
        let seatNumber;

        data =
            this.state.selectedSeatInfo.value == 'languages'
                ? data.seatLangData
                : this.state.selectedSeatInfo.value == 'logins'
                ? data.seatLoginData
                : data.seatData;
        if (!data) {
            return;
        }

        for (var i = 0; i < data.length; i++) {
            let rowInfo = data[i];
            for (var s = 0; s < rowInfo.seats.length; s++) {
                var seat = rowInfo.seats[s];
                if (!seatLettersArray.includes(seat.letter)) {
                    seatLettersArray.push(seat.letter);
                }
            }
        }

        seatLettersArray = seatLettersArray.sort().reverse();

        var seatLetters = [];
        for (var i = 0; i < seatLettersArray.length; i++) {
            seatLetters.push(
                <div className="seat-letter" key={`seat-letter-${seatLettersArray[i]}`}>
                    {seatLettersArray[i]}
                </div>
            );
        }

        for (var i = 0; i < data.length; i++) {
            let rowInfo = data[i];
            if (i !== 0 && rowInfo.row - data[i - 1].row > 1) {
                for (var d = 0; d < seatLetters.length + 1; d++) {
                    lopaSeats.push(<div className="lopa-buffer" key={`lopa-buffer-${this.randomNumberGenerator()}`} />);
                }
            }

            lopaSeats.push(
                <div className="lopa-number" key={`lopa-row-${rowInfo.row}-${rowInfo.seatClass}`}>
                    {rowInfo.row}
                </div>
            );

            for (var sl = 0; sl < seatLettersArray.length; sl++) {
                var letterFound = false;
                var letterIndex = 0;
                for (var s = 0; s < rowInfo.seats.length; s++) {
                    if (rowInfo.seats[s].letter === seatLettersArray[sl]) {
                        letterFound = true;
                        letterIndex = s;
                        break;
                    }
                }

                if (letterFound) {
                    let tooltip = '';
                    let seatColor;
                    if (this.state.selectedSeatInfo.value == 'logins') {
                        let seatLoginData = rowInfo.seats[letterIndex].seatData;
                        if (seatLoginData.login_attempts) {
                            seatNumber = rowInfo.row + rowInfo.seats[letterIndex].letter;
                            var seatContent = [];
                            //var tooltip = '';
                            let loginAttemptsLabel = 'Login Attempts';

                            for (let [dataMetric, metricValue] of Object.entries(seatLoginData)) {
                                if (dataMetric === 'login_attempts') {
                                    seatContent.push(
                                        <li key={seatNumber + dataMetric}>
                                            <span className="lopa-tool-tip-data-name">{loginAttemptsLabel}</span>
                                            <span className="lopa-tool-tip-data-value">{metricValue}</span>
                                        </li>
                                    );
                                }
                            }

                            seatColor = seatLoginData.login_attempts > 0 ? this.state.seatColors[1] : '#F3F5F7';

                            if (seatContent.length > 0) {
                                tooltip = {
                                    tooltip: (
                                        <div className="lopa-tool-tip hidden">
                                            <div className="lopa-tool-tip-container">
                                                <div className="lopa-tool-tip-seatNumber">{seatNumber}</div>
                                                <div className="lopa-tool-tip-header">{`Login Attempts`}</div>

                                                <ul className="lopa-tool-tip-data">{seatContent}</ul>
                                            </div>
                                        </div>
                                    ),
                                    seatColor: seatColor
                                };
                            }
                        }
                    } else if (this.state.selectedSeatInfo.value == 'languages') {
                        let tooltipObj = this.createLanguageTooltip(rowInfo, letterIndex);
                        seatColor = tooltipObj.seatColor;
                        tooltip = tooltipObj.tooltip;
                        seatNumber = tooltipObj.seatNumber;
                    } else {
                        var percentage = parseFloat(rowInfo.seats[letterIndex].seatData.total_usage_time_percent);
                        seatColor = seatColors[0];
                        switch (percentage) {
                            case percentage > 0 && percentage <= 25:
                                seatColor = this.state.seatColors[0];
                                break;
                            case percentage <= 50:
                                seatColor = this.state.seatColors[1];
                                break;
                            case percentage <= 75:
                                seatColor = this.state.seatColors[2];
                                break;
                            case percentage > 75:
                                seatColor = this.state.seatColors[3];
                                break;
                            default:
                                seatColor = '#F3F5F7';
                                break;
                        }
                        seatNumber = rowInfo.row + rowInfo.seats[letterIndex].letter;

                        var noData = rowInfo.seats[letterIndex].seatData.total_usage_time_percent == 0;

                        var seatData = rowInfo.seats[letterIndex].seatData;
                        var seatContent = [];
                        //var tooltip = '';
                        if (!noData) {
                            if (this.state.selectedSeatInfo.value == 'views') {
                                let totalViews = 0;
                                if (seatData.views_by_content_type) {
                                    for (let [contentType, viewCount] of Object.entries(
                                        seatData.views_by_content_type
                                    )) {
                                        totalViews += viewCount;
                                        seatContent.push(
                                            <li key={seatNumber + contentType}>
                                                <span className="lopa-tool-tip-data-name">{contentType}</span>
                                                <span className="lopa-tool-tip-data-value">{viewCount}</span>
                                            </li>
                                        );
                                    }
                                }
                                seatColor = totalViews ? this.state.seatColors[1] : '#F3F5F7';
                                if (seatContent.length > 0) {
                                    tooltip = {
                                        tooltip: (
                                            <div className="lopa-tool-tip hidden">
                                                <div className="lopa-tool-tip-container">
                                                    <div className="lopa-tool-tip-seatNumber">{seatNumber}</div>
                                                    <div className="lopa-tool-tip-header">
                                                        {`Total Views`}:{' '}
                                                        <span className="lopa-tool-tip-header-hours value-small">
                                                            {' '}
                                                            {totalViews}
                                                        </span>
                                                    </div>

                                                    <ul className="lopa-tool-tip-data">{seatContent}</ul>
                                                </div>
                                            </div>
                                        ),
                                        seatColor: seatColor
                                    };
                                }
                            } else {
                                let totalTimePercentage = seatData.total_usage_time_percent;
                                let totalTime = 0;
                                let totalContentPercentage =
                                    this.state.selectedSeatInfo.value === 'usage' ? parseFloat(totalTimePercentage) : 0;

                                if (seatData.duration_by_content_type) {
                                    for (let [contentType, contentInfo] of Object.entries(
                                        seatData.duration_by_content_type
                                    )) {
                                        totalTime += contentInfo.duration;
                                        let seatDataString =
                                            contentInfo.duration == 0
                                                ? contentInfo.duration
                                                : utilHelper.secondsToHmsForDynamicTabs(contentInfo.duration);
                                        if (this.state.selectedSeatInfo.value !== 'usage') {
                                            totalContentPercentage = parseFloat(contentInfo.pct);
                                        }
                                        if (
                                            (this.state.selectedSeatInfo.value === 'usage' ||
                                                this.state.selectedSeatInfo.label === contentType) &&
                                            seatDataString !== 0
                                        ) {
                                            seatContent.push(
                                                <li key={seatNumber + contentType}>
                                                    <span className="lopa-tool-tip-data-name">{contentType}</span>
                                                    <span className="lopa-tool-tip-data-value">{seatDataString}</span>
                                                </li>
                                            );
                                        }
                                    }
                                }

                                switch (true) {
                                    case totalContentPercentage > 0 && totalContentPercentage <= 25:
                                        seatColor = this.state.seatColors[0];
                                        break;
                                    case totalContentPercentage <= 50:
                                        seatColor = this.state.seatColors[1];
                                        break;
                                    case totalContentPercentage <= 75:
                                        seatColor = this.state.seatColors[2];
                                        break;
                                    case totalContentPercentage > 75:
                                        seatColor = this.state.seatColors[3];
                                        break;
                                    default:
                                        seatColor = '#F3F5F7';
                                        break;
                                }

                                if (seatContent.length > 0) {
                                    tooltip = {
                                        tooltip: (
                                            <div className="lopa-tool-tip hidden">
                                                <div className="lopa-tool-tip-container">
                                                    <div className="lopa-tool-tip-seatNumber">{seatNumber}</div>
                                                    <div className="lopa-tool-tip-header">{`Total Seat Usage Time`}</div>

                                                    <div className="lopa-tool-tip-header-value">
                                                        {utilHelper.secondsToHmsForDynamicTabs(totalTime)}
                                                    </div>
                                                    <div className="lopa-tool-tip-header-light">
                                                        ({parseFloat(totalTimePercentage).toFixed(2)}% of flight time)
                                                    </div>
                                                    <ul className="lopa-tool-tip-data">{seatContent}</ul>
                                                </div>
                                            </div>
                                        ),
                                        seatColor: seatColor
                                    };
                                }
                            }
                        }
                    }

                    if (seatColor == '#F3F5F7') {
                        tooltip = (
                            <div className="lopa-tool-tip hidden" style={{ marginTop: 24 }}>
                                <div className="lopa-tool-tip-noData-seatNumber">{seatNumber}</div>
                                <div className="lopa-tool-tip-noData-header">No data available</div>
                            </div>
                        );
                    } else {
                        tooltip = tooltip.tooltip;
                    }

                    lopaSeats.push(
                        <div className="lopa-seat-wrapper" key={'lopa-seat-wrapper-' + seatNumber}>
                            <div
                                id={'lopa-seat-' + seatNumber}
                                className="lopa-seat"
                                style={{ backgroundColor: seatColor, borderColor: noData ? '#DADCDD' : 'transparent' }}
                                onMouseOver={(e) => {
                                    this.openToolTip(seatNumber, e);
                                    e.target.classList.add('active');
                                }}
                                onMouseLeave={(e) => {
                                    this.closeTooltip(e);
                                    e.target.classList.remove('active');
                                }}
                            >
                                {seatNumber}
                            </div>
                            {tooltip}
                        </div>
                    );
                } else {
                    lopaSeats.push(
                        <div
                            id={'lopa-seat-' + i + rowInfo.row + sl}
                            className="lopa-seat no-usage"
                            key={'lopaEmptySeat-' + rowInfo.row + sl + rowInfo.seatClass}
                        />
                    );
                }
            }
        }

        lopaSeats.push(<div className="lopa-buffer" key={`lopa-buffer-${this.randomNumberGenerator()}`} />);

        this.setState({
            seatLetterCount: seatLettersArray.length + 1,
            seatLetters: seatLetters,
            lopaSeats: lopaSeats
        });
    }

    setScroll = (e) => {
        var wrapper = e.target;
        var maxScroll = wrapper.scrollWidth - wrapper.clientWidth;
        var scrollPoint = Math.min(Math.round(wrapper.scrollLeft / (maxScroll / 3)), 3);

        if (scrollPoint !== this.state.activeScroll)
            this.setState({
                activeScroll: scrollPoint
            });
    };

    scrollTo(point) {
        var wrapper = document.getElementById('lopa-grid-wrapper');

        if (point === '++') point = this.state.activeScroll < 3 ? this.state.activeScroll + 1 : 3;
        else if (point === '--') point = this.state.activeScroll > 0 ? this.state.activeScroll - 1 : 0;

        var maxScroll = wrapper.scrollWidth - wrapper.clientWidth;

        wrapper.scrollTo({
            left: (maxScroll / 3) * point,
            behavior: 'smooth'
        });
    }

    handleSeatInfoTypeChange(selectedOption) {
        Analytics.analytics.track('handleSeatInfoTypeChange', {
            visual_name: this.props.title,
            interaction_type: 'Dropdown Selection',
            interaction_value: selectedOption.label
        });

        this.setState({ selectedSeatInfo: selectedOption }, () => {
            this.buildDefaultLopa(this.props.data);
        });
    }

    getLegend() {
        if (
            this.state.selectedSeatInfo.value === 'views' ||
            this.state.selectedSeatInfo.value === 'logins' ||
            this.state.selectedSeatInfo.value === 'languages'
        ) {
            return (
                <div className="lopa-legend-item text-center">
                    <div style={{ backgroundColor: this.state.seatColors[1] }} />
                    Data
                </div>
            );
        } else {
            return (
                <>
                    <div className="lopa-legend-item text-left">
                        <div style={{ backgroundColor: this.state.seatColors[0] }} />0
                    </div>
                    <div className="lopa-legend-item text-left">
                        <div style={{ backgroundColor: this.state.seatColors[1] }} />
                        25%
                    </div>
                    <div className="lopa-legend-item text-left">
                        <div style={{ backgroundColor: this.state.seatColors[2] }} />
                        50%
                    </div>
                    <div className="lopa-legend-item text-left">
                        <div style={{ backgroundColor: this.state.seatColors[3] }} />
                        75%+
                    </div>
                </>
            );
        }
    }

    render() {
        var seatLetterCount = this.state.seatLetterCount;
        var seatLetters = this.state.seatLetters;
        var lopaSeats = this.state.lopaSeats;

        const seatInfoDropdownOptions = [
            {
                label: 'Total Seat Usage Time',
                value: 'usage'
            },
            {
                label: 'Total Views',
                value: 'views'
            },
            {
                label: 'Seat Logins',
                value: 'logins'
            },
            {
                label: 'Seat Languages',
                value: 'languages'
            }
        ];

        if (!this.props.ipsEnabled) {
            let index = seatInfoDropdownOptions.findIndex((option) => option.value === 'logins');
            seatInfoDropdownOptions.splice(index, 1);
        }

        return (
            <>
                <div
                    style={{
                        display:
                            this.props.wizardStep == 4 && _.isEqual(sampleData, this.state.data) ? 'initial' : 'none',
                        width: '100%',
                        position: 'absolute',
                        top: 30,
                        height: 'calc(100% - 30px)'
                    }}
                >
                    <div
                        style={{
                            pointerEvents: 'none',
                            height: '100%',
                            background: '#FFFFFF80',
                            backdropFilter: 'blur(8px)',
                            zIndex: 1,
                            position: 'absolute',
                            top: 0,
                            width: '100%'
                        }}
                    ></div>
                    <span className="no-data" style={{ position: 'absolute', zIndex: 2, marginTop: '21%', top: '0px' }}>
                        Data is not available
                    </span>
                </div>

                <div className="flight-details">
                    <div className="flight-detail">
                        <div className="text">{this.props.flight}</div>
                        <div className="category">Flight</div>
                    </div>
                    <div className="flight-detail">
                        <div className="text">{this.formatRoute(this.props.route)}</div>
                        <div className="category">Route</div>
                    </div>
                    <div className="flight-detail">
                        <div className="text">{utilHelper.secondsToHoursMinutes(this.props.flightDuration)}</div>
                        <div className="category">Duration</div>
                    </div>
                    <div className="flight-detail">
                        <div className="text">{this.props.flightStartDateHeader}</div>
                        <div className="category">Date</div>
                    </div>
                    <div className="flight-detail">
                        <div className="text">{this.props.flightStartTime}</div>
                        <div className="category">Departure</div>
                    </div>
                    <div className="flight-detail">
                        <div className="text">{this.props.aircraftType}</div>
                        <div className="category">Aircraft</div>
                    </div>
                    <div className="flight-detail">
                        <div
                            className="change-flight-link"
                            onClick={() => {
                                Analytics.analytics.track('changeFlightClick', {
                                    visual_name: this.props.title,
                                    interaction_type: 'Change Flight Click',
                                    interaction_value: 'Link Click'
                                });
                                this.props.handleChangeFlightAction();
                            }}
                        >
                            Change flight
                        </div>
                    </div>
                </div>

                <div className="flight-detail-separator">&nbsp;</div>

                <div
                    className={'px-4 pb-3'}
                    style={{
                        display: 'flex',
                        marginTop: 24,
                        pointerEvents: _.isEqual(sampleData, this.props.data) ? 'none' : 'all'
                    }}
                >
                    <div className="w-50 d-inline-block">
                        {this.props.showChangeSeatInfoType && (
                            <Select
                                options={seatInfoDropdownOptions}
                                value={this.state.selectedSeatInfo}
                                menuPortalTarget={document.body}
                                getOptionValue={(option) => option.label}
                                onChange={this.handleSeatInfoTypeChange}
                                classNamePrefix="select"
                                maxMenuHeight={370}
                            />
                        )}
                    </div>
                    <div className="lopa-legend w-50 d-inline-block text-right">
                        <div className="lopa-legend-item text-left">
                            <div
                                style={{
                                    //  'color': '#000000',
                                    backgroundColor: '#F3F5F7',
                                    border: `1px solid #DADCDD`
                                }}
                            ></div>
                            No Data
                        </div>
                        {this.getLegend()}
                    </div>
                </div>

                <div
                    className={clsx({
                        'lopa-wrapper': true,
                        'pb-5 mb-3': this.state.maxScroll || this.state.maxScroll > 0
                    })}
                    style={{ pointerEvents: _.isEqual(sampleData, this.props.data) ? 'none' : 'all' }}
                >
                    <div className="seat-letters">{seatLetters}</div>
                    <div
                        id="lopa-grid-wrapper"
                        aria-label={this.props.wizardStep == 4 ? '4' : ''}
                        className="lopa-grid-wrapper"
                        onScroll={this.setScroll}
                    >
                        {(this.state.selectedSeatInfo.value !== 'logins' &&
                            this.state.selectedSeatInfo.value !== 'languages') ||
                        (this.state.selectedSeatInfo.value === 'logins' &&
                            this.props.data.seatLoginData &&
                            this.props.data.seatLoginData.length > 0) ||
                        (this.state.selectedSeatInfo.value === 'languages' &&
                            this.props.data.seatLangData &&
                            this.props.data.seatLangData.length > 0) ? (
                            <div
                                className="lopa-grid"
                                style={{
                                    gridTemplateRows: 'repeat(' + seatLetterCount + ', 1fr)'
                                }}
                            >
                                {lopaSeats}
                            </div>
                        ) : (
                            <div>
                                <span
                                    className="no-data"
                                    style={{ position: 'absolute', zIndex: 2, marginTop: '21%', top: '0px' }}
                                >
                                    Data is not available
                                </span>
                            </div>
                        )}
                    </div>

                    {this.state.maxScroll &&
                    (this.state.selectedSeatInfo.value !== 'logins' ||
                        (this.state.selectedSeatInfo.value === 'logins' &&
                            this.props.data.seatLoginData &&
                            this.props.data.seatLoginData.length > 0)) ? (
                        <div
                            className="lopa-grid-scroller"
                            style={{
                                display: this.props.wizardStep != 4 ? 'none' : 'initial'
                            }}
                        >
                            <div className="lopa-grid-scroll-arrow left" onClick={() => this.scrollTo('--')}>
                                &#x2039;
                            </div>
                            <div
                                className={
                                    this.state.activeScroll === 0
                                        ? 'lopa-grid-scroll-dot active'
                                        : 'lopa-grid-scroll-dot'
                                }
                                onClick={() => this.scrollTo(0)}
                            />
                            <div
                                className={
                                    this.state.activeScroll === 1
                                        ? 'lopa-grid-scroll-dot active'
                                        : 'lopa-grid-scroll-dot'
                                }
                                onClick={() => this.scrollTo(1)}
                            />
                            <div
                                className={
                                    this.state.activeScroll === 2
                                        ? 'lopa-grid-scroll-dot active'
                                        : 'lopa-grid-scroll-dot'
                                }
                                onClick={() => this.scrollTo(2)}
                            />
                            <div
                                className={
                                    this.state.activeScroll === 3
                                        ? 'lopa-grid-scroll-dot active'
                                        : 'lopa-grid-scroll-dot'
                                }
                                onClick={() => this.scrollTo(3)}
                            />
                            <div className="lopa-grid-scroll-arrow right" onClick={() => this.scrollTo('++')}>
                                &#x203A;
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </>
        );
    }
}

export default Lopa;
