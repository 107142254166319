import React from 'react';

import { Row } from 'reactstrap';
import { Col } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { Chip, ButtonPrimary, ButtonSecondary, TablePagination } from 'pac-ux-components';
import ErrorBoundary from 'util/ErrorBoundary.js';
import SortTableHeaderButton, { sortColumn } from 'components/widgets/SortTableHeaderButton.js';
import * as AppConstants from 'constants/app/constants';
import { Modal, Container, ModalBody } from 'reactstrap';
import AddEditLicense from 'components/widgets/admin/AddEditLicense.js';
import UpgradeEndLicense from 'components/widgets/admin/UpgradeEndLicense.js';
import ErrorMessage from 'components/widgets/admin/ErrorMessage.js';
import FilterBlue from 'assets/images/icons/filter-blue.svg';
import FilterWhite from 'assets/images/icons/filter-white.svg';
import LicenseFilter from 'components/widgets/admin/LicenseFilter';
import LicenseFilterHelper from 'components/widgets/admin/LicenseFilterHelper';

const STAGE_EVALUATIONS = 'evaluation';
const DEFAULT_SORT_COLUMN = 'airline_name';
const DEFAULT_SORT_DIRECTION = 'ascending';

class License extends React.Component {
    constructor(props) {
        super(props);

        this.updateTableStateAction = this.updateTableStateAction.bind(this);

        let licenseData = this.sortLicenses(props);
        let totalLicenses = props.licenses.length;

        this.state = {
            licenses: licenseData.licenses,
            licensesOriginal: licenseData.licenses,
            error: false,
            totalLicenses: totalLicenses,
            licenseOverflowOpen: '',
            containersExpanded: [
                { id: 1, expanded: true },
                { id: 2, expanded: false },
                { id: 3, expanded: false },
                { id: 4, expanded: false }
            ],
            table: licenseData.table,
            modal: false,
            showPopup: false,
            selectedLicense: null,
            filterIcon: FilterBlue,
            filterButtonDisabled: licenseData.filterButtonDisabled,
            filterValues: licenseData.filterValues,
            appliedFilters: {}
        };

        this.paginate = this.paginate.bind(this);

        this.setOverflowState = this.setOverflowState.bind(this);

        this.cancelLicenseRequestSource = null;

        this.togglePopup = this.togglePopup.bind(this);
        this.addLicense = this.addLicense.bind(this);
        this.editLicense = this.editLicense.bind(this);
        this.endLicense = this.endLicense.bind(this);
        this.sortLicenses = this.sortLicenses.bind(this);
        this.upgradeLicense = this.upgradeLicense.bind(this);
        this.displayError = this.displayError.bind(this);
        this.reactivateLicense = this.reactivateLicense.bind(this);
        this.toggleFilterDropdown = this.toggleFilterDropdown.bind(this);
        this.toggleFilterIcon = this.toggleFilterIcon.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleFilterSelectionUpdated = this.handleFilterSelectionUpdated.bind(this);

        this.licenseFilterRef = React.createRef();
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.airlineOptions.length > 0 && !_.isEqual(this.props.airlineOptions, prevProps.airlineOptions)) {
            await this.setSelectedFiltersFromStore();
        }
    }

    handleClickOutside(event) {
        this.setState({ filterIcon: FilterBlue });
    }

    setSelectedFiltersFromStore() {
        if (this.licenseFilterRef.current) {
            this.licenseFilterRef.current.getSelectedFiltersFromStore();
        }
    }

    sortLicenses(props) {
        //sort data by initial column and direction
        let licenses = sortColumn(
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_DIRECTION,
            props.licenses,
            false,
            null,
            'string'
        );

        //paginate data
        let tableState = {
            currentTableSortColumn: DEFAULT_SORT_COLUMN,
            currentTableSortDirection: DEFAULT_SORT_DIRECTION,
            currentPageData: licenses.slice(0, AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE),
            pageCount: AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE,
            paging: {
                count: AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE,
                page: 0
            },
            updateTableStateAction: this.updateTableStateAction
        };

        // Get unique airlines and tiers from licenses json and set it to filter values object.
        let filterValues = {};
        if (licenses && licenses.length > 0) {
            filterValues.airlines = licenses
                .map((item) => item.airline_name)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort();
            filterValues.tiers = licenses
                .map((item) => item.subscription_tier)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort();
            filterValues.stages = licenses
                .map((item) => item.stage)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort();
        }

        return {
            licenses: licenses,
            licensesOriginal: licenses,
            table: tableState,
            filterValues: filterValues,
            filterButtonDisabled: !LicenseFilterHelper.hasFilterValues(filterValues)
        };
    }

    setOverflowState(e) {
        if (e) {
            let target = e.target.getAttribute('id');
            if (target) {
                this.setState({ licenseOverflowOpen: this.state.licenseOverflowOpen === target ? '' : target });
            }
        }
    }

    toggleFilterIcon() {
        let btnFilter = document.querySelector('.filter-button-secondary-license-' + this.props.active);
        if (btnFilter === document.activeElement) {
            this.setState({ filterIcon: FilterWhite });
        } else {
            this.setState({ filterIcon: FilterBlue });
        }
    }

    toggleFilterDropdown(showFilterBlue) {
        this.setState({ filterIcon: showFilterBlue ? FilterBlue : FilterWhite });
        this.setState((prevState) => ({
            filterDropdown: !prevState.filterDropdown
        }));
    }

    handleChipButton(filterType, value, filterNotExists) {
        if (filterNotExists) {
            return false;
        }

        let filters = this.state.appliedFilters;
        for (let i = 0; i < filters[filterType].length; i++) {
            if (filters[filterType][i] === value) {
                filters[filterType].splice(i, 1);
            }
        }
        let appliedFilters = JSON.parse(JSON.stringify(filters));

        this.setState(
            {
                appliedFilters: appliedFilters
            },
            () => {
                if (this.licenseFilterRef.current) {
                    this.licenseFilterRef.current.handleChipChange(filterType, value, appliedFilters);
                }
                this.refreshLicensesData(appliedFilters);
            }
        );
    }

    handleFilterSelectionUpdated(appliedFilters, toggleLicenseFilterDropdown) {
        this.setState({ appliedFilters: appliedFilters }, () => this.refreshLicensesData(appliedFilters));
        if (toggleLicenseFilterDropdown) {
            this.toggleFilterDropdown(true);
        }
    }

    refreshLicensesData(appliedFilters) {
        let { licensesOriginal } = this.state;

        let filteredLicenses = [];
        if (LicenseFilterHelper.hasFilters(appliedFilters)) {
            let licensesDeepCloned = JSON.parse(JSON.stringify(licensesOriginal));
            filteredLicenses = LicenseFilterHelper.getFilteredLicenses(licensesDeepCloned, appliedFilters);
        } else {
            filteredLicenses = licensesOriginal;
        }

        filteredLicenses = sortColumn(
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_DIRECTION,
            filteredLicenses,
            false,
            null,
            'string'
        );

        this.setState({
            licenses: filteredLicenses,
            totalLicenses: filteredLicenses.length
        });

        this.updateTableStateAction(0, filteredLicenses, DEFAULT_SORT_DIRECTION, DEFAULT_SORT_COLUMN);
    }

    renderLicense() {
        let { hasAdminAccess, active, stage } = this.props;
        let { licenses, filterIcon } = this.state;

        let btnSecondaryClassName = 'button-secondary filter-button-secondary-license-' + active;

        return (
            <Row className="admin-outer-container" style={{ maxWidth: '2000px' }}>
                <div>
                    <div className="tail-controls-container">
                        <Col md="6" sm="12" style={{ padding: '0', zIndex: '4' }}>
                            <div>&nbsp;</div>
                        </Col>

                        <Col md="6" sm="12" style={{ textAlign: 'right', padding: '0' }}>
                            {licenses.length > 0 ? (
                                <div>
                                    {!this.state.filterButtonDisabled && (
                                        <ButtonSecondary
                                            className={btnSecondaryClassName}
                                            onMouseOver={() => this.setState({ filterIcon: FilterWhite })}
                                            onMouseLeave={this.toggleFilterIcon}
                                            onClick={() => this.toggleFilterDropdown(false)}
                                        >
                                            <img src={filterIcon} style={{ marginTop: '-3px' }} alt="" />
                                            &nbsp;Filters
                                        </ButtonSecondary>
                                    )}

                                    {hasAdminAccess && active ? (
                                        <ButtonPrimary
                                            className="button-primary margin-left-10"
                                            onClick={() => this.togglePopup('add')}
                                        >
                                            <i className="fas fa-plus">&nbsp;</i>&nbsp;&nbsp; Add license
                                        </ButtonPrimary>
                                    ) : (
                                        ''
                                    )}

                                    {hasAdminAccess ? (
                                        <ButtonPrimary
                                            className="button-primary margin-left-10"
                                            onClick={() => this.props.downloadAdminExcel()}
                                        >
                                            Download All
                                        </ButtonPrimary>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                        </Col>

                        <div className={this.state.filterDropdown ? 'filter-dropdown' : 'filter-dropdown closed'}>
                            <LicenseFilter
                                ref={this.licenseFilterRef}
                                filterValues={this.state.filterValues}
                                licenses={this.props.licenses}
                                active={this.props.active}
                                onFilterSelectionUpdated={this.handleFilterSelectionUpdated}
                                stage={this.props.stage}
                            />
                        </div>

                        <div
                            className="applied-filter-container"
                            style={{
                                paddingBottom: LicenseFilterHelper.hasFilterValues(this.state.appliedFilters)
                                    ? '5px'
                                    : '15px'
                            }}
                        >
                            {Object.entries(this.state.appliedFilters).map(([filterType, filterValues]) => {
                                if (Array.isArray(filterValues)) {
                                    return filterValues.map((value, index) => {
                                        let filterNotExists = LicenseFilterHelper.isAppliedFilterNotExist(
                                            filterType,
                                            value,
                                            this.state.filterValues
                                        );
                                        return (
                                            <Chip
                                                disabled={filterNotExists}
                                                className="filter-chip"
                                                key={index}
                                                onClick={(e) =>
                                                    this.handleChipButton(filterType, value, filterNotExists)
                                                }
                                            >
                                                {LicenseFilterHelper.getDisplayNames()[filterType]}: {value}
                                            </Chip>
                                        );
                                    });
                                } else {
                                    return '';
                                }
                            })}
                        </div>
                    </div>
                </div>

                {licenses.length > 0 ? (
                    <div>
                        <div className="pagination-container">
                            <TablePagination
                                itemCount={this.state.totalLicenses}
                                onChangePage={this.paginate}
                                paging={this.state.table.paging}
                            />
                        </div>
                        <div className={'admin-container license ' + (active ? 'Active ' : 'Inactive ') + stage}>
                            <ErrorBoundary>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="sticky-left airline-name">
                                                <SortTableHeaderButton
                                                    column="airline_name"
                                                    label="Airline"
                                                    dataType="string"
                                                    tableData={this.state.licenses}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th className="tier">
                                                <SortTableHeaderButton
                                                    column="subscription_tier"
                                                    label="Tier"
                                                    dataType="string"
                                                    tableData={this.state.licenses}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            {stage === STAGE_EVALUATIONS && (
                                                <th className="stage">
                                                    <SortTableHeaderButton
                                                        column="stage"
                                                        label="Stage"
                                                        dataType="number"
                                                        tableData={this.state.licenses}
                                                        tableState={this.state.table}
                                                    />
                                                </th>
                                            )}
                                            <th className="start-date">
                                                <SortTableHeaderButton
                                                    column="start_unix"
                                                    label="Start"
                                                    dataType="number"
                                                    tableData={this.state.licenses}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th className="end-date">
                                                <SortTableHeaderButton
                                                    column="expiration_unix"
                                                    label={active ? 'Expiration' : 'Expired'}
                                                    dataType="number"
                                                    tableData={this.state.licenses}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th className="last-updated-date">
                                                <SortTableHeaderButton
                                                    column="last_updated_at"
                                                    label="Last Updated"
                                                    dataType="number"
                                                    tableData={this.state.licenses}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th className="sticky-right" style={{ minWidth: '70px' }}>
                                                &nbsp;
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.table.currentPageData.map((item, index) => (
                                            <tr key={index}>
                                                <td className="sticky-left airline-name">{item.airline_name}</td>
                                                <td className="tier">{item.subscription_tier}</td>
                                                {stage === STAGE_EVALUATIONS && <td className="stage">{item.stage}</td>}
                                                <td className="start-date">{item.start_display}</td>
                                                <td className="end-date">{item.expiration_display}</td>
                                                <td className="last-updated">{item.last_updated_display}</td>
                                                <td
                                                    className={
                                                        this.state.licenseOverflowOpen === 'overflow-license-' + index
                                                            ? 'sticky-right top'
                                                            : 'sticky-right'
                                                    }
                                                    style={{ minWidth: '70px' }}
                                                >
                                                    {hasAdminAccess ? (
                                                        <div>
                                                            {active ? (
                                                                <div>
                                                                    <div
                                                                        className={
                                                                            this.state.licenseOverflowOpen ===
                                                                            'overflow-license-' + index
                                                                                ? 'widget-overflow-popup'
                                                                                : 'widget-overflow-popup closed'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className="widget-overflow-option"
                                                                            onClick={() =>
                                                                                this.togglePopup('edit', item)
                                                                            }
                                                                        >
                                                                            <div className="widget-overflow-label">
                                                                                Edit
                                                                            </div>
                                                                        </div>
                                                                        {item.stage === 2 && (
                                                                            <div>
                                                                                <div className="widget-overflow-divider" />
                                                                                <div
                                                                                    className="widget-overflow-option"
                                                                                    onClick={() =>
                                                                                        this.togglePopup(
                                                                                            'upgrade',
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <div className="widget-overflow-label">
                                                                                        Upgrade
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div className="widget-overflow-divider" />
                                                                        <div
                                                                            className="widget-overflow-option"
                                                                            onClick={() =>
                                                                                this.togglePopup('end', item)
                                                                            }
                                                                        >
                                                                            <div
                                                                                className="widget-overflow-label"
                                                                                style={{ color: '#CA2B2B' }}
                                                                            >
                                                                                End
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        id={'overflow-license-' + index}
                                                                        className="widget-overflow"
                                                                        onClick={(e) => this.setOverflowState(e)}
                                                                    >
                                                                        &#8942;
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <div
                                                                        className={
                                                                            this.state.licenseOverflowOpen ===
                                                                            'overflow-license-' + index
                                                                                ? 'widget-overflow-popup'
                                                                                : 'widget-overflow-popup closed'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className="widget-overflow-option"
                                                                            onClick={() =>
                                                                                this.togglePopup('re-activate', item)
                                                                            }
                                                                        >
                                                                            <div className="widget-overflow-label">
                                                                                Re-activate
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        id={'overflow-license-' + index}
                                                                        className="widget-overflow"
                                                                        onClick={(e) => this.setOverflowState(e)}
                                                                    >
                                                                        &#8942;
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div>&nbsp;</div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </ErrorBoundary>
                        </div>
                        <div className="pagination-container">
                            <TablePagination
                                itemCount={this.state.totalLicenses}
                                onChangePage={this.paginate}
                                paging={this.state.table.paging}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {licenses.length === 0 ? (
                    <div>
                        <div className="license-container data-not-available">
                            <div className="no-license-title">No Licenses Found</div>
                            {hasAdminAccess ? (
                                <div>
                                    <div className="no-license-body">Check back at another time or add a license.</div>
                                    <ButtonPrimary className="button-primary" onClick={() => this.togglePopup('add')}>
                                        <i className="fas fa-plus"></i>&nbsp;&nbsp; Add license
                                    </ButtonPrimary>
                                </div>
                            ) : (
                                <div>
                                    <div className="no-license-body">Check back at another time.</div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {this.state.showPopup ? (
                    <Container>
                        <Modal
                            className={
                                this.state.actionType === 'end' || this.state.actionType === 'upgrade'
                                    ? 'end-license-popup'
                                    : this.state.actionType === 'error'
                                    ? 'error-message-popup'
                                    : 'license-popup'
                            }
                            isOpen={this.state.modal}
                        >
                            <ModalBody
                                className={
                                    this.state.actionType === 'end' || this.state.actionType === 'upgrade'
                                        ? 'end-license'
                                        : this.state.actionType === 'error'
                                        ? 'error-message'
                                        : 'add-license'
                                }
                            >
                                {this.state.actionType === 'end' || this.state.actionType === 'upgrade' ? (
                                    <UpgradeEndLicense
                                        closeAction={this.togglePopup}
                                        endLicenseAction={this.endLicense}
                                        upgradeLicenseAction={this.upgradeLicense}
                                        license={this.state.selectedLicense}
                                        actionType={this.state.actionType}
                                        displayError={this.displayError}
                                    />
                                ) : this.state.actionType === 'error' ? (
                                    <ErrorMessage
                                        closeAction={this.togglePopup}
                                        errorTails={this.state.errorTails}
                                        errorDescription={this.state.errorDescription}
                                    />
                                ) : (
                                    <AddEditLicense
                                        closeAction={this.togglePopup}
                                        addLicenseAction={this.addLicense}
                                        actionType={this.state.actionType}
                                        license={this.state.selectedLicense}
                                        airlineOptions={this.props.airlineOptions}
                                        editLicenseAction={this.editLicense}
                                        reactivateLicenseAction={this.reactivateLicense}
                                        stage={stage}
                                        displayError={this.displayError}
                                    />
                                )}
                            </ModalBody>
                        </Modal>
                    </Container>
                ) : null}
            </Row>
        );
    }

    render() {
        return <React.Fragment>{this.renderLicense()}</React.Fragment>;
    }

    updateTableStateAction(newPage, dataset, direction, column) {
        let itemsPerPage = this.state.table.pageCount;

        let newTableStartIndex = itemsPerPage * newPage;
        let newTableEndIndex = newTableStartIndex + itemsPerPage;
        let newData = dataset.slice(newTableStartIndex, newTableEndIndex);

        let tableState = {};
        Object.assign(tableState, this.state.table);
        tableState.currentPageData = newData;
        tableState.paging = {
            count: itemsPerPage,
            page: newPage
        };
        tableState.currentTableSortDirection = direction;
        tableState.currentTableSortColumn = column;

        this.setState({
            table: tableState
        });
    }

    paginate(newPage) {
        this.updateTableStateAction(
            newPage,
            this.state.licenses,
            this.state.table.currentTableSortDirection,
            this.state.table.currentTableSortColumn
        );
    }

    togglePopup(type, item) {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            showPopup: !this.state.showPopup,
            actionType: type,
            selectedLicense: item,
            licenseOverflowOpen: ''
        }));
    }

    addLicense(airlineName, airlineCode, tier, stage, startDate, endDate, updatedAt) {
        let licenseData = {};

        licenseData.airline_code = airlineCode;
        licenseData.airline_name = airlineName;
        licenseData.subscription_tier = tier;
        licenseData.stage = stage;
        licenseData.start_unix = moment(startDate, 'YYYYMMDD').unix();
        licenseData.start_display = moment(startDate, 'YYYYMMDD').format('MMM DD, YYYY');
        licenseData.license_start_date = startDate;
        licenseData.last_updated_at = moment(updatedAt).unix();
        licenseData.last_updated_display = updatedAt;

        let expirationMoment = moment(endDate, 'YYYYMMDD');
        licenseData.expiration_unix = expirationMoment.unix();
        licenseData.expiration_display = expirationMoment.format('MMM DD, YYYY');

        let licenses = this.state.licenses;
        licenses.push(licenseData);

        let totalLicenses = this.state.totalLicenses + 1;
        this.setState({ licenses: licenses, totalLicenses: totalLicenses });

        //sort data by initial column and direction
        licenses = sortColumn(
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_DIRECTION,
            licenses,
            false,
            null,
            'string'
        );

        this.updateTableStateAction(0, licenses, DEFAULT_SORT_COLUMN, DEFAULT_SORT_DIRECTION);

        this.togglePopup('add');

        this.props.addLicenseAction(airlineCode, tier);
    }

    editLicense(airlineName, airlineCode, tier, stage, startDate, endDate, updatedAt) {
        //filter
        let currentLicense;
        let filteredLicenses = this.state.licenses.filter((obj) => {
            if (obj.subscription_tier === tier && obj.airline_code === airlineCode) {
                currentLicense = obj;
                return false;
            } else {
                return true;
            }
        });

        //update record
        currentLicense.stage = stage;
        currentLicense.start_unix = moment(startDate, 'YYYYMMDD').unix();
        currentLicense.start_display = moment(startDate, 'YYYYMMDD').format('MMM DD, YYYY');
        currentLicense.last_updated_at = updatedAt;
        currentLicense.last_updated_at = moment(updatedAt).unix();
        currentLicense.last_updated_display = updatedAt;

        if (endDate) {
            let expirationMoment = moment(endDate, 'YYYYMMDD');
            currentLicense.expiration_unix = expirationMoment.unix();
            currentLicense.expiration_display = expirationMoment.format('MMM DD, YYYY');
        } else {
            currentLicense.expiration_unix = null;
            currentLicense.expiration_display = null;
        }

        filteredLicenses.push(currentLicense);

        this.setState({ licenses: filteredLicenses, totalLicenses: filteredLicenses.length });

        //sort data by initial column and direction
        filteredLicenses = sortColumn(
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_DIRECTION,
            filteredLicenses,
            false,
            null,
            'string'
        );

        this.updateTableStateAction(0, filteredLicenses, DEFAULT_SORT_COLUMN, DEFAULT_SORT_DIRECTION);

        this.togglePopup('edit');
    }

    upgradeLicense(airlineCode, tier) {
        //filter
        let filteredLicenses = this.state.licenses.filter((obj) => {
            if (obj.subscription_tier !== tier && obj.airline_code !== airlineCode) {
                return true;
            }
            return false;
        });

        this.setState({ licenses: filteredLicenses, totalLicenses: filteredLicenses.length });

        //sort data by initial column and direction
        filteredLicenses = sortColumn(
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_DIRECTION,
            filteredLicenses,
            false,
            null,
            'string'
        );

        this.updateTableStateAction(0, filteredLicenses, DEFAULT_SORT_COLUMN, DEFAULT_SORT_DIRECTION);

        this.togglePopup('upgrade');
    }

    endLicense(airlineCode, tier, updatedAt) {
        this.togglePopup('end');

        this.props.endLicenseAction(airlineCode, tier, updatedAt);
    }

    reactivateLicense(airlineCode, tier, stage, startDate, expiration, updatedAt) {
        this.togglePopup('re-activate');

        this.props.reactivateLicenseAction(airlineCode, tier, stage, startDate, expiration, updatedAt);
    }

    displayError(actionType, errorDescription) {
        this.togglePopup(actionType);
        this.setState({ errorDescription: errorDescription });
        this.togglePopup('error');
    }
}

export default License;
