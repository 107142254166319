const TailFilterHelper = {
    getFilteredTails: function (tails, appliedFilters) {
        let filteredTails = JSON.parse(JSON.stringify(tails));

        if (appliedFilters.systemType && appliedFilters.systemType.length > 0) {
            filteredTails = filteredTails.filter((item) => {
                return this.arrayMatch(item.system_type, appliedFilters.systemType) > 0;
            });
        }

        if (appliedFilters.aircraftType && appliedFilters.aircraftType.length > 0) {
            filteredTails = filteredTails.filter((item) => {
                return appliedFilters.aircraftType.includes(item.aircraft_type_subtype);
            });
        }

        if (appliedFilters.tail && appliedFilters.tail.length > 0) {
            filteredTails = filteredTails.filter((item) => {
                return appliedFilters.tail.includes(item.tail_no);
            });
        }

        if (appliedFilters.app && appliedFilters.app.length > 0) {
            filteredTails = filteredTails.filter((item) => {
                return this.arrayMatch(item.apps, appliedFilters.app) > 0;
            });
        }

        if (appliedFilters.tier && appliedFilters.tier.length > 0) {
            filteredTails = filteredTails.filter((item) => {
                return appliedFilters.tier.includes(item.subscription_tier);
            });
        }

        return filteredTails;
    },

    arrayMatch: function (arr1, arr2) {
        let arr = [];
        for (let i = 0; i < arr1.length; ++i) {
            for (var j = 0; j < arr2.length; ++j) {
                if (arr1[i] === arr2[j]) {
                    arr.push(arr1[i]);
                }
            }
        }

        return arr.length > 0;
    },

    hasFilters: function (filters) {
        return !!(
            (filters.systemType && Array.isArray(filters.systemType) && filters.systemType.length) ||
            (filters.aircraftType && Array.isArray(filters.aircraftType) && filters.aircraftType.length) ||
            (filters.tail && Array.isArray(filters.tail) && filters.tail.length) ||
            (filters.tier && Array.isArray(filters.tier) && filters.tier.length) ||
            (filters.app && Array.isArray(filters.app) && filters.app.length)
        );
    },

    hasFilterValues: function (filter) {
        return !!(
            (filter.system_types && Array.isArray(filter.system_types) && filter.system_types.length) ||
            (filter.systemType && Array.isArray(filter.systemType) && filter.systemType.length) ||
            (filter.aircraft_type_subtypes &&
                Array.isArray(filter.aircraft_type_subtypes) &&
                filter.aircraft_type_subtypes.length) ||
            (filter.aircraftType && Array.isArray(filter.aircraftType) && filter.aircraftType.length) ||
            (filter.tails && Array.isArray(filter.tails) && filter.tails.length) ||
            (filter.tiers && Array.isArray(filter.tiers) && filter.tiers.length) ||
            (filter.apps && Array.isArray(filter.apps) && filter.apps.length)
        );
    },

    hasAppliedFilterValues: function (filter) {
        return !!(
            (filter.systemType && Array.isArray(filter.systemType) && filter.systemType.length) ||
            (filter.aircraftType && Array.isArray(filter.aircraftType) && filter.aircraftType.length) ||
            (filter.tail && Array.isArray(filter.tail) && filter.tail.length) ||
            (filter.tier && Array.isArray(filter.tier) && filter.tier.length) ||
            (filter.app && Array.isArray(filter.app) && filter.app.length)
        );
    },

    getDisplayNames: function () {
        return {
            systemType: 'System',
            aircraftType: 'Aircraft',
            tail: 'Tail',
            tier: 'Tier',
            app: 'Products'
        };
    },

    getMappedKey: function (key) {
        let mappedKeys = {
            systemType: 'system_types',
            aircraftType: 'aircraft_type_subtypes',
            tail: 'tails',
            //tier: "Tier",
            app: 'apps'
        };

        return mappedKeys[key];
    },

    setSelectedFiltersToStore: function (state) {
        let selectedFiltersToStore = {
            //expanded: false,
            applyFilterButtonDisabled: true,
            clearFilterButtonDisabled: true,
            selectedFilters: {
                systemType: [],
                aircraftType: [],
                tail: [],
                tier: [],
                app: []
            },
            appliedFilters: {}
        };

        if (state) {
            selectedFiltersToStore.applyFilterButtonDisabled = state.applyFilterButtonDisabled;
            selectedFiltersToStore.clearFilterButtonDisabled = state.clearFilterButtonDisabled;
            selectedFiltersToStore.selectedFilters = state.selectedFilters;
            selectedFiltersToStore.appliedFilters = state.appliedFilters;
        }

        window.sessionStorage.setItem(
            'pac_next_insights_tail_selected_filters',
            JSON.stringify(selectedFiltersToStore)
        );
    },

    getFilterValuesByTier: function (tails, filterValues) {
        let filterValuesByTier = {};

        for (let i = 0; i < filterValues.tiers.length; i++) {
            let tier = filterValues.tiers[i];
            filterValuesByTier[tier] = { tails: [], system_types: [], apps: [], aircraft_type_subtypes: [] };

            for (let j = 0; j < tails.length; j++) {
                let tail = tails[j];
                if (tier === tail.subscription_tier) {
                    filterValuesByTier[tier].tails.push(tail.tail_no);
                    filterValuesByTier[tier].aircraft_type_subtypes.push(tail.aircraft_type_subtype);
                    filterValuesByTier[tier].system_types.push(...tail.system_type);
                    filterValuesByTier[tier].apps.push(...tail.apps);
                }
            }

            // Remove blanks and get unique
            filterValuesByTier[tier].tails = filterValuesByTier[tier].tails.filter(
                (v, i, a) => a.indexOf(v) === i && v !== null && v !== ''
            );
            filterValuesByTier[tier].aircraft_type_subtypes = filterValuesByTier[tier].aircraft_type_subtypes.filter(
                (v, i, a) => a.indexOf(v) === i && v !== null && v !== ''
            );
            filterValuesByTier[tier].system_types = filterValuesByTier[tier].system_types.filter(
                (v, i, a) => a.indexOf(v) === i && v !== null && v !== ''
            );
            filterValuesByTier[tier].apps = filterValuesByTier[tier].apps.filter(
                (v, i, a) => a.indexOf(v) === i && v !== null && v !== ''
            );
        }

        return filterValuesByTier;
    },

    getFilterValuesBySelectedTiers: function (selectedTiers, filterValues) {
        let filterValuesByTier = { tails: [], system_types: [], apps: [], aircraft_type_subtypes: [] };

        for (let i = 0; i < selectedTiers.length; i++) {
            let tier = filterValues.filterValuesByTier[selectedTiers[i]];

            filterValuesByTier.tails = [...filterValuesByTier.tails, ...tier.tails];
            filterValuesByTier.aircraft_type_subtypes = [
                ...filterValuesByTier.aircraft_type_subtypes,
                ...tier.aircraft_type_subtypes
            ];
            filterValuesByTier.system_types = [...filterValuesByTier.system_types, ...tier.system_types];
            filterValuesByTier.apps = [...filterValuesByTier.apps, ...tier.apps];
        }

        // Remove blanks and get unique
        filterValuesByTier.tails = filterValuesByTier.tails.filter(
            (v, i, a) => a.indexOf(v) === i && v !== null && v !== ''
        );
        filterValuesByTier.aircraft_type_subtypes = filterValuesByTier.aircraft_type_subtypes.filter(
            (v, i, a) => a.indexOf(v) === i && v !== null && v !== ''
        );
        filterValuesByTier.system_types = filterValuesByTier.system_types.filter(
            (v, i, a) => a.indexOf(v) === i && v !== null && v !== ''
        );
        filterValuesByTier.apps = filterValuesByTier.apps.filter(
            (v, i, a) => a.indexOf(v) === i && v !== null && v !== ''
        );

        return filterValuesByTier;
    },

    getSortedFilterValues: function (filterValues) {
        for (let key of Object.keys(filterValues)) {
            let filterValue = filterValues[key];

            if (Array.isArray(filterValue)) {
                filterValues[key].sort();
            } else if (typeof filterValue === 'object') {
                // "filterValuesByTier"
                for (let tierKey of Object.keys(filterValue)) {
                    if (typeof filterValue[tierKey] === 'object') {
                        // "Basic"
                        for (let lineItemKey of Object.keys(filterValue[tierKey])) {
                            // "tails" / "system_types"
                            if (Array.isArray(filterValue[tierKey][lineItemKey])) {
                                filterValue[tierKey][lineItemKey].sort();
                            }
                        }
                    }
                }
            }
        }
        return filterValues;
    },

    isAppliedFilterNotExist: function (filterType, value, filterValues) {
        let filterNotExists = false;

        if (filterType && value && filterValues) {
            const filterKeys = {
                systemType: 'system_types',
                aircraftType: 'aircraft_types',
                flightDuration: 'flight_haul_types',
                seatClass: 'seat_classes',
                guiLanguage: 'languages',
                tail: 'tails'
            };

            if (
                filterKeys[filterType] &&
                filterValues[filterKeys[filterType]] &&
                filterValues[filterKeys[filterType]].data
            ) {
                let data = filterValues[filterKeys[filterType]].data;

                let itemExists = data.some((item) => {
                    if (item.value === value) {
                        return true;
                    }
                    return false;
                });
                filterNotExists = !itemExists;
            }
        }

        return filterNotExists;
    },

    getCellValue: function (valuesArray) {
        if (Array.isArray(valuesArray)) {
            let values = '';
            for (let i = 0; i < valuesArray.length; i++) {
                if (i > 0) {
                    values += '<br/>';
                }
                values += valuesArray[i];
            }
            return values;
        }
        return '\u0020';
    },

    getValuesByKey: function (key, jsonArray) {
        let values = [];
        for (let i = 0; i < jsonArray.length; i++) {
            values.push(jsonArray[i][key]);
        }
        return values;
    }
};

export default TailFilterHelper;
