import React from 'react';
import ScreenTransition from 'components/screen-transition/ScreenTransition';
import WidgetFrameNew from 'components/widget-frame/WidgetFrameNew';

class ScreenTransitionWidget extends React.Component {
    render() {
        let body = (
            <ScreenTransition
                data={this.props.data}
                showWorkbench={this.props.showWorkbenchLink}
                title={this.props.title}
            />
        );
        return (
            <WidgetFrameNew
                type="screen-transition"
                title={this.props.title}
                data={this.props.data}
                showOverflow={true}
                loading={this.props.loading}
                tenantIcaoCode={this.props.tenantIcaoCode || ''}
                showWorkbenchLink={this.props.showWorkbenchLink || false}
                appliedFilters={this.props.appliedFilters}
                app={this.props.app}
                showDropdown={false}
                showWorkbench={false}
                page={this.props.page}
                pageName={this.props.pageName}
                dataAvailable={!this.props.data.length ? false : true}
                body={body}
            />
        );
    }
}

export default ScreenTransitionWidget;
