import React from 'react';
import _ from 'lodash';
import WidgetFrame from 'components/widget-frame/WidgetFrameNew';
import TrendLineChart from 'components/trends/TrendLineChart';
import UtilHelper from 'util/UtilHelper.js';
import TrendConfig from 'components/trends/TrendConfig.js';
import FiltersService from 'services/FiltersService';
import moment from 'moment';

const utilHelper = new UtilHelper();
const trendConfig = new TrendConfig();

class TrendWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            options: null,
            dataAvailable: false,
            yAxisLabel: null,
            unit: 'count',
            comparisonDataFlag: false
        };

        this.createChart = this.createChart.bind(this);
    }

    updateWidgetData = (newData) => {
        this.createChart(newData);
    };

    createChart(newData) {
        let chartData = newData.data;
        let chartText = newData.text;
        let chartUnit = newData.unit;
        let chartLongText = newData.longText;
        let chartKey = newData.key;

        let labelset = [];
        let labelset2 = [];
        let datasets = [];
        let dataSet1 = [];
        let dataSet2 = [];
        let options = {};
        let i = 0;
        let j = 0;
        let allData = [];

        let hoverList = trendConfig.getTrendChartHoverList(this.props.page, chartKey);
        let hoverData1 = null;
        let hoverData2 = null;

        let yAxisLabel = chartText;

        if (chartData) {
            if (
                chartData.current_date_range &&
                chartData.current_date_range.data &&
                Object.keys(chartData.current_date_range.data).length > 0
            ) {
                let currentDateRangeData = [...chartData.current_date_range.data];

                if (this.props.appliedFilters['media_cycle']) {
                    let mediaCycleObj = FiltersService.getStartEndDateByMediaCycle(
                        this.props.appliedFilters['media_cycle'],
                        this.props.pageName
                    );

                    if (mediaCycleObj) {
                        let mediaMinDate = moment(mediaCycleObj['min_date']);
                        let trendsMinDate = moment(currentDateRangeData[0]['date']);
                        if (mediaMinDate.isBefore(trendsMinDate)) {
                            currentDateRangeData.splice(0, 0, {
                                date: moment(mediaCycleObj['min_date']).format('YYYYMMDD'),
                                value: ''
                            });
                        }

                        let mediaMaxDate = moment(mediaCycleObj['max_date']);
                        let trendsMaxDate = moment(currentDateRangeData[currentDateRangeData.length - 1]['date']);
                        if (mediaMaxDate.isAfter(trendsMaxDate)) {
                            currentDateRangeData.push({
                                date: moment(mediaCycleObj['max_date']).format('YYYYMMDD'),
                                value: ''
                            });
                        }
                    }
                }

                currentDateRangeData.forEach((item) => {
                    labelset[i] = utilHelper.formatDateYYYYMMDDToDDMMMYYYY(item.date);

                    if (item.value) {
                        dataSet1[i] = item.value;
                        allData[i] = item.value;
                    } else {
                        dataSet1[i] = null;
                        allData[i] = null;
                    }

                    i++;
                });

                if (this.props.data && hoverList && hoverList.length > 0) {
                    hoverData1 = {};
                    hoverList.forEach((item) => {
                        if (
                            this.props.data[item.key] &&
                            utilHelper.resolve(this.props.data[item.key], `graph.current_date_range.data`) &&
                            Object.keys(this.props.data[item.key].graph.current_date_range.data).length > 0
                        ) {
                            hoverData1[item.key] = this.props.data[item.key].graph.current_date_range.data;
                        }
                    });
                }

                if (
                    this.props.showWorkbenchLink &&
                    this.props.appliedFilters &&
                    this.props.appliedFilters.comparison_range_text &&
                    chartData.comparison_date_range &&
                    chartData.comparison_date_range.data &&
                    Object.keys(chartData.comparison_date_range.data).length > 0
                ) {
                    chartData.comparison_date_range.data.forEach((item) => {
                        labelset2[j] = utilHelper.formatDateYYYYMMDDToDDMMMYYYY(item.date);
                        if (item.value) {
                            dataSet2[j] = item.value;
                            allData[i] = item.value;
                        } else {
                            dataSet2[j] = null;
                            allData[i] = null;
                        }
                        j++;
                        i++;
                    });
                    this.setState({ comparisonDataFlag: true });

                    if (this.props.data && hoverList && hoverList.length > 0) {
                        hoverData2 = {};
                        hoverList.forEach((item) => {
                            if (
                                this.props.data[item.key] &&
                                utilHelper.resolve(this.props.data[item.key], `graph.comparison_date_range.data`) &&
                                Object.keys(this.props.data[item.key].graph.comparison_date_range.data).length > 0
                            ) {
                                hoverData2[item.key] = this.props.data[item.key].graph.comparison_date_range.data;
                            }
                        });
                    }
                } else {
                    this.setState({ comparisonDataFlag: false });
                }

                let chartPropUnit = chartUnit;
                if (chartUnit && chartUnit.toLowerCase() === 'time') {
                    if (allData.length > 0) {
                        let maxValue = Math.max(...allData);
                        var minNonZeroValue = Math.min.apply(null, allData.filter(Boolean));
                        if (chartLongText === 'Avg. Viewing Time') {
                            chartPropUnit = 'mixed';
                        }
                        if (
                            maxValue > 0 &&
                            maxValue !== minNonZeroValue &&
                            utilHelper.isMixedUnit(minNonZeroValue, maxValue)
                        ) {
                            chartPropUnit = 'mixed';
                            if (chartLongText === 'Total Viewing Time' || chartLongText === 'Total Listening Time') {
                                chartPropUnit = 'roundingMixed';
                            }
                        } else {
                            yAxisLabel = utilHelper.getUnit(utilHelper.secondsToHms(maxValue));
                        }
                    }
                }

                let chartObject = trendConfig.getChartProperties(chartPropUnit);
                options = chartObject.options;
                datasets[0] = chartObject.graphStyle;
                datasets[0]['label'] = 'Total Views';
                datasets[0]['data'] = dataSet1;
                datasets[0]['labels'] = labelset;
                datasets[0]['hoverData'] = hoverData1;

                datasets[1] = chartObject.graphStyle2;
                datasets[1]['label'] = 'Comparison Total Views';
                datasets[1]['data'] = dataSet2;
                datasets[1]['labels'] = labelset2;
                datasets[1]['hoverData'] = hoverData2;
            }
        }

        if (labelset2.length > labelset.length) {
            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
            let diff = labelset2.length - labelset.length;
            for (let i = 0; i < diff; i++) {
                let date = new Date(labelset[labelset.length - 1]);
                date.setDate(date.getDate() + 1);
                date = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
                labelset.push(date);
            }
        }

        let data = {
            labels: labelset,
            datasets: datasets,
            unit: chartUnit
        };

        if (!process.env.JEST_WORKER_ID) {
            if (!_.isEmpty(options)) {
                options.tooltips['callbacks'] = {
                    // Remove or change title
                    title: function (tooltipItem, data) {
                        let toolTipTitle = chartLongText;
                        let index = tooltipItem[0].datasetIndex;
                        if (hoverList && hoverList.length > 0) {
                            let currentLabel = data.datasets[index].labels[tooltipItem[0].index];
                            if (currentLabel && utilHelper.isValidDate(currentLabel)) {
                                toolTipTitle = utilHelper.getTooltipDateTitle(currentLabel);
                            }
                        }
                        return toolTipTitle;
                    },
                    label: function (tooltipItem, data) {
                        let dataset = data.datasets[tooltipItem.datasetIndex];
                        let index = tooltipItem.index;
                        let labelData = '';

                        if (hoverList && hoverList.length > 0 && dataset.hoverData) {
                            let labels = [];

                            hoverList.forEach((item) => {
                                if (dataset.hoverData[item.key]) {
                                    let hoverItemData = dataset.hoverData[item.key];
                                    if (hoverItemData) {
                                        let hoverItemLabelData = '';
                                        if (
                                            (chartUnit && chartUnit.toLowerCase() === 'time') ||
                                            (item.unit && (item.unit === 'time' || item.unit === 'hr'))
                                        ) {
                                            hoverItemLabelData = utilHelper.secondsToHoursMinutesSeconds(
                                                hoverItemData[index].value
                                            );
                                        } else {
                                            hoverItemLabelData = utilHelper.numberWithCommas(
                                                hoverItemData[index].value
                                            );
                                        }
                                        labels.push(item.label + ': ' + hoverItemLabelData);
                                    }
                                }
                            });

                            return labels;
                        } else {
                            if (chartUnit && chartUnit.toLowerCase() === 'time') {
                                labelData = utilHelper.secondsToHoursMinutesSeconds(dataset.data[index]);
                            } else {
                                labelData = utilHelper.numberWithCommas(dataset.data[index]);
                            }
                            return dataset.labels[index] + ': ' + labelData;
                        }
                    }
                };
            }
        }

        this.setState({
            data: data,
            options: options,
            dataAvailable: !_.isEmpty(options),
            yAxisLabel: yAxisLabel,
            unit: chartUnit
        });
    }

    render() {
        return (
            <WidgetFrame
                type={Object.keys(this.props.tabDataBySections).length > 0 ? 'trends-with-dropdown' : 'trends'}
                page={this.props.page}
                pageName={this.props.pageName}
                data={this.props.data}
                tabData={this.props.tabData}
                tabDataBySections={this.props.tabDataBySections}
                allTabDataBySections={this.props.allTabDataBySections}
                loading={this.props.loading}
                title={this.props.title}
                reportSubType={this.props.reportSubType}
                updateData={this.updateWidgetData}
                showTabs={true}
                showOverflow={true}
                workbench_type={this.props.workbench_type || ''}
                tenantIcaoCode={this.props.tenantIcaoCode || ''}
                appliedFilters={this.props.appliedFilters}
                app={this.props.app}
                dataAvailable={this.state.dataAvailable}
                dd1Options={this.props.dropdownOptions}
                body={
                    <TrendLineChart
                        page={this.props.page}
                        type={this.props.type}
                        data={this.state.data}
                        options={this.state.options}
                        lineHeight={this.props.page === 'bluetooth' ? 345 : 270}
                        comparisonRangeText={
                            this.props.appliedFilters && this.props.appliedFilters.comparison_range_text
                                ? this.props.appliedFilters.comparison_range_text
                                : ''
                        }
                        dateRangeText={
                            this.props.appliedFilters && this.props.appliedFilters.dateRangeText
                                ? this.props.appliedFilters.dateRangeText
                                : ''
                        }
                        dataAvailable={this.state.dataAvailable}
                        yAxisLabel={this.state.yAxisLabel}
                        loading={this.props.loading}
                        showWorkbenchLink={this.props.showWorkbenchLink || false}
                        appliedFilters={this.props.appliedFilters}
                        contentType={this.props.contentType}
                        comparisonDataFlag={this.state.comparisonDataFlag}
                    />
                }
            />
        );
    }
}

export default TrendWidget;
