import React from 'react';
import ScreenTransitionWidget from 'components/screen-transition/ScreenTransitionWidget';
import ScreenTransitionConfig from 'components/screen-transition/ScreenTransitionConfig';
import { Card } from 'reactstrap';
import ErrorBoundary from 'util/ErrorBoundary.js';
import _, { debounce } from 'lodash';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import * as AppConstants from 'constants/app/constants';
import FiltersService from '../../services/FiltersService';
import SubscriptionService from 'services/SubscriptionService';

const screenTransitionConfig = new ScreenTransitionConfig();

class ScreenTransitionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataset: [],
            loading: true,
            error: false,
            requestedSections: []
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) ||
            prevProps.path !== this.props.path ||
            this.props.forceReloadAll
        ) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        this.setState({ loading: true });

        if (FiltersService.dateRangeFiltersData !== '') {
            const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));
            selectedFiltersDeepClone.usage_source = this.props.usageSource;
            selectedFiltersDeepClone.screens_per_level = 10;

            if (SubscriptionService.subscription() !== '') {
                selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
            }

            if (this.props.app) {
                selectedFiltersDeepClone['app'] = this.props.app;
            }

            if (this.cancelRequestSource) {
                this.cancelRequestSource.cancel('Operation canceled by the user.');
            }

            const CancelToken = axios.CancelToken;
            this.cancelRequestSource = CancelToken.source();

            this.throttleFetch(selectedFiltersDeepClone);
        }
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchNextInsightsMetricsAxios(
                selectedFiltersDeepClone,
                screenTransitionConfig.getAPIPath(),
                this.cancelRequestSource.token
            )
            .then(async (response) => {
                if (!response && !response.visits) {
                    this.setState({ error: true, dataset: [], loading: false });
                } else {
                    let visits = response.visits;
                    if (this.isValidHttpUrl(response.visits)) {
                        try {
                            let rawDataResponse = await axios.get(response.visits);
                            visits = rawDataResponse.data;
                        } catch (error) {
                            console.log(error);
                        }
                    }

                    this.setState({
                        error: false,
                        dataset: visits,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, loading: false });
                } else {
                    this.setState({ error: true, dataset: [], loading: false });
                }
            });
    }

    isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    render() {
        let { dataset, loading } = this.state;
        return (
            <Card>
                <ErrorBoundary>
                    <ScreenTransitionWidget
                        loading={loading}
                        page={this.props.page}
                        key={'screen_transition'}
                        id={'screen_transition'}
                        data={dataset ? dataset : []}
                        title={this.props.title}
                        pageName={this.props.pageName}
                        showWorkbenchLink={this.props.showWorkbenchLink}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        appliedFilters={this.props.appliedFilters.api}
                        app={this.props.app}
                    />
                </ErrorBoundary>
            </Card>
        );
    }
}

export default ScreenTransitionContainer;
