import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { LinkHelper } from 'pac-platform-ui-auth';
import { CloudBar } from 'pac-ux-components';
import UsersService from 'services/UsersService';

const linkHelper = new LinkHelper();
const service = new UsersService();

let portalAppUrls = {
    prd: 'https://portal.nextcloud.aero',
    test: 'https://portal-test.nextcloud.aero',
    dev: 'https://portal-dev.nextcloud.aero'
};

let stage = typeof process.env.REACT_APP_STAGE !== 'undefined' ? process.env.REACT_APP_STAGE : 'prd';

let cloudHomeUrl = portalAppUrls[stage];

let profileUrl = [portalAppUrls[stage], 'app/profile'].join('/');

let notificationsUrl = [portalAppUrls[stage], 'app/notifications'].join('/');

let settingsUrl = [portalAppUrls[stage], 'app/settings'].join('/');

const PlatformCloudbar = ({ cloudHomeUrl, profileUrl, notificationsUrl, settingsUrl }) => {
    const [userSession, setUserSession] = useState({
        idToken: { jwtToken: '' }
    });

    const [user, setUser] = useState({
        attributes: { username: '', given_name: '', family_name: '' }
    });

    const [applications, setApplications] = useState([]);

    const fetchApplications = async () => {
        try {
            let session = await Auth.currentSession();
            setUserSession(session);

            let user = await Auth.currentAuthenticatedUser();
            setUser(user);

            let applications = await service.fetchUserApplications(session, user);
            setApplications(applications.Items);
        } catch (e) {
            console.debug(e);
        }
    };

    useEffect(() => {
        fetchApplications();
    }, []);

    let urlTokenFragment = linkHelper.getTokenFragment(userSession);

    let cloudBarUser = {
        name: [user.attributes.given_name, user.attributes.family_name].join(' '),
        profileUrl: profileUrl,
        notificationsUrl: notificationsUrl,
        settingsUrl: settingsUrl
    };

    return (
        <CloudbarComponent
            cloudHomeUrl={cloudHomeUrl}
            user={cloudBarUser}
            applications={applications}
            urlTokenFragment={urlTokenFragment}
        />
    );
};

export default PlatformCloudbar;

PlatformCloudbar.propTypes = {
    cloudHomeUrl: PropTypes.string,
    profileUrl: PropTypes.string,
    notificationsUrl: PropTypes.string,
    settingsUrl: PropTypes.string
};

PlatformCloudbar.defaultProps = {
    cloudHomeUrl: cloudHomeUrl,
    profileUrl: profileUrl,
    notificationsUrl: notificationsUrl,
    settingsUrl: settingsUrl
};

export const CloudbarComponent = ({ cloudHomeUrl, user, applications, urlTokenFragment }) => {
    let prodApplications = filterTools(applications, 'prod');

    let lastUsedTools = prodApplications.filter((app) => {
        let icon = getResizedImageUrl(app);
        if (icon) {
            return true;
        }
        return false;
    });

    lastUsedTools = lastUsedTools.map((app) => {
        return {
            name: app.name,
            icon: getResizedImageUrl(app),
            path: app['app-link'] + urlTokenFragment
        };
    });

    let devApplications = filterTools(applications, 'dev');

    let otherTools = devApplications.filter((app) => {
        let icon = getResizedImageUrl(app);
        if (icon) {
            return true;
        }
        return false;
    });

    otherTools = otherTools.map((app) => {
        return {
            name: app.name,
            icon: getResizedImageUrl(app),
            path: app['app-link'] + urlTokenFragment
        };
    });

    return (
        <CloudBar
            cloudHomeUrl={cloudHomeUrl}
            user={user}
            notificationCount={0}
            lastUsedTools={lastUsedTools}
            otherTools={otherTools}
        />
    );
};

const filterTools = (allApplications, appType) => {
    let applications = allApplications.filter((item) => item['app-type'] === appType);
    return applications.slice(0, 3);
};

const getResizedImageUrl = (app) => {
    const logoUrl = app['logo-url'];
    const extension = logoUrl.substr(-3);

    if (extension === 'svg') {
        return logoUrl;
    }

    return null;

    /*
    Commenting out for now because of issue with cloudfront endpoint returing 404, resulting in broken images in UI
    
    let request = {
        bucket: 'pac-platform-images-us-west-2',
        key: logoUrl,
        edits: {
            resize: {
                width: 45,
                height: 45,
                fit: 'cover'
            }
        }
    };

    const str = JSON.stringify(request);
    const encoded = window.btoa(str);

    return ['https://d2g0vtxljsrm9e.cloudfront.net', encoded].join('/');
    */
};

CloudbarComponent.propTypes = {
    cloudHomeUrl: PropTypes.string,
    user: PropTypes.shape({
        attributes: PropTypes.shape({
            username: PropTypes.string.isRequired,
            given_name: PropTypes.string.isRequired,
            family_name: PropTypes.string.isRequired
        })
    }),
    applications: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            'app-type': PropTypes.string,
            'app-link': PropTypes.string,
            'logo-url': PropTypes.string
        })
    ).isRequired,
    urlTokenFragment: PropTypes.string
};
