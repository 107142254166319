import InsightsMonthlyService from 'services/InsightsMonthlyService';
import axios from 'axios';
import DynamicFilterHelper from 'data/dynamic-filter-helper.js';

/*
const sample_raw_data = [
  {
    "s": "eX2",
    "a": "B777-400ER",
    "o": "BKK",
    "d": "BKK",
    "n": "SQ713",
    "h": "Short Haul",
    "c": "Custom",
    "t": "9VSWU",
    "p": "Audio"
  },
  {
    "s": "eX3",
    "a": "B777-300ER",
    "o": "BKK",
    "d": "BKK",
    "n": "SQ712",
    "h": "Short Haul",
    "c": "Premium Economy",
    "t": "9VSWV",
    "p": "Movies"
  }
];
*/

const selected_filters_keys_mapping = {
    systemType: 's',
    aircraftType: 'a',
    seatClass: 'c',
    tail: 't',
    flightDuration: 'h',
    flightNumber: 'n',
    toCity: 'd',
    fromCity: 'o',
    product: 'p'
};

const raw_data_keys_mapping = {
    system_type: 's',
    aircraft_type_subtype: 'a',
    dep_airport_iata: 'o',
    arr_airport_iata: 'd',
    flight_number: 'n',
    flight_haul_type: 'h',
    seat_class: 'c',
    aircraft_tail_number: 't',
    product: 'p'
};

class FiltersService {
    static dateRangeFiltersData = '';
    static nonDateRangeFiltersData = '';
    static mediaCycleFiltersData = '';
    static currentIcao = '';
    static currentApp = '';

    static async loadAllFilters(cancelRequestSource, subscription, app) {
        this.app = app;

        let currTenantIcaoCode = sessionStorage.getItem('pac_selected_tenant_icao_code');

        if (
            this.currentIcao === currTenantIcaoCode &&
            this.dateRangeFiltersData !== '' &&
            this.nonDateRangeFiltersData !== ''
        ) {
            return {
                dateRangeFiltersDataIsSet: true,
                nonDateRangeFiltersDataIsSet: true
            };
        }

        this.currentIcao = currTenantIcaoCode;

        let service = new InsightsMonthlyService();

        let response = await service.fetchFilterValuesAxios(
            DynamicFilterHelper.getAllFilterQueryParams(false, true, subscription, app),
            cancelRequestSource.token
        );

        if (!response) {
            return {
                dateRangeFiltersDataIsSet: false,
                nonDateRangeFiltersDataIsSet: false
            };
        }

        this.dateRangeFiltersData = response.data.response.filter_values.date_comparison_ranges.data;

        if (response.data.response.filter_values.dynamic === undefined) {
            return {
                dateRangeFiltersDataIsSet: true,
                nonDateRangeFiltersDataIsSet: false
            };
        }

        let preSignedUrl = response.data.response.filter_values.dynamic.url;
        let mediaCyclesPreSignedUrl = response.data.response.filter_values.media_cycle_versions.url;

        try {
            let rawDataResponse = await axios.get(preSignedUrl);
            let mediaCyclesRawDataResponse = await axios.get(mediaCyclesPreSignedUrl);
            this.nonDateRangeFiltersData = rawDataResponse.data;
            this.mediaCycleFiltersData = mediaCyclesRawDataResponse.data;
        } catch (error) {
            console.log(error);
            return {
                dateRangeFiltersDataIsSet: true,
                nonDateRangeFiltersDataIsSet: false
            };
        }

        return {
            dateRangeFiltersDataIsSet: true,
            nonDateRangeFiltersDataIsSet: true
        };
    }

    static getDateRangeFilters() {
        return this.dateRangeFiltersData;
    }

    static getCustomDateRange() {
        if (this.dateRangeFiltersData !== '') {
            let customRangeOption = this.dateRangeFiltersData.find((range) => {
                if (range.label === 'Custom') {
                    return true;
                } else {
                    return false;
                }
            });

            return {
                date_range_start: customRangeOption['from_date'], //'20220401'
                date_range_end: customRangeOption['to_date'] //'20220430'
            };
        } else {
            return null;
        }
    }

    static getNonDateRangeFilters() {
        return this.nonDateRangeFiltersData;
    }

    static resetFilterValues() {
        this.dateRangeFiltersData = '';
        this.nonDateRangeFiltersData = '';
    }

    static getMediaCycleFilterValuesByPage(page) {
        if (this.mediaCycleFiltersData[page]) {
            let sortable = [];
            for (var versionObj in this.mediaCycleFiltersData[page]) {
                sortable.push([versionObj, this.mediaCycleFiltersData[page][versionObj]]);
            }

            sortable.sort(function (a, b) {
                if (b[1]['min_date'] < a[1]['min_date']) {
                    return -1;
                }
                if (a[1]['min_date'] > b[1]['min_date']) {
                    return 1;
                }

                return 0;
            });

            console.log(sortable);

            return sortable.map((arr) => {
                return { version: arr[0], date_range: arr[1] };
            });
        } else {
            return [];
        }
    }

    static getFilterValuesByTypeAndSelection(type, selectedFilters, page) {
        if ((page === 'Portal' || page === 'Portal Navigation') && type === 'seat_class') {
            return [{ label: 'PED', value: 'PED' }];
        }

        if (page === 'eReader') {
            page = 'Electronic Reader';
        }

        let response = this.nonDateRangeFiltersData;

        if (typeof response === 'object') {
            //filter by current page
            if (page !== 'My Insights') {
                response = response.filter((row) => {
                    let dataKey = raw_data_keys_mapping['product'];
                    if (page === row[dataKey]) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }

            //filter by selected filters
            Object.keys(selectedFilters).forEach((selection) => {
                /*skip current type, for example: if type = tail, filter dataset by all other selected filters except tail,
                    to get list of all tails that match other selected filters*/
                if (selected_filters_keys_mapping[selection] !== raw_data_keys_mapping[type]) {
                    if (selectedFilters[selection].length > 0) {
                        response = response.filter((row) => {
                            let dataKey = selected_filters_keys_mapping[selection];
                            if (selectedFilters[selection].includes(row[dataKey])) {
                                return true;
                            } else {
                                return false;
                            }
                        });
                    }
                }
            });

            response = response.map((row) => {
                let rawDataKey = raw_data_keys_mapping[type];
                return row[rawDataKey];
            });

            //sort
            response.sort();

            //remove duplicates
            let set = new Set(response);

            response = [...set].map((filterOption) => {
                return { label: filterOption, value: filterOption };
            });

            return response;
        } else {
            return [];
        }
    }

    static getStartEndDateByMediaCycle(mediaCycle, page) {
        if (this.mediaCycleFiltersData[page]) {
            return this.mediaCycleFiltersData[page][mediaCycle];
        }

        return null;
    }
}

export default FiltersService;
