import BaseService from 'services/BaseService';
import LinkHelper from 'util/LinkHelper';

/*global fetch*/

const linkhelper = new LinkHelper();

class AnalyticsService extends BaseService {
    async startEndUserSession(payload, browserClose = false, tenantId = null, tenantIcao = null) {
        let path = process.env.REACT_APP_INSIGHTS_API_URL + `/v2/analytics/user_session`;

        if (browserClose) {
            return this.makeFetchApiCall(path, payload);
        } else {
            return this.makeApiCall(path, payload, tenantId, tenantIcao);
        }
    }

    async postInteractionsData(payload) {
        let path = process.env.REACT_APP_INSIGHTS_API_URL + `/v2/analytics/update_interactions`;

        return this.makeApiCall(path, payload);
    }

    async postPageSessionsData(payload, browserClose = false) {
        let path = process.env.REACT_APP_INSIGHTS_API_URL + `/v2/analytics/page_session`;

        if (browserClose) {
            return this.makeFetchApiCall(path, payload);
        } else {
            return this.makeApiCall(path, payload);
        }
    }

    async makeApiCall(path, payload, tenantId = null, tenantIcao = null) {
        let options = {
            headers: {
                'pac-selected-tenant-id': tenantId ? tenantId : linkhelper.getCurAuthTenandid(),
                'pac-selected-tenant-icao': tenantIcao ? tenantIcao : linkhelper.getCurAuthTenantIcao(),
                Authorization: await this.getAuthorizationHeader()
            }
        };

        return new Promise((resolve, reject) => {
            this.makePostApiCallAxios(path, payload, options).then((response) => {
                if (response && response.data) {
                    return resolve(response.data);
                } else {
                    return reject(response);
                }
            });
        });
    }

    async makeFetchApiCall(path, payload) {
        return fetch(path, {
            method: 'POST',
            headers: {
                'pac-selected-tenant-id': linkhelper.getCurAuthTenandid(),
                'pac-selected-tenant-icao': linkhelper.getCurAuthTenantIcao(),
                Authorization: await this.getAuthorizationHeader()
            },
            body: JSON.stringify(payload),
            keepalive: true
        });
    }
}

export default AnalyticsService;
