import React, { Component } from 'react';

import { Card, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { ButtonPrimary, ButtonSecondary } from 'pac-ux-components';
import { DropdownMultiple, Checkbox, CheckboxSelectVariant } from 'pac-ux-components';

import TailFilterHelper from 'components/widgets/admin/TailFilterHelper.js';
import UtilHelper from 'util/UtilHelper.js';

const utilHelper = new UtilHelper();

class TailFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            applyFilterButtonDisabled: true,
            clearFilterButtonDisabled: true,
            allTailOptions: [{ label: 'Any', value: 'Any' }],
            tailOptions: [{ label: 'Any', value: 'Any' }],
            multiSelectFilterLabel: 'Select Tail',
            selectedFilters: {
                systemType: [],
                aircraftType: [],
                tail: [],
                tier: [],
                app: []
            },
            appliedFilters: {},
            prevAppliedFilters: {},
            allFilters: {
                systemType: [],
                aircraftType: [],
                tail: [],
                tier: [],
                app: []
            }
        };

        this.testData = {};

        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.clearTailFilter = this.clearTailFilter.bind(this);
    }

    async componentDidMount() {
        //await this.setTailValues();
        await this.setAllFilterValues();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.filterValues, prevProps.filterValues) ||
            (this.props.filterValues.apps &&
                prevState.allFilters.app &&
                this.props.filterValues.apps.length !== prevState.allFilters.app.length)
        ) {
            //await this.setTailValues();
            await this.setAllFilterValues();
        }

        if (
            !_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) ||
            !_.isEqual(this.props.appliedFilters, this.state.appliedFilters)
        ) {
            //await this.handleFilterChange();
            //this.clearTailFilter();
            //await this.setSelectedFilters();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.airline_icao !== '' && this.props.airline_icao !== nextProps.airline_icao) {
            this.setState({
                selectedFilters: { systemType: [], aircraftType: [], tail: [], tier: [], app: [] },
                appliedFilters: {}
            });
        }
    }

    async setSelectedFilters() {
        this.setState({ selectedFilters: {}, appliedFilters: {} });
    }

    async setTailValues() {
        if (this.props.filterValues) {
            let filterTails = utilHelper.resolve(this.props.filterValues, `tails`);
            let tailOptionData = [];
            if (filterTails) {
                filterTails.forEach(function (tail) {
                    let tailData = {};
                    tailData.label = tail;
                    tailData.value = tail;
                    tailOptionData.push(tailData);
                });
            } else {
                tailOptionData = [{ label: 'Any', value: 'Any' }];
            }

            this.setState({
                allTailOptions: tailOptionData,
                tailOptions: tailOptionData
            });
        }
    }

    setAllFilterValues() {
        let filterValues = this.props.filterValues;
        if (
            this.state.selectedFilters &&
            this.state.selectedFilters.tier &&
            this.state.selectedFilters.tier.length > 0
        ) {
            let selectedTiersLength = this.state.selectedFilters.tier.length;
            let allTiersLength = this.props.filterValues.tiers.length;
            if (selectedTiersLength < allTiersLength) {
                filterValues = TailFilterHelper.getFilterValuesBySelectedTiers(
                    this.state.selectedFilters.tier,
                    this.props.filterValues
                );
            } else if (selectedTiersLength === 1) {
                filterValues = this.props.filterValues.filterValuesByTier[this.state.selectedFilters.tier[0]];
            }

            // Update selected filters based on the selected tier(s).
            if (selectedTiersLength !== allTiersLength) {
                Object.keys(this.state.selectedFilters).forEach((key) => {
                    if (this.state.selectedFilters[key].length > 0) {
                        let mappedKey = TailFilterHelper.getMappedKey(key);
                        if (mappedKey) {
                            for (let i = 0; i < this.state.selectedFilters[key].length; i++) {
                                if (!filterValues[mappedKey].includes(this.state.selectedFilters[key][i])) {
                                    this.state.selectedFilters[key].splice(i, 1);
                                }
                            }
                        }
                    }
                });
            }
        }

        if (filterValues) {
            let filterTails = utilHelper.resolve(filterValues, `tails`);
            let tailOptionData = [];
            if (filterTails) {
                filterTails.forEach(function (tail) {
                    let tailData = {};
                    tailData.label = tail;
                    tailData.value = tail;
                    tailOptionData.push(tailData);
                });
            } else {
                tailOptionData = [{ label: 'Any', value: 'Any' }];
            }

            let filterSystemTypes = utilHelper.resolve(filterValues, `system_types`);
            let filterACTypes = utilHelper.resolve(filterValues, `aircraft_type_subtypes`);
            let filterTiers = utilHelper.resolve(this.props.filterValues, `tiers`);
            let filterApps = utilHelper.resolve(filterValues, `apps`);

            this.setState({
                allFilters: {
                    systemType: filterSystemTypes,
                    aircraftType: filterACTypes,
                    tail: filterTails,
                    tier: filterTiers,
                    app: filterApps
                },
                allTailOptions: tailOptionData,
                tailOptions: tailOptionData
            });
        }
    }

    async handleFilterChange() {
        this.setState({ selectedFilters: this.props.appliedFilters, appliedFilters: this.props.appliedFilters }, () => {
            this.toggleApplyFilterButton();
        });
    }

    getSelectedFiltersFromStore() {
        let selectedFiltersFromStore = JSON.parse(
            window.sessionStorage.getItem('pac_next_insights_tail_selected_filters')
        );
        if (selectedFiltersFromStore) {
            this.setState(selectedFiltersFromStore);
            this.sendAppliedFilters(false);
        }
    }

    handleCheckBoxChange(checkBoxType, e) {
        const item = e.target.name;

        let filters = this.state.selectedFilters;
        let checkboxChecked = false;
        for (let i = 0; i < filters[checkBoxType].length; i++) {
            if (filters[checkBoxType][i] === item) {
                checkboxChecked = true;
                break;
            }
        }

        if (!checkboxChecked) {
            filters[checkBoxType].push(item);
        } else {
            for (let i = 0; i < filters[checkBoxType].length; i++) {
                if (filters[checkBoxType][i] === item) {
                    filters[checkBoxType].splice(i, 1);
                }
            }
        }

        this.setState({ selectedFilters: filters }, () => {
            this.setAllFilterValues();
        });

        this.toggleApplyFilterButton();
    }

    handleChipChange(filterType, value, appliedFilters) {
        let selectedFilters = this.state.selectedFilters;
        for (let i = 0; i < selectedFilters[filterType].length; i++) {
            if (selectedFilters[filterType][i] === value) {
                selectedFilters[filterType].splice(i, 1);
            }
        }
        this.setState(
            {
                selectedFilters: selectedFilters,
                appliedFilters: appliedFilters
            },
            () => {
                this.toggleApplyFilterButton();
                TailFilterHelper.setSelectedFiltersToStore(this.state);
            }
        );
    }

    handleTailChange = (selectedOption) => {
        let filters = this.state.selectedFilters;
        let index = filters.tail.indexOf(selectedOption);
        if (index >= 0) {
            for (let i = 0; i < filters.tail.length; i++) {
                if (filters.tail[i] === selectedOption) {
                    filters.tail.splice(i, 1);
                }
            }
        } else {
            filters.tail.push(selectedOption);
        }
        this.setState({ selectedFilters: filters });
        this.toggleApplyFilterButton();
    };

    handleTailFilter = (selectedOption) => {
        if (this.state.allTailOptions && Object.keys(this.state.allTailOptions).length > 0 && selectedOption) {
            var filteredTails = this.state.allTailOptions.filter(function (el) {
                return el.value.toLowerCase().includes(selectedOption.toLowerCase());
            });
            this.setState({ tailOptions: filteredTails });
        } else {
            this.setState({ tailOptions: this.state.allTailOptions });
        }
    };

    clearTailFilter = () => {
        let filters = this.state.selectedFilters;
        filters.tail = [];
        this.setState({
            tailOptions: this.state.allTailOptions,
            selectedFilters: filters,
            multiSelectFilterLabel: 'Select Tail'
        });
        this.toggleApplyFilterButton();
    };

    toggleApplyFilterButton() {
        if (this.state.selectedFilters) {
            let disableApplyButton = true;
            let disableClearButton = true;
            for (let [key, value] of Object.entries(this.state.selectedFilters)) {
                if (value.length > 0) {
                    disableApplyButton = false;
                    disableClearButton = false;
                    break;
                }
            }

            if (disableClearButton) {
                for (let [key, value] of Object.entries(this.state.appliedFilters)) {
                    if (value.length > 0) {
                        disableClearButton = false;
                        break;
                    }
                }
            }

            this.setState({
                applyFilterButtonDisabled: disableApplyButton,
                clearFilterButtonDisabled: disableClearButton
            });
        }
    }

    handleApplyFilterClick = (e) => {
        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(this.state.selectedFilters));
        let stateToUpdate = { appliedFilters: selectedFiltersDeepClone };

        this.setState(stateToUpdate, () => {
            this.toggleApplyFilterButton();
            this.sendAppliedFilters(true);
        });

        //this.accordionRef.current.click();
        //setTimeout(function() { this.accordionRef.current.click(); }.bind(this), 500); // TODO
    };

    handleClearFilterClick = (e) => {
        let stateToUpdate = {
            appliedFilters: {},
            selectedFilters: { systemType: [], aircraftType: [], tail: [], tier: [], app: [] }
        };

        this.setState(stateToUpdate, () => {
            this.toggleApplyFilterButton();
            this.sendAppliedFilters(true);
        });
    };

    sendAppliedFilters(toggleTailFilterDropdown) {
        //if (!_.isEqual(this.state.prevAppliedFilters, this.state.appliedFilters)) {
        TailFilterHelper.setSelectedFiltersToStore(this.state);
        this.props.onFilterSelectionUpdated(this.state.appliedFilters, toggleTailFilterDropdown);
        //}
    }

    render() {
        return (
            <React.Fragment>
                <Card.Body>
                    <Row>
                        <Col>
                            <ButtonPrimary
                                disabled={this.state.applyFilterButtonDisabled}
                                className="button-primary"
                                onClick={this.handleApplyFilterClick}
                            >
                                Apply Filter
                            </ButtonPrimary>{' '}
                            <ButtonSecondary
                                disabled={this.state.clearFilterButtonDisabled}
                                className="button-secondary"
                                onClick={this.handleClearFilterClick}
                            >
                                Clear All
                            </ButtonSecondary>{' '}
                        </Col>
                    </Row>

                    <Row>
                        {(utilHelper.resolve(this.state.allFilters, `tier`) && this.state.allFilters.tier.length > 0) ||
                        (utilHelper.resolve(this.state.allFilters, `systemType`) &&
                            this.state.allFilters.systemType.length > 0) ? (
                            <Col xs={12} md={4} lg={3}>
                                {this.state.allFilters.tier.length > 0 ? (
                                    <div className="margin-top-20">
                                        <Card.Title>Tier</Card.Title>
                                        <Card>
                                            <ul className="ul-tiers">
                                                {this.state.allFilters.tier.map((item, index) => (
                                                    <li key={index + '_li_tier'} data-item={item}>
                                                        <Checkbox
                                                            name={item}
                                                            key={item}
                                                            style={{ float: 'left' }}
                                                            marked={
                                                                this.state.selectedFilters &&
                                                                this.state.selectedFilters.tier &&
                                                                this.state.selectedFilters.tier.includes(item)
                                                                    ? CheckboxSelectVariant.selected
                                                                    : CheckboxSelectVariant.unselected
                                                            }
                                                            onChange={(e) => this.handleCheckBoxChange('tier', e)}
                                                        />
                                                        <div className="checkbox-title">{item}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Card>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {this.state.allFilters.systemType.length > 0 ? (
                                    <div style={{ marginTop: '20px' }}>
                                        <Card.Title>System Type</Card.Title>
                                        <Card>
                                            <ul>
                                                {this.state.allFilters.systemType.map((item, index) => (
                                                    <li key={index + '_li_system_type'} data-item={item}>
                                                        <Checkbox
                                                            name={item}
                                                            key={item}
                                                            style={{ float: 'left' }}
                                                            marked={
                                                                this.state.selectedFilters &&
                                                                this.state.selectedFilters.systemType &&
                                                                this.state.selectedFilters.systemType.includes(item)
                                                                    ? CheckboxSelectVariant.selected
                                                                    : CheckboxSelectVariant.unselected
                                                            }
                                                            onChange={(e) => this.handleCheckBoxChange('systemType', e)}
                                                        />
                                                        <div className="checkbox-title">{item}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Card>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Col>
                        ) : (
                            ''
                        )}

                        {utilHelper.resolve(this.state.allFilters, `aircraftType`) &&
                        this.state.allFilters.aircraftType.length > 0 ? (
                            <Col xs={12} md={4} lg={3}>
                                <div style={{ marginTop: '20px' }}>
                                    <React.Fragment>
                                        <Card.Title>Aircraft Type/Subtype</Card.Title>
                                        <Card>
                                            <ul>
                                                {this.state.allFilters.aircraftType.map((item, index) => (
                                                    <li key={index + '_li_aircraft_type'}>
                                                        <Checkbox
                                                            name={item}
                                                            key={item}
                                                            style={{ float: 'left' }}
                                                            marked={
                                                                this.state.selectedFilters &&
                                                                this.state.selectedFilters.aircraftType &&
                                                                this.state.selectedFilters.aircraftType.includes(item)
                                                                    ? CheckboxSelectVariant.selected
                                                                    : CheckboxSelectVariant.unselected
                                                            }
                                                            onChange={(e) =>
                                                                this.handleCheckBoxChange('aircraftType', e)
                                                            }
                                                        />
                                                        <div className="checkbox-title">{item}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Card>
                                    </React.Fragment>
                                </div>
                            </Col>
                        ) : (
                            ''
                        )}

                        {utilHelper.resolve(this.state.allFilters, `tail`) && this.state.allFilters.tail.length > 0 ? (
                            <Col xs={12} md={4} lg={3}>
                                <div className="margin-top-20">
                                    <Card.Title>Tail</Card.Title>
                                    <div className="admin-tail-select">
                                        <DropdownMultiple
                                            label={'Select Tail'}
                                            options={this.state.tailOptions}
                                            selected={
                                                this.state.selectedFilters && this.state.selectedFilters.tail
                                                    ? this.state.selectedFilters.tail
                                                    : []
                                            }
                                            onChange={this.handleTailChange}
                                            filterLabel={this.state.multiSelectFilterLabel}
                                            onFilter={this.handleTailFilter}
                                            onClear={this.clearTailFilter}
                                        />
                                    </div>
                                </div>
                            </Col>
                        ) : (
                            ''
                        )}

                        {utilHelper.resolve(this.state.allFilters, `app`) && this.state.allFilters.app.length > 0 ? (
                            <Col xs={12} md={4} lg={3}>
                                <div style={{ marginTop: '20px' }}>
                                    <Card.Title>Products</Card.Title>
                                    <Card>
                                        <ul className="ul-products">
                                            {this.state.allFilters.app.map((item, index) => (
                                                <li key={index + '_li_app'} data-item={item}>
                                                    <Checkbox
                                                        name={item}
                                                        key={item}
                                                        style={{ float: 'left' }}
                                                        marked={
                                                            this.state.selectedFilters &&
                                                            this.state.selectedFilters.app &&
                                                            this.state.selectedFilters.app.includes(item)
                                                                ? CheckboxSelectVariant.selected
                                                                : CheckboxSelectVariant.unselected
                                                        }
                                                        onChange={(e) => this.handleCheckBoxChange('app', e)}
                                                    />
                                                    <div className="checkbox-title">{item}</div>
                                                </li>
                                            ))}
                                        </ul>
                                    </Card>
                                </div>
                            </Col>
                        ) : (
                            ''
                        )}
                    </Row>
                </Card.Body>
            </React.Fragment>
        );
    }
}

export default TailFilter;
