const display_names = {
    systemType: 'System',
    aircraftType: 'Aircraft',
    flightDuration: 'Haul',
    seatClass: 'Class',
    guiLanguage: 'Language',
    tail: 'Tail',
    fromCity: 'Origin',
    toCity: 'Destination',
    flightNumber: 'Flight #'
};

export default display_names;
