import React from 'react';
import 'assets/styles/widgets/screenTransition.scss';
import clsx from 'clsx';
import Util from 'util/UtilHelper';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { screenTransitionData as sampleData } from '../../data/screen-transition';
import Analytics from 'components/widgets/Analytics';

const utilHelper = new Util();
const Transition = (props) => {
    const data = props.data;

    return (
        <div className={clsx({ 'screen-transition-wrapper mx-3': true, 'flex-fill': props.showWorkbench })}>
            <div className="screen-transition-header font-weight-bold px-4 py-4 my-1">{props.header}</div>
            <ul className="screen-transition-items p-0">
                {data.map((e, i) => (
                    <li
                        className={clsx({
                            'border-bottom': i < data.length - 1,
                            'screen-transition-item': true,
                            active: props.activeIndex === i
                        })}
                        key={i}
                        {...(props.onClickHandler ? { onClick: () => props.onClickHandler(i) } : {})}
                    >
                        <div className="screen-transition-1 d-flex align-items-center">
                            <div className={`screen-transition-item-number px-3 align-top`}>
                                <div aria-label={props.isNextLevel ? 'has next-lavel' : 'no-next-lavel'}>{i + 1}</div>
                            </div>
                            <div className={'screen-transition-info-container pt-3'}>
                                {e.screen_name ? (
                                    <OverlayTrigger overlay={<Tooltip>{e.screen_name}</Tooltip>}>
                                        <p
                                            className="screen-transition-item-name mb-0"
                                            id={props.id + 'screenName' + i}
                                            aria-label={props.isNextLevel ? 'has next-lavel' : 'no-next-lavel'}
                                        >
                                            {utilHelper.transformTextfromStart(
                                                e.screen_name,
                                                props.isTierZero ? 40 : 19
                                            )}
                                        </p>
                                    </OverlayTrigger>
                                ) : (
                                    <p
                                        className="screen-transition-item-name mb-0"
                                        id={props.id + 'screenName' + i}
                                        aria-label={props.isNextLevel ? 'has next-lavel' : 'no-next-lavel'}
                                    ></p>
                                )}
                                <p className="screen-transition-item-value">
                                    <span
                                        className="screen-transition-item-value-number"
                                        aria-label={props.isNextLevel ? 'has next-lavel' : 'no-next-lavel'}
                                    >
                                        {utilHelper.formatToUnitsForPillBox(e.hits)}
                                    </span>
                                    <span
                                        className={clsx({
                                            'px-1': true,
                                            'visits-label': true,
                                            invisible: props.activeIndex !== i
                                        })}
                                    >
                                        Visits
                                    </span>
                                </p>
                            </div>
                            {props.showNextLevel && props.isNextLevel && (
                                <div
                                    className={clsx({
                                        'flex-fill text-right pr-4 arrow': true,
                                        invisible: props.activeIndex !== i
                                    })}
                                >
                                    &#8594;
                                </div>
                            )}
                        </div>
                        <span
                            className={clsx({
                                'mx-3 detail': true,
                                invisible: props.activeIndex !== i
                            })}
                        >
                            {'Avg Dwell: ' + utilHelper.numberWithCommas(e.avg_dwell_time) + 's'}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

class ScreenTransition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            level1ActiveIndex: 0,
            level2ActiveIndex: 0,
            level3ActiveIndex: -1
        };
        this.setLevel1ActiveIndex = this.setLevel1ActiveIndex.bind(this);
        this.setLevel2ActiveIndex = this.setLevel2ActiveIndex.bind(this);
    }

    setLevel1ActiveIndex(i) {
        Analytics.analytics.track('setLevel1ActiveIndex', {
            visual_name: this.props.title,
            interaction_type: 'Table 1 Selection',
            interaction_value: this.props.data[i]['screen_name']
        });

        this.setState({
            level1ActiveIndex: i,
            level2ActiveIndex: 0,
            level3ActiveIndex: -1
        });
    }

    setLevel2ActiveIndex(i) {
        if (this.props.showWorkbench) {
            Analytics.analytics.track('setLevel2ActiveIndex', {
                visual_name: this.props.title,
                interaction_type: 'Table 2 Selection',
                interaction_value: this.props.data[this.state.level1ActiveIndex].visits[i]['screen_name']
            });

            this.setState({
                level2ActiveIndex: i,
                level3ActiveIndex: -1
            });
        } else {
            this.setState({
                level2ActiveIndex: -1,
                level3ActiveIndex: -1
            });
        }
    }

    render() {
        var { data } = this.props;
        var isSample = false;

        if (!data.length) {
            data = sampleData.response.visits;
            isSample = true;
        }

        let transition2Dashboard = '';
        let transition3Dashboard = '';
        if (this.props.showWorkbench) {
            transition2Dashboard = (
                <Transition
                    data={
                        data[this.state.level1ActiveIndex].visits.length
                            ? data[this.state.level1ActiveIndex].visits
                            : []
                    }
                    blured={isSample}
                    activeIndex={this.state.level2ActiveIndex}
                    showNextLevel={true}
                    header={'Level 2 (Visits)'}
                    onClickHandler={this.setLevel2ActiveIndex}
                    id={'transition2'}
                    isNextLevel={
                        data[this.state.level1ActiveIndex].visits.length &&
                        data[this.state.level1ActiveIndex].visits[this.state.level2ActiveIndex].visits.length
                            ? true
                            : false
                    }
                />
            );

            transition3Dashboard = (
                <Transition
                    data={
                        data[this.state.level1ActiveIndex].visits.length &&
                        data[this.state.level1ActiveIndex].visits[this.state.level2ActiveIndex].visits.length
                            ? data[this.state.level1ActiveIndex].visits[this.state.level2ActiveIndex].visits
                            : []
                    }
                    blured={isSample}
                    activeIndex={this.state.level3ActiveIndex}
                    showNextLevel={false}
                    header={'Level 3 (Visits)'}
                    id={'transition3'}
                    isNextLevel={false}
                />
            );
        } else {
            transition2Dashboard = (
                <Transition
                    data={
                        data[this.state.level1ActiveIndex].visits.length
                            ? data[this.state.level1ActiveIndex].visits
                            : []
                    }
                    blured={isSample}
                    activeIndex={-1}
                    showNextLevel={true}
                    header={'Level 2 (Visits)'}
                    onClickHandler={this.setLevel2ActiveIndex}
                    id={'transition2'}
                    isTierZero={!this.props.showWorkbench}
                    isNextLevel={false}
                />
            );
        }

        return (
            <>
                {data.length && (
                    <div className={clsx({ 'data-wrapper': true, blurred: isSample })}>
                        <div className={'mx-2 mb-3 d-flex flex-row'}>
                            <Transition
                                data={data}
                                blured={isSample}
                                activeIndex={this.state.level1ActiveIndex}
                                showNextLevel={true}
                                header={'Level 1 (Visits)'}
                                onClickHandler={this.setLevel1ActiveIndex}
                                id={'transition1'}
                                isNextLevel={data[this.state.level1ActiveIndex].visits.length ? true : false}
                                isTierZero={!this.props.showWorkbench}
                            />

                            {transition2Dashboard}
                            {transition3Dashboard}
                        </div>
                    </div>
                )}
                <div className={clsx({ 'no-data': true, 'd-none': !isSample })}>Data is not available</div>
            </>
        );
    }
}

export default ScreenTransition;
