import React from 'react';
import { Row } from 'reactstrap';
import DonutContainer from 'components/donut/DonutContainer';
import HorizontalBarChartContainer from 'components/horizontal-bar-chart/HorizontalBarChartContainer';
import ScreenTransitionContainer from 'components/screen-transition/ScreenTransitionContainer';

let page = 'portal';
let displayPageName = 'Portal';

class PortalNavigationOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let dashboard = (
            <div>
                <Row>
                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        forceReloadAll={this.props.forceReloadAll}
                        renderType=""
                        donut_value="portal_device_browser"
                        page={page}
                        pageName={displayPageName}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />

                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        forceReloadAll={this.props.forceReloadAll}
                        renderType=""
                        donut_value="portal_device_os"
                        page={page}
                        pageName={displayPageName}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>

                <Row>
                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        forceReloadAll={this.props.forceReloadAll}
                        renderType=""
                        donut_value="portal_device_type"
                        page={page}
                        pageName={displayPageName}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource="Portal"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Portal Browsing Pages"
                        workbenchTitle="portal_browsing_pages"
                        page={page}
                        pageName={displayPageName}
                        reportType="pages"
                        color="#2BCAB7"
                        key="portal_overview_portal_browsing_pages_1"
                        id="portal_overview_portal_browsing_pages_1"
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>

                <Row>
                    <ScreenTransitionContainer
                        path={this.props.path}
                        usageSource={displayPageName}
                        pageName={displayPageName}
                        page={page}
                        forceReloadAll={this.props.forceReloadAll}
                        id={'portal_overview_screen_transition'}
                        title={'Portal Screen Transitions'}
                        appliedFilters={this.props.appliedFilters}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default PortalNavigationOverview;
