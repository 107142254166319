import React from 'react';
import { ButtonPrimary } from 'pac-ux-components';
import { Row, Col } from 'reactstrap';
import 'assets/styles/error-message.css';

class ErrorMessage extends React.Component {
    constructor(props) {
        super(props);

        let header = 'Error';
        let message = 'Sorry, an unexpected error occurred. Please try again later.';

        let errors = [
            {
                header: 'Tails Not Added',
                errorDescription: 'The following tails already exists and were not added.'
            },
            {
                header: 'Tails Not Added',
                errorDescription: 'The following tails were not recognized and not added.'
            },
            {
                header: 'Tails Not Added',
                errorDescription:
                    'The following tails were not added because the license is expired. Please contact your Panasonic Avionics sales representative to activate your NEXT Insights subscription.'
            },
            {
                header: 'Tails Not Updated',
                errorDescription: "The following tails were not updated as they don't exist."
            },
            {
                header: 'Tails Not Deleted',
                errorDescription: "The following tails were not deleted as they don't exist."
            }
        ];

        if (this.props.errorDescription) {
            let error = errors.filter((error) => error.errorDescription === this.props.errorDescription.trim());

            if (error && error.length > 0) {
                header = error[0].header;
                message = error[0].errorDescription;
            } else {
                if (this.props.errorDescription !== 'default') {
                    message = this.props.errorDescription;
                }
            }
        }

        this.state = {
            header: header,
            message: message
        };
    }

    render() {
        return (
            <div>
                <div>
                    <div className="header">{this.state.header}</div>
                    <div className="form-body">
                        <Row>
                            <Col>
                                <div>
                                    <div className="note">
                                        {this.state.message &&
                                            this.state.message.split('\n').map((i, key) => {
                                                return <div key={key}>{i}</div>;
                                            })}
                                    </div>
                                    {this.props.errorTails && (
                                        <div className="table-wrapper">
                                            <table className="table table-bordered">
                                                <tbody>
                                                    {this.props.errorTails.map((tail, index) => {
                                                        return (
                                                            <tr
                                                                key={index}
                                                                style={{
                                                                    backgroundColor: index % 2 === 0 ? '' : '#ededed'
                                                                }}
                                                            >
                                                                <td>{tail}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="buttons-section">
                    <ButtonPrimary className="button-primary" onClick={this.props.closeAction}>
                        Okay
                    </ButtonPrimary>{' '}
                </div>
            </div>
        );
    }
}

export default ErrorMessage;
