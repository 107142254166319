import React from 'react';
import { Alert } from 'reactstrap';
import DownloadImage from 'assets/images/icons/popout/download.svg';
import DownloadAlertImage from 'assets/images/icons/popout/download-alert.svg';
import DownloadAlertCloseImage from 'assets/images/icons/popout/download-alert-close.svg';
import { Spinner } from 'react-bootstrap';
import ExcelHelper from 'components/excel/ExcelHelper.js';
import DeviceTypeExcelHelper from 'components/excel/DeviceTypeExcelHelper.js';
import DeviceOSExcelHelper from 'components/excel/DeviceOSExcelHelper.js';
import DeviceBrowserExcelHelper from 'components/excel/DeviceBrowserExcelHelper.js';
import ProgramRankingExcelHelper from 'components/excel/ProgramRankingExcelHelper.js';
import ProgramTitlesExcelHelper from 'components/excel/ProgramTitlesExcelHelper.js';
import TableWidgetExcelHelper from 'components/excel/TableWidgetExcelHelper.js';
import SeatbackLanguagesExcelHelper from 'components/excel/SeatbackLanguagesExcelHelper.js';
import MyInsightsTrendsExcelHelper from 'components/excel/MyInsightsTrendsExcelHelper.js';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import 'assets/styles/download-excel.css';
import * as AppConstants from 'constants/app/constants';
import Analytics from 'components/widgets/Analytics';
import CarouselConfig from 'components/carousel/CarouselConfig.js';
import TrendConfig from 'components/trends/TrendConfig.js';
import HorizontalBarChartConfig from 'components/horizontal-bar-chart/HorizontalBarChartConfig.js';
import DonutChartConfig from 'components/donut/DonutChartConfig.js';
import axios from 'axios';
import SubscriptionService from 'services/SubscriptionService';

const trendConfig = new TrendConfig();
const carouselConfig = new CarouselConfig();
const donutChartConfig = new DonutChartConfig();
const horizontalBarChartConfig = new HorizontalBarChartConfig();
const excelHelper = new ExcelHelper();
const deviceTypeExcelHelper = new DeviceTypeExcelHelper();
const deviceOSExcelHelper = new DeviceOSExcelHelper();
const seatbackLanguagesExcelHelper = new SeatbackLanguagesExcelHelper();
const deviceBrowserExcelHelper = new DeviceBrowserExcelHelper();
const programRankingExcelHelper = new ProgramRankingExcelHelper();
const programTitlesExcelHelper = new ProgramTitlesExcelHelper();
const tableWidgetExcelHelper = new TableWidgetExcelHelper();
const myInsightsTrendsExcelHelper = new MyInsightsTrendsExcelHelper();

let service = new InsightsMonthlyService();

class DownloadExcel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            downloadAlertVisible: false,
            downloading: false,
            downloadText: 'Download Excel',
            downloadFilename: ''
        };

        this.toggleDownloadAlert = this.toggleDownloadAlert.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
    }

    downloadIcon() {
        if (this.state.downloading) {
            return <Spinner size="sm" animation="border" role="status" className="loading-spinner"></Spinner>;
        } else {
            return <img className="widget-overflow-img" src={DownloadImage} alt="" />;
        }
    }

    toggleDownloadAlert() {
        this.setState({ downloadAlertVisible: false });
    }

    /*
    removeDisabledFilters() {
        const filterKeys = {
            "system_types": "systemType",
            "aircraft_types": "aircraftType",
            "flight_haul_types": "flightDuration",
            "seat_classes": "seatClass",
            "tails": "tail",
            "flight_origin": "fromCity",
            "flight_destination": "toCity",
            "flight_number": "flightNumber"
        };
        
        let selectedFiltersDeepClone = JSON.parse(JSON.stringify(this.props.appliedFilters));
        
        Object.keys(selectedFiltersDeepClone).forEach((key) => {
            if (selectedFiltersDeepClone[key].length > 0) {
                if (Array.isArray(selectedFiltersDeepClone[key])) {
                    selectedFiltersDeepClone[key] = selectedFiltersDeepClone[key].filter(item =>
                        !DynamicFilterHelper.isAppliedFilterNotExist(filterKeys[key], item, this.props.pageName)
                    );
                    if (selectedFiltersDeepClone[key].length === 0) {
                        delete selectedFiltersDeepClone[key];
                    }
                } else {
                    if (DynamicFilterHelper.isAppliedFilterNotExist(filterKeys[key], selectedFiltersDeepClone[key], this.props.pageName)) {
                        delete selectedFiltersDeepClone[key];
                    }
                }
            }
        });
        
        return selectedFiltersDeepClone;
    }
    */

    fetchFilterValues() {
        let requestedFilters = [
            'system_types',
            'aircraft_types',
            'tails',
            'flight_origins',
            'flight_destinations',
            'flight_numbers'
        ];

        //let selectedFiltersDeepClone = this.removeDisabledFilters();

        let selectedFiltersDeepClone = JSON.parse(JSON.stringify(this.props.appliedFilters));

        let requestParams = {
            translation_language: 'en-us',
            tier: SubscriptionService.subscription(),
            date_range: selectedFiltersDeepClone['date_range'],
            tails: selectedFiltersDeepClone['tails'] ? selectedFiltersDeepClone['tails'].join() : '',
            system_types: selectedFiltersDeepClone['system_types']
                ? selectedFiltersDeepClone['system_types'].join()
                : '',
            aircraft_types: selectedFiltersDeepClone['aircraft_types']
                ? selectedFiltersDeepClone['aircraft_types'].join()
                : '',
            flight_origin: selectedFiltersDeepClone['flight_origin'] ? selectedFiltersDeepClone['flight_origin'] : '',
            flight_destination: selectedFiltersDeepClone['flight_destination']
                ? selectedFiltersDeepClone['flight_destination']
                : '',
            flight_number: selectedFiltersDeepClone['flight_number'] ? selectedFiltersDeepClone['flight_number'] : ''
        };

        let app = this.props.app;
        if (this.props.title === 'Entertainment Usage' && this.props.type === 'donut') {
            requestParams['app'] = 'Passenger Engagement';
        } else if (app === 'Portal Navigation') {
            app = 'Portal';
        } else if (app !== 'My Insights') {
            requestParams['app'] = this.props.app;
        }

        if (SubscriptionService.subscription() !== 'Basic') {
            requestParams['flight_haul_types'] = selectedFiltersDeepClone['flight_haul_types']
                ? selectedFiltersDeepClone['flight_haul_types'].join()
                : '';
            requestedFilters.push('flight_haul_types');
            if (
                !(
                    (this.props.title === 'Cabin Class' && this.props.type === 'plane') ||
                    this.props.title === 'Device OS' ||
                    this.props.title === 'Device Browsers' ||
                    this.props.title === 'Device Type'
                )
            ) {
                requestParams['seat_classes'] = selectedFiltersDeepClone['seat_classes']
                    ? selectedFiltersDeepClone['seat_classes'].join()
                    : '';
                requestedFilters.push('seat_classes');
            }
        }

        requestParams['requested_filters'] = requestedFilters.join();

        return service.fetchFilterValuesAxios(requestParams, null);
    }

    extractFilterValues(filtersResponse, filters) {
        let filterValues = filtersResponse.data.response.filter_values;

        let filterKeys = [
            'system_types',
            'aircraft_types',
            'tails',
            'flight_origins',
            'flight_destinations',
            'flight_numbers'
        ];
        if (SubscriptionService.subscription() !== 'Basic') {
            filterKeys.push('flight_haul_types');
            if (
                !(
                    (this.props.title === 'Cabin Class' && this.props.type === 'plane') ||
                    this.props.title === 'Device OS' ||
                    this.props.title === 'Device Browsers' ||
                    this.props.title === 'Device Type'
                )
            ) {
                filterKeys.push('seat_classes');
            }
        }

        filterKeys.forEach((filter) => {
            if (filterValues[filter]['data']) {
                filters[filter] = filterValues[filter]['data'].map((option) => {
                    return option.value;
                });
            }
        });

        filters['date_range'] = this.props.appliedFilters['date_range'];
        filters['date_range_text'] = this.props.appliedFilters['dateRangeText'];
        if (this.props.appliedFilters['media_cycle']) {
            filters['media_cycle'] = this.props.appliedFilters['media_cycle'];
        }

        if (SubscriptionService.subscription() !== 'Basic') {
            filters['comparison_range'] = this.props.appliedFilters['comparison_range'];
        }
    }

    async downloadExcel() {
        if (!this.props.dataAvailable || this.state.downloading) {
            return;
        }

        let title = this.props.title.split(' ').join('_');
        let pageName = this.props.pageName.split(' ').join('_');
        let filePageName = this.props.pageName === 'EReader' ? 'eReader' : pageName;
        let filename = `${this.props.tenantIcaoCode.toUpperCase()}_${filePageName}_${title}_${
            this.props.appliedFilters.date_range
        }`;

        if (this.props.appliedFilters['media_cycle']) {
            filename = `${this.props.tenantIcaoCode.toUpperCase()}_${filePageName}_${this.props.title}_${
                this.props.appliedFilters.media_cycle
            }`;
        }

        let tenantIcaoCode = this.props.tenantIcaoCode.toUpperCase();

        Analytics.analytics.track('downloadExcel', {
            visual_name: this.props.title,
            interaction_type: 'Excel Download',
            interaction_value: `${filename}.xlsx`
        });

        this.setState(
            {
                downloadAlertVisible: true,
                downloadFilename: filename,
                downloading: true,
                downloadText: 'Downloading...'
            },
            () => {
                window.setTimeout(() => {
                    this.setState({ downloadAlertVisible: false });
                }, 3000);
            }
        );

        let workbench_type =
            this.props.workbench_type === 'portal_browsing_pages' ? 'pages' : this.props.workbench_type;

        if (this.props.type === 'bar' || this.props.type === 'carousel' || this.props.type === 'donut') {
            let data;
            let dropDownOptions;

            if (this.props.type === 'donut') {
                dropDownOptions = donutChartConfig.getDonutDropdownList('Excel' + this.props.workbench_type);
                if (this.props.page === 'audio' || this.props.page === 'games') {
                    dropDownOptions = donutChartConfig.getDonutDropdownList(
                        'Excel_' + this.props.page + '_' + this.props.workbench_type
                    );
                } else if (this.props.page === 'ereader') {
                    dropDownOptions = donutChartConfig.getDonutDropdownList('ereader_' + this.props.workbench_type);
                }
            } else if (this.props.type === 'carousel') {
                dropDownOptions = carouselConfig.getDropdownList('Excel' + this.props.page.toLowerCase());
                if (!this.props.showWorkbenchLink) {
                    dropDownOptions = dropDownOptions.filter((item) => item.free_tier === 1);
                }
            } else if (this.props.type === 'bar') {
                dropDownOptions = horizontalBarChartConfig.getDropdownList(
                    workbench_type,
                    'Excel' + this.props.page.toLowerCase()
                );
                if (!this.props.showWorkbenchLink) {
                    dropDownOptions = dropDownOptions.filter((item) => item.free_tier === 1);
                }
            }

            let reqSections = [];
            if (this.props.type === 'donut') {
                reqSections = donutChartConfig.getDonutRequestedSections(document.location.pathname);
                reqSections = reqSections
                    .filter((item) => item.value === this.props.workbench_type)
                    .map((item) => item.requested_section);
            } else {
                reqSections = horizontalBarChartConfig.getBarChartRequestedSections(
                    this.props.workbench_type,
                    this.props.pageName
                );
            }

            let path;
            if (this.props.type === 'carousel') {
                path = carouselConfig.getAPIPath();
            } else if (this.props.type === 'bar') {
                path = horizontalBarChartConfig.getAPIPath();
            } else if (this.props.type === 'donut') {
                path = donutChartConfig.getAPIPath();
            }

            let deepCloneFilters = JSON.parse(JSON.stringify(this.props.appliedFilters));

            if (
                ['TV Series', 'Movies', 'Audio', 'Games', 'Live TV'].includes(this.props.pageName) &&
                this.props.type === 'donut' &&
                ['passenger_engagement', 'passenger_touchpoints'].includes(this.props.workbench_type)
            ) {
                deepCloneFilters.content_types = [this.props.pageName];
                deepCloneFilters.exclude_content_types = ['Others'];
            }

            if (['TV Series', 'Movies', 'Audio', 'Games', 'Live TV', 'EReader'].includes(this.props.pageName)) {
                const pageName = this.props.pageName === 'EReader' ? 'Electronic Reader' : this.props.pageName;
                deepCloneFilters.content_types = [pageName];
            }

            deepCloneFilters.requested_sections = reqSections;
            deepCloneFilters.format = 'Excel';

            if (SubscriptionService.subscription()) {
                deepCloneFilters.tier = SubscriptionService.subscription();
            }

            if (this.props.app) {
                deepCloneFilters.app = this.props.app;
            }

            if (this.props.page === 'seatback' || this.props.page === 'portal' || this.props.page === 'bluetooth') {
                deepCloneFilters.usage_source = this.props.pageName;
                delete deepCloneFilters.content_types;
            } else if (this.props.page === 'companion-app') {
                deepCloneFilters.launched_by = [this.props.pageName];
                delete deepCloneFilters.content_types;
            }

            const results = await Promise.allSettled([
                service.fetchNextInsightsMetricsAxios(deepCloneFilters, path),
                this.fetchFilterValues()
            ]);

            if (results[0]['status'] === 'fulfilled' && results[1]['status'] === 'fulfilled') {
                let excelResponse = results[0]['value'];
                let filtersResponse = results[1]['value'];
                if (!excelResponse && !excelResponse.requested_sections) {
                    console.error('Error: Invalid response from excel data API');
                } else {
                    let preSignedUrl = excelResponse.requested_sections;
                    if (this.isValidHttpUrl(preSignedUrl)) {
                        try {
                            let rawDataResponse = await axios.get(preSignedUrl);
                            data = rawDataResponse.data;
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        data = excelResponse.requested_sections;
                    }

                    let filters = [];
                    if (filtersResponse && filtersResponse.data) {
                        this.extractFilterValues(filtersResponse, filters);
                    } else {
                        console.error('Error: Invalid response from filters data API');
                    }

                    if (this.props.title === 'Device Type') {
                        deviceTypeExcelHelper.exportExcel(
                            tenantIcaoCode,
                            this.props.type,
                            this.props.reportSubType,
                            this.props.title,
                            filename,
                            data,
                            filters,
                            this.props.showWorkbenchLink,
                            dropDownOptions,
                            [],
                            this.props.pageName,
                            workbench_type
                        );
                    } else if (this.props.title === 'Device OS') {
                        deviceOSExcelHelper.exportExcel(
                            tenantIcaoCode,
                            this.props.type,
                            this.props.reportSubType,
                            this.props.title,
                            filename,
                            data,
                            filters,
                            this.props.showWorkbenchLink,
                            dropDownOptions,
                            [],
                            this.props.pageName,
                            workbench_type
                        );
                    } else if (this.props.title === 'Device Browsers') {
                        deviceBrowserExcelHelper.exportExcel(
                            tenantIcaoCode,
                            this.props.type,
                            this.props.reportSubType,
                            this.props.title,
                            filename,
                            data,
                            filters,
                            this.props.showWorkbenchLink,
                            dropDownOptions,
                            [],
                            this.props.pageName,
                            workbench_type
                        );
                    } else if (this.props.title === 'Channel Rankings') {
                        programRankingExcelHelper.exportExcel(
                            tenantIcaoCode,
                            this.props.type,
                            this.props.reportSubType,
                            this.props.title,
                            filename,
                            data,
                            filters,
                            this.props.showWorkbenchLink,
                            dropDownOptions,
                            [],
                            this.props.pageName,
                            workbench_type
                        );
                    } else if (this.props.title === 'Program Rankings') {
                        programTitlesExcelHelper.exportExcel(
                            tenantIcaoCode,
                            this.props.type,
                            this.props.reportSubType,
                            this.props.title,
                            filename,
                            data,
                            filters,
                            this.props.showWorkbenchLink,
                            dropDownOptions,
                            [],
                            this.props.pageName,
                            workbench_type
                        );
                    } else if (this.props.title === 'Languages' && this.props.pageName === 'Seatback') {
                        seatbackLanguagesExcelHelper.exportExcel(
                            tenantIcaoCode,
                            this.props.type,
                            this.props.reportSubType,
                            this.props.title,
                            filename,
                            data,
                            filters,
                            this.props.showWorkbenchLink,
                            dropDownOptions,
                            [],
                            this.props.pageName,
                            workbench_type
                        );
                    } else {
                        let page = this.props.pageName == 'EReader' ? 'eReader' : this.props.pageName;
                        excelHelper.exportExcel(
                            tenantIcaoCode,
                            this.props.type,
                            this.props.reportSubType,
                            this.props.title,
                            filename,
                            data,
                            filters,
                            this.props.showWorkbenchLink,
                            dropDownOptions,
                            [],
                            page,
                            workbench_type
                        );
                    }
                }
            }
        } else if (this.props.type === 'table') {
            let dropDownOptions = [
                {
                    label: 'Login Details'
                }
            ];

            let filtersResponse = await this.fetchFilterValues();

            let data = [];
            data['rows'] = this.props.data;

            let filters = [];
            if (filtersResponse && filtersResponse.data) {
                this.extractFilterValues(filtersResponse, filters);
            } else {
                console.error('Error: Invalid response from filters data API');
            }

            tableWidgetExcelHelper.exportExcel(
                tenantIcaoCode,
                this.props.type,
                this.props.reportSubType,
                this.props.title,
                filename,
                data,
                filters,
                this.props.showWorkbenchLink,
                dropDownOptions,
                this.props.pageName,
                workbench_type
            );
        } else {
            let response = await this.fetchFilterValues();

            let filters = [];
            if (response && response.data) {
                this.extractFilterValues(response, filters);
            }

            let data = this.props.data;

            if (this.props.type === 'lopa') {
                if (!(Object.keys(data).length > 0)) {
                    return false;
                } else {
                    data = { data: this.props.data, info: this.props.flightInfo, tabData: this.props.tabData };
                }
            }

            if (this.props.tabDataBySections && Object.keys(this.props.tabDataBySections).length > 0) {
                data.tabDataBySections = this.props.tabDataBySections;
                data.allTabDataBySections = this.props.allTabDataBySections;
                let dd1Options = trendConfig.getDropdownList('Excel' + this.props.title);

                if (!this.props.showWorkbenchLink) {
                    dd1Options = dd1Options.filter((item) => item.free_tier === 1);
                }

                myInsightsTrendsExcelHelper.exportExcelTrends(
                    this.props.tenantIcaoCode.toUpperCase(),
                    this.props.reportSubType,
                    this.props.title,
                    filename,
                    data,
                    filters,
                    this.props.showWorkbenchLink,
                    dd1Options,
                    this.props.pageName
                );
            } else {
                if (this.props.tabData) {
                    data.tabData = this.props.tabData;
                }

                // Add hover metrics data to bluetooth trends excel if available.
                if (this.props.type === 'trends' && this.props.page === 'bluetooth') {
                    data.tabData = trendConfig.getTrendChartHoverList(this.props.page, 'excel');
                }

                excelHelper.exportExcel(
                    this.props.tenantIcaoCode.toUpperCase(),
                    this.props.type,
                    this.props.reportSubType,
                    this.props.title,
                    filename,
                    data,
                    filters,
                    this.props.showWorkbenchLink,
                    this.props.dd1Options,
                    this.props.dd2Options,
                    this.props.pageName,
                    workbench_type
                );
            }
        }

        this.props.setOverflowState(false);
        this.setState({ downloading: false, downloadText: 'Download Excel' });
    }

    isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    render() {
        let downloadClassName =
            !this.props.dataAvailable ||
            this.state.downloading ||
            (SubscriptionService.stage === 1 && this.props.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASKY)
                ? 'widget-overflow-option disable'
                : 'widget-overflow-option';
        let downloadTextClassName =
            !this.props.dataAvailable ||
            this.state.downloading ||
            (SubscriptionService.stage === 1 && this.props.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASKY)
                ? 'widget-overflow-label disable'
                : 'widget-overflow-label';
        let downloadExcelFunction =
            !this.props.dataAvailable ||
            this.state.downloading ||
            (SubscriptionService.stage === 1 && this.props.tenantIcaoCode !== AppConstants.TENANT_ICAO_PANASKY)
                ? () => {
                      return false;
                  }
                : this.downloadExcel;

        return (
            <div>
                <div className={downloadClassName} onClick={downloadExcelFunction}>
                    {this.downloadIcon()}
                    <div className={downloadTextClassName}>{this.state.downloadText}</div>
                </div>

                <div className="widget-overflow-divider" />

                <Alert className="fixed-bottom insights-alert" color="primary" isOpen={this.state.downloadAlertVisible}>
                    <img className="arrow" src={DownloadAlertImage} alt="" />{' '}
                    <img
                        className="close-alert"
                        src={DownloadAlertCloseImage}
                        onClick={this.toggleDownloadAlert}
                        alt=""
                    />{' '}
                    <div className="text-body">
                        <div className="title">Downloading file</div>
                        <div className="filename">{this.state.downloadFilename}</div>
                    </div>
                </Alert>
            </div>
        );
    }
}

export default DownloadExcel;
