import React from 'react';
import { Row } from 'reactstrap';
import TrendContainer from 'components/trends/TrendContainer';
import DonutContainer from 'components/donut/DonutContainer';
import HorizontalBarChartContainer from 'components/horizontal-bar-chart/HorizontalBarChartContainer';
import ScreenTransitionContainer from 'components/screen-transition/ScreenTransitionContainer';
import VerticalBarChartContainer from 'components/vertical-bar-chart/VerticalBarChartContainer';
import LopaContainer from 'components/lopa/LopaContainer';
import AccessControl from 'components/widgets/AccessControl';
import TableContainer from 'components/table/TableContainer';
import SubscriptionService from 'services/SubscriptionService';

let page = 'seatback';
let displayPageName = 'Seatback';

class SeatBackOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ipsEnabled: false,
            ipsColumns: [
                { value: 'tail_no', header: 'Tail Number' },
                { value: 'flight_number', header: 'Flight Number' },
                { value: 'flight_start_date', header: 'Flight Start Date', type: 'date' },
                { value: 'flight_end_date', header: 'Flight End Date', type: 'date' },
                { value: 'flight_duration_secs', header: 'Flight Duration', type: 'time' },
                { value: 'aircraft_type_subtype', header: 'Aircraft Type/Sub-type' },
                { value: 'system_type', header: 'System Type' },
                { value: 'dep_airport_iata', header: 'Flight Origin' },
                { value: 'arr_airport_iata', header: 'Flight Destination' },
                { value: 'seat_class', header: 'Seat Class' },
                { value: 'seatnum', header: 'Seat Number' },
                { value: 'payload_code', header: 'Payload Code' },
                { value: 'payload_event', header: 'Payload Event' }
            ]
        };
    }

    async componentDidUpdate(prevProps, prevState) {
        try {
            if (!this.state.ipsEnabled) {
                let subscriptionData = await SubscriptionService.fetchSubscription();
                let tier = subscriptionData.tiers.find(
                    (tier) => tier['subscription-level'] === SubscriptionService.subscription()
                );
                if (tier.apps.includes('IPS')) {
                    this.setState({
                        ipsEnabled: true
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        let dashboard = (
            <div>
                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    usageSource="Seatback"
                    forceReloadAll={this.props.forceReloadAll}
                    title="Trends"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="seatback_overview_trend_1"
                    id="seatback_overview_trend_1"
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />

                <Row>
                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        renderType=""
                        page={page}
                        pageName={displayPageName}
                        donut_value="passenger_engagement"
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        allowedApps={this.props.allowedApps}
                        app={this.props.app}
                    />

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource="Seatback"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Languages"
                        workbenchTitle="languages"
                        page={page}
                        pageName={displayPageName}
                        reportType="languages"
                        color="#7FCECD"
                        key="seatback_overview_languages_1"
                        id="seatback_overview_languages_1"
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>

                <Row>
                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource="Seatback"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Seatback Browsing"
                        workbenchTitle="pages"
                        page={page}
                        pageName={displayPageName}
                        reportType="pages"
                        color="#2BCAB7"
                        key="seatback_overview_seatback_browsing_1"
                        id="seatback_overview_seatback_browsing_1"
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource="Seatback"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Routes"
                        workbenchTitle="routes"
                        page={page}
                        pageName={displayPageName}
                        reportType="routes"
                        color="#A1C9EB"
                        routes={true}
                        key="seatback_overview_routes_1"
                        id="seatback_overview_routes_1"
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>

                <Row>
                    <ScreenTransitionContainer
                        path={this.props.path}
                        usageSource={displayPageName}
                        pageName={displayPageName}
                        page={page}
                        forceReloadAll={this.props.forceReloadAll}
                        id={'portal_seat_back_overview_screen_transition'}
                        title={'Seatback Screen Transitions'}
                        appliedFilters={this.props.appliedFilters}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>

                <AccessControl page={page} metrics={[]} sections={['passenger_interaction_timeline']}>
                    <Row>
                        <VerticalBarChartContainer
                            path={this.props.path}
                            appliedFilters={this.props.appliedFilters}
                            usageSource={displayPageName}
                            pageName={displayPageName}
                            forceReloadAll={this.props.forceReloadAll}
                            showWorkbenchLink={this.props.showWorkbench}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            title={'Passenger Interaction Timeline'}
                            page={page}
                            app={this.props.app}
                        />
                    </Row>
                </AccessControl>

                <AccessControl page={page} metrics={[]} sections={['seat_usage']}>
                    <Row>
                        <LopaContainer
                            appliedFilters={this.props.appliedFilters}
                            usageSource={displayPageName}
                            pageName={displayPageName}
                            title={'Seat Usage'}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            showWorkbenchLink={this.props.showWorkbench}
                            page={page}
                            app={this.props.app}
                            ipsEnabled={this.state.ipsEnabled}
                        />
                    </Row>
                </AccessControl>

                {this.state.ipsEnabled && (
                    <Row>
                        <TableContainer
                            pageName={displayPageName}
                            appliedFilters={this.props.appliedFilters}
                            title="Login Details"
                            app={this.props.app}
                            columns={this.state.ipsColumns}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            page={page}
                        />
                    </Row>
                )}
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default SeatBackOverview;
