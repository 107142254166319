import React from 'react';
import Select, { components } from 'react-select';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import DynamicFilterHelper from 'data/dynamic-filter-helper.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faSearch : faCaretDown} />
            </components.DropdownIndicator>
        )
    );
};

class InsightsDropdown extends React.Component {
    render() {
        return (
            <Select
                options={this.props.options}
                menuPortalTarget={this.props.menuPortalTarget ? true : false}
                getOptionValue={(option) => option.label}
                onChange={this.props.onChange}
                classNamePrefix="select"
                maxMenuHeight={this.props.maxMenuHeight}
                components={{ DropdownIndicator }}
                styles={this.props.styles ? this.props.styles() : DynamicFilterHelper.getDropdownStyle()}
                placeholder={this.props.placeholder}
                value={this.props.selectedOption ? this.props.selectedOption : null}
                isDisabled={this.props.disabled}
            />
        );
    }
}

export default InsightsDropdown;
