import React from 'react';
import Circular from 'assets/images/icons/circular.gif';
import { Route, Switch } from 'react-router-dom';
import PageSidebar from 'components/sidebar/PageToolsSidebar';
import DashboardBuilder from 'components/containers/DashboardBuilder';
import { Row, Modal, Container, ModalBody } from 'reactstrap';
import { ButtonPrimary } from 'pac-ux-components';
import { Col } from 'react-bootstrap';
import 'assets/styles/disclaimer-popup.css';
import 'assets/styles/release-notification.css';
import 'assets/styles/session-timeout-popup.css';
import Analytics from 'components/widgets/Analytics';
import PlatformCloudbar from 'components/cloudbar/PlatformCloudbar.js';
import { PlatformFooter } from 'pac-platform-ui-components';
import { Auth } from 'aws-amplify';
import SubscriptionService from 'services/SubscriptionService';
import TenantsService from 'services/TenantsService';
import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import ReleaseNotificationService from 'services/ReleaseNotificationService';

let interval = 5 * 60 * 1000; // 5 minutes
let extendSessionPopupTime = 1 * 30 * 1000; // 30 seconds
let sessionIdleTime = 55 * 60 * 1000; // 55 minutes
let maxSessionTime = 55 * 60 * 1000; // 55 minutes

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.updatedTierHandler = this.updatedTierHandler.bind(this);
        this.showDisclaimerPopupAction = this.showDisclaimerPopupAction.bind(this);
        this.showReleaseNotificationAction = this.showReleaseNotificationAction.bind(this);
        this.showTimeoutPopupAction = this.showTimeoutPopupAction.bind(this);
        this.updateReleaseNotificationViewCount = this.updateReleaseNotificationViewCount.bind(this);

        this.state = {
            user: {},
            updatedTier: null,
            showDisclaimerPopup: false,
            showReleaseNotification: false,
            showTimeoutPopup: false,
            modal: false,
            message: '',
            header: '',
            subscriptionData: ''
        };

        sessionStorage.setItem('session_idle_time', sessionIdleTime);
        sessionStorage.setItem('max_session_time', maxSessionTime);

        this.pageSidebarRef = React.createRef();

        setInterval(() => {
            this.doRefresh();
        }, interval);
    }

    extendSession() {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                const refreshToken = new CognitoRefreshToken({ RefreshToken: SubscriptionService.refreshToken });
                user.refreshSession(refreshToken, (err, session) => {
                    if (err) {
                        console.log('refresh session error ', err);
                    } else {
                        console.log(session);
                        Analytics.sessionStartTime = Date.now();
                    }
                });
            })
            .catch(async (err) => {
                console.log(err);
                await Auth.signOut();
                //this.props.history.push('/');
            });
    }

    doRefresh() {
        sessionIdleTime = sessionStorage.getItem('session_idle_time');
        maxSessionTime = sessionStorage.getItem('max_session_time');

        if (Date.now() - Analytics.lastInteractionTime() > sessionIdleTime) {
            // No interaction in last 'sessionIdleTime' minutes
            this.setState({ showTimeoutPopup: true, modal: true }, () => {
                setTimeout(async () => {
                    if (this.state.showTimeoutPopup) {
                        // Check if some interaction in the other open tabs in the last 30 seconds
                        if (Date.now() - Analytics.lastInteractionTime() > sessionIdleTime) {
                            // No interaction in last 'sessionIdleTime' minutes
                            Analytics.handleUnloadEvent(true);
                            await Auth.signOut();
                            this.props.history.push('/');
                        } else {
                            this.extendSession();

                            // close popup
                            this.setState((prevState) => ({
                                modal: !prevState.modal,
                                showTimeoutPopup: !this.state.showTimeoutPopup
                            }));
                        }
                    }
                }, extendSessionPopupTime);
            });
        } else {
            // Some interaction in last 'sessionIdleTime' minutes
            if (Date.now() - Analytics.sessionStartTime > maxSessionTime) {
                // Over 'maxSessionTime' minutes since last session start
                this.extendSession();
            }
        }
    }

    showTimeoutPopupAction(header, message) {
        Analytics.analytics.track('extendSession', {
            visual_name: 'Session Extension',
            interaction_type: 'Button Click',
            interaction_value: 'Extend Session'
        });

        this.extendSession();

        // close popup
        this.setState((prevState) => ({
            modal: !prevState.modal,
            showTimeoutPopup: !this.state.showTimeoutPopup
        }));
    }

    updatedTierHandler(value, landingPagePath) {
        if (value === 'Basic') {
            if (this.props.match.path === '/app' || this.props.match.path === '/app/') {
                this.props.history.push(landingPagePath);
            }
        }

        this.setState({
            updatedTier: value
        });
    }

    async componentDidMount() {
        // Subscription tier selection change
        sessionStorage.removeItem('pac_subscription');

        this.extendSession();

        Analytics.initialize();

        await TenantsService.fetchTenants();

        let subscriptionData = await SubscriptionService.fetchSubscription(true);
        console.log(subscriptionData);
        console.log(ReleaseNotificationService.releaseVersion());
        if (subscriptionData.version !== ReleaseNotificationService.softwareReleaseVersion()) {
            window.location.reload(true);
        } else {
            this.setState((prevState) => ({
                subscriptionData: subscriptionData
            }));
        }
    }

    showDisclaimerPopupAction(header, message) {
        if (this.state.showDisclaimerPopup) {
            if (this.pageSidebarRef.current) {
                this.pageSidebarRef.current.toggleNewFeaturesTooltip(true);
                this.pageSidebarRef.current.updateUserReleaseNotificationViewStatus();
            }
        }

        this.setState((prevState) => ({
            modal: !prevState.modal,
            showDisclaimerPopup: !this.state.showDisclaimerPopup,
            header: header,
            message: message
        }));
    }

    showReleaseNotificationAction(header, message) {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            showReleaseNotification: !this.state.showReleaseNotification,
            header: header,
            message: message
        }));
    }

    getFeaturesHtml() {
        if (this.pageSidebarRef.current) {
            return this.pageSidebarRef.current.getFeaturesHtml();
        }

        return '';
    }

    updateReleaseNotificationViewCount() {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            showReleaseNotification: !this.state.showReleaseNotification
        }));

        if (this.pageSidebarRef.current) {
            this.pageSidebarRef.current.toggleNewFeaturesTooltip(true);
            this.pageSidebarRef.current.updateUserReleaseNotificationViewStatus();
        }
    }

    renderRoutes() {
        let { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.path}/entertainment/movies`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/audio`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/games`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/live-tv`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/tv-series`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/media-details`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/details/:dataName`} component={DashboardBuilder} />
                <Route path={`${match.path}/entertainment/ereader`} component={DashboardBuilder} />

                <Route path={`${match.path}/web-portal/seatback`} component={DashboardBuilder} />
                <Route path={`${match.path}/web-portal/portal`} component={DashboardBuilder} />
                <Route path={`${match.path}/web-portal/portal-navigation`} component={DashboardBuilder} />
                <Route path={`${match.path}/web-portal/companion-app`} component={DashboardBuilder} />
                <Route path={`${match.path}/web-portal/bluetooth`} component={DashboardBuilder} />

                <Route path={`${match.path}/admin/evaluations`} component={DashboardBuilder} />
                <Route path={`${match.path}/admin/deliveries`} component={DashboardBuilder} />
                <Route path={`${match.path}/admin/data-loading-dashboard`} component={DashboardBuilder} />

                <Route path={`${match.path}/rack-test-data/rack-offload-dashboard`} component={DashboardBuilder} />

                <Route path={`${match.path}/analytics/analytics-dashboard`} component={DashboardBuilder} />

                <Route path={`${match.path}/help`} component={DashboardBuilder} />

                <Route path={`${match.path}/release`} component={DashboardBuilder} />

                <Route path={`${match.path}/`} component={DashboardBuilder} {...this.props} />
            </Switch>
        );
    }

    render() {
        const cloudHomeUrl = process.env.REACT_APP_CLOUD_HOME_URL ? process.env.REACT_APP_CLOUD_HOME_URL : '/';

        return (
            <React.Fragment>
                <span className="app-disclaimer" id="app-disclaimer" style={{ display: 'none' }}>
                    <p id="subscription_stage_msg">&nbsp;</p>
                </span>

                <div className="platform-cloudbar-container">
                    <PlatformCloudbar cloudHomeUrl={cloudHomeUrl} />
                </div>

                {this.state.subscriptionData !== '' ? (
                    <div>
                        <PageSidebar
                            {...this.props}
                            onUpdatedTier={this.updatedTierHandler}
                            showDisclaimerPopupAction={this.showDisclaimerPopupAction}
                            showReleaseNotificationAction={this.showReleaseNotificationAction}
                            ref={this.pageSidebarRef}
                        />

                        <div className="main-container">
                            <main className="main-content">{this.renderRoutes()}</main>
                        </div>

                        <div className="pac-footer-wrapper">
                            <div className="pac-footer">
                                <PlatformFooter />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className={'data-wrapper custom-loading'}
                        style={{
                            display: 'block',
                            width: '0%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            height: '100vh'
                        }}
                    >
                        <img src={Circular} alt="" style={{ position: 'relative', top: '50%' }} />
                    </div>
                )}

                {this.state.showDisclaimerPopup ? (
                    <Container>
                        <Modal className="disclaimer-popup" isOpen={this.state.modal}>
                            <ModalBody className="disclaimer">
                                <div>
                                    <div className="wrapper">
                                        <div className="header">{this.state.header}</div>
                                        <div className="form-body">
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <div className="note">{this.state.message}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="buttons-section">
                                        <ButtonPrimary
                                            className="button-primary"
                                            onClick={this.showDisclaimerPopupAction}
                                        >
                                            Okay
                                        </ButtonPrimary>{' '}
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </Container>
                ) : null}

                {this.state.showReleaseNotification ? (
                    <Container>
                        <Modal className="release-notification-popup" isOpen={this.state.modal}>
                            <ModalBody className="release-notification">
                                <div className="row features-wrapper">
                                    <div className="main-title">
                                        <h1>New Features In Release v{ReleaseNotificationService.releaseVersion()}</h1>
                                    </div>
                                    <div className="content">
                                        <iframe
                                            src={this.getFeaturesHtml()}
                                            width="100%"
                                            height="100%"
                                            frameBorder="0"
                                            title="release-notification-popup"
                                        />
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <ButtonPrimary
                                            className="button-primary margin-left-16"
                                            onClick={this.updateReleaseNotificationViewCount}
                                        >
                                            Close
                                        </ButtonPrimary>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </Container>
                ) : null}

                {this.state.showTimeoutPopup ? (
                    <Container>
                        <Modal className="timeout-popup" isOpen={this.state.modal}>
                            <ModalBody className="timeout">
                                <div>
                                    <div className="wrapper">
                                        <div className="header">Session Timeout</div>
                                        <div className="form-body">
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <div className="note">
                                                            Your session has expired due to inactivity. Extend the
                                                            session time by clicking the button below.
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="buttons-section">
                                        <ButtonPrimary className="button-primary" onClick={this.showTimeoutPopupAction}>
                                            I'm still here
                                        </ButtonPrimary>{' '}
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </Container>
                ) : null}
            </React.Fragment>
        );
    }
}

export default Main;
