import moment from 'moment';

class Util {
    HmTimeFormat(time) {
        let formattedTime;
        if (this.isValidNumber(time)) {
            let hours = Math.floor(time / 3600);
            let minutes = Math.floor((time % 3600) / 60);
            hours = hours.toLocaleString('en');
            time = time === null || time === undefined || time === '' ? 0 : time;
            if (hours < 1) {
                if (minutes < 1) {
                    formattedTime = `${Math.floor(time)}s`;
                } else {
                    formattedTime = `${minutes}m`;
                }
            } else {
                formattedTime = `${hours}h ${minutes}m`;
            }
        } else {
            formattedTime = `0h 0m`;
        }

        return formattedTime;
    }

    HMTimeAltFormat(time) {
        // formats to: 000k hr 00 min 00 sec
        let formatTime = this.HmTimeFormat(time);
        formatTime =
            formatTime.indexOf(',') !== -1
                ? formatTime.replace(/,/, '').replace(/h/, 'k hr')
                : formatTime.replace(/h/, ' hr');
        formatTime = formatTime.replace(/m/, ' min');
        formatTime = formatTime.replace(/s/, ' sec').replace(/\.0/, '');

        return formatTime;
    }

    HMTimeFromStrFormat(time) {
        // test argument string in this format --> 02:14:27 formats to 000k hr 00 min 27 00
        try {
            let timeArr = time.split(':');
            let formatTime = 'No Data';

            let hours = timeArr[0] !== '00' ? parseInt(timeArr[0]) : 0;
            let minutes = timeArr[1] !== '00' ? parseInt(timeArr[1]) : 0;
            let seconds = timeArr[2] !== '00' ? parseInt(timeArr[2]) : 0;

            if (!isNaN(hours) && !isNaN(minutes)) {
                formatTime = hours !== 0 ? hours.toString() + ' hr ' : '';
                formatTime = minutes !== 0 ? formatTime + minutes.toString() + ' min' : formatTime + '';
                formatTime = seconds !== 0 ? formatTime + seconds.toString() + ' sec' : formatTime + '';
            }

            return formatTime;
        } catch (e) {
            // console.log('error in Util.HMTimeFromStrFormat : ---->> ', time, e);
            //Consol.log
        }
    }

    formatToUnits(number, precision) {
        if (this.isValidNumber(number)) {
            const abbrev = ['', 'K', 'M', 'B', 'T'];
            const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3);
            const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1));
            const suffix = abbrev[order];
            let result = Number((number / Math.pow(10, order * 3)).toFixed(precision));

            result = !!(result % 1) ? result : result.toFixed(0);

            return result + suffix;
        } else {
            return 0;
        }
    }

    formatToUnitsForPillBox(number) {
        if (this.isValidNumber(number)) {
            const abbrev = ['', 'K', 'M', 'B', 'T'];
            const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3);
            const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1));
            const suffix = abbrev[order];
            let result = number / Math.pow(10, order * 3);
            let precision;
            if (result > 100) {
                precision = 0;
            } else if (result >= 10 && order !== 0) {
                precision = 1;
            } else if (result < 10 && order !== 0) {
                precision = 2;
            }
            // if (number < 1000 && !!(number % 1)) {
            //     precision = 0;
            // }
            // let precision = result > 100 ? 0:result > 10 ? 1 :result < 10 ? 2 : 0;
            // result =  !!(result % 1) ? result : result.toFixed(0);

            result = result.toFixed(precision);
            return result.toString() + suffix;
        } else {
            return 0;
        }
    }

    formatToUnitsByDecimal(number, precision) {
        if (this.isValidNumber(number)) {
            const abbrev = ['', 'K', 'M', 'B', 'T'];
            const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3);
            const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1));
            const suffix = abbrev[order];
            let res = parseFloat((number / Math.pow(10, order * 3)).toFixed(precision));
            res = !!(res % 1) ? res : res.toFixed(0);
            return res.toString() + suffix;
        } else {
            return 0;
        }
    }

    isValidNumber(number) {
        var checkValue = Number(number);
        return Number.isFinite(checkValue);
    }

    formatToPercentage(number, precision) {
        let num = typeof number === 'string' ? parseFloat(number) : number;
        const prec = precision || 2;
        if (this.isValidNumber(num)) {
            return num.toFixed(prec) + '%';
        } else {
            return `0%`;
        }
    }

    transformTextfromStart(text, maxChars) {
        if (text) {
            if (text.length > maxChars) {
                return text.substring(0, maxChars) + '...';
            } else {
                return text;
            }
        } else {
            return '';
        }
    }

    isMixedUnit(d1, d2) {
        if (this.isValidNumber(d1) && this.isValidNumber(d2)) {
            let d1Unit;
            let d2Unit;

            var getUnit = (d) => {
                if (d < 60) return 's';
                else if (d >= 60 && d <= 3599) return 'm';
                else return 'h';
            };

            d1Unit = getUnit(d1);
            d2Unit = getUnit(d2);

            return d1Unit !== d2Unit;
        }
        return false;
    }

    isValidDate(str) {
        return !isNaN(Date.parse(str));
    }

    getTooltipDateTitle(val) {
        if (this.isValidDate(val)) {
            return moment(new Date(val)).format('MMMM DD, YYYY');
        }
        return '';
    }

    getUnit(val) {
        if (val.includes('Hrs')) {
            return 'Hours';
        } else if (val.includes('Min')) {
            return 'Minutes';
        } else if (val.includes('Sec')) {
            return 'Seconds';
        }
        return 'Time';
    }

    numberWithCommas(val) {
        if (this.isValidNumber(val)) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return 0;
        }
    }

    secondsToHms(d, displayDecimal) {
        var h, m, s, formattedTime, hDisplay, mDisplay, sDisplay;
        if (this.isValidNumber(d)) {
            d = Number(d);
            h = displayDecimal ? Math.round(d / 3600, 2) : Math.floor(d / 3600);
            m = displayDecimal ? Math.round((d % 3600) / 60, 2) : Math.floor((d % 3600) / 60);
            s = displayDecimal ? Math.round((d % 3600) % 60, 2) : Math.floor((d % 3600) % 60);

            hDisplay = h > 0 ? this.formatToUnits(h, displayDecimal) + ' Hrs ' : '';
            mDisplay = m > 0 && h < 1 ? m + ' Min ' : '';
            sDisplay = s >= 0 ? s + (s === 1 ? ' Sec' : ' Sec ') : '';

            if (mDisplay.length < 1 && hDisplay.length < 1) {
                formattedTime = sDisplay;
            } else {
                formattedTime = hDisplay + mDisplay;
            }
            return formattedTime;
        } else {
            return 0;
        }
    }

    secondsToHmsForDynamicTabs(d, rNumber) {
        var h, m, s, formattedTime, hDisplay, mDisplay, sDisplay;
        if (this.isValidNumber(d)) {
            d = Number(d);
            h = Math.floor(d / 3600);
            m = Math.floor((d % 3600) / 60);
            s = Math.floor((d % 3600) % 60);
            let formattedHours = rNumber ? this.numberWithCommas(h) : this.formatToUnitsForPillBox(h);
            hDisplay =
                h > 1000
                    ? formattedHours + ' Hours '
                    : h > 0 && h < 1000 && m > 0
                    ? h + 'h ' + m + 'm'
                    : h > 0
                    ? h + 'h'
                    : '';
            mDisplay = m > 0 && h < 1 ? m + 'm ' + s + 's' : '';
            sDisplay = s > 0 ? s + 's' : ' 0s';
            formattedTime = h >= 1 ? hDisplay : m > 0 ? mDisplay : sDisplay;
            return formattedTime;
        } else {
            return 0;
        }
    }

    secondsToHmsWithoutUnit(d, displayDecimal) {
        var h, m, s, formattedTime, hDisplay, mDisplay, sDisplay;
        if (this.isValidNumber(d)) {
            d = Number(d);
            h = displayDecimal ? Math.round(d / 3600, 2) : Math.ceil(d / 3600);
            m = displayDecimal ? Math.round((d % 3600) / 60, 2) : Math.floor((d % 3600) / 60);
            s = displayDecimal ? Math.round((d % 3600) % 60, 2) : Math.floor((d % 3600) % 60);

            hDisplay = h > 0 ? this.formatToUnits(h, displayDecimal) : '';
            mDisplay = m > 0 && h < 1 ? m : '';
            sDisplay = s >= 0 ? s : '';
            if (mDisplay.length < 1 && hDisplay.length < 1) {
                formattedTime = sDisplay;
            } else if (d < 3600 && d > 0) {
                formattedTime = mDisplay;
            } else {
                formattedTime = hDisplay + mDisplay;
            }

            return formattedTime;
        } else {
            return 0;
        }
    }

    secondsToHours(d, displayDecimal) {
        var h, m, s, hDisplay;
        if (this.isValidNumber(d)) {
            d = Number(d);
            h = displayDecimal ? Math.round(d / 3600, 2) : Math.floor(d / 3600);
            m = displayDecimal ? Math.round((d % 3600) / 60, 2) : Math.floor((d % 3600) / 60);
            s = displayDecimal ? Math.round((d % 3600) % 60, 2) : Math.floor((d % 3600) % 60);

            if (d < 3600 && d > 0) {
                return m;
            }
            let secChange = s >= 30 ? 1 : 0;
            m = m + secChange;
            let minChange = m >= 30 ? 1 : 0;
            h = h + minChange;

            hDisplay = this.formatToUnits(h, displayDecimal);
            return hDisplay;
        } else {
            return 0;
        }
    }

    secondsToHoursMinutes(d, notRNumber) {
        var h, m, s, formattedTime;
        if (this.isValidNumber(d)) {
            d = Number(d);
            h = Math.floor(d / 3600);
            m = Math.floor((d % 3600) / 60);
            s = Math.floor((d % 3600) % 60);
            let formatedHours = notRNumber ? this.numberWithCommas(h) : this.formatToUnits(h);
            if (h > 0) {
                formattedTime = formatedHours + 'h ' + m + 'm';
            } else if (m > 0) {
                formattedTime = m + 'm';
            } else {
                formattedTime = s + 's';
            }

            return formattedTime;
        } else {
            return null;
        }
    }

    secondsToHmsWithUnit(d, notFormat) {
        var h, m, s, formattedTime;
        if (this.isValidNumber(d)) {
            d = Number(d);
            h = Math.floor(d / 3600);
            m = Math.floor((d % 3600) / 60);
            s = Math.floor((d % 3600) % 60);
            let formattedHours = notFormat ? this.numberWithCommas(h) : this.formatToUnits(h);
            if (h >= 1000) {
                formattedTime = formattedHours + ' hours';
            } else if (h > 0 && h < 1000) {
                formattedTime = formattedHours + 'h ' + m + 'm';
            } else if (m > 0) {
                formattedTime = m + 'm ' + s + 's';
            } else {
                formattedTime = s + 's';
            }

            return formattedTime;
        } else {
            return null;
        }
    }

    secondsToHoursMinutesSeconds(d, notFormat) {
        var h, m, s, formattedTime;
        if (this.isValidNumber(d)) {
            d = Number(d);
            h = Math.floor(d / 3600);
            m = Math.floor((d % 3600) / 60);
            s = Math.floor((d % 3600) % 60);
            let hours = notFormat ? h : this.formatToUnits(h);
            if (h > 0 && m > 0 && s > 0) {
                formattedTime = hours + 'h ' + m + 'm ' + s + 's';
            } else if (h > 0 && m > 0) {
                formattedTime = hours + 'h ' + m + 'm';
            } else if (h > 0 && s > 0) {
                formattedTime = hours + 'h ' + s + 's';
            } else if (m > 0 && s > 0) {
                formattedTime = m + 'm ' + s + 's';
            } else {
                formattedTime = s + 's';
            }

            return formattedTime;
        } else {
            return null;
        }
    }

    formatDateYYYYMMDDToDDMMMYYYY(d) {
        if (moment(d, 'YYYYMMDD', true).isValid()) {
            return moment(d, 'YYYYMMDD').format('DD MMM YYYY');
        }
        return d;
    }

    formatDateString(dateString) {
        if (dateString && dateString.length === 8) {
            let year = dateString.substring(0, 4);
            let month = dateString.substring(4, 6);
            let day = dateString.substring(6, 8);

            let date = new Date(year, month - 1, day);
            if (date instanceof Date && !isNaN(date)) {
                return moment(date).format('MMM DD, YYYY');
            }
        }
        return '';
    }

    formatDateStringWithPattern(dateString, pattern) {
        if (dateString && dateString.length === 8) {
            let year = dateString.substring(0, 4);
            let month = dateString.substring(4, 6);
            let day = dateString.substring(6, 8);

            let date = new Date(year, month - 1, day);
            if (date instanceof Date && !isNaN(date)) {
                return moment(date).format(pattern);
            }
        }
        return '';
    }

    formatDateRange(dateRange) {
        try {
            let rangeArr = dateRange.split('-');
            let startDate = this.formatDateString(rangeArr[0]);
            let endDate = this.formatDateString(rangeArr[1]);
            return `${startDate} - ${endDate}`;
        } catch (e) {
            return '';
        }
    }

    resolve(obj, path) {
        path = path.split('.');
        var current = obj;
        while (path.length) {
            if (typeof current !== 'object') return undefined;
            current = current[path.shift()];
        }
        return current;
    }

    letterToNumber(str) {
        return str.charCodeAt(0) - 65;
    }

    numberToLetter(value) {
        return (value + 10).toString(36).toUpperCase();
    }

    updateDropdownListLabels(dropdownList, dataset, dataKey = '', clone = false) {
        // use case 1:
        //     if least_popular or most_popular have less than 10 items
        // use case 2:
        //     if least_popular and most_popular have exactly 10 items, compare the 2 lists to check if they are identical
        //     if yes, then total data count is 10
        // for both cases, record indices and change the label to small_count_label
        // use case 3:
        //     if app trends has more than 5 apps, use small_count_label

        let finalList = {};
        let compareOptions = {};
        let updateLabels = [];

        dropdownList.forEach((option, index) => {
            let pathParts = option.value.split('.');
            if (dataKey !== '') {
                if (
                    dataset[dataKey] &&
                    dataset[dataKey][pathParts[0]] &&
                    pathParts[1] &&
                    dataset[dataKey][pathParts[0]][pathParts[1]]
                ) {
                    let data = dataset[dataKey][pathParts[0]][pathParts[1]]['data'];
                    if (data && data.length < 10) {
                        updateLabels.push(index);
                    } else {
                        if (compareOptions[option.text]) {
                            if (this.compareTwoLists(data, compareOptions[option.text]['data'])) {
                                updateLabels.push(index);
                                updateLabels.push(compareOptions[option.text]['index']);
                            }
                        } else {
                            compareOptions[option.text] = { index: index, data: data };
                        }
                    }
                }
            } else {
                if (
                    dataset[pathParts[0]] &&
                    dataset[pathParts[0]][pathParts[1]] &&
                    pathParts[2] &&
                    dataset[pathParts[0]][pathParts[1]][pathParts[2]]
                ) {
                    let data = dataset[pathParts[0]][pathParts[1]][pathParts[2]]['data'];
                    if (data && data.length < 10) {
                        updateLabels.push(index);
                    } else {
                        if (compareOptions[option.text]) {
                            if (this.compareTwoLists(data, compareOptions[option.text]['data'])) {
                                updateLabels.push(index);
                                updateLabels.push(compareOptions[option.text]['index']);
                            }
                        } else {
                            compareOptions[option.text] = { index: index, data: data };
                        }
                    }
                } else if (pathParts.length == 1) {
                    updateLabels.push(index);
                }
            }
        });

        if (updateLabels.length > 0) {
            if (clone) {
                let clonedList = dropdownList.map((a) => ({ ...a }));
                if (updateLabels.length > 0) {
                    // update label
                    updateLabels.forEach((index) => {
                        clonedList[index]['label'] = dropdownList[index]['small_count_label'];
                        if (clonedList[index]['value'].includes(5)) {
                            clonedList[index]['section'] = dropdownList[index]['small_count_section'];
                            clonedList[index]['value'] = dropdownList[index]['small_count_value'];
                        }
                    });

                    // remove duplicates
                    clonedList.forEach((option) => {
                        if (!finalList[option.label]) {
                            finalList[option.label] = option;
                        }
                    });
                    clonedList = Object.values(finalList);

                    return clonedList;
                }
            } else {
                // update label
                updateLabels.forEach((index) => {
                    dropdownList[index]['label'] = dropdownList[index]['small_count_label'];
                });

                // remove duplicates
                dropdownList.forEach((option) => {
                    if (!finalList[option.label]) {
                        finalList[option.label] = option;
                    }
                });
                dropdownList = Object.values(finalList);
            }
        }

        return dropdownList;
    }

    compareTwoLists(list1, list2) {
        // The items in the 2 lists could be out of order.
        // Because of this JSON.stringify(list1.reverse()) === JSON.stringify(list2), doesn't work for all cases
        // Hence using this approach to compare each object in list1 against all values in list2

        if (list1 && list2) {
            if (list1.length !== list2.length) {
                return false;
            }

            list1 = list1.map((option) => {
                return JSON.stringify(option);
            });

            list2 = list2.map((option) => {
                return JSON.stringify(option);
            });

            const uniqueValues = new Set([...list1, ...list2]);
            for (const v of uniqueValues) {
                const list1Count = list1.filter((e) => e === v).length;
                const list2Count = list2.filter((e) => e === v).length;
                if (list1Count !== list2Count) {
                    return false;
                }
            }

            return true;
        } else {
            return false;
        }
    }
}

export default Util;
