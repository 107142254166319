import Excel from 'components/excel/ExcelHelper.js';
import UtilHelper from 'util/UtilHelper.js';
import * as XLSX from 'xlsx';
const utilHelper = new UtilHelper();

class SeatbackLanguagesExcelHelper extends Excel {
    exportExcelCommon(
        airlineIcao,
        title,
        fileNamePrefix,
        data,
        filters,
        showComparison,
        dropdown1Options,
        dropdown2Options,
        page,
        reportType,
        workbenchType
    ) {
        let wb = XLSX.utils.book_new();

        let dataFound = false;

        dropdown1Options.forEach((dropdown1Option) => {
            try {
                let reportData = this.resolve(data, `${dropdown1Option.value}.data`);
                if (reportData) {
                    reportData = reportData.map((obj) => {
                        obj.label = obj.name;
                        return obj;
                    });

                    let valueName = dropdown1Option.label;

                    let tabTitle = dropdown1Option.label;
                    if (dropdown1Option.short_label) {
                        tabTitle = dropdown1Option.short_label;
                    }
                    if (dropdown1Option.title) {
                        title = dropdown1Option.title;
                    }

                    reportData = this.formatReportData(
                        airlineIcao,
                        reportData,
                        filters,
                        title,
                        valueName,
                        'Change (%)',
                        showComparison,
                        dropdown1Option.unit,
                        page,
                        dropdown1Option.label,
                        reportType
                    );

                    let sheet = XLSX.utils.json_to_sheet(reportData);
                    sheet['!cols'] = [
                        { wch: 35 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 }
                    ];
                    sheet = this.addLinksToFooter(reportData, sheet);
                    XLSX.utils.book_append_sheet(wb, sheet, tabTitle);
                    dataFound = true;
                }
            } catch (e) {
                console.log(e);
            }
        });

        if (!dataFound) {
            XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([]), 'Sheet1');
        }
        XLSX.writeFile(wb, `${fileNamePrefix}.xlsx`);
    }

    formatReportData(
        airlineIcao,
        data,
        filters,
        title,
        value,
        comparisionValue,
        showComparison,
        unit,
        page,
        label,
        reportType
    ) {
        try {
            let formattedData = [];

            let originalTitle = title;
            let originalValue = value;
            let originalComparisionValue = comparisionValue;

            // Hack to add rows before the Header row in XLSX
            title = 'NEXT Insights Export';
            value = ' ';
            let comparisonRowData = '  ';
            let comparisonMetricValue = '   ';
            comparisionValue = '    ';

            let langTransFromLanguage = title;
            let langTransToLanguage = ' ';
            let langTransValue = value + ' ';
            let langTransCompRowData = comparisonRowData + ' ';
            let langTransComparisonMetricValue = comparisonMetricValue + ' ';
            let langTransComparisonValue = comparisonMetricValue + ' ';
            let isTransitionSheet = label === 'Transitions';
            let isDateRangeCurrentYear = filters['date_range_text'] === 'Current Year';

            let row = {
                [title]: '',
                [value]: ' ',
                '  ': ''
            };
            formattedData.push(row);

            row = {
                [title]: 'Airline',
                [value]: airlineIcao
            };
            formattedData.push(row);

            row = {
                [title]: 'Report',
                [value]: page
            };
            formattedData.push(row);

            row = {
                [title]: 'Creation Date',
                [value]: this.currentDateTimeForExcelDownload()
            };
            formattedData.push(row);

            row = {
                [title]: 'Date Range',
                [value]: utilHelper.formatDateRange(filters.date_range)
            };
            formattedData.push(row);

            let showComparisonHeader = false;
            data.forEach((dataItem, index) => {
                if (
                    showComparison &&
                    dataItem.hasOwnProperty('comparison_value') &&
                    dataItem.hasOwnProperty('comparison_raw_value')
                ) {
                    showComparisonHeader = true;
                }
            });

            if (showComparisonHeader) {
                row = {
                    [title]: 'Comparison Range',
                    [value]: utilHelper.formatDateRange(filters.comparison_range)
                };
                formattedData.push(row);
            }

            if (filters) {
                const excludeList = ['date_range', 'dateRangeText', 'comparison_range', 'comparison_range_text', 'date_range_text'];
                const displayNames = {
                    flight_origins: 'Flight Origin',
                    flight_destinations: 'Flight Destination',
                    flight_numbers: 'Flight Number',
                    system_types: 'System',
                    aircraft_types: 'Aircraft Type',
                    flight_haul_types: 'Flight Haul',
                    seat_classes: 'Seat Class',
                    gui_languages: 'GUI Language',
                    tails: 'Tail'
                };

                for (const [key, values] of Object.entries(filters)) {
                    if (!excludeList.includes(key)) {
                        row = {
                            [title]: displayNames[key] ? displayNames[key] : key,
                            [value]: Array.isArray(values) ? values.join(', ') : values
                        };
                        formattedData.push(row);
                    }
                }
            }

            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);

            let setContectUs = '  ';
            row = {
                [title]: 'Terms Of Use',
                [value]: 'Privacy Policy',
                [setContectUs]: 'Contact Us'
            };
            formattedData.push(row);

            row = {
                [title]: 'Copyright ©2021 Panasonic Avionics Corporation. All rights reserved.',
                [value]: ''
            };
            formattedData.push(row);

            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);
            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);

            row = {
                [title]: label,
                [value]: ''
            };
            formattedData.push(row);

            if (isTransitionSheet) {
                if (showComparisonHeader) {
                    row = {
                        [langTransFromLanguage]: 'From Language',
                        [langTransToLanguage]: 'To Language',
                        [langTransValue]: `${utilHelper.formatDateRange(filters.date_range)}`,
                        [langTransCompRowData]: `${utilHelper.formatDateRange(filters.comparison_range)}`,
                        [langTransComparisonMetricValue]: originalComparisionValue
                    };
                } else {
                    row = {
                        [langTransFromLanguage]: 'From Language',
                        [langTransToLanguage]: 'To Language',
                        [langTransValue]: originalValue
                    };
                }
            } else {
                if (showComparisonHeader) {
                    row = {
                        [title]: originalTitle,
                        [value]: `${utilHelper.formatDateRange(filters.date_range)}`,
                        [comparisonRowData]: `${utilHelper.formatDateRange(filters.comparison_range)}`,
                        [comparisionValue]: originalComparisionValue
                    };
                } else {
                    row = {
                        [title]: originalTitle,
                        [value]: originalValue
                    };
                }
            }

            formattedData.push(row);

            for (let i = 0; data && i < data.length; i++) {
                let row = {
                    [title]: '',
                    [value]: ''
                };

                if (data[i].hasOwnProperty('label')) {
                    row[title] = data[i].label;

                    if (isTransitionSheet) {
                        delete row[title];
                        let fromTolanguages = data[i].label.split(' to ');
                        let fromLang = fromTolanguages[0];
                        let toLang = fromTolanguages[1];

                        row[langTransFromLanguage] = fromLang;
                        row[langTransToLanguage] = toLang;
                    }
                }

                if (data[i].hasOwnProperty('value')) {
                    let roundedData;
                    if (unit && data[i].value) {
                        if (unit === 'count') {
                            roundedData = { t: 'n', v: data[i].value, z: '#,##0' };
                        } else if (unit === 'hr') {
                            roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i].value) / 3600 / 24 };
                        }
                    }

                    if (data[i].unit && data[i].value) {
                        if (data[i].unit === 'time') {
                            roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i].value) / 3600 / 24 };
                        } else {
                            roundedData = { t: 'n', v: data[i].value, z: '#,##0' };
                        }
                    }
                    let dataValue = data[i].value;
                    let unit_pr = [
                        'Media launches by Content Type',
                        'Entertainment Usage',
                        'Passenger Touchpoints',
                        'Device Browsers',
                        'Device OS',
                        ''
                    ];
                    if (unit_pr.includes(originalTitle)) {
                        dataValue = { t: 'n', z: '0.00%', v: data[i].value / 100 };
                    }

                    let valueCol = isTransitionSheet ? langTransValue : value;

                    row[valueCol] = roundedData ? roundedData : data[i].value ? dataValue : 'No Data';
                }

                //for rounding Comparison Raw data
                if (data[i].hasOwnProperty('comparison_raw_value') && showComparisonHeader) {
                    let roundedData;
                    if (unit) {
                        if (unit === 'count') {
                            roundedData = data[i].comparison_raw_value
                                ? { t: 'n', v: data[i].comparison_raw_value, z: '#,##0' }
                                : 'No Data';
                        } else if (unit === 'hr') {
                            roundedData = {
                                t: 'n',
                                z: '[hh]:mm:ss.0',
                                v: parseFloat(data[i].comparison_raw_value) / 3600 / 24
                            };
                        }
                    }

                    if (data[i].unit && data[i].comparison_raw_value) {
                        if (data[i].unit === 'time') {
                            roundedData = {
                                t: 'n',
                                z: '[hh]:mm:ss.0',
                                v: parseFloat(data[i].comparison_raw_value) / 3600 / 24
                            };
                        } else {
                            roundedData = { t: 'n', v: data[i].comparison_raw_value, z: '#,##0' };
                        }
                    }

                    let dataValue = data[i].comparison_raw_value;
                    roundedData = roundedData ? roundedData : data[i].comparison_raw_value ? dataValue : isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';

                    if (!data[i].comparison_raw_value) {
                        roundedData = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                    }

                    let comparisonRowDataCol = isTransitionSheet ? langTransCompRowData : comparisonRowData;
                    row[comparisonRowDataCol] = roundedData;
                }

                if (showComparison && data[i].hasOwnProperty('comparison_value')) {
                    let comparisonValue = data[i].comparison_value;
                    if (utilHelper.isValidNumber(comparisonValue)) {
                        if (Math.sign(comparisonValue) === -1) {
                            comparisonValue = { t: 'n', z: '0.00%', v: comparisonValue / 100 };
                        } else if (Math.sign(comparisonValue) === 1) {
                            comparisonValue = { t: 'n', z: '0.00%', v: comparisonValue / 100 };
                        }
                    }
                    if (!comparisonValue) {
                        comparisonValue = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                    }

                    let comparisonValueCol = isTransitionSheet ? langTransComparisonValue : comparisionValue;
                    row[comparisonValueCol] = comparisonValue;
                }

                if (
                    showComparison &&
                    reportType === 'donut' &&
                    data[i].hasOwnProperty('comparison_value_metric') &&
                    filters.comparison_range
                ) {
                    let comparisonValueMetric = data[i].comparison_value_metric;
                    if (utilHelper.isValidNumber(comparisonValueMetric)) {
                        if (Math.sign(comparisonValueMetric) === -1) {
                            comparisonValueMetric = { t: 'n', z: '0.00%', v: comparisonValueMetric / 100 };
                        } else if (Math.sign(comparisonValueMetric) === 1) {
                            comparisonValueMetric = { t: 'n', z: '0.00%', v: comparisonValueMetric / 100 };
                        }
                    }
                    if (
                        !comparisonValueMetric ||
                        comparisonValueMetric === '0' ||
                        row[' '] === 'No Data' ||
                        row['   '] === 'Not Applicable'
                    ) {
                        comparisonValueMetric = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                    }
                    row[comparisonMetricValue] = comparisonValueMetric;
                }

                formattedData.push(row);
            }

            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);
            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);
            row = {
                [title]: '',
                [value]: ''
            };
            formattedData.push(row);

            return formattedData;
        } catch (err) {
            console.log(err);
        }
    }
}

export default SeatbackLanguagesExcelHelper;
