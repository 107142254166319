import React from 'react';
import { Input, Label, FormGroup, Form, Row, Col, Spinner } from 'reactstrap';
import { ButtonPrimary, ButtonSecondary } from 'pac-ux-components';
import 'assets/styles/add-edit-tail.css';
import InsightsDropdown from 'components/widgets/InsightsDropdown.js';
import AdminService from 'services/AdminService';

const tiers = [
    { value: 'Basic', label: 'Basic' },
    { value: 'Standard', label: 'Standard' }
];

const service = new AdminService();

class AddEditTail extends React.Component {
    constructor(props) {
        super(props);

        let tier, airline, selectedTier, selectedAirline;
        let tierPlaceholder = 'Select a tier';
        let displayMixedTiersNote = false;
        let tails = [];
        let apps = [];

        if (this.props.selectedTails.length > 0) {
            let basicTierTailsExist = this.props.selectedTails.some((tail) => tail.subscription_tier === 'Basic');
            let standardTierTailsExist = this.props.selectedTails.some((tail) => tail.subscription_tier === 'Standard');

            if (basicTierTailsExist && !standardTierTailsExist) {
                tier = 'Basic';
                selectedTier = { value: 'Basic', label: 'Basic' };
            } else if (standardTierTailsExist && !basicTierTailsExist) {
                tier = 'Standard';
                selectedTier = { value: 'Standard', label: 'Standard' };
            } else {
                tierPlaceholder = 'Multiple tiers selected';
                displayMixedTiersNote = true;
            }

            airline = this.props.selectedTails[0].airline_code;

            tails = this.props.selectedTails.map((tail) => {
                return tail.tail_no;
            });

            this.props.selectedTails.forEach((tail) => {
                apps = apps.concat(tail.apps);
            });

            apps = new Set(apps);

            apps = this.props.apps.map((app) => {
                if (apps.has(app)) {
                    return { name: app, checked: true };
                } else {
                    return { name: app, checked: false };
                }
            });
        } else {
            apps = this.props.apps.map((app) => {
                return { name: app, checked: false };
            });
        }

        apps = apps.map((app) => {
            if (app.name === 'Electronic Reader') {
                app.name = 'eReader';
            }
            return app;
        });

        this.state = {
            tails: tails,
            apps: apps,
            title: this.getTitle(),
            buttonText: this.getButtonText(),
            tierPlaceholder: tierPlaceholder,
            showSpinner: false,
            tier: tier,
            selectedTier: selectedTier,
            airline: airline,
            selectedAirline: selectedAirline,
            airlineName: this.getAirlineName(airline),
            displayMixedTiersNote: displayMixedTiersNote,
            inactiveLicenseSelected: false
        };

        this.setTails = this.setTails.bind(this);
        this.setApps = this.setApps.bind(this);
        this.addTails = this.addTails.bind(this);
        this.updateTailState = this.updateTailState.bind(this);
    }

    setTails(event) {
        let value = event.target.value;
        if (value.length > 0) {
            let tails = value.split(',').map((item) => item.trim());
            this.setState({ tails: tails });
        } else {
            this.setState({ tails: [] });
        }
    }

    isEditAction() {
        return this.props.actionType === 'edit';
    }

    getAirlineName(airlineCode) {
        let airlineOption = this.props.tenantOptions.find((option) => {
            if (option.value === airlineCode) {
                return true;
            } else {
                return false;
            }
        });

        if (airlineOption) {
            return airlineOption.label;
        }

        return '';
    }

    getTitle() {
        if (this.isEditAction()) {
            return 'Edit Tails(s)';
        } else {
            return 'Add Tail(s)';
        }
    }

    setApps(event) {
        let { apps } = this.state;

        let selectedApp = apps.find((app) => {
            return app.name === event.target.name;
        });

        if (event.target.checked) {
            selectedApp.checked = true;
        } else {
            selectedApp.checked = false;
        }

        this.setState({ apps: apps });
    }

    async addTails() {
        if (this.isEditAction()) {
            return this.editTails();
        }

        let { airline, tier, apps, tails } = this.state;

        apps = apps
            .filter((app) => app.checked === true)
            .map((app) => {
                return app.name;
            });

        this.setState({ showSpinner: true });

        let payload = {
            method: 'add-tails',
            airline_code: airline,
            data: {
                subscription_tier: tier,
                apps: apps,
                tails: tails
            }
        };

        let errorTails = null;
        let errorDescription = null;
        let result = null;
        try {
            result = await service.addUpdateDeleteTails(payload);
            if (result.response.error !== undefined) {
                errorTails = result.response.error.tails;
                errorDescription = result.response.error.description;
            }
        } catch (error) {
            console.log('add tail error ##');
            errorDescription = 'default';
        }

        this.setState({ showSpinner: false });

        if (errorDescription === null) {
            if (
                !(
                    result.response.data &&
                    result.response.data.updated_fields &&
                    result.response.data.updated_fields.tails
                )
            ) {
                errorDescription = 'default';
            } else {
                tails = result.response.data.updated_fields.tails;
            }
        }

        if (errorDescription !== null) {
            this.props.displayError('add', errorDescription, errorTails);
        } else {
            this.props.addTailsAction(airline, tier, apps, tails, result.response.data.updated_at);
        }
    }

    async editTails() {
        let { airline, tier, apps, tails } = this.state;

        apps = apps
            .filter((app) => app.checked === true)
            .map((app) => {
                return app.name;
            });

        this.setState({ showSpinner: true });

        let payload = {
            method: 'update-tails',
            airline_code: airline,
            data: {
                subscription_tier: tier,
                apps: apps,
                tails: tails
            }
        };

        let errorTails = null;
        let errorDescription = null;
        let result;
        try {
            result = await service.addUpdateDeleteTails(payload);
            if (result.response.error !== undefined) {
                errorTails = result.response.error.tails;
                errorDescription = result.response.error.description;
            }
        } catch (error) {
            console.log('edit tails error ##');
            errorDescription = 'default';
        }

        this.setState({ showSpinner: false });

        if (errorDescription !== null) {
            this.props.displayError('edit', errorDescription, errorTails);
        } else {
            this.props.editTailsAction(airline, tier, apps, tails, result.response.data.updated_at);
        }
    }

    getButtonText() {
        if (this.isEditAction()) {
            if (this.props.selectedTails.length > 1) {
                return `Update ${this.props.selectedTails.length} tails`;
            } else {
                return `Update tail`;
            }
        } else {
            return 'Add tail(s)';
        }
    }

    updateTailState(event, inputType, selectedProperty) {
        let { inactiveLicenses } = this.props;

        this.setState(
            {
                [inputType]: event.value,
                [selectedProperty]: { value: event.value, label: event.label }
            },
            () => {
                if (this.state.airline && this.state.tier && inactiveLicenses && inactiveLicenses.length > 0) {
                    let inactiveLicenseSelected = inactiveLicenses.some(
                        (license) =>
                            this.state.airline === license.airline_code && license.subscription_tier === this.state.tier
                    );
                    this.setState({ inactiveLicenseSelected: inactiveLicenseSelected });
                }
            }
        );
    }

    enableButton() {
        return (
            this.state['airline'] !== undefined &&
            this.state['tier'] !== undefined &&
            this.state['tails'].length > 0 &&
            this.state['apps'].some((app) => app.checked === true) &&
            !this.state['inactiveLicenseSelected']
        );
    }

    getDropdownStyles() {
        return {
            dropdownIndicator: (provided, state) => ({
                ...provided,
                fontSize: '14px'
            }),
            menu: (base) => ({
                ...base,
                fontSize: '15px'
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                    ...styles,
                    backgroundColor: isSelected ? '#ebebeb' : isFocused ? '#f6f6f6' : null,
                    color: '#333333'
                };
            },
            control: (provided, state) => ({
                ...provided,
                maxWidth: 'none',
                minHeight: 'unset !important'
            })
        };
    }

    getSelectedTailsLabel() {
        return (
            <div>
                <span style={{ fontWeight: 'bold', paddingRight: 5 }}>{this.props.selectedTails.length}</span>
                <span>{this.props.selectedTails.length > 1 ? 'tails selected:' : 'tail selected:'}</span>
            </div>
        );
    }

    render() {
        let { showSpinner, buttonText, inactiveLicenseSelected } = this.state;

        return (
            <div>
                <div className="header">{this.state.title}</div>
                <div className="form-body">
                    <Row>
                        <Col>
                            {this.isEditAction() ? (
                                <div>
                                    {this.state.displayMixedTiersNote && (
                                        <div className="notification">
                                            <div className="title">PLEASE NOTE: </div>
                                            <div className="description">
                                                The selected tails have different tiers and/or apps.
                                            </div>
                                            <div className="description">
                                                Any changes will be applied to all of the selected tails.
                                            </div>
                                        </div>
                                    )}
                                    {this.getSelectedTailsLabel()}
                                    <div className="airline">
                                        <span className="edit-label">Airline:</span>
                                        <span className="value">{this.state.airlineName}</span>
                                    </div>
                                    <div style={{ maxHeight: 206, overflowY: 'auto' }}>
                                        <table className="table table-bordered">
                                            <tbody>
                                                {this.props.selectedTails.map((tail, index) => {
                                                    return (
                                                        <tr style={{ height: 41 }} key={index}>
                                                            <td style={{ verticalAlign: 'middle' }}>{tail.tail_no}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="add-label">Airline</div>
                                    <InsightsDropdown
                                        options={this.props.tenantOptions}
                                        maxMenuHeight="370"
                                        styles={this.getDropdownStyles}
                                        onChange={(e) => this.updateTailState(e, 'airline', 'selectedAirline')}
                                        placeholder="Select an airline"
                                        name="airline"
                                        selectedOption={this.state.selectedAirline}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                    {inactiveLicenseSelected && (
                        <Row className="input-section">
                            <Col>
                                <div className="inactive-license-msg">
                                    The selected airline's license has expired. Please add an {this.props.stage} license
                                    first and then try adding a tail.
                                </div>
                            </Col>
                        </Row>
                    )}
                    {!this.isEditAction() && (
                        <Row className="input-section">
                            <Col>
                                <div className="multi-label">
                                    <span>Enter Tails - </span>
                                    <span>Please enter at least 1 tail</span>
                                </div>

                                <div className="input-sub-label">
                                    Separate each tail number with a comma (e.g. Tail 1, Tail 2, Tail 3)
                                </div>
                                <textarea
                                    rows="3"
                                    value={this.state.tails.join()}
                                    onChange={(e) => this.setTails(e)}
                                    className="form-control"
                                />
                            </Col>
                        </Row>
                    )}
                    <Row className={this.isEditAction() ? 'edit-tier-section input-section' : 'input-section'}>
                        <Col>
                            <div className="input-label">Tier</div>
                            <InsightsDropdown
                                options={tiers}
                                maxMenuHeight="370"
                                styles={this.getDropdownStyles}
                                onChange={(e) => this.updateTailState(e, 'tier', 'selectedTier')}
                                placeholder={this.state.tierPlaceholder}
                                selectedOption={this.state.selectedTier}
                            />
                        </Col>
                        <Col />
                    </Row>
                    <Row className="input-section">
                        <Col>
                            <div className="multi-label">
                                <span>Apps - </span>
                                <span>Please select at least 1 app</span>
                            </div>

                            <Form style={{ overflow: 'auto', paddingTop: 2 }}>
                                {this.state.apps.map((app, index) => {
                                    return (
                                        <FormGroup
                                            check
                                            style={{ float: 'left', width: 210 }}
                                            key={'form-group-' + index}
                                        >
                                            <Input
                                                name={app.name}
                                                type="checkbox"
                                                id={'app-' + index}
                                                onChange={this.setApps}
                                                checked={app.checked}
                                            />
                                            <Label check for={'app-' + index}>
                                                {app.name}
                                            </Label>
                                        </FormGroup>
                                    );
                                })}
                            </Form>
                        </Col>
                    </Row>
                </div>
                <div className="buttons-section">
                    <ButtonSecondary className="button-secondary" onClick={this.props.closeAction}>
                        Cancel
                    </ButtonSecondary>
                    <ButtonPrimary
                        disabled={!this.enableButton()}
                        className="button-primary margin-left-16"
                        onClick={this.addTails}
                    >
                        {showSpinner ? (
                            <div>
                                <Spinner />
                            </div>
                        ) : (
                            buttonText
                        )}
                    </ButtonPrimary>{' '}
                </div>
            </div>
        );
    }
}

export default AddEditTail;
