import Excel from 'components/excel/ExcelHelper.js';
import UtilHelper from 'util/UtilHelper.js';

const utilHelper = new UtilHelper();

class DeviceTypeExcelHelper extends Excel {
    formatReportData(
        airlineIcao,
        data,
        filters,
        title,
        value,
        comparisionValue,
        showComparison,
        unit,
        page,
        label,
        reportType
    ) {
        let formattedData = [];
        try {
            if (showComparison) {
                this.formatReportDataStandard(
                    airlineIcao,
                    data,
                    filters,
                    title,
                    value,
                    comparisionValue,
                    showComparison,
                    page,
                    label,
                    formattedData
                );
            } else {
                this.formatReportDataBasic(airlineIcao, data, filters, title, value, page, label, formattedData);
            }
        } catch (err) {
            console.log(err);
        }

        return formattedData;
    }

    formatReportDataStandard(
        airlineIcao,
        data,
        filters,
        title,
        value,
        comparisionValue,
        showComparison,
        page,
        label,
        formattedData
    ) {
        let originalTitle = title;
        let originalComparisionValue = 'Engagement ' + comparisionValue;
        let deviceBrand = 'Device Brand';
        let deviceFamily = 'Device Family';
        let launchType = 'Launch Type';
        let percentEngagement = 'Percentage engagement - Date Range';
        let percentEngagementComp = 'Percentage engagement - Preceding Date Range';
        let comparisonValueMetric = label + ' Change (%)';
        let isDateRangeCurrentYear = filters['date_range_text'] === 'Current Year';

        // Hack to add rows before the Header row in XLSX
        title = 'NEXT Insights Export';
        value = ' ';
        comparisionValue = '    ';
        let row = {
            [title]: '',
            [value]: ' ',
            '  ': '',
            '   ': '',
            '    ': '',
            '     ': '',
            '      ': '',
            '       ': ''
        };
        formattedData.push(row);

        row = {
            [title]: 'Airline',
            [value]: airlineIcao
        };
        formattedData.push(row);

        row = {
            [title]: 'Report',
            [value]: page
        };
        formattedData.push(row);

        row = {
            [title]: 'Creation Date',
            [value]: this.currentDateTimeForExcelDownload()
        };
        formattedData.push(row);

        row = {
            [title]: 'Date Range',
            [value]: utilHelper.formatDateRange(filters.date_range)
        };
        formattedData.push(row);

        row = {
            [title]: 'Comparison Range',
            [value]: utilHelper.formatDateRange(filters.comparison_range)
        };
        formattedData.push(row);

        if (filters) {
            const excludeList = [
                'date_range',
                'dateRangeText',
                'comparison_range',
                'comparison_range_text',
                'date_range_text'
            ];
            const displayNames = {
                flight_origins: 'Flight Origin',
                flight_destinations: 'Flight Destination',
                flight_numbers: 'Flight Number',
                system_types: 'System',
                aircraft_types: 'Aircraft Type',
                flight_haul_types: 'Flight Haul',
                seat_classes: 'Seat Class',
                gui_languages: 'GUI Language',
                tails: 'Tail'
            };
            for (const [key, values] of Object.entries(filters)) {
                if (!excludeList.includes(key)) {
                    row = {
                        [title]: displayNames[key] ? displayNames[key] : key,
                        [value]: Array.isArray(values) ? values.join(', ') : values
                    };
                    formattedData.push(row);
                }
            }
        }

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);

        let setContectUs = '  ';
        row = {
            [title]: 'Terms Of Use',
            [value]: 'Privacy Policy',
            [setContectUs]: 'Contact Us'
        };
        formattedData.push(row);

        row = {
            [title]: 'Copyright ©2021 Panasonic Avionics Corporation. All rights reserved.',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: label,
            [value]: ''
        };
        formattedData.push(row);

        let deviceFamilyCol = '  ';
        let launchTypeCol = '   ';
        let nominalValueCol = '    ';
        let comparisonNominalValueCol = '     ';
        let comparisonValueMetricCol = '      ';
        let percentEngagementCol = '       ';
        let percentEngagementCompCol = '        ';
        let comparisonValueCol = '         ';
        row = {
            [title]: originalTitle,
            [value]: deviceBrand,
            [deviceFamilyCol]: deviceFamily,
            [launchTypeCol]: launchType,
            [nominalValueCol]: `${utilHelper.formatDateRange(filters.date_range)}`,
            [comparisonNominalValueCol]: `${utilHelper.formatDateRange(filters.comparison_range)}`,
            [comparisonValueMetricCol]: comparisonValueMetric,
            [percentEngagementCol]: percentEngagement,
            [percentEngagementCompCol]: percentEngagementComp,
            [comparisonValueCol]: originalComparisionValue
        };
        formattedData.push(row);

        for (let i = 0; data && i < data.length; i++) {
            let row = {
                [title]: '',
                [value]: '',
                [deviceFamilyCol]: '',
                [launchTypeCol]: '',
                [nominalValueCol]: '',
                [comparisonNominalValueCol]: '',
                [comparisonValueMetricCol]: '',
                [percentEngagementCol]: '',
                [percentEngagementCompCol]: '',
                [comparisonValueCol]: ''
            };

            if (data[i].hasOwnProperty('device_type')) {
                row[title] = data[i].device_type ? data[i].device_type : 'No Data';
            }

            if (data[i].hasOwnProperty('device_brand')) {
                row[value] = data[i].device_brand ? data[i].device_brand : 'No Data';
            }

            if (data[i].hasOwnProperty('device_family')) {
                row[deviceFamilyCol] = data[i].device_family ? data[i].device_family : 'No Data';
            }

            if (data[i].hasOwnProperty('launch_type')) {
                row[launchTypeCol] = data[i].launch_type ? data[i].launch_type : 'No Data';
            }

            if (data[i].hasOwnProperty('nominal_value')) {
                row[nominalValueCol] = data[i].nominal_value
                    ? { t: 'n', v: data[i].nominal_value, z: '#,##0' }
                    : 'No Data';
            }

            if (data[i].hasOwnProperty('comparison_nominal_value')) {
                row[comparisonNominalValueCol] = data[i].comparison_nominal_value
                    ? { t: 'n', v: data[i].comparison_nominal_value, z: '#,##0' }
                    : isDateRangeCurrentYear
                    ? 'Not Applicable'
                    : 'No Data';
            }

            if (data[i].hasOwnProperty('comparison_value_metric')) {
                row[comparisonValueMetricCol] = data[i].comparison_value_metric
                    ? { t: 'n', z: '0.00%', v: data[i].comparison_value_metric / 100 }
                    : isDateRangeCurrentYear
                    ? 'Not Applicable'
                    : 'No Data';
            }

            if (data[i].hasOwnProperty('value')) {
                row[percentEngagementCol] = data[i].value
                    ? { t: 'n', z: '0.00%', v: data[i].value / 100 }
                    : isDateRangeCurrentYear
                    ? 'Not Applicable'
                    : 'No Data';
            }

            if (data[i].hasOwnProperty('comparison_raw_value')) {
                row[percentEngagementCompCol] = data[i].comparison_raw_value
                    ? { t: 'n', z: '0.00%', v: data[i].comparison_raw_value / 100 }
                    : isDateRangeCurrentYear
                    ? 'Not Applicable'
                    : 'No Data';
            }

            if (data[i].hasOwnProperty('comparison_value')) {
                row[comparisonValueCol] = data[i].comparison_value
                    ? { t: 'n', z: '0.00%', v: data[i].comparison_value / 100 }
                    : isDateRangeCurrentYear
                    ? 'Not Applicable'
                    : 'No Data';
            }

            formattedData.push(row);
        }

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
    }

    formatReportDataBasic(airlineIcao, data, filters, title, value, page, label, formattedData) {
        let originalTitle = title;
        let deviceBrand = 'Device Brand';
        let deviceFamily = 'Device Family';
        let launchType = 'Launch Type';

        // Hack to add rows before the Header row in XLSX
        title = 'NEXT Insights Export';
        value = ' ';
        let row = {
            [title]: '',
            [value]: ' ',
            '  ': '',
            '   ': '',
            '    ': '',
            '     ': '',
            '      ': '',
            '       ': ''
        };
        formattedData.push(row);

        row = {
            [title]: 'Airline',
            [value]: airlineIcao
        };
        formattedData.push(row);

        row = {
            [title]: 'Report',
            [value]: page
        };
        formattedData.push(row);

        row = {
            [title]: 'Creation Date',
            [value]: this.currentDateTimeForExcelDownload()
        };
        formattedData.push(row);

        row = {
            [title]: 'Date Range',
            [value]: utilHelper.formatDateRange(filters.date_range)
        };
        formattedData.push(row);

        if (filters) {
            const excludeList = ['date_range', 'dateRangeText', 'comparison_range', 'comparison_range_text'];
            const displayNames = {
                flight_origins: 'Flight Origin',
                flight_destinations: 'Flight Destination',
                flight_numbers: 'Flight Number',
                system_types: 'System',
                aircraft_types: 'Aircraft Type',
                flight_haul_types: 'Flight Haul',
                seat_classes: 'Seat Class',
                gui_languages: 'GUI Language',
                tails: 'Tail'
            };
            for (const [key, values] of Object.entries(filters)) {
                if (!excludeList.includes(key)) {
                    row = {
                        [title]: displayNames[key] ? displayNames[key] : key,
                        [value]: Array.isArray(values) ? values.join(', ') : values
                    };
                    formattedData.push(row);
                }
            }
        }

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);

        let setContectUs = '  ';
        row = {
            [title]: 'Terms Of Use',
            [value]: 'Privacy Policy',
            [setContectUs]: 'Contact Us'
        };
        formattedData.push(row);

        row = {
            [title]: 'Copyright ©2021 Panasonic Avionics Corporation. All rights reserved.',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: label,
            [value]: ''
        };
        formattedData.push(row);

        let deviceFamilyCol = '  ';
        let launchTypeCol = '   ';
        let nominalValueCol = '    ';

        row = {
            [title]: originalTitle,
            [value]: deviceBrand,
            [deviceFamilyCol]: deviceFamily,
            [launchTypeCol]: launchType,
            [nominalValueCol]: `${utilHelper.formatDateRange(filters.date_range)}`
        };
        formattedData.push(row);

        for (let i = 0; data && i < data.length; i++) {
            let row = {
                [title]: '',
                [value]: '',
                [deviceFamilyCol]: '',
                [launchTypeCol]: '',
                [nominalValueCol]: ''
            };

            if (data[i].hasOwnProperty('device_type')) {
                row[title] = data[i].device_type ? data[i].device_type : 'No Data';
            }

            if (data[i].hasOwnProperty('device_brand')) {
                row[value] = data[i].device_brand ? data[i].device_brand : 'No Data';
            }

            if (data[i].hasOwnProperty('device_family')) {
                row[deviceFamilyCol] = data[i].device_family ? data[i].device_family : 'No Data';
            }

            if (data[i].hasOwnProperty('launch_type')) {
                row[launchTypeCol] = data[i].launch_type ? data[i].launch_type : 'No Data';
            }

            if (data[i].hasOwnProperty('nominal_value')) {
                row[nominalValueCol] = data[i].nominal_value
                    ? { t: 'n', v: data[i].nominal_value, z: '#,##0' }
                    : 'No Data';
            }

            formattedData.push(row);
        }

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
    }
}

export default DeviceTypeExcelHelper;
