export default class MediaDetailsConfig {
    getAPIPath = function () {
        return '/v2/metrics/media_usage_data';
    };

    getChartProperties = function () {
        return {
            options: {
                legend: {
                    display: false,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {
                                display: true
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090'
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#2a7fc9',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#2a7fc9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            },
            graphStyle2: {
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#5B00A9',
                pointBackgroundColor: '#7500c5',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#5B00A9',
                pointHoverBorderColor: '#5B00A9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                fill: false,
                borderDash: [3, 3],
                borderColor: '#5B00A9'
            }
        };
    };
}
