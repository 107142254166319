import UsersService from 'services/UsersService';
import { Auth } from 'aws-amplify';
import AuthHelper from 'util/AuthHelper.js';
import * as AppConstants from 'constants/app/constants';
import Analytics from 'components/widgets/Analytics';

const authHelper = new AuthHelper();
const TENANT_OPTIONS_SESSION_KEY = 'pac_tenants';
const SELECTED_TENANT_ID_SESSION_KEY = 'pac_selected_tenant_id';
const SELECTED_TENANT_ICAO_CODE_SESSION_KEY = 'pac_selected_tenant_icao_code';
const SELECTED_TENANT_SESSION_KEY = 'pac_selected_tenant';

class TenantsService {
    static selectedTenant = '';

    static async fetchTenants() {
        let currentSession = await Auth.currentSession();

        let options = JSON.parse(window.sessionStorage.getItem(TENANT_OPTIONS_SESSION_KEY));
        if (options === null) {
            let username = currentSession.idToken.payload['cognito:username'];

            const service = new UsersService();
            let results = await service.fetchUserTenants(username);
            if (typeof results !== 'undefined') {
                options = results.Items.map((item) => {
                    return { label: item.name, value: item.id, icaoCode: item['icao-code'] };
                });
            }
        }

        let homeTenantId = currentSession.idToken.payload['pac_tenant_id'];
        let homeTenantIcao = currentSession.idToken.payload['pac_icao_code'];
        let homeTenantLabel = currentSession.idToken.payload['pac_tenant_name'];

        //@https://jira.panasonic.aero/browse/INSI-2756
        let homeTenant = options.find((item) => item['icaoCode'] === homeTenantIcao);
        if (!homeTenant && !authHelper.currentUserBelongsToOnlyPacRackTesters(currentSession)) {
            options.push({
                label: homeTenantLabel,
                value: homeTenantId,
                icaoCode: homeTenantIcao
            });
            options.sort((a, b) => (a.label > b.label ? 1 : -1));
        }

        window.sessionStorage.setItem(TENANT_OPTIONS_SESSION_KEY, JSON.stringify(options));

        let selectedTenant = null;
        if (window.sessionStorage.getItem(SELECTED_TENANT_ID_SESSION_KEY)) {
            selectedTenant = options
                .filter((option) => {
                    return option.value.toString() === window.sessionStorage.getItem(SELECTED_TENANT_ID_SESSION_KEY);
                })
                .shift();
        } else {
            if (authHelper.currentUserBelongsToOnlyPacRackTesters(currentSession)) {
                if (options.length > 0) {
                    selectedTenant = options
                        .filter((option) => {
                            return option.icaoCode.toString() === AppConstants.TENANT_ICAO_PANASONIC_AVIONICS;
                        })
                        .shift();
                }
            } else {
                selectedTenant = options
                    .filter((option) => {
                        return option.value.toString() === homeTenantId;
                    })
                    .shift();
            }
        }

        if (selectedTenant) {
            window.sessionStorage.setItem(SELECTED_TENANT_ID_SESSION_KEY, selectedTenant.value);
            window.sessionStorage.setItem(SELECTED_TENANT_SESSION_KEY, JSON.stringify(selectedTenant));
            window.sessionStorage.setItem(SELECTED_TENANT_ICAO_CODE_SESSION_KEY, selectedTenant.icaoCode);

            this.selectedTenant = selectedTenant;

            Analytics.analytics.plugins['insights'].updateTenant(selectedTenant.icaoCode, selectedTenant.label);
        }

        return options;
    }
}

export default TenantsService;
