import Excel from 'components/excel/ExcelHelper.js';
import UtilHelper from 'util/UtilHelper.js';
import * as XLSX from 'xlsx';

const utilHelper = new UtilHelper();

class DeviceOSExcelHelper extends Excel {
    formatReportData(
        airlineIcao,
        data,
        filters,
        title,
        value,
        comparisionValue,
        showComparison,
        unit,
        page,
        label,
        reportType
    ) {
        let formattedData = [];
        try {
            if (showComparison) {
                this.formatReportDataStandard(
                    airlineIcao,
                    data,
                    filters,
                    title,
                    value,
                    comparisionValue,
                    showComparison,
                    unit,
                    page,
                    label,
                    reportType,
                    formattedData
                );
            } else {
                this.formatReportDataBasic(airlineIcao, data, filters, title, value, page, label, formattedData);
            }
        } catch (err) {
            console.log(err);
        }

        return formattedData;
    }

    exportExcelCommon(
        airlineIcao,
        title,
        fileNamePrefix,
        data,
        filters,
        showComparison,
        dropdown1Options,
        dropdown2Options,
        page,
        reportType,
        workbenchType
    ) {
        let wb = XLSX.utils.book_new();

        let dataFound = false;

        dropdown1Options.forEach((dropdown1Option) => {
            try {
                let reportData = this.resolve(data, `${dropdown1Option.value}.data`);
                if (reportData) {
                    let valueName = dropdown1Option.label;
                    let tabTitle = dropdown1Option.label;

                    reportData = this.formatReportData(
                        airlineIcao,
                        reportData,
                        filters,
                        title,
                        valueName,
                        'Change (%)',
                        showComparison,
                        dropdown1Option.unit,
                        page,
                        dropdown1Option.label,
                        reportType
                    );

                    let sheet = XLSX.utils.json_to_sheet(reportData);
                    sheet['!cols'] = [
                        { wch: 35 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 },
                        { wch: 30 }
                    ];
                    sheet = this.addLinksToFooter(reportData, sheet);
                    XLSX.utils.book_append_sheet(wb, sheet, tabTitle);
                    dataFound = true;
                }
            } catch (e) {
                console.log(e);
            }
        });

        if (!dataFound) {
            XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([]), 'Sheet1');
        }
        XLSX.writeFile(wb, `${fileNamePrefix}.xlsx`);
    }

    formatReportDataStandard(
        airlineIcao,
        data,
        filters,
        title,
        value,
        comparisionValue,
        showComparison,
        unit,
        page,
        label,
        reportType,
        formattedData
    ) {
        let originalTitle = title;
        let launchType = 'Launch Type';
        let originalComparisionValue = 'Engagement ' + comparisionValue;
        let deviceOSVersion = 'Device OS version';
        let percentEngagement = 'Percentage engagement - Date Range';
        let percentEngagementComp = 'Percentage engagement - Preceding Date Range';
        let comparisonMetricValue = label + ' Change (%)';
        let isDateRangeCurrentYear = filters['date_range_text'] === 'Current Year';

        // Hack to add rows before the Header row in XLSX
        title = 'NEXT Insights Export';
        value = ' ';
        comparisionValue = '    ';
        let row = {
            [title]: '',
            [value]: ' ',
            '  ': '',
            '   ': '',
            '    ': '',
            '     ': '',
            '      ': '',
            '       ': ''
        };
        formattedData.push(row);

        row = {
            [title]: 'Airline',
            [value]: airlineIcao
        };
        formattedData.push(row);

        row = {
            [title]: 'Report',
            [value]: page
        };
        formattedData.push(row);

        row = {
            [title]: 'Creation Date',
            [value]: this.currentDateTimeForExcelDownload()
        };
        formattedData.push(row);

        row = {
            [title]: 'Date Range',
            [value]: utilHelper.formatDateRange(filters.date_range)
        };
        formattedData.push(row);

        row = {
            [title]: 'Comparison Range',
            [value]: utilHelper.formatDateRange(filters.comparison_range)
        };
        formattedData.push(row);

        if (filters) {
            const excludeList = [
                'date_range',
                'dateRangeText',
                'comparison_range',
                'comparison_range_text',
                'date_range_text'
            ];
            const displayNames = {
                flight_origins: 'Flight Origin',
                flight_destinations: 'Flight Destination',
                flight_numbers: 'Flight Number',
                system_types: 'System',
                aircraft_types: 'Aircraft Type',
                flight_haul_types: 'Flight Haul',
                seat_classes: 'Seat Class',
                gui_languages: 'GUI Language',
                tails: 'Tail'
            };
            for (const [key, values] of Object.entries(filters)) {
                if (!excludeList.includes(key)) {
                    row = {
                        [title]: displayNames[key] ? displayNames[key] : key,
                        [value]: Array.isArray(values) ? values.join(', ') : values
                    };
                    formattedData.push(row);
                }
            }
        }

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);

        let setContectUs = '  ';
        row = {
            [title]: 'Terms Of Use',
            [value]: 'Privacy Policy',
            [setContectUs]: 'Contact Us'
        };
        formattedData.push(row);

        row = {
            [title]: 'Copyright ©2021 Panasonic Avionics Corporation. All rights reserved.',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: label,
            [value]: ''
        };
        formattedData.push(row);

        if (label === 'Device Count by OS version') {
            let launchTypeCol = '  ';
            let nominalValueCol = '   ';
            let nominalValueCompCol = '    ';
            let comparisonMetricValueCol = '     ';
            let percentEngagementCol = '      ';
            let percentEngagementCompCol = '       ';
            let comparisonValueCol = '        ';
            row = {
                [title]: originalTitle,
                [value]: deviceOSVersion,
                [launchTypeCol]: launchType,
                [nominalValueCol]: `${utilHelper.formatDateRange(filters.date_range)}`,
                [nominalValueCompCol]: `${utilHelper.formatDateRange(filters.comparison_range)}`,
                [comparisonMetricValueCol]: comparisonMetricValue,
                [percentEngagementCol]: percentEngagement,
                [percentEngagementCompCol]: percentEngagementComp,
                [comparisonValueCol]: originalComparisionValue
            };
            formattedData.push(row);

            for (let i = 0; data && i < data.length; i++) {
                let row = {
                    [title]: '',
                    [value]: '',
                    [launchTypeCol]: '',
                    [nominalValueCol]: '',
                    [nominalValueCompCol]: '',
                    [comparisonMetricValueCol]: '',
                    [percentEngagementCol]: '',
                    [percentEngagementCompCol]: '',
                    [comparisonValueCol]: ''
                };

                if (data[i].hasOwnProperty('label')) {
                    row[title] = data[i].label ? data[i].label : 'No Data';
                }

                if (data[i].hasOwnProperty('os_version')) {
                    row[value] = data[i].os_version ? data[i].os_version : 'No Data';
                }

                if (data[i].hasOwnProperty('launch_type')) {
                    row[launchTypeCol] = data[i].launch_type ? data[i].launch_type : 'No Data';
                }

                if (data[i].hasOwnProperty('nominal_value')) {
                    row[nominalValueCol] = data[i].nominal_value
                        ? { t: 'n', v: data[i].nominal_value, z: '#,##0' }
                        : 'No Data';
                }

                if (data[i].hasOwnProperty('comparison_nominal_value')) {
                    row[nominalValueCompCol] = data[i].comparison_nominal_value
                        ? { t: 'n', v: data[i].comparison_nominal_value, z: '#,##0' }
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                }

                if (data[i].hasOwnProperty('comparison_value_metric')) {
                    row[comparisonMetricValueCol] = data[i].comparison_value_metric
                        ? { t: 'n', z: '0.00%', v: data[i].comparison_value_metric / 100 }
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                }

                if (data[i].hasOwnProperty('value')) {
                    row[percentEngagementCol] = data[i].value
                        ? { t: 'n', z: '0.00%', v: data[i].value / 100 }
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                }

                if (data[i].hasOwnProperty('comparison_raw_value')) {
                    row[percentEngagementCompCol] = data[i].comparison_raw_value
                        ? { t: 'n', z: '0.00%', v: data[i].comparison_raw_value / 100 }
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                }

                if (data[i].hasOwnProperty('comparison_value')) {
                    row[comparisonValueCol] = data[i].comparison_value
                        ? { t: 'n', z: '0.00%', v: data[i].comparison_value / 100 }
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                }

                formattedData.push(row);
            }
        } else {
            let launchTypeCol = ' ';
            let nominalValueCol = '  ';
            let nominalValueCompCol = '   ';
            let comparisonMetricValueCol = '    ';
            let percentEngagementCol = '     ';
            let percentEngagementCompCol = '      ';
            let comparisonValueCol = '       ';
            row = {
                [title]: originalTitle,
                [launchTypeCol]: launchType,
                [nominalValueCol]: `${utilHelper.formatDateRange(filters.date_range)}`,
                [nominalValueCompCol]: `${utilHelper.formatDateRange(filters.comparison_range)}`,
                [comparisonMetricValueCol]: comparisonMetricValue,
                [percentEngagementCol]: percentEngagement,
                [percentEngagementCompCol]: percentEngagementComp,
                [comparisonValueCol]: originalComparisionValue
            };
            formattedData.push(row);

            for (let i = 0; data && i < data.length; i++) {
                let row = {
                    [title]: originalTitle,
                    [launchTypeCol]: launchType,
                    [nominalValueCol]: `${utilHelper.formatDateRange(filters.date_range)}`,
                    [nominalValueCompCol]: `${utilHelper.formatDateRange(filters.comparison_range)}`,
                    [comparisonMetricValueCol]: comparisonMetricValue,
                    [percentEngagementCol]: percentEngagement,
                    [percentEngagementCompCol]: percentEngagementComp,
                    [comparisonValueCol]: originalComparisionValue
                };

                if (data[i].hasOwnProperty('label')) {
                    row[title] = data[i].label;
                }

                if (data[i].hasOwnProperty('launch_type')) {
                    row[launchTypeCol] = data[i].launch_type ? data[i].launch_type : 'No Data';
                }

                if (data[i].hasOwnProperty('nominal_value')) {
                    row[nominalValueCol] = data[i].nominal_value
                        ? { t: 'n', v: data[i].nominal_value, z: '#,##0' }
                        : 'No Data';
                }

                if (data[i].hasOwnProperty('comparison_nominal_value')) {
                    row[nominalValueCompCol] = data[i].comparison_nominal_value
                        ? { t: 'n', v: data[i].comparison_nominal_value, z: '#,##0' }
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                }

                if (data[i].hasOwnProperty('value')) {
                    row[percentEngagementCol] = data[i].value
                        ? { t: 'n', z: '0.00%', v: data[i].value / 100 }
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                }

                if (data[i].hasOwnProperty('comparison_value_metric')) {
                    row[comparisonMetricValueCol] = data[i].comparison_value_metric
                        ? { t: 'n', z: '0.00%', v: data[i].comparison_value_metric / 100 }
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                }

                if (data[i].hasOwnProperty('comparison_raw_value')) {
                    row[percentEngagementCompCol] = data[i].comparison_raw_value
                        ? { t: 'n', z: '0.00%', v: data[i].comparison_raw_value / 100 }
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                }

                if (data[i].hasOwnProperty('comparison_value')) {
                    row[comparisonValueCol] = data[i].comparison_value
                        ? { t: 'n', z: '0.00%', v: data[i].comparison_value / 100 }
                        : isDateRangeCurrentYear
                        ? 'Not Applicable'
                        : 'No Data';
                }

                formattedData.push(row);
            }
        }

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
    }

    formatReportDataBasic(airlineIcao, data, filters, title, value, page, label, formattedData) {
        let originalTitle = title;
        let deviceOSVersion = 'Device OS version';
        let launchType = 'Launch Type';

        // Hack to add rows before the Header row in XLSX
        title = 'NEXT Insights Export';
        value = ' ';
        let row = {
            [title]: '',
            [value]: ' ',
            '  ': '',
            '   ': '',
            '    ': '',
            '     ': '',
            '      ': '',
            '       ': ''
        };
        formattedData.push(row);

        row = {
            [title]: 'Airline',
            [value]: airlineIcao
        };
        formattedData.push(row);

        row = {
            [title]: 'Report',
            [value]: page
        };
        formattedData.push(row);

        row = {
            [title]: 'Creation Date',
            [value]: this.currentDateTimeForExcelDownload()
        };
        formattedData.push(row);

        row = {
            [title]: 'Date Range',
            [value]: utilHelper.formatDateRange(filters.date_range)
        };
        formattedData.push(row);

        if (filters) {
            const excludeList = ['date_range', 'dateRangeText', 'comparison_range', 'comparison_range_text'];
            const displayNames = {
                flight_origins: 'Flight Origin',
                flight_destinations: 'Flight Destination',
                flight_numbers: 'Flight Number',
                system_types: 'System',
                aircraft_types: 'Aircraft Type',
                flight_haul_types: 'Flight Haul',
                seat_classes: 'Seat Class',
                gui_languages: 'GUI Language',
                tails: 'Tail'
            };
            for (const [key, values] of Object.entries(filters)) {
                if (!excludeList.includes(key)) {
                    row = {
                        [title]: displayNames[key] ? displayNames[key] : key,
                        [value]: Array.isArray(values) ? values.join(', ') : values
                    };
                    formattedData.push(row);
                }
            }
        }

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);

        let setContectUs = '  ';
        row = {
            [title]: 'Terms Of Use',
            [value]: 'Privacy Policy',
            [setContectUs]: 'Contact Us'
        };
        formattedData.push(row);

        row = {
            [title]: 'Copyright ©2021 Panasonic Avionics Corporation. All rights reserved.',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);

        row = {
            [title]: label,
            [value]: ''
        };
        formattedData.push(row);

        if (label === 'Device Count by OS version') {
            let nominalValueCol = '  ';
            let launchTypeCol = '   ';

            row = {
                [title]: originalTitle,
                [value]: deviceOSVersion,
                [launchTypeCol]: launchType,
                [nominalValueCol]: `${utilHelper.formatDateRange(filters.date_range)}`
            };
            formattedData.push(row);

            for (let i = 0; data && i < data.length; i++) {
                let row = {
                    [title]: '',
                    [value]: '',
                    [nominalValueCol]: '',
                    [launchTypeCol]: ''
                };

                if (data[i].hasOwnProperty('label')) {
                    row[title] = data[i].label ? data[i].label : 'No Data';
                }

                if (data[i].hasOwnProperty('os_version')) {
                    row[value] = data[i].os_version ? data[i].os_version : 'No Data';
                }

                if (data[i].hasOwnProperty('launch_type')) {
                    row[value] = data[i].launch_type ? data[i].launch_type : 'No Data';
                }

                if (data[i].hasOwnProperty('nominal_value')) {
                    row[nominalValueCol] = data[i].nominal_value
                        ? { t: 'n', v: data[i].nominal_value, z: '#,##0' }
                        : 'No Data';
                }

                formattedData.push(row);
            }
        } else {
            let launchTypeCol = ' ';
            let nominalValueCol = '  ';
            row = {
                [title]: originalTitle,
                [launchTypeCol]: launchType,
                [nominalValueCol]: `${utilHelper.formatDateRange(filters.date_range)}`
            };
            formattedData.push(row);

            for (let i = 0; data && i < data.length; i++) {
                let row = {
                    [title]: originalTitle,
                    [launchTypeCol]: launchType,
                    [nominalValueCol]: `${utilHelper.formatDateRange(filters.date_range)}`
                };

                if (data[i].hasOwnProperty('label')) {
                    row[title] = data[i].label;
                }

                if (data[i].hasOwnProperty('launch_type')) {
                    row[launchTypeCol] = data[i].launch_type ? data[i].launch_type : 'No Data';
                }

                if (data[i].hasOwnProperty('nominal_value')) {
                    row[nominalValueCol] = data[i].nominal_value
                        ? { t: 'n', v: data[i].nominal_value, z: '#,##0' }
                        : 'No Data';
                }

                formattedData.push(row);
            }
        }

        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
        row = {
            [title]: '',
            [value]: ''
        };
        formattedData.push(row);
    }
}

export default DeviceOSExcelHelper;
