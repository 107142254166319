import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Main from 'pages/Main.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-free/css/all.css';
import { faArrowUp, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/styles/index.scss';
import './assets/styles/widgets/carousel.css';
import './assets/styles/widgets/coverage.css';
import './assets/styles/widgets/horizontalBarChart.css';
import './assets/styles/widgets/widget.css';
import './assets/styles/widgets/dropDown.css';
import './assets/styles/widgets/donut.css';
import './assets/styles/widgets/pageTabs.css';
import './assets/styles/widgets/dynamicFilter.css';

library.add(faArrowUp, faAngleRight);

class App extends React.Component {
    render() {
        return (
            <div>
                <Route path="/app" component={Main} {...this.props} />
                <Route
                    exact
                    path="/"
                    render={(props) => {
                        return <Redirect to={{ pathname: '/app' }} />;
                    }}
                    {...this.props}
                />
            </div>
        );
    }
}

export default App;
