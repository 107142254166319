import React from 'react';

import moment from 'moment';
import { debounce } from 'lodash';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import UtilHelper from 'util/UtilHelper';
import PageTabs from 'components/widgets/PageTabs';
import License from 'components/widgets/admin/License';
import Tail from 'components/widgets/admin/Tail';
import * as AppConstants from 'constants/app/constants';
import AdminService from 'services/AdminService';
import AdminExcelHelper from 'components/excel/AdminExcelHelper';

import Circular from 'assets/images/icons/circular.gif';

const adminService = new AdminService();
const utilHelper = new UtilHelper();
const adminExcelHelper = new AdminExcelHelper();

const ADMIN_GROUP_NAME = 'app_admin_865cec22-e8d5-41d4-9fe2-ba86587aa5c7';

const headerTabs = ['Active Licenses', 'Inactive Licenses', 'Tails'];

class AdminOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTabIndex: 0,
            tailsAirlineOptions: [],
            licensesAirlineOptions: [],
            airlinesLoading: true,
            licensesAirlinesLoading: true,
            licensesLoading: true,
            appsLoading: true,
            activeLicenses: [],
            inactiveLicenses: [],
            hasAdminAccess: false,
            excelLoading: false
        };

        this.cancelAirlineRequestSource = null;

        this.throttleTailsAirlineFetch = debounce(
            this.throttleTailsAirlineFetch.bind(this),
            AppConstants.THROTTLE_FETCH_TIME
        );
        this.throttleLicensesAirlineFetch = debounce(
            this.throttleLicensesAirlineFetch.bind(this),
            AppConstants.THROTTLE_FETCH_TIME
        );
        this.throttleLicenseFetch = debounce(this.throttleLicenseFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
        this.throttleAppFetch = debounce(this.throttleAppFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);

        this.pageTabsRef = React.createRef();

        this.addLicenseAction = this.addLicenseAction.bind(this);
        this.endLicenseAction = this.endLicenseAction.bind(this);
        this.reactivateLicenseAction = this.reactivateLicenseAction.bind(this);

        this.downloadAdminExcel = this.downloadAdminExcel.bind(this);
    }

    async componentDidMount() {
        await this.setAdminAccess();
        await this.fetchAirlineData();
        await this.fetchLicenseData();
        await this.fetchAppData();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.stage !== prevProps.stage) {
            await this.fetchAirlineData();
            await this.fetchLicenseData();
            await this.fetchAppData();
        }
    }

    async setAdminAccess() {
        const currentSession = await Auth.currentSession();
        const groups = currentSession.idToken.payload['cognito:groups'];
        console.log('debug - setAdminAccess = groups = ' + JSON.stringify(groups));
        if (typeof groups !== 'undefined') {
            for (let i = 0; i < groups.length; i++) {
                if (groups[i] === ADMIN_GROUP_NAME) {
                    this.setState({ hasAdminAccess: true });
                }
            }
        }
    }

    async fetchAirlineData() {
        this.setState({ licensesAirlinesLoading: true });

        if (this.cancelAirlineRequestSource) {
            this.cancelAirlineRequestSource.cancel('Operation canceled by the user.');
        }

        await this.throttleTailsAirlineFetch();
        await this.throttleLicensesAirlineFetch();
    }

    async throttleTailsAirlineFetch() {
        const CancelToken = axios.CancelToken;
        this.cancelAirlineRequestSource = CancelToken.source();

        await adminService
            .fetchAirlines({ stage: this.props.stage }, this.cancelAirlineRequestSource.token)
            .then((response) => {
                if (utilHelper.resolve(response, `data.airlines`) && Object.keys(response.data.airlines).length > 0) {
                    let airlineOptions = response.data.airlines.map((item) => {
                        return {
                            label: item.airline_name ? item.airline_name : item.airline_code,
                            value: item.airline_code
                        };
                    });

                    this.setState({
                        error: false,
                        tailsAirlineOptions: airlineOptions,
                        airlinesLoading: false
                    });
                } else {
                    this.setState({ error: true, tailsAirlineOptions: [], airlinesLoading: false });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, tailsAirlineOptions: [], airlinesLoading: true });
                } else {
                    this.setState({ error: true, tailsAirlineOptions: [], airlinesLoading: false });
                }
            });
    }

    async throttleLicensesAirlineFetch() {
        const CancelToken = axios.CancelToken;
        this.cancelAirlineRequestSource = CancelToken.source();

        await adminService
            .fetchAirlines({}, this.cancelAirlineRequestSource.token)
            .then((response) => {
                if (utilHelper.resolve(response, `data.airlines`) && Object.keys(response.data.airlines).length > 0) {
                    let airlineOptions = response.data.airlines.map((item) => {
                        return {
                            label: item.airline_name ? item.airline_name : item.airline_code,
                            value: item.airline_code
                        };
                    });

                    this.setState({
                        error: false,
                        licensesAirlineOptions: airlineOptions,
                        licensesAirlinesLoading: false
                    });
                } else {
                    this.setState({ error: true, licensesAirlineOptions: [], licensesAirlinesLoading: false });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, licensesAirlineOptions: [], licensesAirlinesLoading: true });
                } else {
                    this.setState({ error: true, licensesAirlineOptions: [], licensesAirlinesLoading: false });
                }
            });
    }

    async fetchLicenseData(appliedFilters) {
        this.setState({ licensesLoading: true });

        if (this.cancelLicenseRequestSource) {
            this.cancelLicenseRequestSource.cancel('Operation canceled by the user.');
        }

        await this.throttleLicenseFetch();
    }

    async throttleLicenseFetch() {
        const CancelToken = axios.CancelToken;
        this.cancelLicenseRequestSource = CancelToken.source();

        let response = await adminService.fetchLicenses(
            { stage: this.props.stage },
            this.cancelLicenseRequestSource.token
        );

        if (response.error) {
            this.setState({ error: true, inactiveLicenses: [], activeLicenses: [], licensesLoading: false });
        } else {
            this.setState({
                inactiveLicenses: response.inactiveLicenses,
                activeLicenses: response.activeLicenses,
                error: false,
                licensesLoading: false
            });
        }
    }

    async fetchAppData(appliedFilters) {
        this.setState({ appsLoading: true });

        if (this.cancelLicenseRequestSource) {
            this.cancelLicenseRequestSource.cancel('Operation canceled by the user.');
        }

        await this.throttleAppFetch();
    }

    async throttleAppFetch() {
        const CancelToken = axios.CancelToken;
        this.cancelLicenseRequestSource = CancelToken.source();

        let response = await adminService.fetchApps(this.cancelLicenseRequestSource.token);

        if (response.error) {
            this.setState({ error: true, apps: [], appsLoading: false });
        } else {
            this.setState({
                apps: response.apps,
                error: false,
                appsLoading: false
            });
        }
    }

    async downloadAdminExcel() {
        let tailsData = [];
        const CancelToken = axios.CancelToken;
        const option = ['Active Licenses', 'Inactive Licenses', 'Tails'][this.state.activeTabIndex];
        this.cancelTailRequestSource = CancelToken.source();

        let licensedAirlines = [];

        for (let airline of this.state.activeLicenses) {
            licensedAirlines.push(airline.airline_code);
        }

        for (let airline of this.state.inactiveLicenses) {
            licensedAirlines.push(airline.airline_code);
        }

        if (option != 'Tails') {
            adminExcelHelper.exportExcel(
                this.state.activeLicenses,
                this.state.inactiveLicenses,
                tailsData,
                this.state.apps,
                this.props.stage,
                option
            );
        } else {
            this.setState({ excelLoading: true }, () =>
                adminService
                    .downloadAdminExcel(
                        {
                            airlines: licensedAirlines
                        },
                        this.cancelTailRequestSource.token,
                        this.props.stage
                    )
                    .then((response) => {
                        if (!response) {
                            this.setState({ error: true, tails: [], tailsLoading: false, excelLoading: false });
                        } else {
                            let preSignedUrl = response.body;
                            try {
                                axios.get(preSignedUrl).then((rawDataResponse) => {
                                    tailsData = rawDataResponse.data;
                                    adminExcelHelper.exportExcel(
                                        this.state.activeLicenses,
                                        this.state.inactiveLicenses,
                                        tailsData,
                                        this.state.apps,
                                        this.props.stage,
                                        option
                                    );
                                    this.setState({ excelLoading: false });
                                });
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    })
                    .catch((error) => {
                        if (error && error.error && error.error === 'Operation canceled by the user.') {
                            this.setState({ error: false, tails: [], tailsLoading: true, excelLoading: false });
                        } else {
                            this.setState({ error: true, tails: [], tailsLoading: false, excelLoading: false });
                        }
                    })
            );
        }
    }

    changeTab = (tabIndex) => {
        this.setState({ activeTabIndex: tabIndex });

        if (this.pageTabsRef.current) {
            this.pageTabsRef.current.setActive(tabIndex);
        }
    };

    render() {
        let {
            activeTabIndex,
            tailsAirlineOptions,
            licensesAirlineOptions,
            licensesAirlinesLoading,
            inactiveLicenses,
            activeLicenses,
            licensesLoading,
            appsLoading,
            apps
        } = this.state;

        return (
            <div className="admin-start">
                <div>
                    <PageTabs
                        tabs={headerTabs}
                        tabFunction={this.changeTab}
                        ref={this.pageTabsRef}
                        path={this.props.path}
                    />

                    <div
                        style={{
                            display: activeTabIndex === 0 ? 'block' : 'none'
                        }}
                    >
                        {licensesLoading ? (
                            <div className="card" style={{ marginLeft: -15 }}>
                                <div className="license-container loading">
                                    <div>
                                        <img src={Circular} alt="" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <License
                                    active={true}
                                    stage={this.props.stage}
                                    airlineOptions={licensesAirlineOptions}
                                    licenses={activeLicenses}
                                    endLicenseAction={this.endLicenseAction}
                                    addLicenseAction={this.addLicenseAction}
                                    hasAdminAccess={this.state.hasAdminAccess}
                                    downloadAdminExcel={this.downloadAdminExcel}
                                />
                            </div>
                        )}
                    </div>

                    <div
                        style={{
                            display: activeTabIndex === 1 ? 'block' : 'none'
                        }}
                    >
                        {licensesLoading ? (
                            <div className="card" style={{ marginLeft: -15 }}>
                                <div className="license-container loading">
                                    <div>
                                        <img src={Circular} alt="" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <License
                                    active={false}
                                    stage={this.props.stage}
                                    airlineOptions={licensesAirlineOptions}
                                    licenses={inactiveLicenses}
                                    reactivateLicenseAction={this.reactivateLicenseAction}
                                    hasAdminAccess={this.state.hasAdminAccess}
                                    downloadAdminExcel={this.downloadAdminExcel}
                                />
                            </div>
                        )}
                    </div>

                    <div style={{ display: activeTabIndex === 2 ? 'block' : 'none' }}>
                        {appsLoading ? (
                            <div className="card" style={{ marginLeft: -15 }}>
                                <div className="license-container loading">
                                    <div>
                                        <img src={Circular} alt="" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Tail
                                stage={this.props.stage}
                                airlineOptions={licensesAirlineOptions}
                                activeAirlineOptions={tailsAirlineOptions}
                                airlinesLoading={licensesAirlinesLoading}
                                inactiveLicenses={inactiveLicenses}
                                apps={apps}
                                hasAdminAccess={this.state.hasAdminAccess}
                                downloadAdminExcel={this.downloadAdminExcel}
                                excelLoading={this.state.excelLoading}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    addLicenseAction(airlineCode, tier) {
        this.throttleTailsAirlineFetch();
    }

    endLicenseAction(airlineCode, tier, updatedAt) {
        this.setState({ licensesLoading: true });

        let selectedLicense;
        let activeLicenses = this.state.activeLicenses.filter((license) => {
            if (license.airline_code === airlineCode && license.subscription_tier === tier) {
                selectedLicense = license;
                return false;
            } else {
                return true;
            }
        });

        selectedLicense.is_active = false;
        selectedLicense.expiration_unix = moment().unix();
        selectedLicense.expiration_display = moment().format('MMM DD, YYYY');
        selectedLicense.last_updated_at = moment(updatedAt).unix();
        selectedLicense.last_updated_display = updatedAt;

        let inactiveLicenses = this.state.inactiveLicenses;
        inactiveLicenses.push(selectedLicense);

        this.setState({ licensesLoading: false, inactiveLicenses: inactiveLicenses, activeLicenses: activeLicenses });

        this.throttleTailsAirlineFetch();
    }

    reactivateLicenseAction(airlineCode, tier, stage, startDate, expiration, updatedAt) {
        this.setState({ licensesLoading: true });

        let selectedLicense;
        let inactiveLicenses = this.state.inactiveLicenses.filter((license) => {
            if (license.airline_code === airlineCode && license.subscription_tier === tier) {
                selectedLicense = license;
                return false;
            } else {
                return true;
            }
        });

        selectedLicense.is_active = true;
        selectedLicense.stage = stage;
        selectedLicense.start_unix = moment(startDate, 'YYYYMMDD').unix();
        selectedLicense.start_display = moment(startDate, 'YYYYMMDD').format('MMM DD, YYYY');
        selectedLicense.license_start_date = startDate;
        selectedLicense.last_updated_at = moment(updatedAt).unix();
        selectedLicense.last_updated_display = updatedAt;

        let expirationMoment = moment(expiration, 'YYYYMMDD');
        selectedLicense.expiration_unix = expirationMoment.unix();
        selectedLicense.expiration_display = expirationMoment.format('MMM DD, YYYY');

        let activeLicenses = this.state.activeLicenses;
        activeLicenses.push(selectedLicense);

        this.setState({ licensesLoading: false, inactiveLicenses: inactiveLicenses, activeLicenses: activeLicenses });

        this.throttleTailsAirlineFetch();
    }
}

export default AdminOverview;
