import React from 'react';
import Util from 'util/UtilHelper';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'assets/styles/widgets/lopa.scss';
import chevronLeftImage from 'assets/images/icons/chevronLeft.svg';
import chevronRightImage from 'assets/images/icons/chevronRight.svg';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import SearchIcon from 'assets/images/icons/searchIcon.svg';
import sampleData from 'components/lopa/lopa_sample_data.js';
import Analytics from 'components/widgets/Analytics';
import LopaFlightView from 'components/lopa/LopaFlightView';

const utilHelper = new Util();

class Lopa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: sampleData,
            flightList: [],
            activeRow: 0,
            searchTerm: '',
            wizardStep: 1,
            loadingFlights: false,
            flightStartDate: null,
            loadingSeats: false,
            flight: '',
            date: '',
            flightStartTime: '',
            route: '',
            flightDuration: '',
            aircraftType: '',
            tail: '',
            activeCalendarStartDate: null,
            showFlightView: false,
            selectedRow: 0
        };

        this.handleChangeFlightAction = this.handleChangeFlightAction.bind(this);
    }

    componentWillUpdate(newProps, newState) {
        let icon = document.getElementById('disclaimer-icon');
        if (icon) {
            if (newState.wizardStep == 4) {
                icon.style.display = 'initial';
            } else {
                icon.style.display = 'none';
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.startDate !== nextProps.startDate || this.props.endDate !== nextProps.endDate) {
            this.setState({
                activeCalendarStartDate: ''
            });
        }
        if (this.state.date && this.props != nextProps) {
            this.setState({
                data: sampleData,
                wizardStep: 2
            });
        }
    }

    formatRoute(route) {
        if (route) {
            return route.split('-').join(' - ');
        }
        return route;
    }

    formatDate(date, type) {
        var weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
        return weekdays[date.getDay()];
    }

    async setDate(date) {
        var months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        this.setState({
            date: months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear(),
            loadingFlights: true,
            searchTerm: '',
            flightStartDate: date,
            flightStartDateHeader: moment(date).format('ddd, M/D/YY'),
            flightList: []
        });

        let flightList = await this.props.getFlights(date);

        this.setState({
            flightList: flightList,
            wizardStep: 3,
            loadingFlights: false,
            date: months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear(),
            flightStartDate: date,
            flightStartDateHeader: moment(date).format('ddd, M/D/YY')
        });
    }

    async setFlight(flight, i) {
        let months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        this.setState({
            flight: flight.flight_number,
            flightStartTime: flight.flight_start_time,
            route: flight.route,
            flightDuration: flight.flight_duration,
            aircraftType: flight.aircraft_type,
            tail: flight.aircraft_tail_number,
            selectedRow: i
        });

        this.setState({
            loadingSeats: true
        });
        let lopaData = await this.props.getFlightData(flight, this.state.flightStartDate);

        let flightDate =
            months[this.state.flightStartDate.getMonth()] +
            ' ' +
            this.state.flightStartDate.getDate() +
            ', ' +
            this.state.flightStartDate.getFullYear();

        if (this.state.date) {
            if (Object.keys(lopaData).length > 0) {
                this.props.setFlightInfo({
                    startTime: flight.flight_start_time,
                    date: flightDate,
                    flightNumber: flight.flight_number,
                    route: this.formatRoute(flight.route),
                    flightDuration: utilHelper.secondsToHoursMinutes(flight.flight_duration),
                    aircraftType: flight.aircraft_type,
                    tail: flight.aircraft_tail_number
                });
            } else {
                lopaData = sampleData;
            }
            this.setState({
                searchTerm: '',
                wizardStep: 4,
                loadingSeats: false,
                data: lopaData,
                showFlightView: true
            });
        } else {
            this.setState({
                searchTerm: '',
                wizardStep: 1,
                loadingSeats: false,
                data: sampleData
            });
        }
    }

    setSearchTerm = (event) => {
        this.setState({
            searchTerm: event.target.value
        });
    };

    handleChangeFlightAction() {
        this.setState({ wizardStep: 3, data: sampleData });
    }

    render() {
        var startDate = this.props.startDate;
        var endDate = this.props.endDate;

        const formatDateToString = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}${month}${day}`;
        };

        const hasNoData = ({ date, view }) => {
            if (view === 'month' && this.props.validDates) {
                return !this.props.validDates.includes(formatDateToString(date));
            }

            return false;
        };

        return (
            <>
                {this.state.showFlightView && (
                    <LopaFlightView
                        flight={this.state.flight}
                        route={this.state.route}
                        flightDuration={this.state.flightDuration}
                        flightStartDateHeader={this.state.flightStartDateHeader}
                        flightStartTime={this.state.flightStartTime}
                        aircraftType={this.state.aircraftType}
                        title={this.props.title}
                        data={this.state.data}
                        showChangeSeatInfoType={this.props.showChangeSeatInfoType}
                        handleChangeFlightAction={this.handleChangeFlightAction}
                        wizardStep={this.state.wizardStep}
                    />
                )}

                <div
                    style={{
                        display:
                            this.state.wizardStep == 4 && _.isEqual(sampleData, this.state.data) ? 'initial' : 'none',
                        width: '100%',
                        position: 'absolute',
                        top: 30,
                        height: 'calc(100% - 30px)'
                    }}
                >
                    <div
                        style={{
                            pointerEvents: 'none',
                            height: '100%',
                            background: '#FFFFFF80',
                            backdropFilter: 'blur(8px)',
                            zIndex: 1,
                            position: 'absolute',
                            top: 0,
                            width: '100%'
                        }}
                    ></div>
                    <span className="no-data" style={{ position: 'absolute', zIndex: 2, marginTop: '21%', top: '0px' }}>
                        Data is not available
                    </span>
                </div>

                <div
                    className="lopa-wizard"
                    style={{
                        display: this.state.wizardStep != 4 ? 'initial' : 'none'
                    }}
                >
                    <div
                        className="lopa-wizard-popup"
                        style={{
                            display: this.state.wizardStep === 1 ? 'initial' : 'none'
                        }}
                    >
                        View seat usage for an individual flight
                        <div
                            className="lopa-wizard-button"
                            onClick={() => {
                                this.setState({ wizardStep: 2 });

                                Analytics.analytics.track('selectFlightClick', {
                                    visual_name: this.props.title,
                                    interaction_type: 'Button Click',
                                    interaction_value: 'Select a Flight'
                                });
                            }}
                        >
                            Select a Flight
                        </div>
                    </div>

                    <div
                        className="lopa-wizard-flight"
                        style={{
                            display: this.state.wizardStep === 2 || this.state.wizardStep === 3 ? 'initial' : 'none'
                        }}
                    >
                        <div className="lopa-wizard-label">Select a date and flight:</div>
                        <div
                            className="lopa-wizard-cancel-label"
                            onClick={() => {
                                this.setState(
                                    {
                                        wizardStep: _.isEqual(sampleData, this.state.data) ? 1 : 4,
                                        searchTerm: '',
                                        data: sampleData,
                                        flightList: [],
                                        loadingSeats: false,
                                        date: ''
                                    },
                                    () => {
                                        this.props.clearFlightData();
                                    }
                                );
                            }}
                        >
                            Cancel
                        </div>

                        {this.state.wizardStep > 1 && (
                            <div style={{ pointerEvents: this.state.loadingSeats ? 'none' : 'all' }}>
                                <Calendar
                                    className="lopa-wizard-flight-calendar"
                                    tileClassName="lopa-wizard-flight-calendar-tile"
                                    onClickDay={(value, event) => {
                                        this.setDate(value);

                                        Analytics.analytics.track('selectFlightClick', {
                                            visual_name: this.props.title,
                                            interaction_type: 'Calendar Click',
                                            interaction_value: value.toISOString()
                                        });
                                    }}
                                    calendarType="US"
                                    minDetail="month"
                                    minDate={startDate}
                                    activeStartDate={
                                        this.state.activeCalendarStartDate
                                            ? this.state.activeCalendarStartDate
                                            : endDate
                                    }
                                    onActiveStartDateChange={({ activeStartDate }) =>
                                        this.setState({ activeCalendarStartDate: activeStartDate })
                                    }
                                    maxDate={endDate}
                                    formatShortWeekday={(locale, date) => this.formatDate(date, 'dd')}
                                    prevLabel={<img src={chevronLeftImage} />}
                                    nextLabel={<img src={chevronRightImage} />}
                                    tileDisabled={hasNoData}
                                    validDates={this.props.validDates}
                                />
                            </div>
                        )}

                        <div className="lopa-flight-picker-right">
                            <div className="lopa-flight-list-wrapper">
                                {this.state.flightList.length > 0 && (
                                    <>
                                        <div className="lopa-flight-list-date">{this.state.date}</div>
                                        <div className="lopa-flight-list-search">
                                            <input
                                                className="lopa-flight-list-search-text"
                                                value={this.state.searchTerm}
                                                placeholder="Search by flight #"
                                                onChange={this.setSearchTerm}
                                            />
                                            <img src={SearchIcon} className="lopa-flight-list-search-icon" />
                                        </div>
                                        <div className="lopa-flight-list-header flight">Flight #</div>
                                        <div className="lopa-flight-list-header departure">Departure (UTC)</div>
                                    </>
                                )}
                                <div className="lopa-flight-list">
                                    {this.state.loadingFlights ? (
                                        <div className="lopa-loading-flights">Loading Flights ...</div>
                                    ) : this.state.flightList.length > 0 ? (
                                        <table className="lopa-flight-list-table">
                                            <tbody>
                                                {this.state.flightList.map((e, i) => {
                                                    if (
                                                        e.flight_number
                                                            .toLowerCase()
                                                            .includes(this.state.searchTerm.toLowerCase())
                                                    ) {
                                                        return (
                                                            <tr
                                                                key={e.flight_number + i}
                                                                className={
                                                                    !this.state.loadingSeats &&
                                                                    this.state.activeRow == i
                                                                        ? 'lopa-flight-list-table-row active'
                                                                        : 'lopa-flight-list-table-row'
                                                                }
                                                                onMouseOver={() => this.setState({ activeRow: i })}
                                                            >
                                                                <td
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        width: '111px',
                                                                        paddingLeft: '20px',
                                                                        color:
                                                                            this.state.loadingSeats &&
                                                                            this.state.flight != e.flight_number
                                                                                ? 'gray'
                                                                                : ''
                                                                    }}
                                                                >
                                                                    {e.flight_number}{' '}
                                                                    <div className="route">
                                                                        {this.formatRoute(e.route)}
                                                                    </div>{' '}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: '120px',
                                                                        color:
                                                                            this.state.loadingSeats &&
                                                                            this.state.flight != e.flight_number
                                                                                ? 'gray'
                                                                                : '',
                                                                        fontWeight:
                                                                            this.state.loadingSeats &&
                                                                            this.state.flight == e.flight_number &&
                                                                            this.state.selectedRow == i
                                                                                ? 'bold'
                                                                                : ''
                                                                    }}
                                                                >
                                                                    {e.flight_start_time}
                                                                </td>
                                                                <td>
                                                                    {this.state.wizardStep &&
                                                                    !this.state.loadingSeats ? (
                                                                        <div
                                                                            className={
                                                                                this.state.activeRow == i
                                                                                    ? 'lopa-flight-select active'
                                                                                    : 'lopa-flight-select'
                                                                            }
                                                                            onClick={() => {
                                                                                this.setFlight(e, i);

                                                                                Analytics.analytics.track(
                                                                                    'viewSeatUsageClick',
                                                                                    {
                                                                                        visual_name: this.props.title,
                                                                                        interaction_type:
                                                                                            'Button Click',
                                                                                        interaction_value:
                                                                                            'View Seat Usage'
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            View Seat Usage
                                                                        </div>
                                                                    ) : (
                                                                        this.state.loadingSeats &&
                                                                        this.state.selectedRow == i && (
                                                                            <div
                                                                                className={clsx({
                                                                                    'loading-flight': true
                                                                                })}
                                                                                style={{
                                                                                    marginLeft: '50px',
                                                                                    fontWeight:
                                                                                        this.state.loadingSeats &&
                                                                                        this.state.flight ==
                                                                                            e.flight_number
                                                                                            ? 'bold'
                                                                                            : ''
                                                                                }}
                                                                            >
                                                                                Loading...
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {!this.state.flightList.length > 0 && !this.state.loadingFlights && (
                                    <div className="lopa-no-flights-found">
                                        <span>No flights found for</span>
                                        <span className="no-flight-date">{this.state.date}</span>
                                        <span className="select-another"> &#8592; Please select another date</span>
                                    </div>
                                )}
                            </div>
                            <div
                                className="lopa-flight-list-placeholder"
                                style={{
                                    display: this.state.wizardStep === 2 ? 'initial' : 'none'
                                }}
                            >
                                {this.state.loadingFlights ? 'Loading Flights ...' : '← Select a date to view flights'}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Lopa;
