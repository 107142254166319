import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import UtilHelper from 'util/UtilHelper.js';

const utilHelper = new UtilHelper();

function invertColor(hex) {
    if (hex) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }

        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }

        var r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);

        // https://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }

    return '#FFFFFF';
}

const HorizontalBarChart = (props) => {
    let data;

    let items = [];
    let dataAvailable = false;
    let chartData;
    let blurred = false;

    if (props && props.data && props.reportType === 'events') {
        data = JSON.parse(JSON.stringify(props.data));
        dataAvailable = true;
    } else if (props && props.data && props.data.data && props.data.data.length > 0) {
        data = JSON.parse(JSON.stringify(props.data.data)); // @todo @important - updating container "data" props on "splice"
        dataAvailable = true;
    }
    if (dataAvailable) {
        // Change all the null label to unknown.
        for (let i = 0; i < data.length; i++) {
            if (data[i].label == null) {
                //data[i].label = 'Unknown';
                data[i].label = 'null';
            }
        }

        let allZeroValues = true;

        for (let i = 0; i < data.length; i++) {
            if (data[i].value !== '0' && data[i].value !== null) {
                allZeroValues = false;
                break;
            }
        }

        if (allZeroValues) {
            data = [
                { label: 'Drama', value: '57970', comparison_value: '32.6' },
                { label: 'Action-Adventure', value: '38220', comparison_value: '5.2' },
                { label: 'Comedy', value: '29570', comparison_value: '-21.5' },
                { label: 'Kids', value: '23459', comparison_value: '0' },
                { label: 'Thriller', value: '19543', comparison_value: '10' },
                { label: 'Sports', value: '16943', comparison_value: '20.5' },
                { label: 'Movies Extra', value: '12567', comparison_value: '10.5' },
                { label: 'Sci-Fi', value: '9187', comparison_value: '-1.5' },
                { label: 'Documentary', value: '5267', comparison_value: '100' },
                { label: 'Romance', value: '722', comparison_value: '-1000' }
            ];
            dataAvailable = true;
            blurred = true;
        }
    } else {
        data = [
            { label: 'Drama', value: '57970', comparison_value: '32.6' },
            { label: 'Action-Adventure', value: '38220', comparison_value: '5.2' },
            { label: 'Comedy', value: '29570', comparison_value: '-21.5' },
            { label: 'Kids', value: '23459', comparison_value: '0' },
            { label: 'Thriller', value: '19543', comparison_value: '10' },
            { label: 'Sports', value: '16943', comparison_value: '20.5' },
            { label: 'Movies Extra', value: '12567', comparison_value: '10.5' },
            { label: 'Sci-Fi', value: '9187', comparison_value: '-1.5' },
            { label: 'Documentary', value: '5267', comparison_value: '100' },
            { label: 'Romance', value: '722', comparison_value: '-1000' }
        ];
        dataAvailable = true;
        blurred = true;
    }

    if (dataAvailable) {
        let maxRows = Math.min(10, data.length); //May not be needed if only correct amount of data is passed in

        var max = 0;
        chartData = data.splice(0, maxRows); // @todo @info - updating container "data" props on "splice"

        //determine max line length
        for (let j = 0; j < chartData.length; j++) {
            if (parseInt(chartData[j]['value'], 10) > max) {
                max = chartData[j]['value'];
            }
        }

        let savePrev;

        for (let i = 0; i < chartData.length; i++) {
            let roundedData = chartData[i]['value'];
            if (props.unit === 'hr') {
                roundedData = utilHelper.secondsToHmsForDynamicTabs(roundedData);
            } else if (props.unit === 'count') {
                roundedData = utilHelper.formatToUnitsForPillBox(roundedData);
            }
            //Set min percentage to 5 if too low so bar is still visible
            let per = (chartData[i]['value'] / max) * 100;
            let percentage = per < 5 && per > 0 ? 5 : per > 5 ? per : 0;
            let usagePercentage = chartData[i].total_pct === 0 ? '<0.1%' : chartData[i].total_pct + '%';

            let changeColor =
                chartData[i]['comparison_value'] > 0 || chartData[i]['comparison_value'] > 999
                    ? '#225522'
                    : chartData[i]['comparison_value'] < 0
                    ? '#FF0000'
                    : '#9E9E9E';

            let title = null;
            if (props.reportType && props.reportType === 'pages') {
                title = (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip className="chart-tooltip">{chartData[i]['label']}</Tooltip>}
                    >
                        <td className="item-title">
                            {utilHelper.transformTextfromStart(
                                chartData[i]['label'],
                                props.showWorkbenchLink ? 20 : 30
                            )}
                        </td>
                    </OverlayTrigger>
                );
            } else {
                let titleLabel = chartData[i]['label'];
                if (chartData[i]['label'].includes('|') && props.reportType && props.reportType === 'categories') {
                    let categories = chartData[i]['label'].split('|');
                    titleLabel = categories[categories.length - 1];
                }

                let labelFieldName = 'label';
                if (props.reportType && props.reportType === 'languages' && props.page && props.page === 'seatback') {
                    titleLabel = chartData[i]['name'];
                    labelFieldName = 'name';
                }

                title = (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip className="chart-tooltip">{chartData[i][labelFieldName]}</Tooltip>}
                    >
                        <td className="item-title">{titleLabel}</td>
                    </OverlayTrigger>
                );
            }

            let comparisionData =
                chartData[i]['comparison_value'] == null
                    ? 'N/A'
                    : chartData[i]['comparison_value'] > 999
                    ? '999+%'
                    : chartData[i]['comparison_value'] < -999
                    ? '999+%'
                    : chartData[i]['comparison_value'] > 100
                    ? `${Math.abs(Math.round(chartData[i]['comparison_value']))}%`
                    : `${Math.abs(chartData[i]['comparison_value'])}%`;

            //Less is better then +1 Lengh to take data inside the bar, tried 1.1 it takes data outside  could do .85 if needed in future
            let topBottom = undefined;
            if (
                chartData[i - 1] &&
                chartData[i] &&
                chartData[i - 1]['value'] &&
                parseInt(chartData[i - 1]['value'], 10) > parseInt(chartData[i]['value'], 10)
            ) {
                topBottom = true;
            }
            if (
                chartData[i - 1] &&
                chartData[i] &&
                chartData[i - 1]['value'] &&
                parseInt(chartData[i - 1]['value'], 10) < parseInt(chartData[i]['value'], 10)
            ) {
                topBottom = false;
            }

            let isInner =
                percentage <= 5
                    ? false
                    : topBottom && savePrev === false
                    ? savePrev
                    : topBottom === false && savePrev
                    ? savePrev
                    : (roundedData.length + 2) * 5 > percentage
                    ? false
                    : true; // 1 character = 4.5%
            savePrev = isInner;

            if (props.routes && !blurred) {
                var letters = [];
                for (var l = 0; l < chartData[i]['label'].length; l++) {
                    var letter = chartData[i]['label'][l];
                    if (letter === '-') {
                        letters.push(
                            <div className="route-character dash" key={'dash-_' + l}>
                                {letter}
                            </div>
                        );
                    } else {
                        letters.push(
                            <div className="route-character" key={'dash_' + l}>
                                {letter}
                            </div>
                        );
                    }
                }
                title = (
                    <td className="item-title">
                        <div className="route-character-break left" />
                        <div className="route-character-break right" />
                        {letters}
                    </td>
                );
            }
            if (props.showWorkbenchLink && props.appliedFilters && props.appliedFilters.comparison_range_text) {
                let arrowClass =
                    chartData[i]['comparison_value'] > 0
                        ? 'item-change-arrow up'
                        : chartData[i]['comparison_value'] < 0
                        ? 'item-change-arrow down'
                        : 'item-change-arrow';
                let changeArrow =
                    chartData[i]['comparison_value'] > 0 ? (
                        '\u25B2'
                    ) : chartData[i]['comparison_value'] < 0 ? (
                        '\u25BC'
                    ) : (
                        <i className="fas fa-sort" style={{ fontSize: 16, color: 'gray' }}></i>
                    );

                items.push(
                    <tr className="horizontal-bar-row" key={props.type + i} id={Math.random()}>
                        {title}
                        <td className={arrowClass}>{changeArrow}</td>

                        <td className="item-change-number" style={{ color: changeColor }}>
                            {comparisionData}
                        </td>
                        <td className="item-bar">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip className="chart-tooltip">
                                        {props.text}: {usagePercentage}
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: 'flex' }}>
                                    <div
                                        className="bar-chart-rect"
                                        style={{
                                            width: percentage + '%',
                                            backgroundColor: props.color,
                                            color: isInner && invertColor(props.color)
                                        }}
                                    >
                                        <span className="bar-chart-rect-inner-text">{isInner ? roundedData : ''}</span>
                                    </div>
                                    <span className="bar-chart-rect-inner-text">{!isInner ? roundedData : ''}</span>
                                </div>
                            </OverlayTrigger>
                        </td>
                    </tr>
                );
            } else {
                let isMediaCycle =
                    props.showWorkbenchLink && props.appliedFilters && 'media_cycle' in props.appliedFilters;
                items.push(
                    <tr className="horizontal-bar-row" key={props.type + i} id={Math.random()}>
                        {title}
                        <td className="item-bar">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip className="chart-tooltip">
                                        {props.text}: {isMediaCycle ? usagePercentage : roundedData}
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: 'flex' }}>
                                    <div
                                        className="bar-chart-rect"
                                        style={{
                                            width: percentage + '%',
                                            backgroundColor: props.color,
                                            color: isInner && invertColor(props.color)
                                        }}
                                    >
                                        <span className="bar-chart-rect-inner-text">{isInner ? roundedData : ''}</span>
                                    </div>
                                    <span className="bar-chart-rect-inner-text">{!isInner ? roundedData : ''}</span>
                                </div>
                            </OverlayTrigger>
                        </td>
                    </tr>
                );
            }
        }
    }

    return (
        <div className="horizontal-bar-chart-wrapper">
            <div>
                <div className={blurred ? 'data-wrapper blurred' : 'data-wrapper'}>
                    <table className="horizontal-bar">
                        <tbody>{items}</tbody>
                    </table>
                </div>
                <div className="no-data" style={{ display: !blurred ? 'none' : 'initial' }}>
                    Data is not available
                </div>
            </div>
        </div>
    );
};

export default HorizontalBarChart;
