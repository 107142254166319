import React from 'react';
import { Row } from 'reactstrap';
import TrendContainer from 'components/trends/TrendContainer';
import DonutContainer from 'components/donut/DonutContainer';
import HorizontalBarChartContainer from 'components/horizontal-bar-chart/HorizontalBarChartContainer';
import VerticalBarChartContainer from 'components/vertical-bar-chart/VerticalBarChartContainer';
import AccessControl from 'components/widgets/AccessControl';
import TableContainer from 'components/table/TableContainer';
import SubscriptionService from 'services/SubscriptionService';
import _ from 'lodash';

let page = 'companion-app';
let displayPageName = 'Companion App';

class CompanionAppOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ipsEnabled: false,
            ipsColumns: [
                { value: 'tail_no', header: 'Tail Number' },
                { value: 'flight_number', header: 'Flight Number' },
                { value: 'flight_start_date', header: 'Flight Start Date', type: 'date' },
                { value: 'flight_end_date', header: 'Flight End Date', type: 'date' },
                { value: 'flight_duration_secs', header: 'Flight Duration', type: 'time' },
                { value: 'aircraft_type_subtype', header: 'Aircraft Type/Sub-type' },
                { value: 'system_type', header: 'System Type' },
                { value: 'dep_airport_iata', header: 'Flight Origin' },
                { value: 'arr_airport_iata', header: 'Flight Destination' },
                { value: 'seat_class', header: 'Seat Class' },
                { value: 'seatnum', header: 'Seat Number' },
                { value: 'payload_code', header: 'Payload Code' },
                { value: 'payload_event', header: 'Payload Event' }
            ]
        };
    }

    async componentDidUpdate(prevProps, prevState) {
        try {
            if (!this.state.ipsEnabled) {
                let subscriptionData = await SubscriptionService.fetchSubscription();
                let tier = subscriptionData.tiers.find(
                    (tier) => tier['subscription-level'] === SubscriptionService.subscription()
                );
                if (tier.apps.includes('IPS')) {
                    this.setState({
                        ipsEnabled: true
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        let dashboard = (
            <div>
                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    usageSource={displayPageName}
                    forceReloadAll={this.props.forceReloadAll}
                    title="Trends - Media Launches"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="Companion_App_overview_trend_1"
                    id="Companion_App_overview_trend_1"
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />

                <Row>
                    {this.props.showWorkbench && (
                        <DonutContainer
                            path={this.props.path}
                            appliedFilters={this.props.appliedFilters}
                            renderType=""
                            donut_value="passenger_engagement"
                            page={page}
                            pageName={displayPageName}
                            forceReloadAll={this.props.forceReloadAll}
                            showWorkbenchLink={this.props.showWorkbench}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            allowedApps={this.props.allowedApps}
                            app={this.props.app}
                        />
                    )}

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        title="Routes"
                        workbenchTitle="routes"
                        page={page}
                        pageName={displayPageName}
                        reportType="routes"
                        color="#A1C9EB"
                        routes={true}
                        key="companion_app_overview_routes_1"
                        id="companion_app_overview_routes_1"
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>

                <AccessControl page={page} metrics={[]} sections={['passenger_interaction_timeline']}>
                    <Row>
                        <VerticalBarChartContainer
                            path={this.props.path}
                            appliedFilters={this.props.appliedFilters}
                            usageSource={displayPageName}
                            pageName={displayPageName}
                            forceReloadAll={this.props.forceReloadAll}
                            showWorkbenchLink={this.props.showWorkbench}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            title={'Passenger Interaction Timeline'}
                            page={page}
                            app={this.props.app}
                        />
                    </Row>
                </AccessControl>

                {this.state.ipsEnabled && (
                    <Row>
                        <TableContainer
                            pageName={displayPageName}
                            data={this.props.data}
                            dataset={this.props.dataset}
                            loading={this.props.loading}
                            appliedFilters={this.props.appliedFilters}
                            title="Login Details"
                            app={this.props.app}
                            columns={this.state.ipsColumns}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            page={page}
                            workbenchTitle="table"
                        />
                    </Row>
                )}
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default CompanionAppOverview;
