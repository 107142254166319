import React from 'react';
import { Row } from 'reactstrap';
import TrendContainer from 'components/trends/TrendContainer';
import DonutContainer from 'components/donut/DonutContainer';
import HorizontalBarChartContainer from 'components/horizontal-bar-chart/HorizontalBarChartContainer';
import ScreenTransitionContainer from 'components/screen-transition/ScreenTransitionContainer';
import VerticalBarChartContainer from 'components/vertical-bar-chart/VerticalBarChartContainer';
import AccessControl from 'components/widgets/AccessControl';

let page = 'portal';
let displayPageName = 'Portal';

class PortalOverview extends React.Component {
    render() {
        let dashboard = (
            <div>
                <TrendContainer
                    path={this.props.path}
                    appliedFilters={this.props.appliedFilters}
                    usageSource="Portal"
                    forceReloadAll={this.props.forceReloadAll}
                    title="Trends"
                    workbenchTitle="trends"
                    page={page}
                    pageName={displayPageName}
                    key="portal_overview_trend_1"
                    id="portal_overview_trend_1"
                    showWorkbenchLink={this.props.showWorkbench}
                    tenantIcaoCode={this.props.tenantIcaoCode}
                    app={this.props.app}
                />

                <Row>
                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        renderType=""
                        donut_value="portal_device_browser"
                        page={page}
                        pageName={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />

                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        renderType=""
                        donut_value="portal_device_os"
                        page={page}
                        pageName={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>

                <Row>
                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        renderType=""
                        donut_value="passenger_engagement"
                        page={page}
                        pageName={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        allowedApps={this.props.allowedApps}
                        app={this.props.app}
                    />

                    <DonutContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        renderType=""
                        donut_value="portal_device_type"
                        page={page}
                        pageName={displayPageName}
                        forceReloadAll={this.props.forceReloadAll}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>
                <Row>
                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource="Portal"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Portal Browsing Pages"
                        workbenchTitle="portal_browsing_pages"
                        page={page}
                        pageName={displayPageName}
                        reportType="pages"
                        color="#2BCAB7"
                        key="portal_overview_portal_browsing_pages_1"
                        id="portal_overview_portal_browsing_pages_1"
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />

                    <HorizontalBarChartContainer
                        path={this.props.path}
                        appliedFilters={this.props.appliedFilters}
                        usageSource="Portal"
                        forceReloadAll={this.props.forceReloadAll}
                        title="Routes"
                        workbenchTitle="routes"
                        page={page}
                        pageName={displayPageName}
                        reportType="routes"
                        color="#A1C9EB"
                        routes={true}
                        key="portal_overview_routes_1"
                        id="portal_overview_routes_1"
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>
                <Row>
                    <ScreenTransitionContainer
                        path={this.props.path}
                        usageSource={displayPageName}
                        pageName={displayPageName}
                        page={page}
                        forceReloadAll={this.props.forceReloadAll}
                        id={'portal_overview_screen_transition'}
                        title={'Portal Screen Transitions'}
                        appliedFilters={this.props.appliedFilters}
                        showWorkbenchLink={this.props.showWorkbench}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        app={this.props.app}
                    />
                </Row>

                <AccessControl page={page} metrics={[]} sections={['passenger_interaction_timeline']}>
                    <Row>
                        <VerticalBarChartContainer
                            path={this.props.path}
                            appliedFilters={this.props.appliedFilters}
                            usageSource={displayPageName}
                            pageName={displayPageName}
                            forceReloadAll={this.props.forceReloadAll}
                            showWorkbenchLink={this.props.showWorkbench}
                            tenantIcaoCode={this.props.tenantIcaoCode}
                            title={'Passenger Interaction Timeline'}
                            page={page}
                            app={this.props.app}
                        />
                    </Row>
                </AccessControl>
            </div>
        );

        return <div>{dashboard}</div>;
    }
}

export default PortalOverview;
