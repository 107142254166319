import Excel from 'components/excel/ExcelHelper.js';
import UtilHelper from 'util/UtilHelper.js';
import * as XLSX from 'xlsx';

const utilHelper = new UtilHelper();

class ProgramTitlesExcelHelper extends Excel {
    exportExcelCommon(
        airlineIcao,
        title,
        fileNamePrefix,
        data,
        filters,
        showComparison,
        dropdown1Options,
        dropdown2Options,
        page,
        reportType,
        workbenchType
    ) {
        let wb = XLSX.utils.book_new();

        let dataFound = false;

        data = data['program_titles']['all_records'];
        if (dropdown1Options && Object.keys(dropdown1Options).length > 0) {
            dropdown1Options.forEach((dropdown1Option) => {
                try {
                    let consolidatedData = [];

                    for (let i = 0; i < data[dropdown1Option.data].data.length; i++) {
                        consolidatedData[i] = {};

                        let curr = data[dropdown1Option.data].data[i];
                        consolidatedData[i] = {};
                        consolidatedData[i]['label'] = curr.label;
                        consolidatedData[i]['channel'] = curr.channel;
                        consolidatedData[i]['comparison_raw_value'] = curr.comparison_raw_value;
                        consolidatedData[i]['comparison_value'] = curr.comparison_value;
                        consolidatedData[i][dropdown1Option.data] = curr.value;

                        for (const key in data) {
                            if (key === dropdown1Option.data) {
                                continue;
                            }

                            const dataArr = data[key].data;
                            let matchedData = dataArr.find((row) => row.label === curr.label);
                            if (matchedData) {
                                consolidatedData[i][key] = matchedData.value;
                            } else {
                                console.log('matched data not found');
                            }
                        }
                    }

                    let reportData = consolidatedData;
                    if (reportData) {
                        let tableColumnTitles = [];
                        let tabTitle = dropdown1Option.tab_title;

                        tableColumnTitles.push('Program Rankings');
                        tableColumnTitles.push('Channel Title');

                        if (showComparison) {
                            tableColumnTitles.push(
                                dropdown1Option.label + ' (Date Range)',
                                dropdown1Option.label + ' (Comparison Range)',
                                dropdown1Option.label + ' Change (%)'
                            );
                        } else {
                            tableColumnTitles.push(dropdown1Option.label + ' (Date Range)');
                        }

                        let allTierMetrics = [
                            'Flight Count',
                            'Unique Passenger Count',
                            'Total Views',
                            'Total Viewing Time'
                        ];

                        dropdown1Option.additional_metrics.forEach((addMetric) => {
                            if (showComparison || allTierMetrics.includes(addMetric.title)) {
                                tableColumnTitles.push(addMetric.title + ' (Date Range)');
                            }
                        });

                        reportData = this.formatReportDataForTitles(
                            airlineIcao,
                            reportData,
                            filters,
                            tableColumnTitles,
                            dropdown1Option,
                            page,
                            reportType,
                            !showComparison
                        );

                        let sheet = XLSX.utils.json_to_sheet(reportData);
                        sheet['!cols'] = [
                            { wch: 35 },
                            { wch: 30 },
                            { wch: 30 },
                            { wch: 30 },
                            { wch: 30 },
                            { wch: 30 },
                            { wch: 30 },
                            { wch: 30 },
                            { wch: 30 },
                            { wch: 30 },
                            { wch: 30 }
                        ];
                        sheet = this.addLinksToFooter(reportData, sheet);
                        XLSX.utils.book_append_sheet(wb, sheet, tabTitle);
                        dataFound = true;
                    }
                } catch (e) {
                    console.log(e);
                }
            });
        } else {
            data = this.formatReportData(
                airlineIcao,
                data,
                filters,
                title,
                'Value',
                'Change (%)',
                showComparison,
                '',
                page
            );

            let sheet = XLSX.utils.json_to_sheet(data);
            sheet['!cols'] = [{ wch: 35 }, { wch: 30 }, { wch: 30 }, { wch: 30 }];
            sheet = this.addLinksToFooter(data, sheet);
            XLSX.utils.book_append_sheet(wb, sheet, title);
            dataFound = true;
        }

        if (!dataFound) {
            XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([]), 'Sheet1');
        }
        XLSX.writeFile(wb, `${fileNamePrefix}.xlsx`);
    }

    formatReportDataForTitles(
        airlineIcao,
        data,
        filters,
        tableColumnTitles,
        dropdown1Option,
        page,
        reportType,
        isBasicTier
    ) {
        try {
            let formattedData = [];

            // Hack to add rows before the Header row in XLSX
            let column1 = 'NEXT Insights Export';
            let column2 = ' ';
            let column3 = '  ';
            let isDateRangeCurrentYear = filters['date_range_text'] === 'Current Year';

            this.populateSummaryRowsData(
                formattedData,
                airlineIcao,
                filters,
                page,
                column1,
                column2,
                column3,
                isBasicTier
            );

            this.populateTableHeaderRowsData(tableColumnTitles, column1, column2, column3, formattedData);

            if (isBasicTier) {
                this.populateBasicTierTableBodyRowsData(
                    column1,
                    column2,
                    column3,
                    dropdown1Option,
                    data,
                    reportType,
                    tableColumnTitles,
                    formattedData
                );
            } else {
                this.populateStandardTierTableBodyRowsData(
                    column1,
                    column2,
                    column3,
                    dropdown1Option,
                    data,
                    reportType,
                    tableColumnTitles,
                    formattedData,
                    isDateRangeCurrentYear
                );
            }

            //empty rows for spacing
            for (let i = 0; i < 3; i++) {
                let row = {
                    [column1]: '',
                    [column2]: ''
                };
                formattedData.push(row);
            }

            return formattedData;
        } catch (err) {
            console.log(err);
        }
    }

    populateStandardTierTableBodyRowsData(
        column1,
        column2,
        column3,
        dropdown1Option,
        data,
        reportType,
        tableColumnTitles,
        formattedData,
        isDateRangeCurrentYear
    ) {
        let metrics = [];
        let column4 = '   ';
        let column5 = '    ';

        metrics.push(
            {
                col: column3,
                metric: dropdown1Option.data,
                unit: dropdown1Option.unit
            },
            {
                col: column4,
                metric: dropdown1Option.comparison_data_1,
                unit: dropdown1Option.unit
            },
            {
                col: column5,
                metric: dropdown1Option.comparison_data_2,
                unit: dropdown1Option.unit
            }
        );

        for (let i = 0; i < dropdown1Option.additional_metrics.length; i++) {
            let column = ' '.repeat(i + 5);
            let addMetric = dropdown1Option.additional_metrics[i];
            metrics.push({ col: column, metric: addMetric.data, unit: addMetric.unit });
        }

        //table rows
        for (let i = 0; data && i < data.length; i++) {
            let row = {
                [column1]: '',
                [column2]: '',
                [column3]: '',
                [column4]: ''
            };

            if (data[i].hasOwnProperty('label')) {
                row[column1] = data[i].label;
            }
            if (data[i].hasOwnProperty('channel')) {
                row[column2] = data[i].channel;
            }

            metrics.forEach((metricObj) => {
                let { col, metric, unit } = metricObj;

                if (data[i].hasOwnProperty(metric)) {
                    if (metric === 'comparison_value') {
                        let comparisonValue = data[i][metric];
                        if (utilHelper.isValidNumber(comparisonValue)) {
                            if (Math.sign(comparisonValue) === -1) {
                                comparisonValue = { t: 'n', z: '0.00%', v: data[i][metric] / 100 };
                            } else if (Math.sign(comparisonValue) === 1) {
                                comparisonValue = { t: 'n', z: '0.00%', v: data[i][metric] / 100 };
                            }
                        }
                        if (!comparisonValue) {
                            comparisonValue = isDateRangeCurrentYear ? 'Not Applicable' : 'No Data';
                        }
                        row[col] = comparisonValue;
                    } else {
                        let roundedData;
                        if (unit && data[i][metric]) {
                            if (unit === 'count') {
                                roundedData = { t: 'n', v: data[i][metric], z: '#,##0' };
                            } else if (unit === 'hr' || unit === 'hr,min' || unit === 'hr,min,sec') {
                                roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i][metric]) / 3600 / 24 };
                            }
                        }

                        if (data[i].unit && data[i][metric]) {
                            if (data[i].unit === 'time') {
                                roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i][metric]) / 3600 / 24 };
                            } else {
                                roundedData = { t: 'n', z: '#,##0', v: data[i][metric] };
                            }
                        }

                        let dataValue = data[i][metric];

                        row[col] = roundedData ? roundedData : data[i][metric] ? dataValue : 'No Data';
                    }
                }
            });

            formattedData.push(row);
        }
    }

    populateBasicTierTableBodyRowsData(
        column1,
        column2,
        column3,
        dropdown1Option,
        data,
        reportType,
        tableColumnTitles,
        formattedData
    ) {
        let metrics = [];
        let column4 = '   ';
        metrics.push({
            col: column3,
            metric: dropdown1Option.data,
            unit: dropdown1Option.unit
        });

        let addMetrics = dropdown1Option.additional_metrics.filter((addMetric) => addMetric.free_tier);

        for (let i = 0; i < addMetrics.length; i++) {
            let column = ' '.repeat(i + 3);

            let addMetric = addMetrics[i];
            metrics.push({ col: column, metric: addMetric.data, unit: addMetric.unit });
        }

        //table rows
        for (let i = 0; data && i < data.length; i++) {
            let row = {
                [column1]: '',
                [column2]: '',
                [column3]: '',
                [column4]: ''
            };

            if (data[i].hasOwnProperty('label')) {
                row[column1] = data[i].label;
            }
            if (data[i].hasOwnProperty('channel')) {
                row[column2] = data[i].channel;
            }

            metrics.forEach((metricObj) => {
                let { col, metric, unit } = metricObj;

                if (data[i].hasOwnProperty(metric)) {
                    let roundedData;
                    if (unit && data[i][metric]) {
                        if (unit === 'count') {
                            roundedData = { t: 'n', v: data[i][metric], z: '#,##0' };
                        } else if (unit === 'hr' || unit === 'hr,min' || unit === 'hr,min,sec') {
                            roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i][metric]) / 3600 / 24 };
                        }
                    }

                    if (data[i].unit && data[i][metric]) {
                        if (data[i].unit === 'time') {
                            roundedData = { t: 'n', z: '[hh]:mm:ss.0', v: parseFloat(data[i][metric]) / 3600 / 24 };
                        } else {
                            roundedData = { t: 'n', z: '#,##0', v: data[i][metric] };
                        }
                    }

                    let dataValue = data[i][metric];

                    row[col] = roundedData ? roundedData : data[i][metric] ? dataValue : 'No Data';
                }
            });

            formattedData.push(row);
        }
    }
}

export default ProgramTitlesExcelHelper;
