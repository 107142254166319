import React from 'react';
import axios from 'axios';
import PillBox from 'components/media-details/PillBox';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import MediaDetailsConfig from 'components/media-details/MediaDetailsConfig';
import LineChart from 'components/media-details/LineChart';
import AudioPlaceholderImage from 'assets/images/posters/audio.svg';
import GamesPlaceholderImage from 'assets/images/posters/games.svg';
import LiveTVPlaceholderImage from 'assets/images/posters/livetv.svg';
import MoviesPlaceholderImage from 'assets/images/posters/movies.svg';
import TVSeriesPlaceholderImage from 'assets/images/posters/tvseries.svg';
import EReaderPlaceholderImage from 'assets/images/posters/ereader.svg';
import Circular from 'assets/images/icons/circular.gif';
import AccessControl from 'components/widgets/AccessControl';
import UtilHelper from 'util/UtilHelper.js';
import SubscriptionService from 'services/SubscriptionService';

const utilHelper = new UtilHelper();
const mediaDetailsConfig = new MediaDetailsConfig();

function invertColor(hex) {
    if (hex) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }

        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }

        var r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);

        // https://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }

    return '#FFFFFF';
}

class MediaDetailsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            dataset: null,
            options: null,
            dataAvailable: true,
            error: false,
            loading: true,
            comparisonDataFlag: false,
            isBig: false,
            tooltip: false
        };
        this.cancelRequestSource = null;

        this.createChart = this.createChart.bind(this);
    }

    componentDidMount() {
        let mediaProps = this.props.dataset;
        if (mediaProps) {
            this.fetchData(
                mediaProps.page,
                mediaProps.imageId,
                mediaProps.imagename,
                mediaProps.contentType,
                mediaProps.appliedFilters,
                mediaProps.app
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dataset !== this.props.dataset) {
            let mediaProps = this.props.dataset;
            this.fetchData(
                mediaProps.page,
                mediaProps.imageId,
                mediaProps.imagename,
                mediaProps.contentType,
                mediaProps.appliedFilters,
                mediaProps.app
            );
        }
    }

    componentWillUnmount() {
        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }
    }

    async fetchData(type, imageId, imagename, contentType, appliedFilters, app) {
        let service = new InsightsMonthlyService();

        this.setState({ loading: true });

        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));

        selectedFiltersDeepClone.content_types = [contentType];

        selectedFiltersDeepClone.id = imageId;

        if (SubscriptionService.subscription() !== '') {
            selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
        }

        if (app) {
            selectedFiltersDeepClone.app = app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchNextInsightsMetricsAxios(
                selectedFiltersDeepClone,
                mediaDetailsConfig.getAPIPath(),
                this.cancelRequestSource.token
            )
            .then(async (response) => {
                if ((!response && !response.data) || !utilHelper.resolve(response, `data.label`)) {
                    this.setState({ error: true, dataset: [], loading: false });
                } else {
                    if (response.data.image_url) {
                        var img = new Image();
                        img.src = process.env.REACT_APP_IMAGE_URL + response.data.image_url;

                        await img
                            .decode()
                            .then(() => {
                                if (img.height === img.width) {
                                    this.setState({ isBig: 'square' });
                                } else if (img.height > img.width) {
                                    this.setState({ isBig: 'rect' });
                                } else if (img.height < img.width) {
                                    this.setState({ isBig: 'nano' });
                                }
                            })
                            .catch((encodingError) => {});
                    }
                    this.setState({
                        error: false,
                        dataset: response.data,
                        loading: false
                    });
                    this.createChart(response.data);
                }
            })
            .catch((error) => {
                this.setState({ error: true, dataset: [], loading: false });
            });
    }

    createChart(detailsData) {
        let labelset = [];
        let labelset2 = [];
        let datasets = [];
        let dataSet1 = [];
        let dataSet2 = [];
        let options = {};
        let i = 0;
        let j = 0;

        if (detailsData) {
            let artworkData = null;
            if (this.props.dataset.page === 'ereader') {
                if (detailsData.page_reads_trends_data) {
                    artworkData = detailsData.page_reads_trends_data;
                }
            } else {
                if (detailsData.total_views_trends_data) {
                    artworkData = detailsData.total_views_trends_data;
                }
            }

            if (
                artworkData &&
                artworkData.current_date_range &&
                artworkData.current_date_range.data &&
                Object.keys(artworkData.current_date_range.data).length > 0
            ) {
                artworkData.current_date_range.data.forEach((item) => {
                    labelset[i] = utilHelper.formatDateYYYYMMDDToDDMMMYYYY(item.date);
                    if (item.value) {
                        dataSet1[i] = item.value;
                    }
                    i++;
                });

                let chartObject = mediaDetailsConfig.getChartProperties();
                options = chartObject.options;
                datasets[0] = chartObject.graphStyle;
                datasets[0]['label'] = 'Total Views';
                datasets[0]['data'] = dataSet1;
                datasets[0]['labels'] = labelset;

                if (
                    this.props.dataset.showWorkbenchLink &&
                    this.props.dataset.appliedFilters &&
                    this.props.dataset.appliedFilters.comparison_range_text &&
                    artworkData.comparison_date_range &&
                    artworkData.comparison_date_range.data &&
                    Object.keys(artworkData.comparison_date_range.data).length > 0
                ) {
                    artworkData.comparison_date_range.data.forEach((item) => {
                        labelset2[j] = utilHelper.formatDateYYYYMMDDToDDMMMYYYY(item.date);
                        if (item.value) {
                            dataSet2[j] = item.value;
                        }
                        j++;
                    });
                    datasets[1] = chartObject.graphStyle2;
                    datasets[1]['data'] = dataSet2;
                    datasets[1]['labels'] = labelset2;
                    this.setState({ comparisonDataFlag: true });
                } else {
                    this.setState({ comparisonDataFlag: false });
                }
            }
        }

        let data = {
            labels: labelset,
            datasets: datasets
        };

        if (!process.env.JEST_WORKER_ID) {
            const yAxisLabel =
                this.props.dataset.page === 'audio'
                    ? 'Total Listens'
                    : this.props.dataset.page === 'games'
                      ? 'Total Plays'
                      : this.props.dataset.page === 'ereader'
                        ? 'Total Page Reads'
                        : 'Total Views';
            options.scales.yAxes[0]['scaleLabel'] = {
                display: true,
                fontStyle: 'normal',
                fontColor: '#000000',
                fontSize: 15,
                labelString: yAxisLabel
            };
            options.tooltips['callbacks'] = {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var index = tooltipItem.index;
                    return dataset.labels[index] + ': ' + dataset.data[index];
                },
                // Remove or change title
                title: function (tooltipItem, data) {
                    return yAxisLabel;
                }
            };
        }

        this.setState({ data: data, options: options });
    }

    getPlaceHolderPoster(page) {
        var placeholderPoster;

        switch (page) {
            case 'audio':
                placeholderPoster = AudioPlaceholderImage;
                break;
            case 'games':
                placeholderPoster = GamesPlaceholderImage;
                break;
            case 'live-tv':
                placeholderPoster = LiveTVPlaceholderImage;
                break;
            case 'movies':
                placeholderPoster = MoviesPlaceholderImage;
                break;
            case 'tv-series':
                placeholderPoster = TVSeriesPlaceholderImage;
                break;
            case 'ereader':
                placeholderPoster = EReaderPlaceholderImage;
                break;
            default:
                placeholderPoster = AudioPlaceholderImage;
        }

        return placeholderPoster;
    }

    formatNumber(number) {
        if (!isNaN(number)) {
            let numberFormat = new Intl.NumberFormat('en-US');
            return numberFormat.format(number);
        }
        return '';
    }

    render() {
        let dashboard = '';

        if (this.state.dataset && Object.keys(this.state.dataset).length > 0 && !this.state.loading) {
            const { dataset } = this.state;

            let duration = utilHelper.secondsToHoursMinutes(dataset.media_duration);
            let imageName = dataset.label ? dataset.label : this.props.dataset.imagename;

            dashboard = (
                <div>
                    {' '}
                    <div className="trend-image-title">{imageName}</div>
                    <AccessControl page={this.props.dataset.page} metrics={['media_duration']}>
                        {duration && <div>Runtime: {duration}</div>}
                    </AccessControl>
                    <div className="media-detail-container">
                        {this.renderPillBoxes(duration)}
                        {this.renderLineChart(imageName)}
                        {this.renderChildData(imageName)}
                    </div>
                </div>
            );
        } else if (!this.state.error && this.state.loading) {
            dashboard = (
                <div className="data-wrapper" style={{ marginTop: '20%', textAlign: 'center' }}>
                    <img src={Circular} alt="" />
                </div>
            );
        } else if (this.state.error) {
            dashboard = <h4>Details Not Available for {this.props.dataset.imagename}</h4>;
        }

        return <div> {dashboard}</div>;
    }

    renderLineChart(imageName) {
        let isGames =
            this.props.dataset.page === 'games' ||
            this.props.dataset.page === 'movies' ||
            this.props.dataset.page === 'tv-series';

        let placeholderPoster = this.getPlaceHolderPoster(this.props.dataset.page);
        let comparisonRangeText = this.props.dataset.appliedFilters.comparison_range_text
            ? this.props.dataset.appliedFilters.comparison_range_text
            : '';
        let dateRangeText = this.props.dataset.appliedFilters.dateRangeText
            ? this.props.dataset.appliedFilters.dateRangeText
            : '';
        let imagepath = this.state.dataset.image_url
            ? process.env.REACT_APP_IMAGE_URL + this.state.dataset.image_url
            : '';

        return (
            <div className={'line-chart-container ' + this.props.dataset.page}>
                <div className="trend-col" id={this.state.isBig === 'rect' ? 'big' : 'small'}>
                    <div className="trend-left">
                        <div
                            className="trend-image"
                            style={{
                                height: !isGames
                                    ? ''
                                    : this.state.isBig === 'rect'
                                      ? ''
                                      : this.state.isBig === 'square'
                                        ? '167px'
                                        : this.state.isBig === 'nano'
                                          ? '127px'
                                          : ''
                            }}
                        >
                            <img className="trend-placeholder-image" src={placeholderPoster} alt="" />
                            <div className="trend-alt-title">{imageName}</div>
                            <img
                                className="trend-item-image"
                                src={imagepath}
                                alt=""
                                style={{
                                    display: imagepath.endsWith('None') ? 'none' : 'block',
                                    height: !isGames
                                        ? ''
                                        : this.state.isBig === 'rect'
                                          ? ''
                                          : this.state.isBig === 'square'
                                            ? '167px'
                                            : this.state.isBig === 'nano'
                                              ? '127px'
                                              : ''
                                }}
                                onLoad={(e) => {
                                    e.target.classList.add('trend-item-image-shadow');
                                }}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.style.opacity = '0.01';
                                }}
                            />
                        </div>
                    </div>
                </div>
                <LineChart
                    style={{ width: '100%' }}
                    data={this.state.data}
                    options={this.state.options}
                    cardClass="media-detail-card"
                    lineHeight={260}
                    comparisonRangeText={comparisonRangeText}
                    dateRangeText={dateRangeText}
                    showWorkbenchLink={this.props.dataset.showWorkbenchLink}
                    dataAvailable={this.state.dataAvailable}
                    comparisonDataFlag={this.state.comparisonDataFlag}
                    visual={this.props.visual}
                />
            </div>
        );
    }

    renderPillBoxes(duration) {
        let users = utilHelper.formatToUnitsForPillBox(this.state.dataset.total_views);
        let uniqueUsers = utilHelper.formatToUnitsForPillBox(this.state.dataset.unique_views);

        const pillbox1Title = this.props.dataset.page === 'ereader' ? 'Total Page Reads' : 'Avg. Completion Rate';
        let pillbox1Data =
            this.props.dataset.page !== 'ereader' && this.state.dataset.avg_completion_rate
                ? utilHelper.numberWithCommas(this.state.dataset.avg_completion_rate) + '%'
                : this.state.dataset.total_page_reads
                  ? utilHelper.formatToUnitsForPillBox(this.state.dataset.total_page_reads)
                  : null;

        const pillbox2Title =
            this.props.dataset.page === 'ereader'
                ? 'Total Reading Time '
                : this.props.dataset.page === 'games'
                  ? 'Avg. Playing Time '
                  : 'Avg. Viewing Time ';
        let pillbox2Data = '';
        let avgEngDuration =
            this.props.dataset.page !== 'ereader' && this.state.dataset.avg_engagement_duration
                ? utilHelper.secondsToHmsForDynamicTabs(this.state.dataset.avg_engagement_duration)
                : this.state.dataset.time_to_read
                  ? utilHelper.secondsToHmsForDynamicTabs(Number(this.state.dataset.time_to_read))
                  : null;
        if (avgEngDuration && avgEngDuration !== 0) {
            pillbox2Data = avgEngDuration;
        }
        let pillbox2Color = this.props.dataset.page === 'ereader' ? '#BCE0E7' : '#F3D6C9';

        const pillbox3Title =
            this.props.dataset.page === 'audio'
                ? 'Total Listening Time '
                : this.props.dataset.page === 'games'
                  ? 'Total Playing Time '
                  : this.props.dataset.page === 'ereader'
                    ? 'Avg. Page Reads '
                    : 'Total Viewing Time ';
        let pillbox3Data = '';
        let usageDuration =
            this.props.dataset.page !== 'ereader' && this.state.dataset.total_engagement_time
                ? utilHelper.secondsToHmsForDynamicTabs(this.state.dataset.total_engagement_time)
                : this.state.dataset.avg_page_reads
                  ? utilHelper.formatToUnitsForPillBox(this.state.dataset.avg_page_reads)
                  : null;
        if (usageDuration && usageDuration !== 0) {
            pillbox3Data = usageDuration;
        }

        const pillbox4Title =
            this.props.dataset.page === 'audio'
                ? 'Total Listens'
                : this.props.dataset.page === 'games'
                  ? 'Total Plays '
                  : this.props.dataset.page === 'ereader'
                    ? 'Avg. Reading Time '
                    : 'Total Views';
        let pillbox4Data = users;
        let avgReadingTime = this.state.dataset.avg_reading_time
            ? utilHelper.secondsToHmsForDynamicTabs(this.state.dataset.avg_reading_time)
            : null;
        if (avgReadingTime && avgReadingTime !== 0) {
            pillbox4Data = avgReadingTime;
        }

        const pillbox5Title =
            this.props.dataset.page === 'audio'
                ? 'Unique Listens'
                : this.props.dataset.page === 'games'
                  ? 'Unique Players'
                  : this.props.dataset.page === 'ereader'
                    ? 'Total Readers '
                    : 'Unique Viewers';
        let pillbox5Data =
            this.props.dataset.page === 'ereader'
                ? utilHelper.formatToUnitsForPillBox(this.state.dataset.total_readers)
                : uniqueUsers;

        return (
            <div className="pill-box-container">
                {/* TODO: comment if average completion rate is not available and update styles in pillbox.css
                            - .pill-box-data and .pill-box-title */}

                <AccessControl page={this.props.dataset.page} metrics={['avg_completion_rate', 'total_page_reads']}>
                    <PillBox color="#F3D6C9" title={pillbox1Title} data={pillbox1Data} percentage={false} />
                </AccessControl>

                <AccessControl page={this.props.dataset.page} metrics={['avg_engagement_duration']}>
                    {duration && <PillBox color={pillbox2Color} title={pillbox2Title} data={pillbox2Data} />}
                </AccessControl>

                <AccessControl page={this.props.dataset.page} metrics={['time_to_read']}>
                    <PillBox color={pillbox2Color} title={pillbox2Title} data={pillbox2Data} />
                </AccessControl>

                <AccessControl page={this.props.dataset.page} metrics={['total_engagement_time', 'avg_reading_time']}>
                    <PillBox color="#BCE0E7" title={pillbox3Title} data={pillbox3Data} />
                </AccessControl>

                <AccessControl page={this.props.dataset.page} metrics={['total_views']}>
                    <PillBox textSize={'big'} color="#D5DEDB" title={pillbox4Title} data={pillbox4Data} />
                </AccessControl>

                <PillBox color="#E0CBE2" title={pillbox5Title} data={pillbox5Data} />
            </div>
        );
    }

    renderChildData(parentTitle) {
        if (
            this.props.dataset.page === 'tv-series' ||
            this.props.dataset.page === 'audio' ||
            this.props.dataset.page === 'live-tv'
        ) {
            let heading = '';
            if (this.props.dataset.unit === 'hr') {
                if (this.props.dataset.page === 'audio') {
                    heading = 'Total Listening Time by Track';
                } else if (this.props.dataset.page === 'live-tv') {
                    heading = 'Total Viewing Time by Live TV Program';
                } else {
                    heading = 'Total Viewing Time by Episode';
                }
            } else {
                if (this.props.dataset.page === 'audio') {
                    heading = 'Total Listens by Track';
                } else if (this.props.dataset.page === 'live-tv') {
                    heading = 'Total Views by Live TV Program';
                } else {
                    heading = 'Total Views by Episode';
                }
            }

            if (
                this.state.dataset &&
                this.state.dataset.child_data &&
                this.state.dataset.child_data.data &&
                this.state.dataset.child_data.data.length > 0
            ) {
                let valueColumn = 'total_views';
                if (this.props.dataset.unit === 'hr') {
                    valueColumn = 'total_engagement_time';
                }

                let items = [];
                let max = 0;

                for (let j = 0; j < this.state.dataset.child_data.data.length; j++) {
                    if (this.state.dataset.child_data.data[j][valueColumn]) {
                        if (parseInt(this.state.dataset.child_data.data[j][valueColumn], 10) > max) {
                            max = parseInt(this.state.dataset.child_data.data[j][valueColumn], 10);
                        }
                    }
                }

                const propComparator = (propName) => {
                    return (a, b) => {
                        if (parseInt(a[propName], 10) === parseInt(b[propName], 10)) {
                            return 0;
                        } else if (parseInt(a[propName], 10) < parseInt(b[propName], 10)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    };
                };

                // Sort data
                this.state.dataset.child_data.data.sort(propComparator(valueColumn));

                // Filter out TV episodes with same title as parent
                if (this.props.dataset.page === 'tv-series') {
                    this.state.dataset.child_data.data = this.state.dataset.child_data.data.filter((episode) => {
                        if (episode.label !== parentTitle) {
                            return true;
                        } else {
                            console.log('excluded same title' + episode.label);
                            return false;
                        }
                    });
                }

                for (let i = 0; i < this.state.dataset.child_data.data.length; i++) {
                    let data = this.state.dataset.child_data.data[i];
                    let dataValue = '';
                    if (this.props.dataset.unit === 'hr') {
                        dataValue = utilHelper.secondsToHmsForDynamicTabs(data[valueColumn]);
                    } else {
                        dataValue = utilHelper.formatToUnitsForPillBox(data[valueColumn]);
                    }

                    let px = parseInt((parseInt(data[valueColumn], 10) * 350) / max, 10);
                    if (px < 20) {
                        px = 20;
                    }
                    let isInner = px > 200 ? true : false;

                    let usagePercentage;
                    let usage = parseFloat(data[valueColumn]) / parseFloat(this.state.dataset[valueColumn]);
                    if (usage < 0.1) {
                        usagePercentage = '<0.1%';
                    } else {
                        usagePercentage = utilHelper.formatToPercentage(usage, 1);
                    }

                    items.push(
                        <tr className="child-table-row" key={this.props.dataset.page + i} id={Math.random()}>
                            <td className="child-item-title">
                                <div title={data.label}> {i + 1 + '. ' + data.label} </div>
                            </td>
                            <td className="child-item-bar">
                                {this.renderChildItemBar(px, isInner, dataValue, usagePercentage)}
                            </td>
                        </tr>
                    );
                }

                return (
                    <div className="child-data-container">
                        <div className="child-data-header"> {heading} </div>
                        <table className="child-data-table">
                            <tbody>{items}</tbody>
                        </table>
                    </div>
                );
            } else {
                return (
                    <div className="child-data-container">
                        <div className="child-data-header"> {heading} </div>
                        <p style={{ fontStyle: 'italic', paddingTop: '20px' }}>
                            {this.props.dataset.page === 'audio' ? 'No tracks found' : 'No episodes found'}
                        </p>
                    </div>
                );
            }
        } else {
            return '';
        }
    }

    renderChildItemBar(px, isInner, dataValue, usagePercentage) {
        let childItemBar = (
            <div style={{ display: 'flex' }}>
                <div
                    className="bar-chart-rect"
                    style={{
                        maxWidth: 350,
                        width: px,
                        backgroundColor: '#3695D5',
                        color: isInner && invertColor('#3695D5')
                    }}
                >
                    <span className="bar-chart-rect-inner-text" style={{ fontWeight: 'normal' }}>
                        {isInner ? dataValue : ''}
                    </span>
                </div>
                <span className="bar-chart-rect-inner-text" style={{ fontWeight: 'normal' }}>
                    {!isInner ? dataValue : ''}
                </span>
            </div>
        );

        return childItemBar;
    }
}

export default MediaDetailsContainer;
