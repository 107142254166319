import React from 'react';
import { Card } from 'reactstrap';
import ErrorBoundary from 'util/ErrorBoundary.js';
import _, { debounce } from 'lodash';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import * as AppConstants from 'constants/app/constants';
import VerticalBarChartWidget from 'components/vertical-bar-chart/VerticalBarChartWidget';
import VerticalBarChartConfig from 'components/vertical-bar-chart/VerticalBarChartConfig.js';
import SubscriptionService from 'services/SubscriptionService';

const verticalBarChartConfig = new VerticalBarChartConfig();

class VerticalBarChartContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataset: [],
            loading: true,
            error: false,
            requestedSections: []
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) ||
            prevProps.path !== this.props.path ||
            this.props.forceReloadAll
        ) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        this.setState({ loading: true });

        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));
        let requestedSections = verticalBarChartConfig.getVerticalChartRequestedSections(this.props.usageSource);
        if (!this.props.showWorkbenchLink) {
            requestedSections = requestedSections.filter(function (option, index) {
                return option.basic === '0';
            });
        }

        selectedFiltersDeepClone.requested_sections = requestedSections.map(function (section) {
            return section['key'];
        });
        selectedFiltersDeepClone.usage_source = this.props.usageSource;
        if (this.props.usageSource === 'Companion App') {
            delete selectedFiltersDeepClone.usage_source;
            selectedFiltersDeepClone.launched_by = ['Companion App'];
        }

        if (SubscriptionService.subscription() !== '') {
            selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
        }

        if (this.props.app) {
            selectedFiltersDeepClone['app'] = this.props.app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        this.throttleFetch(selectedFiltersDeepClone);
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchNextInsightsMetricsAxios(
                selectedFiltersDeepClone,
                verticalBarChartConfig.getAPIPath('vertical-bar'),
                this.cancelRequestSource.token
            )
            .then((response) => {
                if (!response && !response.requested_sections) {
                    this.setState({ error: true, dataset: [], loading: false });
                } else {
                    this.setState({ error: false, dataset: response.requested_sections, loading: false });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, loading: false });
                } else {
                    this.setState({ error: true, dataset: [], loading: false });
                }
            });
    }

    render() {
        let { dataset, loading } = this.state;

        let requestedSections = verticalBarChartConfig.getVerticalChartRequestedSections(this.props.usageSource);

        if (!this.props.showWorkbenchLink) {
            requestedSections = requestedSections.filter(function (option, index) {
                return option.basic === '0';
            });
        }

        let tabData = [];
        if (dataset) {
            if (requestedSections && requestedSections.length > 0) {
                for (let i = 0; i < requestedSections.length; i++) {
                    let tab = JSON.parse(JSON.stringify(requestedSections[i]));
                    tab.value = null;
                    tab.comparison_value = null;
                    if (dataset[requestedSections[i].key]) {
                        let verticalChartData = dataset[requestedSections[i].key];
                        tab.value = verticalChartData.hasOwnProperty('value') ? verticalChartData.value : null;
                        tab.comparison_value = verticalChartData.hasOwnProperty('comparison_value')
                            ? verticalChartData.comparison_value
                            : null;
                        tabData.push(tab);
                    }
                }
            }
        }

        return (
            <Card>
                <ErrorBoundary>
                    <VerticalBarChartWidget
                        loading={loading}
                        key={'vertical_bar_chart'}
                        id={'vertical_bar_chart'}
                        data={dataset ? dataset : []}
                        title={this.props.title}
                        page={this.props.page}
                        pageName={this.props.pageName}
                        tabData={tabData}
                        workbench_type={this.props.workbenchTitle}
                        showWorkbenchLink={this.props.showWorkbenchLink}
                        tenantIcaoCode={this.props.tenantIcaoCode}
                        appliedFilters={this.props.appliedFilters.api}
                        app={this.props.app}
                    />
                </ErrorBoundary>
            </Card>
        );
    }
}

export default VerticalBarChartContainer;
