import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { CardBody } from 'reactstrap';
import _ from 'lodash';
import Analytics from 'components/widgets/Analytics';

//for circular JSON Format
const replacerFunc = () => {
    const visited = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (visited.has(value)) {
                return;
            }
            visited.add(value);
        }
        return value;
    };
};

const LineChart = (props) => {
    const [realTimeData, setRealTimeData] = useState(true);
    const [precedingData, setPrecedingData] = useState(true);
    const [data, setData] = useState(props.data);
    const legendArray = [
        { backgroundColor: '#2264A0', text: props.dateRangeText, type: 'current' },
        { backgroundColor: '#722BCA', text: props.comparisonRangeText, type: 'preceding' }
    ];

    useEffect(() => {
        if (!_.isEqual(data, props.data)) {
            if (props.data !== undefined && props.data !== null && props.data.datasets.length > 0) {
                let copiedData = JSON.parse(JSON.stringify(props.data, replacerFunc()));
                copiedData.datasets[0].labels = copiedData.labels;
                if (realTimeData && precedingData) {
                    //
                } else if (!precedingData) {
                    copiedData.datasets[1].data = [];
                    copiedData.datasets[1].labels = [];
                    copiedData.labels = copiedData.datasets[0].labels;
                } else if (!realTimeData) {
                    copiedData.datasets[0].data = [];
                    copiedData.datasets[0].labels = [];
                    copiedData.labels = copiedData.datasets[1].labels;
                }
                setData(copiedData);
            }
        }
    }, [props.data]);

    function getData(canvas, data, options) {
        let gradientFill;
        if (data !== undefined) {
            let ctx = canvas.getContext('2d');
            gradientFill = ctx.createLinearGradient(0, 0, 0, 300);
            gradientFill.addColorStop(0, 'rgba(17, 118, 220, 0.3)');
            gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0.3)');
            if (data && data.datasets && data.datasets !== undefined && data.datasets[0] !== undefined) {
                data.datasets[0]['backgroundColor'] = gradientFill;
            }
        }

        return data;
    }

    let { options } = props;

    if (data === null || data === undefined) {
        return null;
    }

    const handleRealtimeChkBox = (event, legendText) => {
        Analytics.analytics.track('handleRealtimeChkBox', {
            visual_name: props.visual,
            interaction_type: 'Popup Legends Checkbox Click',
            interaction_value: legendText
        });

        setRealTimeData(event);
        let copiedData = JSON.parse(JSON.stringify(props.data));

        if (event) {
        } else {
            copiedData.datasets[0].data = [];
            copiedData.datasets[0].labels = [];
            copiedData.labels = copiedData.datasets[1].labels;
            setPrecedingData(true);
        }

        setData(copiedData);
    };

    const handlePrecedingChkBox = (event, legendText) => {
        Analytics.analytics.track('handlePrecedingChkBox', {
            visual_name: props.visual,
            interaction_type: 'Popup Legends Checkbox Click',
            interaction_value: legendText
        });

        setPrecedingData(event);
        let copiedData = JSON.parse(JSON.stringify(props.data));

        if (event) {
        } else {
            if (copiedData.datasets[1] && Object.keys(copiedData.datasets[1]).length > 0) {
                copiedData.datasets[1].data = [];
                copiedData.datasets[1].labels = [];
                copiedData.labels = copiedData.datasets[0].labels;
            }

            setRealTimeData(true);
        }

        setData(copiedData);
    };

    return (
        <CardBody className={props.cardClass ? props.cardClass : 'min-top'}>
            <div>
                <div className={!props.dataAvailable ? 'data-wrapper blurred' : 'data-wrapper'}>
                    <Line
                        data={(canvas) => getData(canvas, data, options)}
                        options={options}
                        id="chartEl"
                        height={props.lineHeight ? props.lineHeight : ''}
                    />
                </div>
                {props.showWorkbenchLink && props.comparisonRangeText && props.comparisonDataFlag && (
                    <div style={{ display: 'flex', marginLeft: 14, marginTop: 15 }}>
                        <div className="vertical-bar-legend" style={{ position: 'initial', height: 'auto' }}>
                            {legendArray.map((lagend, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{ height: '24px' }}
                                        onClick={() => {
                                            lagend.type === 'current'
                                                ? handleRealtimeChkBox(!realTimeData, lagend.text)
                                                : handlePrecedingChkBox(!precedingData, lagend.text);
                                        }}
                                        className={
                                            index === 0 && !realTimeData
                                                ? 'unselected'
                                                : index === 1 && !precedingData
                                                  ? 'unselected'
                                                  : ''
                                        }
                                    >
                                        <div
                                            className="vertical-bar-chart-legend-color"
                                            style={{
                                                color:
                                                    (index === 0 && realTimeData) || (index === 1 && precedingData)
                                                        ? '#fff'
                                                        : '#000',
                                                backgroundColor:
                                                    (index === 0 && realTimeData) || (index === 1 && precedingData)
                                                        ? lagend.backgroundColor
                                                        : '#E5E5E5'
                                            }}
                                        >
                                            {(index === 0 && realTimeData) || (index === 1 && precedingData)
                                                ? '\u2713'
                                                : ''}
                                        </div>
                                        <div className="vertical-bar-chart-legend-label">
                                            <span>{lagend.text}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <div className="no-data" style={{ display: props.dataAvailable ? 'none' : 'initial' }}>
                Data is not available
            </div>
        </CardBody>
    );
};

export default LineChart;
