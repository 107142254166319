import React from 'react';
import * as AppConstants from '../../constants/app/constants';
import UtilHelper from 'util/UtilHelper.js';
const utilHelper = new UtilHelper();

class SubscriptionBanner extends React.Component {
    renderBanner() {
        let startDate = utilHelper.formatDateStringWithPattern(this.props.licenseStartDate, 'DD MMM, YYYY');
        let endDate = utilHelper.formatDateStringWithPattern(this.props.licenseEndDate, 'DD MMM, YYYY');

        let banner = (
            <div className="subscription-banner">
                {this.props.subscriptionStatus === AppConstants.SUBSCRIPTION_STATUS_EXPIRED && (
                    <div>
                        <div className="message">
                            Please contact your Panasonic Avionics sales representative to activate your NEXT Insights
                            subscription.
                        </div>
                        <div className="message2">
                            Subscription expired on <span className="message3">{endDate}.</span>
                        </div>
                    </div>
                )}

                {this.props.subscriptionStatus === AppConstants.SUBSCRIPTION_STATUS_NOT_STARTED && (
                    <div className="message">
                        Subscription not active until <span className="message3">{startDate}.</span>
                    </div>
                )}

                {this.props.subscriptionStatus === AppConstants.SUBSCRIPTION_STATUS_ACTIVE && (
                    <div className="message">
                        Subscription is active until <span className="message3">{endDate}.</span>
                    </div>
                )}

                {this.props.subscriptionStatus === AppConstants.SUBSCRIPTION_STATUS_NO_SUBSCRIPTION && (
                    <div className="message">No subscription found.</div>
                )}
            </div>
        );

        return <React.Fragment>{banner}</React.Fragment>;
    }

    render() {
        return <React.Fragment>{this.renderBanner()}</React.Fragment>;
    }
}

export default SubscriptionBanner;
