import React from 'react';

import { Row } from 'reactstrap';
import Select, { components } from 'react-select';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
    Chip,
    ButtonPrimary,
    ButtonSecondary,
    Checkbox,
    CheckboxSelectVariant,
    TablePagination
} from 'pac-ux-components';
import _, { debounce } from 'lodash';
import axios from 'axios';
import * as AppConstants from 'constants/app/constants';
import FilterBlue from 'assets/images/icons/filter-blue.svg';
import FilterWhite from 'assets/images/icons/filter-white.svg';
import CheckMark from 'assets/images/icons/checkmark.svg';
import Circular from 'assets/images/icons/circular.gif';
import ErrorBoundary from 'util/ErrorBoundary';
import UtilHelper from 'util/UtilHelper';
import TailFilter from 'components/widgets/admin/TailFilter';
import TailFilterHelper from 'components/widgets/admin/TailFilterHelper';
import DynamicFilterHelper from 'data/dynamic-filter-helper';
import SortTableHeaderButton, { sortColumn } from 'components/widgets/SortTableHeaderButton.js';
import { Modal, Container, ModalBody } from 'reactstrap';
import AddEditTail from 'components/widgets/admin/AddEditTail.js';
import RemoveTail from 'components/widgets/admin/RemoveTail.js';
import ErrorMessage from 'components/widgets/admin/ErrorMessage.js';
import moment from 'moment';
import AdminService from 'services/AdminService';
import { Spinner } from 'react-bootstrap';

const adminService = new AdminService();

const utilHelper = new UtilHelper();

const SELECTED_ADMIN_ICAO_CODE_SESSION_KEY = 'pac_selected_admin_icao_code';

const DEFAULT_SORT_COLUMN = 'tail_no';
const DEFAULT_SORT_DIRECTION = 'ascending';

const SELECT_ALL = 'Select all';
const DESELECT_ALL = 'Deselect all';

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faSearch : faCaretDown} />
            </components.DropdownIndicator>
        )
    );
};

class Tail extends React.Component {
    constructor(props) {
        super(props);

        this.updateTableStateAction = this.updateTableStateAction.bind(this);

        this.state = {
            selectedTenant: null,
            selectedTenantLabel: '',
            tails: [],
            tailsOriginal: [],
            filterApps: [],
            tailsLoading: false,
            error: false,
            headerCheckBoxState: CheckboxSelectVariant.unselected,
            tailsSelected: [],
            tailRowsSelected: [],
            filterButtonDisabled: true,
            filterValues: {},
            appliedFilters: {},
            filterIcon: FilterBlue,
            filterDropdown: false,
            table: {
                initialDataLoaded: false,
                currentTableSortColumn: DEFAULT_SORT_COLUMN,
                currentTableSortDirection: DEFAULT_SORT_DIRECTION,
                currentPageData: [],
                currentPageTailsSelected: [],
                currentPageTailRowsSelected: [],
                pageCount: AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE,
                paging: {
                    count: AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE,
                    page: 0
                },
                updateTableStateAction: this.updateTableStateAction
            },
            modal: false,
            showPopup: false,
            excelLoading: false
        };

        this.cancelTailRequestSource = null;
        this.throttleTailFetch = debounce(this.throttleTailFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);

        this.paginate = this.paginate.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.toggleFilterDropdown = this.toggleFilterDropdown.bind(this);
        this.toggleFilterIcon = this.toggleFilterIcon.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleFilterSelectionUpdated = this.handleFilterSelectionUpdated.bind(this);
        this.handleTailHeaderCheckBoxChange = this.handleTailHeaderCheckBoxChange.bind(this);
        this.addTails = this.addTails.bind(this);
        this.editTails = this.editTails.bind(this);
        this.removeTails = this.removeTails.bind(this);
        this.displayError = this.displayError.bind(this);

        this.tailFilterRef = React.createRef();
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            this.props.airlineOptions.length > 0 &&
            (this.state.selectedTenant === null ||
                !_.isEqual(this.props.airlinesLoading, prevProps.airlinesLoading) ||
                !_.isEqual(this.props.airlineOptions, prevProps.airlineOptions))
        ) {
            await this.setDefaultTenant();
        }

        if (prevProps.excelLoading !== this.props.excelLoading) {
            this.setState({ excelLoading: this.props.excelLoading });
        }
    }

    handleClickOutside(event) {
        this.setState({ filterIcon: FilterBlue });
    }

    async setDefaultTenant() {
        let { selectedTenant } = this.state;
        let { airlinesLoading, airlineOptions } = this.props;

        let selectedTenantExists = false;
        if (selectedTenant) {
            selectedTenantExists = airlineOptions.some((option) => option.value === selectedTenant.value);
        }

        if (
            (!selectedTenant || !selectedTenantExists) &&
            !airlinesLoading &&
            airlineOptions &&
            airlineOptions.length > 0
        ) {
            let defaultTenant = null;

            if (window.sessionStorage.getItem(SELECTED_ADMIN_ICAO_CODE_SESSION_KEY)) {
                defaultTenant = airlineOptions
                    .filter((option) => {
                        return (
                            option.value.toString() ===
                            window.sessionStorage.getItem(SELECTED_ADMIN_ICAO_CODE_SESSION_KEY)
                        );
                    })
                    .shift();
            }

            if (!defaultTenant) {
                defaultTenant = airlineOptions[0];
                window.sessionStorage.setItem(SELECTED_ADMIN_ICAO_CODE_SESSION_KEY, airlineOptions[0].value);
            }

            if (defaultTenant) {
                this.setState(
                    {
                        selectedTenant: defaultTenant,
                        selectedTenantLabel: defaultTenant.label
                    },
                    () => this.handleTenantChange(defaultTenant)
                );
            }
        }
    }

    handleTenantChange = (selectedItem) => {
        let { table } = this.state;

        let cachedTenantIcaoCode = window.sessionStorage.getItem(SELECTED_ADMIN_ICAO_CODE_SESSION_KEY);
        if (!cachedTenantIcaoCode || (cachedTenantIcaoCode && cachedTenantIcaoCode !== selectedItem.value)) {
            window.sessionStorage.removeItem('pac_next_insights_tail_selected_filters');
        }
        window.sessionStorage.setItem(SELECTED_ADMIN_ICAO_CODE_SESSION_KEY, selectedItem.value);

        table.currentPageTailsSelected = [];
        table.currentPageTailRowsSelected = [];

        this.setState(
            {
                selectedTenant: selectedItem,
                selectedTenantLabel: selectedItem.label,
                appliedFilters: {},
                headerCheckBoxState: CheckboxSelectVariant.unselected,
                tailsSelected: [],
                tailRowsSelected: [],
                table: table
            },
            () => this.fetchTailData()
        );
    };

    fetchTailData() {
        this.setState({ tailsLoading: true });

        if (this.cancelTailRequestSource) {
            this.cancelTailRequestSource.cancel('Operation canceled by the user.');
        }

        if (this.state.selectedTenant && this.state.selectedTenant.value) {
            this.throttleTailFetch();
        }
    }

    throttleTailFetch() {
        const CancelToken = axios.CancelToken;
        this.cancelTailRequestSource = CancelToken.source();

        adminService
            .fetchTails(
                {
                    icao: this.state.selectedTenant.value,
                    stage: this.props.stage
                },
                this.cancelTailRequestSource.token
            )
            .then((response) => {
                if (!response) {
                    this.setState({ error: true, tails: [], tailsLoading: false });
                } else {
                    let filterValues = utilHelper.resolve(response, `data.filter_values`);
                    if (!filterValues) {
                        filterValues = {};
                    } else {
                        // Remove empty and null values
                        Object.keys(filterValues).forEach((k) => {
                            Object.keys(filterValues[k]).forEach((j) => {
                                (filterValues[k][j] == null || filterValues[k][j] === '') &&
                                    filterValues[k].splice(j, 1);
                            });
                        });
                    }

                    let filterApps = utilHelper.resolve(response, `data.filter_values.apps`);
                    if (!filterApps) {
                        filterApps = [];
                    }

                    let tails = utilHelper.resolve(response, `data.tails`);
                    if (!tails) {
                        tails = [];
                    }

                    // Get unique tiers from tails array and set it to filter values array.
                    if (tails && tails.length > 0) {
                        filterValues.tiers = tails
                            .map((item) => item.subscription_tier)
                            .filter((value, index, self) => self.indexOf(value) === index)
                            .sort();

                        if (filterValues.tiers && filterValues.tiers.length > 0) {
                            filterValues.filterValuesByTier = TailFilterHelper.getFilterValuesByTier(
                                tails,
                                filterValues
                            );
                        }

                        tails.forEach((tail) => {
                            if (tail.last_updated_at) {
                                let updatedVal = tail.last_updated_at.split('').join('');
                                tail.last_updated_display = updatedVal;
                                tail.last_updated_at = moment(updatedVal).unix();
                            } else {
                                tail.last_updated_display = '';
                                tail.last_updated_at = null;
                            }
                        });
                    }

                    filterValues = TailFilterHelper.getSortedFilterValues(filterValues);

                    //sort data by initial column and direction
                    tails = sortColumn(
                        this.state.table.currentTableSortColumn,
                        this.state.table.currentTableSortColumn,
                        this.state.table.currentTableSortDirection,
                        tails,
                        false,
                        null,
                        'string'
                    );

                    //paginate data
                    let tableState = {};
                    Object.assign(tableState, this.state.table);
                    let currentPageData = tails.slice(0, tableState.pageCount);
                    tableState.currentPageData = currentPageData;
                    tableState.initialDataLoaded = true;

                    filterApps = filterApps.map((app) => {
                        if (app === 'Electronic Reader') {
                            app = 'eReader';
                        }
                        return app;
                    });

                    //set state to re-render table
                    this.setState({
                        error: false,
                        tails: tails,
                        tailsOriginal: tails,
                        filterApps: filterApps,
                        filterValues: filterValues,
                        appliedFilters: {},
                        filterButtonDisabled: !TailFilterHelper.hasFilterValues(filterValues),
                        tailsLoading: false,
                        table: tableState
                    });

                    if (this.tailFilterRef.current) {
                        this.tailFilterRef.current.getSelectedFiltersFromStore();
                    }
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, tails: [], tailsLoading: true });
                } else {
                    this.setState({ error: true, tails: [], tailsLoading: false });
                }
            });
    }

    updateTailHeaderCheckBox() {
        let { table, headerCheckBoxState, tailsSelected } = this.state;
        let currentPageData = table.currentPageData;

        let currentPageTailsSelected = [];
        let currentPageTailRowsSelected = [];
        currentPageData.forEach((data) => {
            let tail = tailsSelected.filter((item) => item === data.tail_no)[0];
            if (tail) {
                currentPageTailsSelected.push(tail);
                currentPageTailRowsSelected.push(data);
            }
        });

        if (currentPageData.length === currentPageTailsSelected.length) {
            headerCheckBoxState = CheckboxSelectVariant.selected;
        } else if (currentPageTailsSelected.length > 0 && currentPageData.length > currentPageTailsSelected.length) {
            headerCheckBoxState = CheckboxSelectVariant.semi;
        } else {
            headerCheckBoxState = CheckboxSelectVariant.unselected;
        }

        table.currentPageTailsSelected = currentPageTailsSelected;
        table.currentPageTailRowsSelected = currentPageTailRowsSelected;

        this.setState({
            headerCheckBoxState: headerCheckBoxState,
            table: table
        });
    }

    handleTailHeaderCheckBoxChange() {
        let { table, headerCheckBoxState, tailsSelected, tailRowsSelected } = this.state;
        let currentPageData = table.currentPageData;
        let currentPageTailsSelected = table.currentPageTailsSelected;
        let currentPageTailRowsSelected = table.currentPageTailRowsSelected;

        let checkBoxState;
        if (headerCheckBoxState === CheckboxSelectVariant.unselected) {
            checkBoxState = CheckboxSelectVariant.selected;
            currentPageData.forEach((data) => {
                tailsSelected.push(data.tail_no);
                tailRowsSelected.push(data);
                currentPageTailsSelected.push(data.tail_no);
                currentPageTailRowsSelected.push(data);
            });
        } else {
            checkBoxState = CheckboxSelectVariant.unselected;
            currentPageData.forEach((data) => {
                tailsSelected = tailsSelected.filter((item) => item !== data.tail_no);
                tailRowsSelected = tailRowsSelected.filter((item) => item.tail_no !== data.tail_no);
            });
            currentPageTailsSelected = [];
            currentPageTailRowsSelected = [];
        }

        tailsSelected = [...new Set([...tailsSelected])];
        tailRowsSelected = [...new Set(tailRowsSelected.map(JSON.stringify))].map(JSON.parse);

        currentPageTailsSelected = [...new Set([...currentPageTailsSelected])];
        currentPageTailRowsSelected = [...new Set(currentPageTailRowsSelected.map(JSON.stringify))].map(JSON.parse);

        table.currentPageTailsSelected = currentPageTailsSelected;
        table.currentPageTailRowsSelected = currentPageTailRowsSelected;

        this.setState({
            headerCheckBoxState: checkBoxState,
            tailsSelected: tailsSelected,
            tailRowsSelected: tailRowsSelected,
            table: table
        });
    }

    handleTailRowCheckBoxChange(e) {
        const item = e.target.name;
        let { table, headerCheckBoxState, tailsSelected, tailRowsSelected } = this.state;
        let currentPageData = table.currentPageData;

        if (tailsSelected.includes(item)) {
            tailsSelected = tailsSelected.filter((value) => value !== item);
            tailRowsSelected = tailRowsSelected.filter((data) => data.tail_no !== item);
        } else {
            tailsSelected.push(item);
            currentPageData.forEach((data) => {
                if (data.tail_no === item) {
                    tailRowsSelected.push(data);
                }
            });
        }

        let currentPageTailsSelected = [];
        let currentPageTailRowsSelected = [];
        currentPageData.forEach((data) => {
            let tail = tailsSelected.filter((item) => item === data.tail_no)[0];
            if (tail) {
                currentPageTailsSelected.push(tail);
                currentPageTailRowsSelected.push(data);
            }
        });

        if (currentPageData.length === currentPageTailsSelected.length) {
            headerCheckBoxState = CheckboxSelectVariant.selected;
        } else if (currentPageTailsSelected.length > 0 && currentPageData.length > currentPageTailsSelected.length) {
            headerCheckBoxState = CheckboxSelectVariant.semi;
        } else {
            headerCheckBoxState = CheckboxSelectVariant.unselected;
        }

        table.currentPageTailsSelected = currentPageTailsSelected;
        table.currentPageTailRowsSelected = currentPageTailRowsSelected;

        this.setState({
            headerCheckBoxState: headerCheckBoxState,
            tailsSelected: tailsSelected,
            tailRowsSelected: tailRowsSelected,
            table: table
        });
    }

    selectAllTails(e) {
        let { table, headerCheckBoxState, tailsSelected, tailRowsSelected } = this.state;
        let currentPageData = table.currentPageData;

        let currentPageTailsSelected = [];
        let currentPageTailRowsSelected = [];
        const selectedText = e.target.getAttribute('data-select-text');

        if (selectedText === SELECT_ALL) {
            currentPageData.forEach((data) => {
                tailsSelected.push(data.tail_no);
                tailRowsSelected.push(data);
                currentPageTailsSelected.push(data.tail_no);
                currentPageTailRowsSelected.push(data);
            });
            headerCheckBoxState = CheckboxSelectVariant.selected;
        } else {
            currentPageData.forEach((data) => {
                tailsSelected = tailsSelected.filter((item) => item !== data.tail_no);
                tailRowsSelected = tailRowsSelected.filter((item) => item.tail_no !== data.tail_no);
            });
            headerCheckBoxState = CheckboxSelectVariant.unselected;
        }

        tailsSelected = [...new Set([...tailsSelected])];
        tailRowsSelected = [...new Set(tailRowsSelected.map(JSON.stringify))].map(JSON.parse);

        table.currentPageTailsSelected = currentPageTailsSelected;
        table.currentPageTailRowsSelected = currentPageTailRowsSelected;

        this.setState({
            headerCheckBoxState: headerCheckBoxState,
            tailsSelected: tailsSelected,
            tailRowsSelected: tailRowsSelected,
            table: table
        });
    }

    showDeleteTailsPopup() {
        this.togglePopup('remove');
    }

    showEditTailsPopup() {
        this.togglePopup('edit');
    }

    toggleFilterIcon() {
        let btnFilter = document.querySelector('.filter-button-secondary');
        if (btnFilter === document.activeElement) {
            this.setState({ filterIcon: FilterWhite });
        } else {
            this.setState({ filterIcon: FilterBlue });
        }
    }

    toggleFilterDropdown(showFilterBlue) {
        this.setState({ filterIcon: showFilterBlue ? FilterBlue : FilterWhite });
        this.setState((prevState) => ({
            filterDropdown: !prevState.filterDropdown
        }));
    }

    handleChipButton(filterType, value, filterNotExists) {
        if (filterNotExists) {
            return false;
        }

        let filters = this.state.appliedFilters;
        for (let i = 0; i < filters[filterType].length; i++) {
            if (filters[filterType][i] === value) {
                filters[filterType].splice(i, 1);
            }
        }
        let appliedFilters = JSON.parse(JSON.stringify(filters));

        this.setState(
            {
                appliedFilters: appliedFilters
            },
            () => {
                if (this.tailFilterRef.current) {
                    this.tailFilterRef.current.handleChipChange(filterType, value, appliedFilters);
                }
                this.refreshTailsData(appliedFilters);
            }
        );
    }

    handleFilterSelectionUpdated(appliedFilters, toggleTailFilterDropdown) {
        this.setState({ appliedFilters: appliedFilters }, () => this.refreshTailsData(appliedFilters));
        if (toggleTailFilterDropdown) {
            this.toggleFilterDropdown(true);
        }
    }

    refreshTailsData(appliedFilters) {
        let { table, tailsOriginal, tailsSelected, tailRowsSelected } = this.state;
        this.setState({ tailsLoading: true });

        let filteredTails = [];
        if (TailFilterHelper.hasFilters(appliedFilters)) {
            let tailsDeepCloned = JSON.parse(JSON.stringify(tailsOriginal));
            filteredTails = TailFilterHelper.getFilteredTails(tailsDeepCloned, appliedFilters);
        } else {
            filteredTails = tailsOriginal;
        }

        filteredTails = sortColumn(
            this.state.table.currentTableSortColumn,
            this.state.table.currentTableSortColumn,
            this.state.table.currentTableSortDirection,
            filteredTails,
            false,
            null,
            'string'
        );

        // Unselect the selected tail checkboxes if that is not in the filtered list.
        let headerCheckBoxState = CheckboxSelectVariant.unselected;
        if (tailsSelected.length > 0 && filteredTails) {
            let filteredTailsList = TailFilterHelper.getValuesByKey('tail_no', filteredTails);
            tailsSelected = tailsSelected.filter((value) => filteredTailsList.includes(value));
            tailRowsSelected = tailRowsSelected.filter((data) => tailsSelected.includes(data.tail_no));

            let currentPageData = JSON.parse(JSON.stringify(filteredTailsList));
            if (currentPageData.length > AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE) {
                currentPageData = currentPageData.splice(0, AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE);
            }

            let currentPageSelectedTails = JSON.parse(JSON.stringify(tailsSelected));
            if (currentPageSelectedTails.length > AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE) {
                currentPageSelectedTails = currentPageSelectedTails.splice(
                    0,
                    AppConstants.TABLE_PAGINATION_ROWS_PER_PAGE
                );
            }

            if (currentPageData.length === currentPageSelectedTails.length) {
                headerCheckBoxState = CheckboxSelectVariant.selected;
            } else if (
                currentPageSelectedTails.length > 0 &&
                currentPageData.length > currentPageSelectedTails.length
            ) {
                headerCheckBoxState = CheckboxSelectVariant.semi;
            } else {
                headerCheckBoxState = CheckboxSelectVariant.unselected;
            }
        }

        if (tailsSelected && tailsSelected.length > 0) {
            table.currentPageTailsSelected = tailsSelected;
            table.currentPageTailRowsSelected = tailRowsSelected;
        }

        this.setState({
            tailsLoading: false,
            tails: filteredTails,
            tailsSelected: tailsSelected,
            tailRowsSelected: tailRowsSelected,
            headerCheckBoxState: headerCheckBoxState,
            table: table
        });

        this.updateTableStateAction(0, filteredTails, DEFAULT_SORT_DIRECTION, DEFAULT_SORT_COLUMN);
    }

    renderTail() {
        let { hasAdminAccess, stage, airlinesLoading, airlineOptions, apps } = this.props;
        let { tailsLoading, tails, filterApps, selectedTenant, filterIcon, table } = this.state;
        let btnSelectText =
            table.currentPageTailsSelected.length < table.currentPageData.length ? SELECT_ALL : DESELECT_ALL;

        return (
            <Row className="admin-outer-container" style={{ maxWidth: '2000px' }}>
                <div>
                    <div className="tail-controls-container">
                        {!airlinesLoading && airlineOptions.length > 0 ? (
                            <Col md="6" sm="12" style={{ padding: '0', zIndex: '4' }}>
                                <div style={{ width: '270px' }}>
                                    <Select
                                        className="selectBox"
                                        name="admin_tenant"
                                        value={selectedTenant}
                                        options={airlineOptions}
                                        onChange={this.handleTenantChange}
                                        classNamePrefix="select"
                                        styles={DynamicFilterHelper.getDropdownStyle()}
                                        components={{ DropdownIndicator }}
                                        placeholder="Select Airline"
                                    />
                                </div>
                            </Col>
                        ) : (
                            <Col md="6" sm="12" style={{ padding: '0', zIndex: '4' }}></Col>
                        )}

                        <Col md="6" sm="12" style={{ textAlign: 'right', padding: '0' }}>
                            {(!airlinesLoading && !tailsLoading && tails.length > 0) || true ? (
                                <div>
                                    {!this.state.filterButtonDisabled && (
                                        <ButtonSecondary
                                            className="button-secondary filter-button-secondary"
                                            onMouseOver={() => this.setState({ filterIcon: FilterWhite })}
                                            onMouseLeave={this.toggleFilterIcon}
                                            onClick={() => this.toggleFilterDropdown(false)}
                                        >
                                            <img src={filterIcon} style={{ marginTop: '-3px' }} alt="" />
                                            &nbsp;Filters
                                        </ButtonSecondary>
                                    )}

                                    {hasAdminAccess ? (
                                        <ButtonPrimary
                                            className="button-primary margin-left-10"
                                            onClick={this.togglePopup}
                                        >
                                            <i className="fas fa-plus">&nbsp;</i>&nbsp;&nbsp; Add tail(s)
                                        </ButtonPrimary>
                                    ) : (
                                        ''
                                    )}

                                    <ButtonPrimary
                                        className="button-primary margin-left-10"
                                        onClick={() => {
                                            this.setState({ excelLoading: true }, () => {
                                                this.props.downloadAdminExcel();
                                            });
                                        }}
                                    >
                                        <div style={{ minWidth: '92.5px' }}>
                                            {this.state.excelLoading ? (
                                                <Spinner
                                                    size="sm"
                                                    animation="border"
                                                    role="status"
                                                    className="loading-spinner"
                                                ></Spinner>
                                            ) : (
                                                'Download All'
                                            )}
                                        </div>
                                    </ButtonPrimary>
                                </div>
                            ) : (
                                ''
                            )}
                        </Col>

                        <div className={this.state.filterDropdown ? 'filter-dropdown' : 'filter-dropdown closed'}>
                            <TailFilter
                                ref={this.tailFilterRef}
                                filterValues={this.state.filterValues}
                                airline_icao={this.state.selectedTenant ? this.state.selectedTenant.value : ''}
                                onFilterSelectionUpdated={this.handleFilterSelectionUpdated}
                            />
                        </div>

                        <div
                            className="applied-filter-container"
                            style={{
                                paddingBottom: TailFilterHelper.hasFilterValues(this.state.appliedFilters)
                                    ? '5px'
                                    : '15px'
                            }}
                        >
                            {Object.entries(this.state.appliedFilters).map(([filterType, filterValues]) => {
                                if (Array.isArray(filterValues)) {
                                    return filterValues.map((value, index) => {
                                        let filterNotExists = TailFilterHelper.isAppliedFilterNotExist(
                                            filterType,
                                            value,
                                            this.state.filterValues
                                        );
                                        return (
                                            <Chip
                                                disabled={filterNotExists}
                                                className="filter-chip"
                                                key={index}
                                                onClick={(e) =>
                                                    this.handleChipButton(filterType, value, filterNotExists)
                                                }
                                            >
                                                {TailFilterHelper.getDisplayNames()[filterType]}: {value}
                                            </Chip>
                                        );
                                    });
                                } else {
                                    return {};
                                }
                            })}
                        </div>
                    </div>
                </div>

                {!airlinesLoading && !tailsLoading && tails.length > 0 ? (
                    <div>
                        <div className="pagination-container">
                            <TablePagination
                                itemCount={this.state.tails.length}
                                onChangePage={this.paginate}
                                paging={this.state.table.paging}
                            />
                        </div>
                        <div className={'admin-container tail ' + stage} style={{ textAlign: 'center' }}>
                            <ErrorBoundary>
                                <table>
                                    <thead>
                                        <tr>
                                            {hasAdminAccess && (
                                                <th className="sticky-left check-box">
                                                    <Checkbox
                                                        name="th_tail_checkbox"
                                                        key="th_tail_checkbox"
                                                        marked={this.state.headerCheckBoxState}
                                                        onChange={(e) => this.handleTailHeaderCheckBoxChange()}
                                                    />
                                                </th>
                                            )}

                                            <th
                                                className={
                                                    hasAdminAccess ? 'sticky-left-2 tail' : 'sticky-left check-box'
                                                }
                                            >
                                                <SortTableHeaderButton
                                                    column="tail_no"
                                                    label="Tail"
                                                    dataType="string"
                                                    tableData={this.state.tails}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th className="tier">
                                                <SortTableHeaderButton
                                                    column="subscription_tier"
                                                    label="Tier"
                                                    dataType="string"
                                                    tableData={this.state.tails}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th className="aircraft">
                                                <SortTableHeaderButton
                                                    column="aircraft_type_subtype"
                                                    label="Aircraft"
                                                    dataType="string"
                                                    tableData={this.state.tails}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th className="system">
                                                <SortTableHeaderButton
                                                    column="system_type"
                                                    label="System"
                                                    dataType="string"
                                                    tableData={this.state.tails}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            <th className="last-updated-date">
                                                <SortTableHeaderButton
                                                    column="last_updated_at"
                                                    label="Last Updated"
                                                    dataType="number"
                                                    tableData={this.state.tails}
                                                    tableState={this.state.table}
                                                />
                                            </th>
                                            {filterApps.map((app, index) => (
                                                <th className="app" key={'tail_th_' + index}>
                                                    {app}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.table.currentPageData.map((tail, index) => (
                                            <tr key={'tail_tr' + index}>
                                                {hasAdminAccess && (
                                                    <td className="sticky-left check-box">
                                                        <Checkbox
                                                            name={tail.tail_no}
                                                            key={tail.tail_no}
                                                            marked={
                                                                this.state.tailsSelected &&
                                                                this.state.tailsSelected.includes(tail.tail_no)
                                                                    ? CheckboxSelectVariant.selected
                                                                    : CheckboxSelectVariant.unselected
                                                            }
                                                            onChange={(e) => this.handleTailRowCheckBoxChange(e)}
                                                        />
                                                    </td>
                                                )}

                                                <td
                                                    className={
                                                        hasAdminAccess ? 'sticky-left-2 tail' : 'sticky-left check-box'
                                                    }
                                                >
                                                    {tail.tail_no}
                                                </td>
                                                <td className="tier">{tail.subscription_tier}</td>
                                                <td className="aircraft">{tail.aircraft_type_subtype}</td>
                                                <td
                                                    className="system"
                                                    dangerouslySetInnerHTML={{
                                                        __html: TailFilterHelper.getCellValue(tail.system_type)
                                                    }}
                                                />
                                                <td className="last-updated">{tail.last_updated_display}</td>
                                                {filterApps.map((app, appIndex) => {
                                                    if (
                                                        utilHelper.resolve(tail, `apps`) &&
                                                        Array.isArray(tail.apps) &&
                                                        tail.apps.includes(app)
                                                    ) {
                                                        return (
                                                            <td className="app" key={'tail_td' + appIndex}>
                                                                <img src={CheckMark} alt="" />
                                                            </td>
                                                        );
                                                    } else {
                                                        return (
                                                            <td className="app" key={'tail_td' + appIndex}>
                                                                &nbsp;
                                                            </td>
                                                        );
                                                    }
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </ErrorBoundary>
                        </div>
                        <div className="pagination-container">
                            <TablePagination
                                itemCount={this.state.tails.length}
                                onChangePage={this.paginate}
                                paging={this.state.table.paging}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {!airlinesLoading && airlineOptions.length === 0 ? (
                    <div>
                        <div className="admin-container data-not-available">
                            <div className="no-airline-title">No Airlines/Tails Found</div>
                            <div className="no-airline-body">Check back at another time.</div>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {!airlinesLoading &&
                airlineOptions.length > 0 &&
                !tailsLoading &&
                tails.length === 0 &&
                this.state.selectedTenant &&
                this.state.selectedTenant.value ? (
                    <div>
                        <div className="admin-container data-not-available">
                            <div className="no-tail-title">No Tails Found</div>
                            {hasAdminAccess ? (
                                <div>
                                    <div className="no-tail-body">Check back at another time or add tails.</div>
                                    <ButtonPrimary className="button-primary margin-left-10" onClick={this.togglePopup}>
                                        <i className="fas fa-plus">&nbsp;</i>&nbsp;&nbsp; Add tail(s)
                                    </ButtonPrimary>
                                </div>
                            ) : (
                                <div>
                                    <div className="no-tail-body">Check back at another time.</div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {!airlinesLoading &&
                airlineOptions.length > 0 &&
                !tailsLoading &&
                tails.length === 0 &&
                (!this.state.selectedTenant || !this.state.selectedTenant.value) ? (
                    <div>
                        <div className="admin-container data-not-available">
                            <div className="no-airline-title">Please select an airline.</div>
                            <div className="no-airline-body">&nbsp;</div>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {airlinesLoading || tailsLoading ? (
                    <div>
                        <div className="admin-container loading">
                            <div>
                                <img src={Circular} alt="" />
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {this.state.showPopup ? (
                    <Container>
                        <Modal
                            isOpen={this.state.modal}
                            className={this.state.actionType === 'error' ? 'error-message-popup' : 'tail-popup'}
                        >
                            <ModalBody
                                className={
                                    this.state.actionType === 'remove'
                                        ? 'remove-tail'
                                        : this.state.actionType === 'error'
                                        ? 'error-message'
                                        : 'add-tail'
                                }
                            >
                                {this.state.actionType === 'remove' ? (
                                    <RemoveTail
                                        closeAction={this.togglePopup}
                                        removeTailsAction={this.removeTails}
                                        selectedTails={this.state.table.currentPageTailRowsSelected}
                                        tenantOptions={this.props.activeAirlineOptions}
                                        displayError={this.displayError}
                                    />
                                ) : this.state.actionType === 'error' ? (
                                    <ErrorMessage
                                        closeAction={this.togglePopup}
                                        errorTails={this.state.errorTails}
                                        errorDescription={this.state.errorDescription}
                                    />
                                ) : (
                                    <AddEditTail
                                        closeAction={this.togglePopup}
                                        addTailsAction={this.addTails}
                                        editTailsAction={this.editTails}
                                        apps={apps}
                                        actionType={this.state.actionType}
                                        tenantOptions={this.props.activeAirlineOptions}
                                        selectedTails={this.state.table.currentPageTailRowsSelected}
                                        displayError={this.displayError}
                                        inactiveLicenses={this.props.inactiveLicenses}
                                        stage={this.props.stage}
                                    />
                                )}
                            </ModalBody>
                        </Modal>
                    </Container>
                ) : null}

                {this.state.table.currentPageTailsSelected.length > 0 ? (
                    <div className="tails-action-bar">
                        <div className="selected-tails">
                            {this.state.table.currentPageTailsSelected.length} rows selected
                        </div>
                        <ButtonSecondary
                            className="button-secondary select-all-tails"
                            data-select-text={btnSelectText}
                            onClick={(e) => this.selectAllTails(e)}
                        >
                            {btnSelectText}
                        </ButtonSecondary>
                        <div className="vertical-line"></div>
                        <ButtonSecondary
                            className="button-secondary delete-tails"
                            onClick={() => this.showDeleteTailsPopup()}
                        >
                            Delete
                        </ButtonSecondary>
                        <ButtonPrimary className="edit-tails" onClick={() => this.showEditTailsPopup()}>
                            Edit
                        </ButtonPrimary>
                    </div>
                ) : null}
            </Row>
        );
    }

    updateTableStateAction(newPage, dataset, direction, column) {
        let itemsPerPage = this.state.table.pageCount;

        let newTableStartIndex = itemsPerPage * newPage;
        let newTableEndIndex = newTableStartIndex + itemsPerPage;
        let newData = dataset.slice(newTableStartIndex, newTableEndIndex);

        let tableState = {};
        Object.assign(tableState, this.state.table);
        tableState.currentPageData = newData;
        tableState.paging = {
            count: itemsPerPage,
            page: newPage
        };
        tableState.currentTableSortDirection = direction;
        tableState.currentTableSortColumn = column;

        this.setState(
            {
                table: tableState
            },
            () => {
                this.updateTailHeaderCheckBox();
            }
        );
    }

    paginate(newPage) {
        this.updateTableStateAction(
            newPage,
            this.state.tails,
            this.state.table.currentTableSortDirection,
            this.state.table.currentTableSortColumn
        );
    }

    addTails(airline, tier, apps, tails, updatedAt) {
        if (airline === this.state.selectedTenant.value) {
            let currentTails = this.state.tails;
            let currentApps = this.state.filterApps;

            tails.forEach((tail) => {
                let tailData = {};
                tailData.airline_code = airline;
                tailData.tail_no = tail['tail'];
                tailData.subscription_tier = tier;
                tailData.apps = apps;
                tailData.system_type = tail['system_type'];
                tailData.aircraft_type_subtype = tail['aircraft_type_subtype'];
                tailData.last_updated_at = moment(updatedAt).unix();
                tailData.last_updated_display = updatedAt;

                currentTails.push(tailData);

                let insertApps = [];
                apps.forEach((app) => {
                    if (!this.state.filterApps.includes(app)) {
                        insertApps.push(app);
                    }
                });
                if (insertApps.length > 0) {
                    currentApps = [...currentApps, ...insertApps];
                }
            });

            let filterValues = this.state.filterValues;
            filterValues.apps = currentApps;

            this.setState({ tails: currentTails, filterApps: currentApps, filterValues: filterValues });

            //sort data by initial column and direction
            currentTails = sortColumn(
                DEFAULT_SORT_COLUMN,
                DEFAULT_SORT_COLUMN,
                DEFAULT_SORT_DIRECTION,
                currentTails,
                false,
                null,
                'string'
            );

            this.updateTableStateAction(0, currentTails, DEFAULT_SORT_COLUMN, DEFAULT_SORT_DIRECTION);
        }

        this.togglePopup('add');
    }

    editTails(airline, tier, apps, tails, updatedAt) {
        //filter
        let selectedTails = [];
        let filteredTails = this.state.tails.filter((obj) => {
            if (tails.includes(obj.tail_no)) {
                selectedTails.push(obj);
                return false;
            } else {
                return true;
            }
        });

        //update records
        selectedTails.forEach((tail) => {
            tail.subscription_tier = tier;
            tail.apps = apps;
            tail.last_updated_at = moment(updatedAt).unix();
            tail.last_updated_display = updatedAt;

            filteredTails.push(tail);
        });

        this.setState({ tails: filteredTails });

        //sort data by initial column and direction
        filteredTails = sortColumn(
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_DIRECTION,
            filteredTails,
            false,
            null,
            'string'
        );

        this.updateTableStateAction(0, filteredTails, DEFAULT_SORT_COLUMN, DEFAULT_SORT_DIRECTION);

        this.togglePopup('edit');
    }

    displayError(actionType, errorDescription, errorTails = null) {
        this.togglePopup(actionType);
        this.setState({ errorDescription: errorDescription, errorTails: errorTails });
        this.togglePopup('error');
    }

    removeTails(airline, tails) {
        //filter
        let filteredTails = this.state.tails.filter((obj) => {
            if (!tails.includes(obj.tail_no)) {
                return true;
            } else {
                return false;
            }
        });

        this.setState({ tails: filteredTails });

        //sort data by initial column and direction
        filteredTails = sortColumn(
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_COLUMN,
            DEFAULT_SORT_DIRECTION,
            filteredTails,
            false,
            null,
            'string'
        );

        this.updateTableStateAction(0, filteredTails, DEFAULT_SORT_COLUMN, DEFAULT_SORT_DIRECTION);

        this.togglePopup('remove');
    }

    togglePopup(type) {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            showPopup: !this.state.showPopup,
            actionType: type
        }));
    }

    render() {
        return <React.Fragment>{this.renderTail()}</React.Fragment>;
    }
}

export default Tail;
