export default {
    getCategoriesDropdownListHelper(reportSubType) {
        let dropdownList;
    
        switch (reportSubType) {
            case 'Movies':
                dropdownList = [
                    {
                        label: 'Total Views (Top 10)',
                        unit: 'count',
                        value: 'categories.most_popular.total_views',
                        text: 'Movie Total Views',
                        default: 1,
                        free_tier: 1,
                        section: 'top_10_total_views',
                        small_count_label: 'Total Views'
                    },
                    {
                        label: 'Total Viewing Time (Top 10)',
                        unit: 'hr',
                        value: 'categories.most_popular.total_time',
                        text: 'Movie Total Viewing Time',
                        free_tier: 0,
                        section: 'top_10_total_time',
                        small_count_label: 'Total Viewing Time'
                    },
                    {
                        label: 'Total Views (Bottom 10)',
                        unit: 'count',
                        value: 'categories.least_popular.total_views',
                        text: 'Movie Total Views',
                        free_tier: 1,
                        section: 'bottom_10_total_views',
                        small_count_label: 'Total Views'
                    },
                    {
                        label: 'Total Viewing Time (Bottom 10)',
                        unit: 'hr',
                        value: 'categories.least_popular.total_time',
                        text: 'Movie Total Viewing Time',
                        free_tier: 0,
                        section: 'bottom_10_total_time',
                        small_count_label: 'Total Viewing Time'
                    }
                ];
                break;
            case 'Excelmovies':
                dropdownList = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'categories.all_records.total_views',
                        text: 'Movies Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'categories.all_records.total_time',
                        text: 'Audio Total Listening Time'
                    }
                ];
                break;
            case 'TV Series':
                dropdownList = [
                    {
                        label: 'Top 10 Total Views',
                        unit: 'count',
                        value: 'categories.most_popular.total_views',
                        text: 'TV Series Total Views',
                        default: 1,
                        free_tier: 1,
                        section: 'top_10_total_views',
                        small_count_label: 'Total Views'
                    },
                    {
                        label: 'Top 10 Total Viewing Time',
                        unit: 'hr',
                        value: 'categories.most_popular.total_time',
                        text: 'TV Series Total Viewing Time',
                        free_tier: 0,
                        section: 'top_10_total_time',
                        small_count_label: 'Total Viewing Time'
                    },
                    {
                        label: 'Bottom 10 Total Views',
                        unit: 'count',
                        value: 'categories.least_popular.total_views',
                        text: 'TV Series Total Views',
                        free_tier: 1,
                        section: 'bottom_10_total_views',
                        small_count_label: 'Total Views'
                    },
                    {
                        label: 'Bottom 10 Total Viewing Time',
                        unit: 'hr',
                        value: 'categories.least_popular.total_time',
                        text: 'TV Series Total Viewing Time',
                        free_tier: 0,
                        section: 'bottom_10_total_time',
                        small_count_label: 'Total Viewing Time'
                    }
                ];
                break;
            case 'Exceltv-series':
                dropdownList = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'categories.all_records.total_views',
                        text: 'Movies Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'hr',
                        value: 'categories.all_records.total_time',
                        text: 'Audio Total Listening Time'
                    }
                ];
                break;
            case 'Audio':
                dropdownList = [
                    {
                        label: 'Total Listens (Top 10)',
                        unit: 'count',
                        value: 'categories.most_popular.total_views',
                        text: 'Audio Total Hits',
                        section: 'top_10_total_views',
                        default: 1,
                        free_tier: 1,
                        small_count_label: 'Total Hits'
                    },
                    {
                        label: 'Total Listening Time (Top 10)',
                        unit: 'hr',
                        value: 'categories.most_popular.total_time',
                        text: 'Audio Total Listening Time',
                        section: 'top_10_total_time',
                        free_tier: 0,
                        small_count_label: 'Total Listening Time'
                    },
                    {
                        label: 'Total Listens (Bottom 10)',
                        unit: 'count',
                        value: 'categories.least_popular.total_views',
                        text: 'Audio Total Hits',
                        section: 'bottom_10_total_views',
                        free_tier: 1,
                        small_count_label: 'Total Hits'
                    },
                    {
                        label: 'Total Listening Time (Bottom 10)',
                        unit: 'hr',
                        value: 'categories.least_popular.total_time',
                        text: 'Audio Total Listening Time',
                        section: 'bottom_10_total_time',
                        free_tier: 0,
                        small_count_label: 'Total Listening Time'
                    }
                ];
                break;
            case 'Excelaudio':
                dropdownList = [
                    {
                        label: 'Total Listens',
                        unit: 'count',
                        value: 'categories.all_records.total_views',
                        text: 'Audio Total Listens',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Total Listening Time',
                        unit: 'hr',
                        value: 'categories.all_records.total_time',
                        text: 'Audio Total Listening Time'
                    }
                ];
                break;
            case 'Games':
                dropdownList = [
                    {
                        label: 'Top 10 Total Views',
                        unit: 'count',
                        value: 'categories.most_popular.total_views',
                        text: 'Games Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Top 10 Total Viewing Time',
                        unit: 'hr',
                        value: 'categories.most_popular.total_time',
                        text: 'Games Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Bottom 10 Total Views',
                        unit: 'count',
                        value: 'categories.least_popular.total_views',
                        text: 'Games Total Views',
                        free_tier: 1
                    },
                    {
                        label: 'Bottom 10 Total Viewing Time',
                        unit: 'hr',
                        value: 'categories.least_popular.total_time',
                        text: 'Games Total Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Live TV':
                dropdownList = [
                    {
                        label: 'Top 10 Total Views',
                        unit: 'count',
                        value: 'categories.most_popular.total_views',
                        text: 'Live TV Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        label: 'Top 10 Total Viewing Time',
                        unit: 'hr',
                        value: 'categories.most_popular.total_time',
                        text: 'Live TV Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        label: 'Bottom 10 Total Views',
                        unit: 'count',
                        value: 'categories.least_popular.total_views',
                        text: 'Live TV Total Views',
                        free_tier: 1
                    },
                    {
                        label: 'Bottom 10 Total Viewing Time',
                        unit: 'hr',
                        value: 'categories.least_popular.total_time',
                        text: 'Live TV Total Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            default:
        }
    
        return dropdownList;
    }
};


