import React, { useEffect, useState } from 'react';
import AudioPlaceholderImage from 'assets/images/posters/audio.svg';
import GamesPlaceholderImage from 'assets/images/posters/games.svg';
import LiveTVPlaceholderImage from 'assets/images/posters/livetv.svg';
import MoviesPlaceholderImage from 'assets/images/posters/movies.svg';
import TVSeriesPlaceholderImage from 'assets/images/posters/tvseries.svg';
import EReaderPlaceholderImage from 'assets/images/posters/ereader.svg';
import Slider from 'react-slick';
import UtilHelper from 'util/UtilHelper.js';
import Analytics from 'components/widgets/Analytics';
import { Modal, Container, ModalHeader, ModalBody } from 'reactstrap';
import Popup from 'components/media-details/MediaDetailsContainer';
import SubscriptionService from 'services/SubscriptionService';

const utilHelper = new UtilHelper();

const CarouselItem = (props) => {
    const [itemState, setItemState] = useState({ isBig: 'rect', placeholderDisplay: 'none' });

    useEffect(() => {
        async function getImage() {
            let img = new Image();
            if (props.image) {
                img.src = props.image;
                await img.decode();
                if (Math.abs(img.height - img.width) < 10) {
                    setItemState({ isBig: 'square', placeholderDisplay: itemState.placeholderDisplay });
                } else if (img.height > img.width) {
                    setItemState({ isBig: 'rect', placeholderDisplay: itemState.placeholderDisplay });
                } else if (img.height < img.width) {
                    setItemState({ isBig: 'nano', placeholderDisplay: itemState.placeholderDisplay });
                }
            } else {
                setItemState({ isBig: itemState.isBig, placeholderDisplay: 'block' });
            }
        }
        getImage();
    }, [props.image]);

    var src = props.image;
    var isGames = props.page === 'games' || props.page === 'movies' || props.page === 'tv-series';
    let percentage = !props.pct ? '' : props.pct < 0.1 ? '<0.1%' : `${props.pct}%`;

    //hide change until data is available
    function handleLangChange() {
        Analytics.analytics.track('handleLangChange', {
            visual_name: props.visual,
            interaction_type: 'Carousel Item Click',
            interaction_value: props.title
        });
        return props.onMediaSelected();
    }

    var color =
        props.comparison_value && props.comparison_value > 0
            ? '#1A6B32'
            : props.comparison_value < 0
              ? '#ea4335'
              : '#7A7A7A';

    var arrow =
        props.comparison_value && props.comparison_value > 0 ? (
            '▲'
        ) : props.comparison_value < 0 ? (
            '▼'
        ) : props.comparison_value === 0 ? (
            <i className="fas fa-sort" style={{ fontSize: 16 }}></i>
        ) : (
            ''
        );
    var placeholderPoster = AudioPlaceholderImage;
    let value = props.comparison_value ? props.comparison_value.replace('-', '') : null;
    value = value == null ? 'N/A' : Math.abs(value) > 999 ? '999+' : value > 100 ? Math.round(value) : value;

    switch (props.page) {
        case 'audio':
            placeholderPoster = AudioPlaceholderImage;
            break;
        case 'games':
            placeholderPoster = GamesPlaceholderImage;
            break;
        case 'live-tv':
            placeholderPoster = LiveTVPlaceholderImage;
            break;
        case 'movies':
            placeholderPoster = MoviesPlaceholderImage;
            break;
        case 'tv-series':
            placeholderPoster = TVSeriesPlaceholderImage;
            break;
        case 'ereader':
            placeholderPoster = EReaderPlaceholderImage;
            break;
        default:
            placeholderPoster = AudioPlaceholderImage;
    }

    var altTitle = props.title ? props.title : props.title === null ? 'null' : '\u00A0';
    let _dashboard = '';

    let cursorStyle =
        props.imageId && props.title && props.title.toLowerCase() !== '===' && props.title !== 'null'
            ? ' '
            : ' cursor-default';

    let showComparison = SubscriptionService.subscription() === 'Standard' && props.comparison_value != null;

    _dashboard = (
        <div className={'carousel-item ' + props.page} style={{ width: props.width }}>
            <div
                className={'carousel-image-wrapper ' + props.page + cursorStyle}
                style={{
                    height: 248,
                    backgroundColor: 'black',
                    opacity: props.blurred ? '0.25' : '1.0'
                }}
                onClick={handleLangChange}
            >
                <img
                    className="carousel-placeholder-image"
                    src={placeholderPoster}
                    alt={''}
                    style={{ display: itemState.placeholderDisplay }}
                />
                <div className="carousel-alt-title" style={{ display: itemState.placeholderDisplay }}>
                    {altTitle}
                </div>
                <img
                    className="carousel-item-image"
                    src={src}
                    alt={''}
                    style={{
                        display: src ? (src.endsWith('null') || src.endsWith('None') ? 'none' : 'block') : 'none',
                        margin: 0,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        borderRadius: itemState.isBig === 'rect' ? '' : 0,
                        boxShadow: 'none',
                        transform: 'translate(-50%, -50%)',
                        height: !isGames
                            ? ''
                            : itemState.isBig === 'rect'
                              ? ''
                              : itemState.isBig === 'square'
                                ? '167px'
                                : itemState.isBig === 'nano'
                                  ? '127px'
                                  : ''
                    }}
                    onLoad={(e) => {
                        e.target.classList.add('carousel-item-image-shadow');
                        e.target.style.opacity = '1';
                    }}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.style.opacity = '0.01';
                        window.setTimeout(() => {
                            setItemState({ isBig: itemState.isBig, placeholderDisplay: 'block' });
                        }, 300);
                    }}
                />
                <div className="carousel-flag-number">{props.number}</div>
            </div>
            <div className="carousel-item-title" title={props.title}>
                {props.title ? props.title : props.title === null ? 'null' : '\u00A0'}
            </div>
            <div className="carousel-item-data" style={{ display: !props.blurred ? 'initial' : 'none' }}>
                {props.data}
                {percentage ? ` (${percentage})` : ''}
            </div>
            <div
                className="carousel-item-change"
                style={{ color: color, display: showComparison ? 'initial' : 'none' }}
            >
                <span className="arrow">{arrow}</span>
                {value + '%'}
            </div>
        </div>
    );

    return <div>{_dashboard}</div>;
};

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leftVisible: 'hide',
            rightVisible: 'show',
            modal: props.modal,
            popup_props: '',
            showPopup: false
        };

        this.togglePopup = this.togglePopup.bind(this);
    }

    togglePopup() {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            showPopup: !this.state.showPopup
        }));
    }

    clickHandler(direction) {
        if (direction === 'prev') {
            this.slider.slickPrev();
        } else if (direction === 'next') {
            this.slider.slickNext();
        }
    }

    hideArrow(side) {
        var lv = 'show';
        var rv = 'show';
        if (side === 'left') lv = 'hide';
        else if (side === 'right') rv = 'hide';

        this.setState({
            leftVisible: lv,
            rightVisible: rv
        });
    }

    closeModal() {
        this.setState({ modal: false, showPopup: false });
    }

    showModal() {
        this.setState({ modal: true });
    }

    async componentDidUpdate(prevProps) {
        var ret = {};
        for (var i in this.props) {
            if (!prevProps.hasOwnProperty(i) || this.props[i] !== prevProps[i]) {
                ret[i] = this.props[i];
            }
        }
        if (Object.keys(ret).length) {
            this.slider.slickGoTo(0);
        }
    }

    onMediaSelected(props) {
        if (props.imageId && props.imagename && props.imagename.toLowerCase() !== '===' && props.imagename !== 'null') {
            this.setState({
                showPopup: !this.state.showPopup,
                modal: !this.state.modal,
                popup_props: props
            });
        }
    }

    render() {
        let { data } = this.props;

        if (typeof data === 'string') {
            data = JSON.parse(this.props.data);
        }

        if (typeof data === 'undefined') {
            data = [];
        }

        let blurred = false;
        if (data && data.length === 0) {
            data = [
                { label: null, value: null },
                { label: null, value: null },
                { label: null, value: null },
                { label: null, value: null },
                { label: null, value: null }
            ];
            blurred = true;
        }

        let items = [];
        let _reactapphost = process.env.REACT_APP_IMAGE_URL;

        for (var i = 0; i < data.length; i++) {
            var roundedData;
            if (this.props.unit === 'count') {
                roundedData = utilHelper.formatToUnitsForPillBox(data[i].value);
            } else if (this.props.unit === 'hr') {
                roundedData = utilHelper.secondsToHmsForDynamicTabs(data[i].value);
            }

            var width = /*i===(data.length-1) ? 167 : */ 191;
            let imageId = data[i].id ? data[i].id : null;
            let imagename = data[i].label;

            let imagepath;
            if (data[i].image_url) {
                imagepath = _reactapphost + '/' + data[i].image_url;
            }

            let page = this.props.page,
                contentType = this.props.contentType,
                appliedFilters = this.props.appliedFilters,
                app = this.props.app,
                unit = this.props.unit;

            items.push(
                <CarouselItem
                    number={i + 1}
                    key={i}
                    image={imagepath}
                    title={data[i].label}
                    data={roundedData}
                    comparison_value={data[i].comparison_value ? data[i].comparison_value : null}
                    imageId={imageId}
                    width={width}
                    page={this.props.page}
                    app={app}
                    onMediaSelected={() =>
                        this.onMediaSelected({
                            page,
                            imageId,
                            imagename,
                            contentType,
                            appliedFilters,
                            app,
                            unit
                        })
                    }
                    change={data[i].change}
                    blurred={blurred}
                    pct={data[i].total_pct ? data[i].total_pct : null}
                    isBig="rect"
                    visual={this.props.title}
                />
            );
        }

        var { leftVisible } = this.state;
        var { rightVisible } = this.state;

        var settings = {
            infinite: false,
            dots: true,
            arrows: false,
            speed: 600,
            slidesToShow: 3,
            slidesToScroll: 3,
            variableWidth: false,
            cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
            beforeChange: (current, next) => {
                let direction;
                if (next < current) {
                    direction = 'Previous';
                } else if (next > current) {
                    direction = 'Next';
                }

                if (next === 0) {
                    this.hideArrow('left');
                } else if (next >= 5) {
                    this.hideArrow('right');
                } else {
                    this.hideArrow('none');
                }

                Analytics.analytics.track('beforeChange', {
                    visual_name: this.props.title,
                    interaction_type: 'Carousel Scroll',
                    interaction_value: direction
                });
            },
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 1350,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 1450,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 1550,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5
                    }
                },
                {
                    breakpoint: 1850,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5
                    }
                },
                {
                    breakpoint: 1950,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5
                    }
                },
                {
                    breakpoint: 1550,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5
                    }
                },
                {
                    breakpoint: 2200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5
                    }
                }
            ]
        };

        let showArrows = items && items.length > 5;

        let carousel = [
            <div
                key={'arrow_left_content_tiles_' + this.props.type}
                className={'carousel-arrow left ' + leftVisible + ' ' + this.props.page}
                onClick={(e) => this.clickHandler('prev', true)}
                style={{ display: showArrows ? 'initial' : 'none' }}
            >
                &lsaquo;
            </div>,

            <Slider
                key={'slider_content_tiles_' + this.props.type}
                ref={(slider) => (this.slider = slider)}
                {...settings}
                className={blurred ? ' blurred' : ''}
            >
                {items}
            </Slider>,

            <div
                className="carousel-no-data"
                key={'no_data_content_tiles_' + this.props.type}
                style={{ display: blurred ? 'initial' : 'none' }}
            >
                Data is not available
            </div>,

            <div
                key={'arrow_right_content_tiles_' + this.props.type}
                className={'carousel-arrow right ' + rightVisible + ' ' + this.props.page}
                onClick={(e) => this.clickHandler('next', true)}
                style={{ display: showArrows ? 'initial' : 'none' }}
            >
                &rsaquo;
            </div>
        ];

        const closeBtn = (
            <button className="close" onClick={this.togglePopup}>
                &times;
            </button>
        );

        return (
            <div>
                <div
                    key={'outer_content_tiles_' + this.props.type}
                    className={'carousel-wrapper ' + this.props.type + ' ' + this.props.page}
                >
                    {carousel}
                </div>

                {this.state.showPopup ? (
                    <Container>
                        <Modal isOpen={this.state.modal} toggle={this.togglePopup} className="manage-detail">
                            <ModalHeader toggle={this.togglePopup} close={closeBtn}>
                                {' '}
                                title
                            </ModalHeader>
                            <ModalBody>
                                <Popup dataset={this.state.popup_props} visual={this.props.title} />
                            </ModalBody>
                        </Modal>
                    </Container>
                ) : null}
            </div>
        );
    }
}

export default Carousel;
