import { useState } from 'react';
import Lopa from 'components/lopa/Lopa';
import WidgetFrameNew from 'components/widget-frame/WidgetFrameNew';

function LopaWidget(props) {
    const [flightInfo, setFlightInfo] = useState({});
    let startDate = '';
    let endDate = '';

    if (props.appliedFilters.api && props.appliedFilters.api.date_range) {
        let dr = props.appliedFilters.api.date_range;
        dr = dr.split('-');

        let year = parseInt(dr[0].substring(0, 4), 10);
        let month = parseInt(dr[0].substring(4, 6), 10);
        let day = parseInt(dr[0].substring(6, 8), 10);
        startDate = new Date(year, month - 1, day);

        year = parseInt(dr[1].substring(0, 4), 10);
        month = parseInt(dr[1].substring(4, 6), 10);
        day = parseInt(dr[1].substring(6, 8), 10);
        endDate = new Date(year, month - 1, day);
    }

    let body = (
        <Lopa
            startDate={startDate}
            endDate={endDate}
            data={props.data}
            getFlights={props.getFlights}
            getFlightData={props.getFlightData}
            clearFlightData={props.clearFlightData}
            showChangeSeatInfoType={props.showWorkbenchLink}
            setFlightInfo={setFlightInfo}
            tenantIcaoCode={props.tenantIcaoCode || ''}
            ipsEnabled={props.ipsEnabled}
            title={props.title}
            validDates={props.validDates}
        />
    );

    if (props && props.validDates.length == 0) {
        body = (
            <div className="no-data" style={{ marginTop: '18%', fontSize: '32px' }}>
                Data is not available
            </div>
        );
    }

    return (
        <WidgetFrameNew
            type="lopa"
            title={props.title}
            showTabs={false}
            showWorkbenchLink={props.showWorkbenchLink || false}
            showOverflow={true}
            page={props.page}
            loading={props.loading}
            appliedFilters={props.appliedFilters.api}
            app={props.app}
            tenantIcaoCode={props.tenantIcaoCode || ''}
            pageName={props.pageName}
            data={props.data}
            flightInfo={flightInfo}
            dataAvailable={!(Object.keys(props.data).length > 0) ? false : true}
            body={body}
        />
    );
}

export default LopaWidget;
