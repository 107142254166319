import { Component } from 'react';
import { Row } from 'reactstrap';
import Analytics from 'components/widgets/Analytics';

class PageTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: 0
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.path !== this.props.path) {
            this.tabClick(0);
        }
    }

    setActive = (i) => {
        this.setState({
            active: i
        });
    };

    tabClick = (i) => {
        Analytics.analytics.track('tabClick', {
            visual_name: 'Dashboard',
            interaction_type: 'Tabs Click',
            interaction_value: this.props.tabs[i]
        });

        this.setState({
            active: i
        });
        this.props.tabFunction(i);
    };

    render() {
        var tabsData = this.props.tabs;
        var tabs = [];

        for (var i = 0; i < tabsData.length; i++) {
            var active = this.state.active === i;
            tabs.push(
                <div
                    key={`workbench_tab_${i}`}
                    className={active ? 'header-tab active' : 'header-tab'}
                    onClick={this.tabClick.bind(this, i)}
                >
                    <div className="tab-title" style={{ color: active ? '#000000' : '#909090' }}>
                        {tabsData[i]}
                    </div>
                </div>
            );
        }

        return (
            <Row>
                <div className="header-tabs">{tabs}</div>
            </Row>
        );
    }
}

export default PageTabs;
