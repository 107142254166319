import React from 'react';
import Select, { components } from 'react-select';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import QuicksightDataHelper from 'components/workbench/quicksight-data-helper.js';
import QuickSightEmbedUrlService from 'services/QuickSightEmbedUrlService';
import Circular from 'assets/images/icons/circular.gif';
import DynamicFilterHelper from '../../data/dynamic-filter-helper';
import Analytics from 'components/widgets/Analytics';

const quicksightDataHelper = new QuicksightDataHelper();

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faSearch : faCaretDown} />
            </components.DropdownIndicator>
        )
    );
};

class QuicksightWorkbenchComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            embedUrl: '',
            loading: true,
            contentType: '',
            options: [],
            selected: null,
            iframeIndex: 0,
            filters: '',
            filtersUpdated: false,
            iframeAttributes: {
                'overflow-y': 'auto',
                frameBorder: '0',
                height: '100%',
                width: '100%',
                id: 'quickSightDashboard',
                title: 'dashboard',
                src: ''
            },
            invalidMyInsightsDateRange: false
        };
    }

    async componentDidMount() {
        this.props.tabFunction(0);
        this.setState({ filters: this.props.filters, filtersUpdated: true }, () => this.setDropdownValues());
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.path !== this.props.path) {
            this.setDropdownValues();
        }
    }

    setDropdownValues() {
        let mediaCycleFilterSet = false;
        if (this.state.filters && this.state.filters.indexOf('MediaCycle') !== -1) {
            mediaCycleFilterSet = true;
        }

        let isRackDataMode = window.sessionStorage.getItem('rack_data_env') === 'Rack';
        let options = quicksightDataHelper.getDropdownValues(this.props.path, mediaCycleFilterSet, isRackDataMode);
        let defaultSelected = options.filter(function (option, index) {
            return option.default;
        });

        this.setState(
            { options: options, selected: defaultSelected[0], contentType: defaultSelected[0].content_type },
            () => {
                if (this.props.loadDataOnMount) {
                    this.fetchQuickSightWorkbenchEmbeddedUrl(false);
                }
            }
        );
    }

    async fetchQuickSightWorkbenchEmbeddedUrl(refetchDashboard = true) {
        this.setState({ loading: true });

        let dashboardId = this.state.selected.dashboard_id[process.env.REACT_APP_STAGE];
        if (refetchDashboard && ['Movies', 'TV Series', 'Audio'].includes(this.props.app)) {
            let mediaCycleFilterSet = false;
            if (this.state.filters && this.state.filters.indexOf('MediaCycle') !== -1) {
                mediaCycleFilterSet = true;
            }
            let isRackDataMode = window.sessionStorage.getItem('rack_data_env') === 'Rack';
            let options = quicksightDataHelper.getDropdownValues(this.props.path, mediaCycleFilterSet, isRackDataMode);
            let defaultSelected = options.filter(function (option, index) {
                return option.default;
            });

            this.setState({
                options: options,
                selected: defaultSelected[0],
                contentType: defaultSelected[0].content_type
            });
            dashboardId = defaultSelected[0].dashboard_id[process.env.REACT_APP_STAGE];
        }

        let service = new QuickSightEmbedUrlService();

        if (
            this.props.app == 'My Insights' &&
            (dashboardId.includes('Application_Usage') || dashboardId.includes('Entertainment_Usage'))
        ) {
            let filters = this.state.filters.split('&p.');
            let end_date = filters[2].split('EndDate=')[1];
            let end_data = end_date.split('-');
            let end_month = end_data[1];

            let isCurrentYearJan = end_month == '01' && this.props.dateRangeText == 'Current Year';

            if (this.props.dateRangeText == 'Custom' || this.props.dateRangeText == 'Last Week' || isCurrentYearJan) {
                this.setState({ invalidMyInsightsDateRange: true }, () => this.buildQuicksightWorkbenchDashboard());
                return;
            }
        }

        await service
            .fetchUrl(dashboardId)
            .then((response) => {
                if (!response.arn) {
                    this.setState({ error: 'Data Unavailable', invalidMyInsightsDateRange: false });
                } else {
                    this.setState({ embedUrl: response['embed-url'], invalidMyInsightsDateRange: false }, () =>
                        this.buildQuicksightWorkbenchDashboard()
                    );
                }
            })
            .catch((error) => {
                console.error('onRejected function called: ' + error.message);
            });
    }

    buildQuicksightWorkbenchDashboard() {
        if (this.state.embedUrl && this.state.selected && (this.state.filters || this.props.forceLoad)) {
            let quickSightUrl = this.state.embedUrl;
            quickSightUrl = quickSightUrl.replace('resetDisabled=true', 'resetDisabled=false');

            // Set 'ContentType' parameter.
            let contentTypeParam = '';
            if (['passenger_engagement', 'passenger_touch_points'].includes(this.state.selected.value)) {
                if (this.state.contentType !== '') {
                    contentTypeParam = '&p.ContentType=' + this.state.contentType;
                } else {
                    let allowedContentTypes = this.props.allowedContentTypes;

                    // Unique merged content types
                    if (
                        this.state.selected.include_content_types &&
                        Object.keys(this.state.selected.include_content_types).length > 0
                    ) {
                        if (allowedContentTypes && Object.keys(allowedContentTypes).length > 0) {
                            allowedContentTypes = allowedContentTypes.concat(this.state.selected.include_content_types);
                            allowedContentTypes = allowedContentTypes.filter((v, i, a) => a.indexOf(v) === i);
                        }
                    }
                    if (allowedContentTypes && Object.keys(allowedContentTypes).length > 0) {
                        for (let i = 0; i < allowedContentTypes.length; i++) {
                            contentTypeParam += '&p.ContentType=' + allowedContentTypes[i];
                        }
                    }
                }
            }

            // Exclude 'Tail' filters without key=value format.
            let selectedFilters = '';
            if (this.state.filters) {
                selectedFilters = JSON.parse(JSON.stringify(this.state.filters));
                if (selectedFilters) {
                    let filterList = selectedFilters.split('&');

                    filterList = filterList.filter((element) => {
                        return element !== '';
                    });

                    filterList = filterList.filter((element) => {
                        if (element.indexOf('Tail') > 0) {
                            let tailKeyValue = element.split('=');
                            if (tailKeyValue.length === 2) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    });

                    selectedFilters = '&' + filterList.join('&');
                }
            }

            let icaoCode = this.props.tenantIcaoCode.toUpperCase();
            let icaoCodeParam = '&p.AirlineOperatorCode=' + icaoCode; // todo uncoment after testing.

            let appParam = '&p.AppName=' + this.props.app;

            quickSightUrl +=
                '&#p.requestedSec=' +
                this.state.selected.value +
                selectedFilters +
                contentTypeParam +
                icaoCodeParam +
                appParam;
            if (quickSightUrl !== undefined) {
                let iframeAttributes = {
                    'overflow-y': 'auto',
                    frameBorder: '0',
                    height: '100%',
                    width: '100%',
                    id: 'quickSightDashboard',
                    title: 'dashboard',
                    src: quickSightUrl
                };
                this.setState({
                    loading: false,
                    filtersUpdated: false,
                    iframeAttributes: iframeAttributes,
                    embedUrl: '',
                    iframeIndex: this.state.iframeIndex + 1
                });
            }
        } else {
            this.setState({
                loading: false
            });
        }
    }

    changeSelected() {
        if (this.state.filtersUpdated) {
            this.fetchQuickSightWorkbenchEmbeddedUrl();
        }
    }

    updateFilters(filters) {
        this.setState({ filters: filters, filtersUpdated: true }, () => this.changeSelected());
    }

    handleDropdownChange = (selectedOption) => {
        if (this.state.selected) {
            if (selectedOption) {
                Analytics.analytics.track('handleDropdownChange', {
                    visual_name: 'Workbench',
                    interaction_type: 'Dropdown Selection',
                    interaction_value: selectedOption.label
                });
                this.setState({ selected: selectedOption, loading: true }, () =>
                    this.fetchQuickSightWorkbenchEmbeddedUrl()
                );
            }
        }
    };

    render() {
        let { options, selected } = this.state;
        return (
            <React.Fragment>
                <Row className="mt-3">
                    <Col
                        md={!this.props.dropdownWidth ? '3' : '5'}
                        style={{ maxWidth: this.props.dropdownWidth ? this.props.dropdownWidth : '' }}
                        className="quick-sight"
                    >
                        <Select
                            value={selected}
                            name="tenant"
                            options={options}
                            classNamePrefix={this.props.dropdownClassPrefix ? this.props.dropdownClassPrefix : 'select'}
                            onChange={this.handleDropdownChange}
                            maxMenuHeight={320}
                            styles={DynamicFilterHelper.getDropdownStyle()}
                            components={{ DropdownIndicator }}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md="12">
                        <div
                            style={{
                                height: selected && selected.iframe_height ? selected.iframe_height : '1315px',
                                width: selected && selected.iframe_width ? selected.iframe_width : '1032px'
                            }}
                        >
                            {this.state.invalidMyInsightsDateRange && (
                                <Row className="mt-3">
                                    <Col md="12" className={this.state.loading ? 'quick-sight' : 'quick-sight-modal'}>
                                        {this.state.loading && (
                                            <div className="custom-loading" style={{ marginTop: '10%' }}>
                                                <img src={Circular} alt="" />
                                            </div>
                                        )}
                                        {!this.state.loading && (
                                            <Col>
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                        marginTop: '23%',
                                                        color: 'gray',
                                                        fontSize: '16px',
                                                        lineHeight: '24px'
                                                    }}
                                                >
                                                    No data to display
                                                </div>
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                        marginTop: '2%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        maxWidth: '480px'
                                                    }}
                                                >
                                                    Month-on-Month reports require at least a full set of completed
                                                    month(s). Please select from the following: Last Month, Last 3
                                                    months, Last 6 months, Last Year, Current Year
                                                </div>
                                            </Col>
                                        )}
                                    </Col>
                                </Row>
                            )}

                            {!this.state.invalidMyInsightsDateRange && (
                                <Row className="mt-3">
                                    <Col md="12" className="quick-sight">
                                        <div
                                            style={{
                                                height:
                                                    selected && selected.iframe_height
                                                        ? selected.iframe_height
                                                        : '1315px',
                                                width:
                                                    selected && selected.iframe_width ? selected.iframe_width : '1032px'
                                            }}
                                        >
                                            {this.state.loading && (
                                                <div className="custom-loading" style={{ marginTop: '10%' }}>
                                                    <img src={Circular} alt="" />
                                                </div>
                                            )}
                                            {!this.state.loading && (
                                                <iframe
                                                    id="quickSightDashboard"
                                                    key={this.state.iframeIndex}
                                                    {...this.state.iframeAttributes}
                                                    title="QuicksightDashboard"
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default QuicksightWorkbenchComponent;
