import React from 'react';
import _, { debounce } from 'lodash';
import axios from 'axios';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import * as AppConstants from 'constants/app/constants';
import { filterMetricsByTier } from 'components/widgets/AccessControl';
import PillboxConfig from 'components/pillbox/PillboxConfig.js';
import 'assets/styles/widgets/coverage.css';
import PillboxTabs from 'components/pillbox/PillboxTabs.js';
import SubscriptionService from 'services/SubscriptionService';

const pillboxConfig = new PillboxConfig();

class AirlineSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: '',
            loading: true,
            error: false,
            ipsEnabled: false
        };

        this.cancelRequestSource = null;
        this.throttleFetch = debounce(this.throttleFetch.bind(this), AppConstants.THROTTLE_FETCH_TIME);
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) || this.props.forceLoad) {
            if (!this.props.onlyComparisonRangeUpdated || this.props.forceLoad) {
                if (this.props.appliedFilters.api) {
                    await this.fetchData(this.props.appliedFilters.api);
                }
            }
        }

        if (this.props.allowedApps.includes('IPS') && !this.state.ipsEnabled) {
            this.setState({ ipsEnabled: true });
        }
    }

    async fetchData(appliedFilters) {
        let requested_sections = pillboxConfig.getPillBoxSections(this.props.title);
        this.setState({ loading: true });

        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));

        // remove some metrics from basic tier
        if (this.props.title.includes('Electronic')) {
            requested_sections = filterMetricsByTier('ereader', requested_sections);
        }

        if (this.props.title === 'Live TV') {
            requested_sections = filterMetricsByTier('live-tv', requested_sections);
        }

        selectedFiltersDeepClone.requested_sections = requested_sections;

        if (this.props.title === 'Seatback') {
            selectedFiltersDeepClone.usage_source = 'Seatback';
        } else if (this.props.title === 'Portal') {
            selectedFiltersDeepClone.usage_source = 'Portal';
        } else if (this.props.title === 'Portal Navigation') {
            selectedFiltersDeepClone.usage_source = 'Portal';
        } else if (this.props.title === 'Companion App') {
            delete selectedFiltersDeepClone.usage_source;
            selectedFiltersDeepClone.launched_by = ['Companion App'];
        } else if (this.props.title === 'Bluetooth') {
            selectedFiltersDeepClone.usage_source = 'Bluetooth';
        }

        let contentType = pillboxConfig.getContentType(this.props.title);
        if (this.props.title.includes('Electronic')) {
            contentType = ['Electronic Reader'];
        }

        if (contentType) {
            selectedFiltersDeepClone.content_types = contentType;
        }

        if (SubscriptionService.subscription() !== '') {
            selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
        }

        if (this.props.app) {
            selectedFiltersDeepClone['app'] = this.props.app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        this.throttleFetch(selectedFiltersDeepClone);
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchFlightMetricsAxios(selectedFiltersDeepClone, this.cancelRequestSource.token)
            .then((response) => {
                if (!response && !response.requested_sections) {
                    this.setState({ error: true, dataset: [], loading: false });
                } else {
                    this.setState({
                        error: false,
                        dataset: response.requested_sections,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, dataset: [], loading: true });
                } else {
                    this.setState({ error: true, dataset: [], loading: false });
                }
            });
    }

    render() {
        let pillboxData = Object.entries(this.state.dataset).map((item) => ({
            key: item[0],
            data: item[1].data
        }));

        if (!this.state.ipsEnabled && !this.state.loading) {
            pillboxData = pillboxData.filter((obj) => !obj.key.includes('login'));
        }

        return (
            <React.Fragment>
                <div className="coverage-widget">
                    <PillboxTabs
                        loading={this.state.loading}
                        data={pillboxData}
                        title={this.props.title}
                        ipsEnabled={this.state.ipsEnabled}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default AirlineSummary;
