import React from 'react';
import { Card } from 'reactstrap';
import axios from 'axios';
import ErrorBoundary from 'util/ErrorBoundary.js';
import LopaWidget from 'components/lopa/LopaWidget';
import InsightsMonthlyService from 'services/InsightsMonthlyService';
import moment from 'moment';
import SubscriptionService from 'services/SubscriptionService';
import _, { debounce } from 'lodash';
import FiltersService from '../../services/FiltersService';
import LopaConfig from 'components/lopa/LopaConfig';

let service = new InsightsMonthlyService();
const lopaConfig = new LopaConfig();

class LopaContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataset: [],
            error: false,
            requestedSections: [],
            flights: [],
            validDates: [],
            loading: true
        };
        this.getFlights = this.getFlights.bind(this);
        this.getFlightData = this.getFlightData.bind(this);
        this.clearFlightData = this.clearFlightData.bind(this);

        this.cancelRequestSource = null;
    }

    async componentDidMount() {
        if (this.props.appliedFilters.api) {
            await this.fetchData(this.props.appliedFilters.api);
        }
    }

    async componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.appliedFilters, prevProps.appliedFilters) || prevProps.path !== this.props.path) {
            if (this.props.appliedFilters.api) {
                await this.fetchData(this.props.appliedFilters.api);
            }
        }
    }

    async fetchData(appliedFilters) {
        this.setState({ loading: true });

        if (FiltersService.dateRangeFiltersData !== '') {
            const selectedFiltersDeepClone = JSON.parse(JSON.stringify(appliedFilters));

            selectedFiltersDeepClone.usage_source = this.props.usageSource;
            selectedFiltersDeepClone.precheck = true;

            if (SubscriptionService.subscription() !== '') {
                selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
            }

            if (this.props.app) {
                selectedFiltersDeepClone['app'] = this.props.app;
            }

            if (this.cancelRequestSource) {
                this.cancelRequestSource.cancel('Operation canceled by the user.');
            }

            const CancelToken = axios.CancelToken;
            this.cancelRequestSource = CancelToken.source();
            this.throttleFetch(selectedFiltersDeepClone);
        }
    }

    async throttleFetch(selectedFiltersDeepClone) {
        let service = new InsightsMonthlyService();
        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        await service
            .fetchNextInsightsMetricsAxios(
                selectedFiltersDeepClone,
                lopaConfig.getAPIPath(),
                this.cancelRequestSource.token
            )

            .then(async (response) => {
                if (!response || response.data.flight_info.error) {
                    this.setState({ error: true, validDates: [], dataset: [], loading: false });
                } else {
                    this.setState({ error: false, validDates: response.data.flight_info, dataset: [], loading: false });
                }
            })

            .catch((error) => {
                if (error && error.error && error.error === 'Operation canceled by the user.') {
                    this.setState({ error: false, loading: false });
                } else {
                    this.setState({ error: true, dataset: [], loading: false });
                }
            });
    }

    async getFlights(date) {
        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(this.props.appliedFilters.api));
        selectedFiltersDeepClone.flight_date = moment(date).format('YYYYMMDD');

        if (SubscriptionService.subscription() !== '') {
            selectedFiltersDeepClone['tier'] = SubscriptionService.subscription();
        }

        if (this.props.app) {
            selectedFiltersDeepClone['app'] = this.props.app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        let res = await service.fetchFlightsAxios(selectedFiltersDeepClone, this.cancelRequestSource.token);
        if (res && res.data && res.data.flight_info && !res.data.flight_info.error) {
            return res.data.flight_info;
        }
        return [];
    }

    populateLopaData(usage, lopaData, seatClassMap, configKey, usageKey) {
        for (let [seatClass, seats] of Object.entries(usage)) {
            if (seats[configKey].rows) {
                lopaData[parseInt(seats[configKey].rows.min_row, 10)] = {};
                for (
                    let seatNum = parseInt(seats[configKey].rows.min_row, 10);
                    seatNum <= parseInt(seats[configKey].rows.max_row, 10);
                    seatNum++
                ) {
                    lopaData[parseInt(seats[configKey].rows.min_row, 10)][parseInt(seatNum, 10)] = {
                        row: parseInt(seatNum, 10),
                        seats: [],
                        seatClass: seatClassMap.hasOwnProperty(seatClass) ? seatClassMap[seatClass] : seatClass,
                        classInfo: seats[configKey]
                    };
                }

                for (let [seatNumber, seatUsageInfo] of Object.entries(seats[usageKey])) {
                    let num = seatNumber.slice(0, seatNumber.length - 1);
                    let letter = seatNumber.slice(-1);
                    if (lopaData[parseInt(seats[configKey].rows.min_row, 10)][parseInt(num, 10)]) {
                        lopaData[parseInt(seats[configKey].rows.min_row, 10)][parseInt(num, 10)].seats.push({
                            letter: letter,
                            seatData: seatUsageInfo
                        });
                    }
                }
            }
        }
    }

    async getFlightData(flight, date) {
        const selectedFilters = {};
        const seatClassMap = { CrewRestArea: 'Crew Rest' };
        selectedFilters.flight_number = flight.flight_number;
        selectedFilters.flight_start_date = moment(date).format('YYYYMMDD');
        selectedFilters.aircraft_tail_number = flight.aircraft_tail_number;
        selectedFilters.route = flight.route;
        selectedFilters.flight_start_time = flight.flight_start_time;

        if (SubscriptionService.subscription() !== '') {
            selectedFilters['tier'] = SubscriptionService.subscription();
        }

        if (this.props.app) {
            selectedFilters['app'] = this.props.app;
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        const CancelToken = axios.CancelToken;
        this.cancelRequestSource = CancelToken.source();

        let res = await service.fetchLopa(selectedFilters, this.cancelRequestSource.token);
        if (
            res &&
            !res.error &&
            res.data &&
            res.data.usage &&
            res.data.usage.class_usage &&
            res.data.usage.class_login
        ) {
            let lopaData = {};
            let lopaLoginData = {};
            let lopaLangData = {};

            this.populateLopaData(
                res.data.usage.class_usage,
                lopaData,
                seatClassMap,
                'seat_configs_per_usage',
                'seat_usage'
            );

            this.populateLopaData(
                res.data.usage.class_lang,
                lopaLangData,
                seatClassMap,
                'seat_configs_with_lang',
                'seat_lang'
            );

            this.populateLopaData(
                res.data.usage.class_login,
                lopaLoginData,
                seatClassMap,
                'seat_configs_with_login',
                'seat_login'
            );

            let seatLetters = [];
            let seatLoginLetters = [];
            let seatLangLetters = [];

            for (
                let letter = res.data.usage.seat_configs_per_usage.columns.min_column.charCodeAt();
                letter <= res.data.usage.seat_configs_per_usage.columns.max_column.charCodeAt();
                letter++
            ) {
                seatLetters.push(String.fromCharCode(letter));
            }

            if (
                res.data.usage.seat_configs_with_login.columns.min_column &&
                res.data.usage.seat_configs_with_login.columns.max_column
            ) {
                for (
                    let letter = res.data.usage.seat_configs_with_login.columns.min_column.charCodeAt();
                    letter <= res.data.usage.seat_configs_with_login.columns.max_column.charCodeAt();
                    letter++
                ) {
                    seatLoginLetters.push(String.fromCharCode(letter));
                }
            }

            if (
                res.data.usage.seat_configs_with_lang.columns.min_column &&
                res.data.usage.seat_configs_with_lang.columns.max_column
            ) {
                for (
                    let letter = res.data.usage.seat_configs_with_lang.columns.min_column.charCodeAt();
                    letter <= res.data.usage.seat_configs_with_lang.columns.max_column.charCodeAt();
                    letter++
                ) {
                    seatLangLetters.push(String.fromCharCode(letter));
                }
            }

            const sortedData = Object.values(lopaData).reduce((total, value, index, array) =>
                Object.values(total).concat(Object.values(value))
            );

            const sortedLoginData =
                Object.entries(lopaLoginData).length > 0
                    ? Object.values(lopaLoginData).reduce((total, value, index, array) =>
                          Object.values(total).concat(Object.values(value))
                      )
                    : [];

            const sortedLangData =
                Object.entries(lopaLangData).length > 0
                    ? Object.values(lopaLangData).reduce((total, value, index, array) =>
                          Object.values(total).concat(Object.values(value))
                      )
                    : [];

            this.setState({
                dataset: {
                    seatData: Object.values(sortedData),
                    seatLetters: seatLetters,
                    seatLoginData: Object.values(sortedLoginData),
                    seatLoginLetters: seatLoginLetters,
                    seatLangData: Object.values(sortedLangData),
                    seatLangLetters: seatLangLetters
                }
            });

            return {
                seatData: Object.values(sortedData),
                seatLetters: seatLetters,
                seatLoginData: Object.values(sortedLoginData),
                seatLoginLetters: seatLoginLetters,
                seatLangData: Object.values(sortedLangData),
                seatLangLetters: seatLangLetters
            };
        } else {
            return {};
        }
    }

    clearFlightData() {
        this.setState({ dataset: [] });
    }

    render() {
        let { dataset, loading, validDates } = this.state;

        return (
            <Card>
                <ErrorBoundary>
                    <LopaWidget
                        loading={loading}
                        key={'lopa'}
                        id={'lopa'}
                        data={dataset ? dataset : []}
                        title={this.props.title}
                        page={this.props.page}
                        getFlights={this.getFlights}
                        getFlightData={this.getFlightData}
                        clearFlightData={this.clearFlightData}
                        showWorkbenchLink={this.props.showWorkbenchLink}
                        appliedFilters={this.props.appliedFilters}
                        tenantIcaoCode={this.props.tenantIcaoCode || ''}
                        pageName={this.props.pageName}
                        app={this.props.app}
                        ipsEnabled={this.props.ipsEnabled}
                        validDates={validDates}
                    />
                </ErrorBoundary>
            </Card>
        );
    }
}

export default LopaContainer;
