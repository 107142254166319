import BaseService from './BaseService';

class UsersService extends BaseService {
    constructor() {
        let apiName = 'TenantManagementServiceAPI';
        let collectionPath = '/users';
        super(apiName, collectionPath);
    }

    async fetchUserTenants(userid) {
        let path = [this.collectionPath, userid, 'tenants'].join('/');

        let headers = {
            Authorization: await this.getAuthorizationHeader()
        };

        let queryStringParameters = { offset: 0, limit: 200 };

        let options = { headers: headers, queryStringParameters: queryStringParameters };

        return this.makeGetApiCall(this.apiName, path, options);
    }

    async fetchUserApplications(userSession, user) {
        let path = '/users/' + user.username + '/apps';

        let headers = {
            Authorization: userSession.idToken.jwtToken
        };

        let queryStringParameters = Object.assign({}, { offset: 0, limit: 200 });

        let options = { headers: headers, queryStringParameters: queryStringParameters };

        try {
            return await this.makeGetApiCall(this.apiName, path, options);
        } catch (e) {
            console.error(e);
        }
    }
}

export default UsersService;
