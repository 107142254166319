import UtilHelper from 'util/UtilHelper.js';

const utilHelper = new UtilHelper();

export default class TrendConfig {
    getAPIPath = function () {
        return '/v2/metrics/trend_usage_data';
    };

    getApplicationsAPIPath = function () {
        return '/v2/metrics/my_insights_trend_data';
    };

    getChartProperties = function (unit) {
        let linePropertiesTrend = {
            options: {
                unit: 'count',
                legend: {
                    display: false,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    displayColors: false,
                    titleFontSize: 14,
                    titleMarginBottom: 10,
                    bodyFontSize: 14,
                    bodyFontFamily: "'Inter', sans-serif",
                    bodySpacing: 8,
                    xPadding: 16,
                    yPadding: 12,
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {},
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090',
                                crossAlign: 'far',
                                callback: (dataSet) => {
                                    return utilHelper.formatToUnitsForPillBox(dataSet) + '   ';
                                }
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#2a7fc9',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#2a7fc9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            },
            graphStyle2: {
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#5B00A9',
                pointBackgroundColor: '#7500c5',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#5B00A9',
                pointHoverBorderColor: '#5B00A9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                fill: false,
                borderDash: [3, 3],
                borderColor: '#5B00A9'
            }
        };

        let linePropertiesTrendTime = {
            options: {
                unit: 'count',
                legend: {
                    display: false,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {},
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090',
                                crossAlign: 'far',
                                callback: (dataSet) => {
                                    return utilHelper.secondsToHmsWithoutUnit(dataSet, 1) + '   ';
                                }
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#2a7fc9',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#2a7fc9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            },
            graphStyle2: {
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#5B00A9',
                pointBackgroundColor: '#7500c5',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#5B00A9',
                pointHoverBorderColor: '#5B00A9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                fill: false,
                borderDash: [3, 3],
                borderColor: '#5B00A9'
            }
        };

        let linePropertiesTrendRoundingMixed = {
            options: {
                unit: 'count',
                legend: {
                    display: false,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {},
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090',
                                crossAlign: 'far',
                                callback: (dataSet) => {
                                    return utilHelper.secondsToHours(dataSet, 1) + '   ';
                                }
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#2a7fc9',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#2a7fc9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            },
            graphStyle2: {
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#5B00A9',
                pointBackgroundColor: '#7500c5',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#5B00A9',
                pointHoverBorderColor: '#5B00A9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                fill: false,
                borderDash: [3, 3],
                borderColor: '#5B00A9'
            }
        };

        let linePropertiesTrendTimeMixed = {
            options: {
                unit: 'count',
                legend: {
                    display: false,
                    position: 'top',
                    onClick: (e, item) => e.stopPropagation()
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {}
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {},
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 6,
                                fontColor: '#909090',
                                crossAlign: 'far',
                                callback: (dataSet) => {
                                    return utilHelper.secondsToHoursMinutes(dataSet) + '   ';
                                }
                            },
                            gridLines: {
                                drawBorder: false
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                fontColor: '#909090',
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                maintainAspectRatio: false
            },
            graphStyle: {
                datalabels: {
                    display: false
                },
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#2a7fc9',
                pointBackgroundColor: '#2a7fc9',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#2a7fc9',
                pointHoverBorderColor: '#2a7fc9',
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                pointHitRadius: 10,
                label: 'Actual',
                backgroundColor: 'rgba(57,100,148, 1.0)',
                borderColor: '#2a7fc9',
                fill: true
            },
            graphStyle2: {
                lineTension: 0,
                borderCapStyle: 'butt',
                borderDashOffset: 0,
                borderJoinStyle: 'miter',
                pointBorderColor: '#5B00A9',
                pointBackgroundColor: '#7500c5',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#5B00A9',
                pointHoverBorderColor: '#5B00A9',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                fill: false,
                borderDash: [3, 3],
                borderColor: '#5B00A9'
            }
        };

        if (unit === 'time') {
            return linePropertiesTrendTime;
        } else if (unit === 'mixed') {
            return linePropertiesTrendTimeMixed;
        } else if (unit === 'roundingMixed') {
            return linePropertiesTrendRoundingMixed;
        }

        return linePropertiesTrend;
    };

    getTrendChartHoverList = function (page, requestedSection) {
        let hoverList;

        switch (page) {
            case 'Bluetooth':
            case 'bluetooth':
                switch (requestedSection) {
                    case 'paired_devices':
                        hoverList = [
                            {
                                key: 'paired_devices',
                                title: 'Paired Devices',
                                unit: 'count',
                                label: 'Paired Devices',
                                excel: 0
                            },
                            {
                                key: 'bt_users',
                                title: 'Unique Passengers Used Bluetooth',
                                unit: 'count',
                                label: 'Unique Passengers Used Bluetooth',
                                short_label: 'Unique Passengers Used BT',
                                excel: 1
                            },
                            {
                                key: 'pairing_attempts',
                                title: 'Pairing Attempts',
                                unit: 'count',
                                label: 'Pairing Attempts',
                                excel: 1
                            },
                            {
                                key: 'successful_attempts',
                                title: 'Successful Attempts',
                                unit: 'count',
                                label: 'Successful Attempts',
                                excel: 1
                            },
                            {
                                key: 'failed_attempts',
                                title: 'Failed Attempts',
                                unit: 'count',
                                label: 'Failed Attempts',
                                excel: 1
                            },
                            {
                                key: 'reconnects',
                                title: 'Reconnects',
                                unit: 'count',
                                label: 'Reconnects',
                                excel: 1
                            }
                        ];
                        break;
                    case 'total_connections':
                        hoverList = [
                            {
                                key: 'avg_connection_time',
                                title: 'Avg. Connection Duration by Passenger Device',
                                unit: 'hr',
                                label: 'Avg. Connection Duration by Passenger Device',
                                short_label: 'Avg. Connection Duration',
                                excel: 1
                            },
                            {
                                key: 'avg_connections',
                                title: 'Avg. Connections per Flight',
                                unit: 'count',
                                label: 'Avg. Connections per Flight',
                                excel: 1
                            }
                        ];
                        break;
                    case 'total_disconnections':
                        hoverList = [
                            {
                                key: 'avg_disconnections',
                                title: 'Avg. Disconnections per Flight',
                                unit: 'count',
                                label: 'Avg. Disconnections per Flight',
                                excel: 1
                            }
                        ];
                        break;
                    case 'excel':
                        hoverList = [
                            {
                                key: 'paired_devices',
                                title: 'Paired Devices',
                                unit: 'count',
                                label: 'Paired Devices',
                                excel: 0
                            },
                            {
                                key: 'bt_users',
                                title: 'Unique Passengers Used Bluetooth',
                                unit: 'count',
                                label: 'Unique Passengers Used Bluetooth',
                                short_label: 'Unique Passengers Used BT',
                                excel: 1
                            },
                            {
                                key: 'pairing_attempts',
                                title: 'Pairing Attempts',
                                unit: 'count',
                                label: 'Pairing Attempts',
                                excel: 1
                            },
                            {
                                key: 'successful_attempts',
                                title: 'Successful Attempts',
                                unit: 'count',
                                label: 'Successful Attempts',
                                excel: 1
                            },
                            {
                                key: 'failed_attempts',
                                title: 'Failed Attempts',
                                unit: 'count',
                                label: 'Failed Attempts',
                                excel: 1
                            },
                            {
                                key: 'reconnects',
                                title: 'Reconnects',
                                unit: 'count',
                                label: 'Reconnects',
                                excel: 1
                            },
                            {
                                key: 'total_connections',
                                title: 'Total Connections',
                                y_axis_label: 'Connections',
                                unit: 'count',
                                label: 'Total Connections',
                                free_tier: 1,
                                excel: 1
                            },
                            {
                                key: 'avg_connection_time',
                                title: 'Avg. Connection Duration by Passenger Device',
                                unit: 'time',
                                label: 'Avg. Connection Duration by Passenger Device',
                                short_label: 'Avg. Connection Duration',
                                excel: 1
                            },
                            {
                                key: 'avg_connections',
                                title: 'Avg. Connections per Flight',
                                unit: 'count',
                                label: 'Avg. Connections per Flight',
                                excel: 1
                            },
                            {
                                key: 'total_disconnections',
                                title: 'Total Disconnections',
                                y_axis_label: 'Disconnections',
                                unit: 'count',
                                label: 'Total Disconnections',
                                free_tier: 1,
                                excel: 1
                            },
                            {
                                key: 'avg_disconnections',
                                title: 'Avg. Disconnections per Flight',
                                unit: 'count',
                                label: 'Avg. Disconnections per Flight',
                                excel: 1
                            }
                        ];
                        break;
                    default:
                }
                break;
            default:
                //  throw new Error("Please check the label you are passing")
                break;
        }

        return hoverList;
    };

    getTrendChartRequestedSections = function (reportSubType) {
        let requestedSections;

        switch (reportSubType) {
            case 'Movies':
            case 'TV Series':
                requestedSections = [
                    {
                        key: 'total_views',
                        title: 'Total Views',
                        y_axis_label: 'Views',
                        unit: 'count',
                        label: 'Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        key: 'total_users',
                        title: 'Unique Viewers',
                        y_axis_label: 'Viewers',
                        unit: 'count',
                        label: 'Unique Viewers',
                        free_tier: 0
                    },
                    {
                        key: 'total_usage_time',
                        title: 'Total Viewing Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        key: 'avg_users',
                        title: 'Avg. Viewers',
                        y_axis_label: 'Viewers',
                        unit: 'count',
                        label: 'Average Viewers',
                        free_tier: 0
                    },
                    {
                        key: 'avg_usage_time',
                        title: 'Avg. Viewing Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Average Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Seatback':
            case 'Portal':
                requestedSections = [
                    {
                        key: 'total_views',
                        title: 'Total Views',
                        y_axis_label: 'Views',
                        unit: 'count',
                        label: 'Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        key: 'total_users',
                        title: 'Unique Viewers',
                        y_axis_label: 'Viewers',
                        unit: 'count',
                        label: 'Unique Viewers',
                        free_tier: 0
                    },
                    {
                        key: 'total_usage_time',
                        title: 'Total Viewing Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        key: 'avg_users',
                        title: 'Avg. Viewers',
                        y_axis_label: 'Viewers',
                        unit: 'count',
                        label: 'Average Viewers',
                        free_tier: 0
                    },
                    {
                        key: 'avg_usage_time',
                        title: 'Avg. Viewing Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Average Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Games':
                requestedSections = [
                    {
                        key: 'total_views',
                        title: 'Total Plays',
                        y_axis_label: 'Plays',
                        unit: 'count',
                        label: 'Total Plays',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        key: 'total_users',
                        title: 'Unique Players',
                        y_axis_label: 'Players',
                        unit: 'count',
                        label: 'Unique Players',
                        free_tier: 0
                    },
                    {
                        key: 'total_usage_time',
                        title: 'Total Playing Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Total Playing Time',
                        free_tier: 0
                    },
                    {
                        key: 'avg_users',
                        title: 'Avg. Players',
                        y_axis_label: 'Players',
                        unit: 'count',
                        label: 'Average Players',
                        free_tier: 0
                    },
                    {
                        key: 'avg_usage_time',
                        title: 'Avg. Playing Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Average Playing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Audio':
                requestedSections = [
                    {
                        key: 'total_views',
                        title: 'Total Listens',
                        y_axis_label: 'Listens',
                        unit: 'count',
                        label: 'Total Listens',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        key: 'total_users',
                        title: 'Unique Listeners',
                        y_axis_label: 'Listeners',
                        unit: 'count',
                        label: 'Unique Listeners',
                        free_tier: 0
                    },
                    {
                        key: 'total_usage_time',
                        title: 'Total Listening Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Total Listening Time',
                        free_tier: 0
                    },
                    {
                        key: 'avg_users',
                        title: 'Avg. Listeners',
                        y_axis_label: 'Listeners',
                        unit: 'count',
                        label: 'Avg. Listeners',
                        free_tier: 0
                    },
                    {
                        key: 'avg_usage_time',
                        title: 'Avg. Listening Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Avg. Listening Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'Electronic Reader':
                requestedSections = [
                    {
                        key: 'total_title_views',
                        title: 'Total Title Views',
                        y_axis_label: 'Views',
                        unit: 'count',
                        label: 'Total Title Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        key: 'total_readers',
                        title: 'Unique Readers',
                        y_axis_label: 'Readers',
                        unit: 'count',
                        label: 'Unique Readers',
                        free_tier: 0
                    },
                    {
                        key: 'total_reading_time',
                        title: 'Total Reading Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Total Reading Time',
                        free_tier: 0
                    },
                    {
                        key: 'avg_reading_time',
                        title: 'Avg. Reading Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Avg. Reading Time',
                        free_tier: 0
                    },
                    {
                        key: 'avg_readers',
                        title: 'Avg. Readers',
                        y_axis_label: 'Readers',
                        unit: 'count',
                        label: 'Avg. Readers',
                        free_tier: 0
                    },
                    {
                        key: 'avg_page_reads',
                        title: 'Avg. Page Reads',
                        y_axis_label: 'Reads',
                        unit: 'count',
                        label: 'Avg. Page Reads',
                        free_tier: 0
                    }
                    //{ key: "total_views", title: "Total Title Views", y_axis_label: "Views", unit: "count", label: "Total Title Views", "free_tier": 0 },
                ];
                break;
            case 'Companion App':
                requestedSections = [
                    {
                        key: 'total_paired_users',
                        title: 'Total Paired Users',
                        y_axis_label: 'Users',
                        unit: 'count',
                        label: 'Avg. Users',
                        default: 1
                    },
                    {
                        key: 'total_users',
                        title: 'Total Paired Users launched Media',
                        y_axis_label: 'Users',
                        unit: 'count',
                        label: 'Total Users'
                    },
                    {
                        key: 'total_views',
                        title: 'Total Media Launches',
                        y_axis_label: 'Launches',
                        unit: 'count',
                        label: 'Total Media Launches',
                        free_tier: 1
                    },
                    {
                        key: 'avg_media_launches',
                        title: 'Avg. Media Launches per User',
                        y_axis_label: 'Launches',
                        unit: 'count',
                        label: 'Avg. Media Launches per User'
                    }
                ];
                break;
            case 'Bluetooth':
                requestedSections = [
                    {
                        key: 'paired_devices',
                        title: 'Paired Devices',
                        y_axis_label: 'Devices',
                        unit: 'count',
                        label: 'Paired Devices',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        key: 'total_connections',
                        title: 'Total Connections',
                        y_axis_label: 'Connections',
                        unit: 'count',
                        label: 'Total Connections',
                        free_tier: 1
                    },
                    {
                        key: 'total_disconnections',
                        title: 'Total Disconnections',
                        y_axis_label: 'Disconnections',
                        unit: 'count',
                        label: 'Total Disconnections',
                        free_tier: 1
                    }
                ];
                break;
            case 'EntertainmentTrends':
                requestedSections = [
                    {
                        key: 'movies',
                        title: 'Movies',
                        y_axis_label: 'Views',
                        unit: 'count',
                        label: 'Movies - Views',
                        free_tier: 1
                    },
                    {
                        key: 'tv series',
                        title: 'TV Series',
                        y_axis_label: 'Views',
                        unit: 'count',
                        label: 'TV Series - Views',
                        free_tier: 1
                    },
                    {
                        key: 'audio',
                        title: 'Audio',
                        y_axis_label: 'Listens',
                        unit: 'count',
                        label: 'Audio - Listens',
                        free_tier: 1
                    },
                    {
                        key: 'games',
                        title: 'Games',
                        y_axis_label: 'Plays',
                        unit: 'count',
                        label: 'Games - Plays',
                        free_tier: 1
                    },
                    {
                        key: 'live tv',
                        title: 'Live TV',
                        y_axis_label: 'Views',
                        unit: 'count',
                        label: 'Live TV - Views',
                        free_tier: 1
                    }
                ];
                break;
            case 'Live TV':
                requestedSections = [
                    {
                        key: 'total_views',
                        title: 'Total Views',
                        y_axis_label: 'Views',
                        unit: 'count',
                        label: 'Total Views',
                        default: 1,
                        free_tier: 1
                    },
                    {
                        key: 'total_users',
                        title: 'Unique Viewers',
                        y_axis_label: 'Viewers',
                        unit: 'count',
                        label: 'Total Viewers',
                        free_tier: 0
                    },
                    {
                        key: 'total_usage_time',
                        title: 'Total Viewing Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Total Viewing Time',
                        free_tier: 0
                    },
                    {
                        key: 'avg_users',
                        title: 'Avg. Viewers',
                        y_axis_label: 'Viewers',
                        unit: 'count',
                        label: 'Average Viewers',
                        free_tier: 0
                    },
                    {
                        key: 'avg_usage_time',
                        title: 'Avg. Viewing Time',
                        y_axis_label: 'Time',
                        unit: 'time',
                        label: 'Average Viewing Time',
                        free_tier: 0
                    }
                ];
                break;
            case 'ApplicationTrends':
                requestedSections = [
                    {
                        key: 'applications',
                        title: 'Applications',
                        free_tier: 1
                    }
                ];
                break;
            default:
                break;
        }

        return requestedSections;
    };

    setTopTrendApplicationConfigs = function (reqSectionsApplications) {
        let reqSections = [];
        for (let section of Object.keys(reqSectionsApplications)) {
            let sectionConfig = {};
            sectionConfig['key'] = section;
            sectionConfig['title'] = section;
            sectionConfig['y_axis_label'] = 'Views';
            sectionConfig['unit'] = 'count';
            sectionConfig['label'] = section;
            sectionConfig['free_tier'] = 1;
            reqSections.push(sectionConfig);
        }

        return reqSections;
    };

    getDropdownList = function (reportSubType) {
        let dropdownList;

        switch (reportSubType) {
            case 'Application Trends':
                dropdownList = [
                    {
                        label: 'Total App Launches (Top 5)',
                        unit: 'count',
                        value: 'top_5_total_views',
                        text: 'Total Views',
                        default: 1,
                        section: 'top_5_total_views',
                        small_count_label: 'Total App Launches',
                        small_count_section: 'total_views',
                        small_count_value: 'total_views',
                        y_axis_label: 'Launches'
                    },
                    {
                        label: 'Total App Launches (Bottom 5)',
                        unit: 'count',
                        value: 'bottom_5_total_views',
                        text: 'Total Views',
                        section: 'bottom_5_total_views',
                        small_count_label: 'Total App Launches',
                        small_count_section: 'total_views',
                        small_count_value: 'total_views',
                        y_axis_label: 'Launches'
                    },
                    {
                        label: 'Total Time Spent (Top 5)',
                        unit: 'time',
                        value: 'top_5_total_viewing_time',
                        text: 'Total Viewing Time',
                        section: 'top_5_total_viewing_time',
                        small_count_label: 'Total Time Spent',
                        small_count_section: 'total_viewing_time',
                        small_count_value: 'total_viewing_time',
                        y_axis_label: 'Time'
                    },
                    {
                        label: 'Total Time Spent (Bottom 5)',
                        unit: 'time',
                        value: 'bottom_5_total_viewing_time',
                        text: 'Total Viewing Time',
                        section: 'bottom_5_total_viewing_time',
                        small_count_label: 'Total Time Spent',
                        small_count_section: 'total_viewing_time',
                        small_count_value: 'total_viewing_time',
                        y_axis_label: 'Time'
                    }
                ];
                break;
            case 'ExcelApplication Trends':
                dropdownList = [
                    {
                        label: 'Total App Launches',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Total Views',
                        default: 1,
                        section: 'total_views',
                        small_count_label: 'Total Views',
                        y_axis_label: 'Launches',
                        free_tier: 1
                    },
                    {
                        label: 'Total Time Spent',
                        unit: 'time',
                        value: 'total_viewing_time',
                        text: 'Total Viewing Time',
                        section: 'total_viewing_time',
                        small_count_label: 'Total Viewing Time',
                        y_axis_label: 'Time'
                    }
                ];
                break;
            case 'Entertainment Trends':
                dropdownList = [
                    {
                        label: 'Total Views',
                        unit: 'count',
                        value: 'total_views',
                        text: 'Total Views',
                        default: 1,
                        section: 'total_views',
                        small_count_label: 'Total Views',
                        y_axis_label: 'Views'
                    },
                    {
                        label: 'Total Viewing Time',
                        unit: 'time',
                        value: 'total_viewing_time',
                        text: 'Total Viewing Time',
                        section: 'total_viewing_time',
                        small_count_label: 'Total Viewing Time',
                        y_axis_label: 'Time'
                    }
                ];
                break;
            default:
                dropdownList = [];
        }

        return dropdownList;
    };
}
