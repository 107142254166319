import React, { Component } from 'react';
import { Accordion, Card, Row, Col } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment';
import { ButtonPrimary, ButtonSecondary, DateRangePicker } from 'pac-ux-components';
import { DropdownMultiple, Checkbox, CheckboxSelectVariant, Chip } from 'pac-ux-components';
import _, { debounce } from 'lodash';
import display_names from 'data/dynamic-filter-display-data.js';
import DynamicFilterHelper from 'data/dynamic-filter-helper.js';
import FiltersService from '../../services/FiltersService';
import Analytics from 'components/widgets/Analytics';
import SubscriptionService from 'services/SubscriptionService';

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? faSearch : faCaretDown} />
            </components.DropdownIndicator>
        )
    );
};

const filterCheckboxNamesMapping = {
    systemType: 'System Type',
    aircraftType: 'Aircraft Type/Subtype',
    flightDuration: 'Flight Haul',
    seatClass: 'Seat Class'
};

class DynamicFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            applyFilterButtonDisabled: true,
            clearFilterButtonDisabled: true,
            dateRangeOptions: [],
            mediaCycleOptions: [],
            comparisonRangeOptions: [{ label: 'None', value: 'None' }],
            dateRangeValue: 'None',
            dateRangeText: '',
            mediaCycleText: 'None',
            mediaCycleValue: '',
            defaultDateRangeOption: {},
            comparisonRangeValue: '',
            comparisonRangeText: '',
            filterValues: [],
            selectedSystemTypes: new Map(),
            fromCityOptions: [{ label: 'None', value: 'None' }],
            toCityOptions: [{ label: 'None', value: 'None' }],
            flightNumbersOptions: [{ label: 'None', value: 'None' }],
            selectedFromCity: null,
            selectedToCity: null,
            selectedFlightNumber: null,
            appliedFromCity: null,
            appliedToCity: null,
            appliedFlightNumber: null,
            routeFilters: ['appliedFromCity', 'appliedToCity', 'appliedFlightNumber'],
            tailOptions: [{ label: 'Any', value: 'Any' }],
            allTailOptions: [{ label: 'Any', value: 'Any' }],
            multiSelectFilterLabel: 'Select Tail',
            parentLoading: true,
            forceSendUpdatedData: false,
            pageSelectedFilters: {
                systemType: [],
                aircraftType: [],
                flightDuration: [],
                seatClass: [],
                guiLanguage: [],
                tail: [],
                toCity: [],
                fromCity: [],
                flightNumber: []
            },
            selectedFilters: {
                systemType: [],
                aircraftType: [],
                flightDuration: [],
                seatClass: [],
                guiLanguage: [],
                tail: [],
                toCity: [],
                fromCity: [],
                flightNumber: []
            },
            appliedFilters: {},
            prevAppliedFilters: {},
            displayFilters: false,
            systemTypes: [{ label: 'None', value: 'None' }],
            aircraftTypes: [{ label: 'None', value: 'None' }],
            flightHaulTypes: [{ label: 'None', value: 'None' }],
            seatClasses: [{ label: 'None', value: 'None' }],
            displayDateRangeSelector: false,
            customStartDate: moment().subtract(1, 'months').startOf('month'),
            customEndDate: moment().subtract(1, 'months').endOf('month'),
            showDateRange: true
        };

        this.testData = {};

        this.cancelRequestSource = null;
        this.fetchFilterValues = debounce(this.fetchFilterValues.bind(this), 1000);

        this.accordionRef = React.createRef();

        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.handleFromCityChange = this.handleFromCityChange.bind(this);
        this.handleToCityChange = this.handleToCityChange.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleComparisonRangeChange = this.handleComparisonRangeChange.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.showMediaCycleFilters = this.showMediaCycleFilters.bind(this);
        this.handleMediaCycleChange = this.handleMediaCycleChange.bind(this);
    }

    async componentDidMount() {
        this.getSelectedFiltersFromStore();

        await this.fetchFilterValues();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.forceLoad) {
            this.setState({ parentLoading: true, forceSendUpdatedData: true }, () => this.fetchFilterValues());
        } else if (!_.isEqual(this.props.title, prevProps.title)) {
            this.setState({ parentLoading: true, forceSendUpdatedData: true }, () => this.fetchFilterValues());
        }
    }

    async fetchFilterValues() {
        if (this.state.expanded) {
            this.accordionRef.current.click();
        }

        if (this.cancelRequestSource) {
            this.cancelRequestSource.cancel('Operation canceled by the user.');
        }

        const CancelToken = axios.CancelToken;

        this.cancelRequestSource = CancelToken.source();

        let loadFiltersResponse = await FiltersService.loadAllFilters(
            this.cancelRequestSource,
            SubscriptionService.subscription(),
            this.props.app
        );

        let dateRangeOptionsOnly = [],
            defaultDateRange = [],
            dateRangeValue = [],
            dateRangeText = [];
        let comparisonRanges = [],
            defaultComparisionRange = '',
            comparisonRangeText = '',
            aircraftTypes = [];
        let fromCities = [],
            toCities = [],
            flightNumbers = [],
            systemTypes = [];
        let tails = [],
            displayFilters = false;
        let flightHaulTypes = [],
            seatClasses = [];

        if (!loadFiltersResponse.dateRangeFiltersDataIsSet && !loadFiltersResponse.nonDateRangeFiltersDataIsSet) {
            this.setState({ displayFilters: false });
        } else {
            this.setState({ displayFilters: false });

            let dateRangeFiltersData = [];
            let mediaCycles = [];
            let mediaCycleValue = '';
            let mediaCycleText = '';
            let mediaCycleDateRange = '';
            let showDateRange = true;

            if (DynamicFilterHelper.getMediaCycleFilteringPages().includes(this.props.title)) {
                mediaCycles = FiltersService.getMediaCycleFilterValuesByPage(this.props.title);
                mediaCycles = mediaCycles.map((obj) => {
                    let dateRange =
                        moment(obj.date_range.min_date).format('YYYYMMDD') +
                        '-' +
                        moment(obj.date_range.max_date).format('YYYYMMDD');

                    let label = obj.version;
                    if (!isNaN(parseInt(obj.version, 10))) {
                        label = moment(obj.version).format('MMMM YYYY');
                    }

                    return { label: label, value: obj.version, date_range: dateRange };
                });

                mediaCycleValue = this.state.mediaCycleValue ? this.state.mediaCycleValue : '';
                mediaCycleText = this.state.mediaCycleText ? this.state.mediaCycleText : '';
                mediaCycleDateRange = this.state.mediaCycleDateRange ? this.state.mediaCycleDateRange : '';

                if (mediaCycleValue !== '') {
                    showDateRange = false;
                }
            }

            if (loadFiltersResponse.dateRangeFiltersDataIsSet) {
                //populate date range filters

                dateRangeFiltersData = FiltersService.getDateRangeFilters();

                if (process.env.JEST_WORKER_ID) {
                    dateRangeFiltersData = this.testData.filterValues;
                }

                let dateRange = [];
                if (dateRangeFiltersData) {
                    defaultDateRange = dateRangeFiltersData.filter(
                        (dateRange) => dateRange.default && dateRange.default === 1
                    );

                    if (mediaCycleValue === '') {
                        if (this.state.dateRangeValue === 'None') {
                            dateRange = dateRangeFiltersData.filter(
                                (dateRange) => dateRange.default && dateRange.default === 1
                            );
                        } else {
                            dateRange = dateRangeFiltersData.filter(
                                (dateRange) => dateRange.label === this.state.dateRangeText
                            );

                            // TODO - Check if this needs to be removed after API is ready
                            if (dateRange && dateRange.length === 0) {
                                dateRange = dateRangeFiltersData.filter(
                                    (dateRange) => dateRange.default && dateRange.default === 1
                                );
                            }
                        }
                    }

                    if (
                        mediaCycleValue === '' &&
                        (this.state.dateRangeText === '' || this.state.dateRangeValue === 'None')
                    ) {
                        dateRangeValue = dateRange.map((dateRange) => dateRange.value);
                        dateRangeText = dateRange.map((dateRange) => dateRange.label);
                    } else {
                        dateRangeValue = this.state.dateRangeValue;
                        dateRangeText = this.state.dateRangeText;
                    }

                    comparisonRanges = [{ label: 'None', value: 'None' }];
                    defaultComparisionRange = 'None';
                    comparisonRangeText = '';

                    if (
                        this.props.showWorkbenchLink &&
                        dateRange.length > 0 &&
                        dateRange[0].comparison_ranges &&
                        Object.keys(dateRange[0].comparison_ranges).length > 0
                    ) {
                        comparisonRanges = dateRange[0].comparison_ranges;

                        if (this.state.comparisonRangeValue === '' || this.state.comparisonRangeValue === 'None') {
                            defaultComparisionRange = dateRange[0].comparison_ranges[0].value;
                            comparisonRangeText = dateRange[0].comparison_ranges[0].label;
                        } else {
                            defaultComparisionRange = this.state.comparisonRangeValue;
                            comparisonRangeText = this.state.comparisonRangeText;
                        }
                    } else if (
                        this.props.showWorkbenchLink &&
                        dateRange.length > 0 &&
                        dateRange[0].label === 'Custom'
                    ) {
                        comparisonRanges = [{ label: 'Same period a year ago' }];
                        comparisonRangeText = comparisonRanges[0].label;
                        defaultComparisionRange = '';
                        if (this.state.comparisonRangeValue) {
                            defaultComparisionRange = this.state.comparisonRangeValue;
                        }
                        comparisonRanges[0].value = defaultComparisionRange;
                    }

                    let dateRangeData = JSON.parse(JSON.stringify(dateRangeFiltersData));
                    dateRangeOptionsOnly = dateRangeData.map(({ comparison_ranges, ...rest }) => rest);
                }
            }

            // Disable options
            dateRangeOptionsOnly.forEach((option) => {
                option.isDisabled = true;
                if (typeof option.enabled === 'object' && option.enabled !== null) {
                    let app = this.props.app;
                    // filter_values API returns 'Portal' for 'Portal Navigation' app
                    if (app === 'Portal Navigation') {
                        app = 'Portal';
                    }
                    Object.entries(option.enabled).forEach(([key, value]) => {
                        if (key && key === app && value === true) {
                            option.isDisabled = false;
                        }
                    });
                    if (option.label === 'Custom') {
                        option.isDisabled = false;
                    }
                }

                if (
                    option.isDisabled &&
                    dateRangeValue &&
                    option.label === dateRangeText.toString() &&
                    dateRangeFiltersData
                ) {
                    let dateRange = dateRangeFiltersData.filter(
                        (dateRange) => dateRange.default && dateRange.default === 1
                    );
                    dateRangeValue = dateRange.map((dateRange) => dateRange.value);
                    dateRangeText = dateRange.map((dateRange) => dateRange.label);

                    if (
                        this.props.showWorkbenchLink &&
                        dateRange.length > 0 &&
                        dateRange[0].comparison_ranges &&
                        Object.keys(dateRange[0].comparison_ranges).length > 0
                    ) {
                        comparisonRanges = dateRange[0].comparison_ranges;
                        defaultComparisionRange = dateRange[0].comparison_ranges[0].value;
                        comparisonRangeText = dateRange[0].comparison_ranges[0].label;
                    }
                }
            });

            let nonDateRangeFiltersData = [];

            if (loadFiltersResponse.nonDateRangeFiltersDataIsSet) {
                //populate non date range filters

                nonDateRangeFiltersData = FiltersService.getNonDateRangeFilters();

                fromCities = FiltersService.getFilterValuesByTypeAndSelection('dep_airport_iata', [], this.props.title);

                toCities = FiltersService.getFilterValuesByTypeAndSelection('arr_airport_iata', [], this.props.title);

                flightNumbers = FiltersService.getFilterValuesByTypeAndSelection('flight_number', [], this.props.title);

                tails = FiltersService.getFilterValuesByTypeAndSelection('aircraft_tail_number', [], this.props.title);

                systemTypes = FiltersService.getFilterValuesByTypeAndSelection('system_type', [], this.props.title);

                aircraftTypes = FiltersService.getFilterValuesByTypeAndSelection(
                    'aircraft_type_subtype',
                    [],
                    this.props.title
                );
                flightHaulTypes = FiltersService.getFilterValuesByTypeAndSelection(
                    'flight_haul_type',
                    [],
                    this.props.title
                );
                seatClasses = FiltersService.getFilterValuesByTypeAndSelection('seat_class', [], this.props.title);

                displayFilters = true;
            }

            this.setState({
                filterValues: nonDateRangeFiltersData,
                dateRangeFiltersData: dateRangeFiltersData,
                dateRangeOptions: dateRangeOptionsOnly,
                dateRangeValue: dateRangeValue ? dateRangeValue.toString() : null,
                dateRangeText: dateRangeText.toString(),
                defaultDateRangeOption: defaultDateRange[0],
                comparisonRangeOptions: comparisonRanges,
                comparisonRangeValue: defaultComparisionRange,
                comparisonRangeText: comparisonRangeText,
                fromCityOptions: fromCities,
                toCityOptions: toCities,
                flightNumbersOptions: flightNumbers,
                tailOptions: tails,
                allTailOptions: tails,
                displayFilters: displayFilters,
                systemTypes: systemTypes,
                aircraftTypes: aircraftTypes,
                flightHaulTypes: flightHaulTypes,
                seatClasses: seatClasses,
                customDateRange: FiltersService.getCustomDateRange(),
                mediaCycleOptions: mediaCycles,
                mediaCycleValue: mediaCycleValue,
                mediaCycleText: mediaCycleText,
                mediaCycleDateRange: mediaCycleDateRange,
                showDateRange: showDateRange
            });

            if (this.state.parentLoading) {
                this.sendUpdatedData();

                this.updateAllFilters();
            }
        }
    }

    handleAccordionClick() {
        Analytics.analytics.track('handleAccordionClick', {
            visual_name: 'Filters',
            interaction_type: 'Add Filters Click',
            interaction_value: !this.state.expanded === true ? 'Open' : 'Close'
        });

        this.setState((prevState) => ({
            expanded: !prevState.expanded
        }));
    }

    handleCheckBoxChange(checkBoxType, e) {
        const item = e.target.name;

        let filters = this.state.selectedFilters;
        let checkboxChecked = false;
        for (let i = 0; i < filters[checkBoxType].length; i++) {
            if (filters[checkBoxType][i] === item) {
                checkboxChecked = true;
                break;
            }
        }

        if (!checkboxChecked) {
            filters[checkBoxType].push(item);
        } else {
            for (let i = 0; i < filters[checkBoxType].length; i++) {
                if (filters[checkBoxType][i] === item) {
                    filters[checkBoxType].splice(i, 1);
                }
            }
        }

        this.setState({ selectedFilters: filters }, () => this.updateAllFilters());
        this.toggleApplyFilterButton();

        Analytics.analytics.track('handleCheckBoxChange', {
            visual_name: 'Filters',
            interaction_type: filterCheckboxNamesMapping[checkBoxType],
            interaction_value: item
        });
    }

    getPageSelectedFilters() {
        let selectedFiltersDeepClone = JSON.parse(JSON.stringify(this.state.selectedFilters));
        Object.keys(selectedFiltersDeepClone).forEach((key) => {
            if (selectedFiltersDeepClone[key].length > 0) {
                selectedFiltersDeepClone[key] = selectedFiltersDeepClone[key].filter(
                    (item) => !DynamicFilterHelper.isAppliedFilterNotExist(key, item, this.props.title)
                );
            }
        });
        return selectedFiltersDeepClone;
    }

    updateAllFilters() {
        let selectedFiltersDeepClone = this.getPageSelectedFilters();
        this.setState({ pageSelectedFilters: selectedFiltersDeepClone });

        let selectedFilterKeys = [];
        Object.keys(selectedFiltersDeepClone).forEach((key) => {
            if (selectedFiltersDeepClone[key].length > 0) {
                let shouldSkip = false;
                selectedFiltersDeepClone[key].forEach((filter) => {
                    if (shouldSkip) {
                        return;
                    }
                    if (!DynamicFilterHelper.isAppliedFilterNotExist(key, filter, this.props.title)) {
                        selectedFilterKeys.push(key);
                        shouldSkip = true;
                    }
                });
            }
        });

        let stateUpdateObj = {};
        let filterTypesAndStateProperties = [
            { dataProperty: 'flight_number', stateProperty: 'flightNumbersOptions', filterValue: 'flightNumber' },
            { dataProperty: 'aircraft_tail_number', stateProperty: 'tailOptions', filterValue: 'tail' },
            { dataProperty: 'aircraft_type_subtype', stateProperty: 'aircraftTypes', filterValue: 'aircraftType' },
            { dataProperty: 'system_type', stateProperty: 'systemTypes', filterValue: 'systemType' },
            { dataProperty: 'flight_haul_type', stateProperty: 'flightHaulTypes', filterValue: 'flightDuration' },
            { dataProperty: 'dep_airport_iata', stateProperty: 'fromCityOptions', filterValue: 'fromCity' },
            { dataProperty: 'arr_airport_iata', stateProperty: 'toCityOptions', filterValue: 'toCity' },
            { dataProperty: 'seat_class', stateProperty: 'seatClasses', filterValue: 'seatClass' }
        ];

        filterTypesAndStateProperties.forEach((filter) => {
            let updatedData = FiltersService.getFilterValuesByTypeAndSelection(
                filter.dataProperty,
                selectedFiltersDeepClone,
                this.props.title
            );
            stateUpdateObj[filter.stateProperty] = updatedData;
        });

        stateUpdateObj['allTailOptions'] = stateUpdateObj['tailOptions'];

        this.setState(stateUpdateObj);
    }

    handleChipButton(filterType, value, selectedRoute, filterNotExists) {
        Analytics.analytics.track('handleChipButton', {
            visual_name: 'Filters',
            interaction_type: 'Remove Applied Filter',
            interaction_value: 'Click'
        });

        let selectedFromCity = this.state.selectedFromCity;
        let selectedToCity = this.state.selectedToCity;
        let selectedFlightNumber = this.state.selectedFlightNumber;

        let filters = this.state.appliedFilters;
        for (let i = 0; i < filters[filterType].length; i++) {
            if (filters[filterType][i] === value) {
                filters[filterType].splice(i, 1);

                if ('fromCity' === filterType) {
                    selectedFromCity = null;
                }

                if ('toCity' === filterType) {
                    selectedToCity = null;
                }

                if ('flightNumber' === filterType) {
                    selectedFlightNumber = null;
                }
            }
        }

        let selectedFilters = this.state.selectedFilters;

        for (let i = 0; i < selectedFilters[filterType].length; i++) {
            if (selectedFilters[filterType][i] === value) {
                selectedFilters[filterType].splice(i, 1);
            }
        }

        this.setState(
            {
                selectedFilters: selectedFilters,
                appliedFilters: JSON.parse(JSON.stringify(filters)),
                selectedFlightNumber: selectedFlightNumber,
                selectedFromCity: selectedFromCity,
                selectedToCity: selectedToCity,
                appliedFromCity: selectedFromCity,
                appliedToCity: selectedToCity,
                appliedFlightNumber: selectedFlightNumber
            },

            () => {
                this.toggleApplyFilterButton();
                this.sendUpdatedData();
                this.updateAllFilters();
            }
        );
    }

    handleMediaCycleChange(selectedOption) {
        console.log('handleMediaCycleChange');

        this.setState(
            {
                mediaCycleValue: selectedOption.value,
                mediaCycleText: selectedOption.label,
                mediaCycleDateRange: selectedOption.date_range
            },
            () => this.sendUpdatedData()
        );

        if (this.state.expanded) {
            setTimeout(
                function () {
                    this.accordionRef.current.click();
                }.bind(this),
                500
            );
        }
    }

    handleDateRangeChange(selectedOption) {
        if (selectedOption.label === 'Custom') {
            let rangeOptions = [{ label: 'None', value: 'None' }];
            if (SubscriptionService.subscription() !== 'Basic') {
                rangeOptions = [{ label: 'Same period a year ago', value: '' }];
            }

            if (this.state.customStartDate && this.state.customEndDate) {
                let selectedRange =
                    this.state.customStartDate.format('YYYYMMDD') + '-' + this.state.customEndDate.format('YYYYMMDD');
                let startClone = this.state.customStartDate.clone();
                let endClone = this.state.customEndDate.clone();
                endClone.subtract(12, 'months');
                startClone.subtract(12, 'months');

                let comparisonRange = startClone.format('YYYYMMDD') + '-' + endClone.format('YYYYMMDD');
                let comparisonRangeOptions = [{ label: 'Same period a year ago', value: comparisonRange }];

                this.setState(
                    {
                        dateRangeValue: selectedRange,
                        comparisonRangeOptions: comparisonRangeOptions,
                        comparisonRangeValue: comparisonRange,
                        comparisonRangeText: comparisonRangeOptions[0]['label'],
                        customComparisonEndDate: endClone,
                        customComparisonStartDate: startClone,
                        forceSendUpdatedData: true,
                        dateRangeText: selectedOption.label,
                        displayDateRangeSelector: true
                    },
                    () => this.sendUpdatedData()
                );
            } else {
                this.setState({
                    comparisonRangeOptions: rangeOptions,
                    comparisonRangeValue: rangeOptions[0].value,
                    dateRangeValue: selectedOption.value,
                    dateRangeText: selectedOption.label,
                    displayDateRangeSelector: true
                });
            }
        } else {
            this.setState({
                dateRangeValue: selectedOption.value,
                dateRangeText: selectedOption.label,
                displayDateRangeSelector: false
            });

            let dateRange = this.state.dateRangeFiltersData.filter(
                (dateRange) => dateRange.label === selectedOption.label
            );

            let comparisonRanges = [{ label: 'None', value: 'None' }];
            let defaultComparisionRange = 'None';
            let comparisonRangeText = '';

            if (
                dateRange.length > 0 &&
                dateRange[0].comparison_ranges &&
                Object.keys(dateRange[0].comparison_ranges).length > 0
            ) {
                comparisonRanges = dateRange[0].comparison_ranges;
                defaultComparisionRange = dateRange[0].comparison_ranges[0].value;
                comparisonRangeText = dateRange[0].comparison_ranges[0].label;
            }

            this.setState(
                {
                    dateRangeValue: selectedOption.value,
                    dateRangeText: selectedOption.label,
                    comparisonRangeOptions: comparisonRanges,
                    comparisonRangeValue: defaultComparisionRange,
                    comparisonRangeText: comparisonRangeText
                },
                () => this.sendUpdatedData()
            );

            if (this.state.expanded) {
                setTimeout(
                    function () {
                        this.accordionRef.current.click();
                    }.bind(this),
                    500
                );
            }
        }

        Analytics.analytics.track('handleDateRangeChange', {
            visual_name: 'Filters',
            interaction_type: 'Date Range',
            interaction_value: selectedOption.label
        });
    }

    handleComparisonRangeChange(selectedOption) {
        this.setState({ comparisonRangeValue: selectedOption.value, comparisonRangeText: selectedOption.label }, () =>
            this.sendUpdatedData(true)
        );
        if (this.state.expanded) {
            setTimeout(
                function () {
                    this.accordionRef.current.click();
                }.bind(this),
                500
            );
        }

        Analytics.analytics.track('handleComparisonRangeChange', {
            visual_name: 'Filters',
            interaction_type: 'Comparison Range',
            interaction_value: selectedOption.label
        });
    }

    sendUpdatedData(onlyComparisonRangeUpdated) {
        if (this.state.parentLoading) {
            this.setState({ parentLoading: false });
        }

        let allAppliedFilters = DynamicFilterHelper.getAllAppliedFilters(this.state, this.props.title);
        let currentAppliedFilters = allAppliedFilters.all_applied_filters;
        if (!_.isEqual(this.state.prevAppliedFilters, currentAppliedFilters) || this.state.forceSendUpdatedData) {
            DynamicFilterHelper.setSelectedFiltersToStore(this.state);
            this.setState({ prevAppliedFilters: currentAppliedFilters, forceSendUpdatedData: false }, () =>
                this.props.onSelectionUpdated(currentAppliedFilters, onlyComparisonRangeUpdated)
            );
        }
    }

    getSelectedFiltersFromStore() {
        let selectedFiltersFromStore = JSON.parse(window.sessionStorage.getItem('pac_next_insights_selected_filters'));

        if (selectedFiltersFromStore) {
            ['customStartDate', 'customEndDate', 'customComparisonStartDate', 'customComparisonEndDate'].forEach(
                (dateOption) => {
                    if (selectedFiltersFromStore[dateOption]) {
                        selectedFiltersFromStore[dateOption] = moment(selectedFiltersFromStore[dateOption]);
                    }
                }
            );

            this.setState(selectedFiltersFromStore);
        }
    }

    async handleFromCityChange(selectedOption) {
        if (selectedOption === null) {
            let filters = this.state.selectedFilters;
            if (filters.fromCity.length > 0) {
                filters.fromCity = [];
            }
            this.setState({
                selectedFilters: filters,
                selectedFromCity: null,
                applyFilterButtonDisabled: true,
                clearFilterButtonDisabled: true
            });
        } else if (selectedOption) {
            let filters = this.state.selectedFilters;
            let index = filters.fromCity.indexOf(selectedOption.value);
            if (index < 0) {
                filters.fromCity = [];
                filters.fromCity.push(selectedOption.value);
            }
            //this.setState({selectedFromCity: selectedOption.value}, () => this.toggleApplyFilterButton());
            this.setState({
                selectedFilters: filters,
                selectedFromCity: selectedOption.value,
                applyFilterButtonDisabled: true,
                clearFilterButtonDisabled: true
            });
            this.toggleApplyFilterButton();
        }

        Analytics.analytics.track('handleFromCityChange', {
            visual_name: 'Filters',
            interaction_type: 'Flight Origin',
            interaction_value: selectedOption.value
        });

        this.updateAllFilters();
    }

    async handleToCityChange(selectedOption) {
        if (selectedOption === null) {
            let filters = this.state.selectedFilters;
            if (filters.toCity.length > 0) {
                filters.toCity = [];
            }
            this.setState({
                selectedFilters: filters,
                selectedToCity: null,
                applyFilterButtonDisabled: true,
                clearFilterButtonDisabled: true
            });
        } else if (selectedOption) {
            let filters = this.state.selectedFilters;
            let index = filters.toCity.indexOf(selectedOption.value);
            if (index < 0) {
                filters.toCity = [];
                filters.toCity.push(selectedOption.value);
            }
            this.setState({
                selectedFilters: filters,
                selectedToCity: selectedOption.value,
                applyFilterButtonDisabled: true,
                clearFilterButtonDisabled: true
            });
            this.toggleApplyFilterButton();
        }

        Analytics.analytics.track('handleToCityChange', {
            visual_name: 'Filters',
            interaction_type: 'Flight Destination',
            interaction_value: selectedOption.value
        });

        this.updateAllFilters();
    }

    handleFlightNumberChange = (selectedOption) => {
        if (selectedOption === null) {
            let filters = this.state.selectedFilters;
            if (filters.flightNumber.length > 0) {
                filters.flightNumber = [];
            }
            this.setState({ selectedFilters: filters, selectedFlightNumber: null }, () =>
                this.toggleApplyFilterButton()
            );
        } else if (selectedOption) {
            let filters = this.state.selectedFilters;
            let index = filters.flightNumber.indexOf(selectedOption.value);
            if (index < 0) {
                filters.flightNumber = [];
                filters.flightNumber.push(selectedOption.value);
            }

            this.setState({ selectedFilters: filters, selectedFlightNumber: selectedOption.value }, () =>
                this.toggleApplyFilterButton()
            );
        }

        Analytics.analytics.track('handleFlightNumberChange', {
            visual_name: 'Filters',
            interaction_type: 'Flight Number',
            interaction_value: selectedOption.value
        });

        this.updateAllFilters();
    };

    handleTailChange = (selectedOption) => {
        let filters = this.state.selectedFilters;
        let index = filters.tail.indexOf(selectedOption);
        if (index >= 0) {
            for (let i = 0; i < filters.tail.length; i++) {
                if (filters.tail[i] === selectedOption) {
                    filters.tail.splice(i, 1);
                }
            }
        } else {
            filters.tail.push(selectedOption);
        }
        this.setState({ selectedFilters: filters, tailOptions: this.state.allTailOptions });
        this.toggleApplyFilterButton();

        Analytics.analytics.track('handleTailChange', {
            visual_name: 'Filters',
            interaction_type: 'Tail',
            interaction_value: selectedOption
        });

        this.updateAllFilters();
    };

    clearTailFilter = (selectedOption) => {
        let filters = this.state.selectedFilters;
        filters.tail = [];
        this.setState({ selectedFilters: filters, multiSelectFilterLabel: 'Select Tail' });
        this.toggleApplyFilterButton();

        this.updateAllFilters();
    };

    handleTailFilter = (selectedOption) => {
        if (this.state.allTailOptions && this.state.allTailOptions.length > 0 && selectedOption) {
            var filteredTails = this.state.allTailOptions.filter(function (el) {
                return el.value.toLowerCase().includes(selectedOption.toLowerCase());
            });
            if (filteredTails.length > 0) {
                this.setState({ tailOptions: filteredTails });
            }
        } else {
            this.setState({ tailOptions: this.state.allTailOptions });
        }
    };

    toggleApplyFilterButton() {
        let disableApplyButton = true;
        let disableClearButton = true;

        for (let [key, value] of Object.entries(this.state.selectedFilters)) {
            if (value.length > 0) {
                disableApplyButton = false;
                disableClearButton = false;
                break;
            }
        }

        if (disableApplyButton) {
            if (DynamicFilterHelper.atleastOneRoutePropSelected(this.state)) {
                disableApplyButton = false;
            }
        }

        if (disableClearButton) {
            if (
                DynamicFilterHelper.atleastOneRoutePropSelected(this.state) ||
                DynamicFilterHelper.atleastOneRoutePropApplied(this.state)
            ) {
                disableClearButton = false;
            }
        }

        if (disableClearButton) {
            for (let [key, value] of Object.entries(this.state.appliedFilters)) {
                if (value.length > 0) {
                    disableClearButton = false;
                    break;
                }
            }
        }

        this.setState({ applyFilterButtonDisabled: disableApplyButton, clearFilterButtonDisabled: disableClearButton });
    }

    showMediaCycleFilters() {
        console.log('showMediaCycleFilters');

        if (this.state.showDateRange) {
            let mediaCycles = this.state.mediaCycleOptions;

            let prevDateRangeValues = {
                dateRangeValue: this.state.dateRangeValue,
                dateRangeText: this.state.dateRangeText,
                comparisonRangeValue: this.state.comparisonRangeValue,
                comparisonRangeText: this.state.comparisonRangeText
            };

            let mediaCycleText = '';
            let mediaCycleValue = '';
            let mediaCycleDateRange = '';

            if (mediaCycles.length > 0) {
                let mediaCycleObj = mediaCycles[0];
                if (mediaCycles.length > 1) {
                    mediaCycleObj = mediaCycles[1];
                }

                mediaCycleText = mediaCycleObj['label'];
                mediaCycleValue = mediaCycleObj['value'];
                mediaCycleDateRange = mediaCycleObj['date_range'];
            }

            if (this.state.prevMediaCycleValues) {
                mediaCycleText = this.state.prevMediaCycleValues.mediaCycleText;
                mediaCycleValue = this.state.prevMediaCycleValues.mediaCycleValue;
                mediaCycleDateRange = this.state.prevMediaCycleValues.mediaCycleDateRange;
            }

            this.setState(
                {
                    showDateRange: false,
                    dateRangeValue: null,
                    dateRangeText: '',
                    comparisonRangeValue: 'None',
                    comparisonRangeText: '',
                    mediaCycleText: mediaCycleText,
                    mediaCycleValue: mediaCycleValue,
                    mediaCycleDateRange: mediaCycleDateRange,
                    prevDateRangeValues: prevDateRangeValues
                },
                () => this.sendUpdatedData()
            );
        } else {
            let prevMediaCycleValues = {
                mediaCycleText: this.state.mediaCycleText,
                mediaCycleValue: this.state.mediaCycleValue,
                mediaCycleDateRange: this.state.mediaCycleDateRange
            };

            let dateRangeValue = this.state.dateRangeOptions[0].value;
            let dateRangeText = this.state.dateRangeOptions[0].label;
            let comparisonRangeValue = this.state.comparisonRangeOptions[0].value;
            let comparisonRangeText = this.state.comparisonRangeOptions[0].label;

            if (this.state.prevDateRangeValues) {
                dateRangeValue = this.state.prevDateRangeValues.dateRangeValue;
                dateRangeText = this.state.prevDateRangeValues.dateRangeText;
                comparisonRangeValue = this.state.prevDateRangeValues.comparisonRangeValue;
                comparisonRangeText = this.state.prevDateRangeValues.comparisonRangeText;
            }

            this.setState(
                {
                    showDateRange: true,
                    mediaCycleText: 'None',
                    mediaCycleValue: null,
                    mediaCycleDateRange: null,
                    dateRangeValue: dateRangeValue,
                    dateRangeText: dateRangeText,
                    comparisonRangeValue: comparisonRangeValue,
                    comparisonRangeText: comparisonRangeText,
                    prevMediaCycleValues: prevMediaCycleValues
                },
                () => this.sendUpdatedData()
            );
        }
    }

    onDatesChange({ startDate, endDate }) {
        this.setState({
            customStartDate: startDate,
            customEndDate: endDate
        });

        // The 'Done' button in DateRangePicker component does not provide a callback.
        // Using the DOM events to check when the calendar view is closed by the Done button click.
        let elements = document.getElementsByClassName('DateRangePicker_picker');
        let targetNode = elements[0];
        let parentNode = targetNode.parentNode;

        const config = { childList: true };

        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    mutation.removedNodes.forEach((node) => {
                        if (node === targetNode) {
                            if (!(startDate && endDate)) {
                                startDate = moment().subtract(1, 'months').startOf('month');
                                endDate = moment().subtract(1, 'months').endOf('month');
                            }

                            let selectedRange = startDate.format('YYYYMMDD') + '-' + endDate.format('YYYYMMDD');

                            let startClone = startDate.clone();
                            let endClone = endDate.clone();
                            endClone.subtract(12, 'months');
                            startClone.subtract(12, 'months');

                            let comparisonRange = startClone.format('YYYYMMDD') + '-' + endClone.format('YYYYMMDD');
                            let comparisonRangeOptions = [{ label: 'Same period a year ago', value: comparisonRange }];

                            this.setState(
                                {
                                    dateRangeValue: selectedRange,
                                    comparisonRangeOptions: comparisonRangeOptions,
                                    comparisonRangeValue: comparisonRange,
                                    comparisonRangeText: comparisonRangeOptions[0]['label'],
                                    customComparisonEndDate: endClone,
                                    customComparisonStartDate: startClone,
                                    customStartDate: startDate,
                                    customEndDate: endDate
                                },
                                () => this.sendUpdatedData()
                            );

                            if (this.state.expanded) {
                                setTimeout(
                                    function () {
                                        this.accordionRef.current.click();
                                    }.bind(this),
                                    500
                                );
                            }
                        }

                        observer.disconnect();
                    });
                }
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(parentNode, config);
    }

    outsideCustomDateRange = (day) => {
        if (this.state.customDateRange) {
            if (
                day.isAfter(moment(this.state.customDateRange.date_range_end), 'day') ||
                day.isBefore(moment(this.state.customDateRange.date_range_start), 'day')
            ) {
                return true;
            }
        }

        return false;
    };

    handleApplyFilterClick = (e) => {
        const selectedFiltersDeepClone = JSON.parse(JSON.stringify(this.state.selectedFilters));
        let stateToUpdate = { appliedFilters: selectedFiltersDeepClone };

        if (this.state.selectedFromCity || this.state.selectedToCity || this.state.selectedFlightNumber) {
            stateToUpdate = {
                ...stateToUpdate,
                appliedFromCity: this.state.selectedFromCity,
                appliedToCity: this.state.selectedToCity,
                appliedFlightNumber: this.state.selectedFlightNumber
            };
        }

        this.setState(stateToUpdate, () => {
            this.toggleApplyFilterButton();

            this.sendUpdatedData();
        });

        setTimeout(
            function () {
                this.accordionRef.current.click();
            }.bind(this),
            500
        );

        Analytics.analytics.track('handleApplyFilterClick', {
            visual_name: 'Filters',
            interaction_type: 'Apply Button',
            interaction_value: 'Apply Filter'
        });

        this.updateAllFilters();
    };

    handleClearFilterClick = (e) => {
        this.handleDateRangeChange(this.state.defaultDateRangeOption);

        let stateToUpdate = {
            appliedFilters: {},

            selectedFilters: {
                systemType: [],
                aircraftType: [],
                flightDuration: [],
                seatClass: [],
                guiLanguage: [],
                tail: [],
                toCity: [],
                fromCity: [],
                flightNumber: []
            },

            appliedFromCity: null,
            appliedToCity: null,
            appliedFlightNumber: null,
            selectedFromCity: null,
            selectedToCity: null,
            selectedFlightNumber: null
        };

        this.setState(stateToUpdate, () => {
            this.toggleApplyFilterButton();
            this.sendUpdatedData();
        });

        Analytics.analytics.track('handleClearFilterClick', {
            visual_name: 'Filters',
            interaction_type: 'Clear Button',
            interaction_value: 'Clear All'
        });

        this.updateAllFilters();
    };

    render() {
        let toggleIcon = this.state.expanded ? (
            <div>
                <i className="fa fa-chevron-up custom-fa"></i>
                <br />
                <span className="hidden">Add Filters</span>
            </div>
        ) : (
            <div>
                <i className="fa fa-plus custom-fa"></i>
                <br />
                <span>Add Filters</span>
            </div>
        );

        let infoText = DynamicFilterHelper.getDateInfoMessage(
            this.state.dateRangeText,
            this.state.showDateRange,
            SubscriptionService.subscription()
        );

        return (
            <Accordion>
                <Row style={{ marginLeft: '0', marginRight: '0' }}>
                    <Card style={{ width: '100%' }}>
                        <Card.Header>
                            <Row>
                                <Col xs={10} md={10} lg={10}>
                                    <Row>
                                        {this.state.showDateRange && (
                                            <Col xs={12} md={4} lg={4}>
                                                <div
                                                    style={{
                                                        fontFamily: 'Inter SemiBold',
                                                        fontSize: 16,
                                                        color: '#3E8ACD',
                                                        marginBottom: 4
                                                    }}
                                                >
                                                    Date Range
                                                </div>
                                                <Select
                                                    options={this.state.dateRangeOptions}
                                                    menuPortalTarget={document.body}
                                                    value={this.state.dateRangeOptions.find((option) => {
                                                        return option.label === this.state.dateRangeText;
                                                    })}
                                                    getOptionValue={(option) => option.label}
                                                    onChange={this.handleDateRangeChange}
                                                    classNamePrefix="select"
                                                    maxMenuHeight={370}
                                                    components={{ DropdownIndicator }}
                                                    styles={DynamicFilterHelper.getDropdownStyle()}
                                                />
                                                {this.state.dateRangeOptions.length > 0 ? (
                                                    this.state.displayDateRangeSelector ? (
                                                        <DateRangePicker
                                                            disabled={false}
                                                            displayFormat="MM/DD/YYYY"
                                                            endDate={this.state.customEndDate}
                                                            endDatePlaceholderText="mm/dd/yyyy"
                                                            numberOfMonths={2}
                                                            onDatesChange={this.onDatesChange}
                                                            startDate={this.state.customStartDate}
                                                            startDatePlaceholderText="mm/dd/yyyy"
                                                            isOutsideRange={this.outsideCustomDateRange}
                                                            firstDayOfWeek={1}
                                                            minimumNights={0}
                                                        />
                                                    ) : (
                                                        <div style={{ marginTop: 4 }}>
                                                            <span className="filter-date-desc">
                                                                {DynamicFilterHelper.getDateDescription(
                                                                    this.state.dateRangeValue
                                                                )}
                                                            </span>
                                                        </div>
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </Col>
                                        )}

                                        {!this.state.showDateRange && (
                                            <Col xs={12} md={4} lg={4}>
                                                <div
                                                    style={{
                                                        fontFamily: 'Inter SemiBold',
                                                        fontSize: 16,
                                                        color: '#3E8ACD',
                                                        marginBottom: 4
                                                    }}
                                                >
                                                    Media Cycle
                                                </div>
                                                <Select
                                                    options={this.state.mediaCycleOptions}
                                                    menuPortalTarget={document.body}
                                                    value={this.state.mediaCycleOptions.find((option) => {
                                                        return option.label === this.state.mediaCycleText;
                                                    })}
                                                    getOptionValue={(option) => option.label}
                                                    onChange={this.handleMediaCycleChange}
                                                    classNamePrefix="select"
                                                    maxMenuHeight={370}
                                                    components={{ DropdownIndicator }}
                                                    styles={DynamicFilterHelper.getDropdownStyle()}
                                                />
                                                <div style={{ marginTop: 4 }}>
                                                    <div style={{ height: '19px' }} className="filter-date-desc">
                                                        {DynamicFilterHelper.getDateDescription(
                                                            this.state.mediaCycleDateRange
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                        )}

                                        {this.props.showWorkbenchLink && !this.state.showDateRange && (
                                            <Col xs={12} md={4} lg={4}>
                                                <div
                                                    style={{
                                                        fontFamily: 'Inter SemiBold',
                                                        fontSize: 16,
                                                        color: '#3E8ACD',
                                                        marginBottom: 4
                                                    }}
                                                >
                                                    Comparison Cycle
                                                </div>
                                                <Select
                                                    options={[{ label: 'None', value: 'None' }]}
                                                    menuPortalTarget={document.body}
                                                    value={[{ label: 'None', value: 'None' }]}
                                                    isDisabled={true}
                                                    classNamePrefix="select"
                                                    components={{ DropdownIndicator }}
                                                    styles={DynamicFilterHelper.getDropdownStyle()}
                                                />
                                            </Col>
                                        )}

                                        {this.props.showWorkbenchLink &&
                                            this.state.showDateRange &&
                                            this.state.comparisonRangeOptions &&
                                            this.state.comparisonRangeOptions.length > 0 && (
                                                <Col xs={12} md={4} lg={4}>
                                                    <div
                                                        style={{
                                                            fontFamily: 'Inter SemiBold',
                                                            fontSize: 16,
                                                            color: '#3E8ACD',
                                                            marginBottom: 4
                                                        }}
                                                    >
                                                        Comparison Range
                                                    </div>
                                                    <Select
                                                        options={this.state.comparisonRangeOptions}
                                                        menuPortalTarget={document.body}
                                                        value={this.state.comparisonRangeOptions.filter(
                                                            ({ value }) => value === this.state.comparisonRangeValue
                                                        )}
                                                        onChange={this.handleComparisonRangeChange}
                                                        isDisabled={this.state.comparisonRangeValue === 'None'}
                                                        classNamePrefix="select"
                                                        components={{ DropdownIndicator }}
                                                        styles={DynamicFilterHelper.getDropdownStyle()}
                                                    />
                                                    {this.state.comparisonRangeOptions[0].value !== 'None' &&
                                                    this.state.comparisonRangeValue !== '' ? (
                                                        this.state.dateRangeText === 'Custom' ? (
                                                            <DateRangePicker
                                                                disabled={true}
                                                                displayFormat="MM/DD/YYYY"
                                                                endDate={this.state.customComparisonEndDate}
                                                                startDate={this.state.customComparisonStartDate}
                                                                onDatesChange={(a, b) => {
                                                                    return;
                                                                }}
                                                            />
                                                        ) : (
                                                            <div style={{ marginTop: 4 }}>
                                                                <span className="filter-date-desc">
                                                                    {DynamicFilterHelper.getDateDescription(
                                                                        this.state.comparisonRangeValue
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </Col>
                                            )}
                                        {DynamicFilterHelper.getMediaCycleFilteringPages().includes(
                                            this.props.title
                                        ) && (
                                            <Col
                                                xs={12}
                                                md={3}
                                                lg={3}
                                                style={{
                                                    paddingLeft: '0px',
                                                    display: this.state.displayFilters ? 'block' : 'none'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontFamily: 'Inter SemiBold',
                                                        fontSize: 16,
                                                        color: '#3E8ACD',
                                                        marginBottom: 4,
                                                        marginTop: '34px',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => this.showMediaCycleFilters()}
                                                >
                                                    {this.state.showDateRange
                                                        ? 'Switch to Media Cycle'
                                                        : 'Switch to Date Range'}
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={10}
                                            lg={10}
                                            style={{ marginTop: infoText === '' ? '0px' : '5px' }}
                                        >
                                            <span className="info">{infoText}</span>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col
                                    xs={2}
                                    md={2}
                                    lg={2}
                                    style={{ display: this.state.displayFilters ? 'block' : 'none' }}
                                >
                                    <Accordion.Toggle
                                        eventKey="1"
                                        className="AccordionButton"
                                        ref={this.accordionRef}
                                        onClick={() => this.handleAccordionClick()}
                                    >
                                        {toggleIcon}
                                    </Accordion.Toggle>
                                </Col>
                            </Row>

                            {this.state.displayFilters && (
                                <div className="applied-filter-container">
                                    {Object.entries(this.state.appliedFilters).map(([filterType, filterValues]) => {
                                        if (Array.isArray(filterValues)) {
                                            return filterValues.map((value, index) => {
                                                let filterNotExists = DynamicFilterHelper.isAppliedFilterNotExist(
                                                    filterType,
                                                    value,
                                                    this.props.title
                                                );
                                                return (
                                                    <Chip
                                                        disabled={false}
                                                        className={
                                                            filterNotExists ? 'filter-chip disabled' : 'filter-chip'
                                                        }
                                                        key={index}
                                                        onClick={(e) =>
                                                            this.handleChipButton(
                                                                filterType,
                                                                value,
                                                                null,
                                                                filterNotExists
                                                            )
                                                        }
                                                    >
                                                        {display_names[filterType]}: {value}
                                                    </Chip>
                                                );
                                            });
                                        } else {
                                            return '';
                                        }
                                    })}
                                </div>
                            )}
                        </Card.Header>
                        {this.state.displayFilters && (
                            <Accordion.Collapse eventKey="1" className="accordion-collapse">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <ButtonPrimary
                                                disabled={this.state.applyFilterButtonDisabled}
                                                className="button-primary"
                                                onClick={this.handleApplyFilterClick}
                                            >
                                                Apply Filter
                                            </ButtonPrimary>{' '}
                                            <ButtonSecondary
                                                disabled={this.state.clearFilterButtonDisabled}
                                                className="button-secondary margin-left-10"
                                                onClick={this.handleClearFilterClick}
                                            >
                                                Clear All
                                            </ButtonSecondary>{' '}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} md={4} lg={3}>
                                            {this.state.systemTypes.length > 0 && (
                                                <div style={{ marginTop: '20px' }}>
                                                    <Card.Title>System Type</Card.Title>

                                                    <Card>
                                                        <ul className="filters-checkbox-list">
                                                            {this.state.systemTypes.map((item, index) => (
                                                                <li key={index + '_li_system_type'} data-item={item}>
                                                                    <Checkbox
                                                                        name={item.value}
                                                                        key={item.value}
                                                                        style={{ float: 'left' }}
                                                                        marked={
                                                                            this.state.selectedFilters.systemType.includes(
                                                                                item.value
                                                                            )
                                                                                ? CheckboxSelectVariant.selected
                                                                                : CheckboxSelectVariant.unselected
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleCheckBoxChange('systemType', e)
                                                                        }
                                                                    />

                                                                    <div className="checkbox-title">{item.value}</div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Card>
                                                </div>
                                            )}

                                            <div style={{ marginTop: '20px' }}>
                                                {this.props.showWorkbenchLink &&
                                                    this.state.aircraftTypes.length > 0 && (
                                                        <React.Fragment>
                                                            <Card.Title>Aircraft Type/Subtype</Card.Title>

                                                            <Card>
                                                                <ul className="filters-checkbox-list">
                                                                    {this.state.aircraftTypes.map((item, index) => (
                                                                        <li key={index + '_li_aircraft_type'}>
                                                                            <Checkbox
                                                                                name={item.value}
                                                                                key={item.value}
                                                                                style={{ float: 'left' }}
                                                                                marked={
                                                                                    this.state.selectedFilters.aircraftType.includes(
                                                                                        item.value
                                                                                    )
                                                                                        ? CheckboxSelectVariant.selected
                                                                                        : CheckboxSelectVariant.unselected
                                                                                }
                                                                                onChange={(e) =>
                                                                                    this.handleCheckBoxChange(
                                                                                        'aircraftType',

                                                                                        e
                                                                                    )
                                                                                }
                                                                            />

                                                                            <div className="checkbox-title">
                                                                                {item.value}
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </Card>
                                                        </React.Fragment>
                                                    )}
                                            </div>
                                        </Col>

                                        {!this.props.showWorkbenchLink && this.state.aircraftTypes.length > 0 && (
                                            <Col xs={12} md={4} lg={3}>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Card.Title>Aircraft Type/Subtype</Card.Title>

                                                    <Card>
                                                        <ul className="filters-checkbox-list">
                                                            {this.state.aircraftTypes.map((item, index) => (
                                                                <li key={index + '_li_aircraft_type'}>
                                                                    <Checkbox
                                                                        name={item.value}
                                                                        key={item.value}
                                                                        style={{ float: 'left' }}
                                                                        marked={
                                                                            this.state.selectedFilters.aircraftType.includes(
                                                                                item.value
                                                                            )
                                                                                ? CheckboxSelectVariant.selected
                                                                                : CheckboxSelectVariant.unselected
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.handleCheckBoxChange('aircraftType', e)
                                                                        }
                                                                    />

                                                                    <div className="checkbox-title">{item.value}</div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Card>
                                                </div>
                                            </Col>
                                        )}

                                        <Col xs={12} md={4} lg={3}>
                                            {this.state.tailOptions.length > 0 && (
                                                <div className="margin-top-20">
                                                    <Card.Title>Tail</Card.Title>

                                                    <div className="tail-select">
                                                        <DropdownMultiple
                                                            label={'Select Tail'}
                                                            options={this.state.tailOptions}
                                                            selected={this.state.pageSelectedFilters.tail}
                                                            onChange={this.handleTailChange}
                                                            filterLabel={this.state.multiSelectFilterLabel}
                                                            onClear={this.clearTailFilter}
                                                            onFilter={this.handleTailFilter}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            <div style={{ marginTop: '20px' }}>
                                                {(this.state.fromCityOptions.length > 0 ||
                                                    this.state.toCityOptions.length > 0 ||
                                                    this.state.flightNumbersOptions.length > 0) && (
                                                    <Card style={{ padding: '10px' }}>
                                                        {this.state.fromCityOptions.length > 0 && (
                                                            <div>
                                                                <Card.Title>Flight Origin</Card.Title>

                                                                <Select
                                                                    options={this.state.fromCityOptions}
                                                                    menuPortalTarget={document.body}
                                                                    value={this.state.fromCityOptions.filter(
                                                                        ({ value }) =>
                                                                            value === this.state.selectedFromCity
                                                                    )}
                                                                    onChange={this.handleFromCityChange}
                                                                    hideSelectedOptions
                                                                    placeholder="Select Origin"
                                                                    classNamePrefix="select"
                                                                    maxMenuHeight={200}
                                                                    isClearable={true}
                                                                    components={{ DropdownIndicator }}
                                                                    styles={DynamicFilterHelper.getDropdownStyle()}
                                                                />
                                                            </div>
                                                        )}

                                                        {this.state.toCityOptions.length > 0 && (
                                                            <div className="margin-top-20">
                                                                <Card.Title>Flight Destination</Card.Title>

                                                                <Select
                                                                    options={this.state.toCityOptions}
                                                                    menuPortalTarget={document.body}
                                                                    value={this.state.toCityOptions.filter(
                                                                        ({ value }) =>
                                                                            value === this.state.selectedToCity
                                                                    )}
                                                                    onChange={this.handleToCityChange}
                                                                    hideSelectedOptions
                                                                    isDisabled={
                                                                        this.state.selectedToCity === 'None' ||
                                                                        this.state.selectedToCity === 'Loading...'
                                                                    }
                                                                    placeholder="Select Destination"
                                                                    classNamePrefix="select"
                                                                    maxMenuHeight={200}
                                                                    isClearable={true}
                                                                    components={{ DropdownIndicator }}
                                                                    styles={DynamicFilterHelper.getDropdownStyle()}
                                                                />
                                                            </div>
                                                        )}

                                                        {this.state.flightNumbersOptions.length > 0 && (
                                                            <div>
                                                                <Card.Title className="margin-top-20">
                                                                    Flight Number
                                                                </Card.Title>

                                                                <Select
                                                                    options={this.state.flightNumbersOptions}
                                                                    menuPortalTarget={document.body}
                                                                    value={this.state.flightNumbersOptions.filter(
                                                                        ({ value }) =>
                                                                            value === this.state.selectedFlightNumber
                                                                    )}
                                                                    onChange={this.handleFlightNumberChange}
                                                                    hideSelectedOptions
                                                                    isDisabled={
                                                                        this.state.selectedFlightNumber === 'None' ||
                                                                        this.state.selectedFlightNumber === 'Loading...'
                                                                    }
                                                                    placeholder="Select Flight #"
                                                                    classNamePrefix="select"
                                                                    maxMenuHeight={200}
                                                                    isClearable={true}
                                                                    components={{ DropdownIndicator }}
                                                                    styles={DynamicFilterHelper.getDropdownStyle()}
                                                                />
                                                            </div>
                                                        )}
                                                    </Card>
                                                )}
                                            </div>
                                        </Col>

                                        <Col xs={12} md={4} lg={3}>
                                            <div style={{ marginTop: '20px' }}>
                                                {this.props.showWorkbenchLink &&
                                                    this.state.flightHaulTypes.length > 0 && (
                                                        <React.Fragment>
                                                            <Card.Title>Flight Haul</Card.Title>

                                                            <Card>
                                                                <ul className="filters-checkbox-list">
                                                                    {this.state.flightHaulTypes.map((item, index) => (
                                                                        <li key={index + '_li_flight_duration'}>
                                                                            <Checkbox
                                                                                name={item.value}
                                                                                key={item.value}
                                                                                style={{ float: 'left' }}
                                                                                marked={
                                                                                    this.state.selectedFilters.flightDuration.includes(
                                                                                        item.value
                                                                                    )
                                                                                        ? CheckboxSelectVariant.selected
                                                                                        : CheckboxSelectVariant.unselected
                                                                                }
                                                                                onChange={(e) =>
                                                                                    this.handleCheckBoxChange(
                                                                                        'flightDuration',

                                                                                        e
                                                                                    )
                                                                                }
                                                                            />

                                                                            <div className="checkbox-title">
                                                                                {item.value}
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </Card>
                                                        </React.Fragment>
                                                    )}
                                            </div>

                                            <div style={{ marginTop: '20px' }}>
                                                {this.props.showWorkbenchLink && this.state.seatClasses.length > 0 && (
                                                    <React.Fragment>
                                                        <Card.Title>Seat Class</Card.Title>

                                                        <Card>
                                                            <ul className="filters-checkbox-list">
                                                                {this.state.seatClasses.map((item, index) => (
                                                                    <li key={index + '_li_seat_classes'}>
                                                                        <Checkbox
                                                                            name={item.value}
                                                                            key={item.value}
                                                                            style={{ float: 'left' }}
                                                                            marked={
                                                                                this.state.selectedFilters.seatClass.includes(
                                                                                    item.value
                                                                                )
                                                                                    ? CheckboxSelectVariant.selected
                                                                                    : CheckboxSelectVariant.unselected
                                                                            }
                                                                            onChange={(e) =>
                                                                                this.handleCheckBoxChange(
                                                                                    'seatClass',

                                                                                    e
                                                                                )
                                                                            }
                                                                        />

                                                                        <div className="checkbox-title">
                                                                            {item.value}
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Card>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        )}
                    </Card>
                </Row>
            </Accordion>
        );
    }
}

export default DynamicFilter;
