import React from 'react';
import { Bar } from 'react-chartjs-2';
import util from '../../util/UtilHelper.js';
import 'assets/styles/widgets/verticalBarChart.scss';
import clsx from 'clsx';
import Analytics from 'components/widgets/Analytics';

const utilHelper = new util();

class VerticalBarChart extends React.Component {
    constructor(props) {
        super(props);
        this.updated = false;
    }

    componentWillMount() {
        let chartData = {
            datasets: [],
            labels: ['First Phase (20% of flight)', 'Middle Phase (60% of flight)', 'Last Phase (20% of flight)']
        };
        this.setState({
            data: chartData
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            let chartData = this.state.data;
            chartData.datasets = [];
            let { data, labels, colors } = nextProps.data;
            for (var i = 0; i < data.length; i++) {
                chartData.datasets.push({
                    label: labels[i],
                    grouped: true,
                    data: data[i],
                    backgroundColor: colors[i].color,
                    hoverBackgroundColor: colors[i].hoverColor ? colors[i].hoverColor : colors[i].color + '80',
                    hoverShadowBlur: 10,
                    hoverShadowColor: '#00000080',
                    hoverBorderColor: 'white',
                    hidden: false
                });
            }

            this.setState({
                dataWithoutChange: nextProps.dataWithoutChange,
                data: chartData,
                yAxisLabel: nextProps.yAxisLabel,
                unit: nextProps.unit,
                chartPropUnit: nextProps.chartPropUnit
            });
        }
    }

    toggleData(index) {
        Analytics.analytics.track('toggleData', {
            visual_name: this.props.title,
            interaction_type: 'Checkbox Click',
            interaction_value: this.state.data.datasets[index].label
        });

        var newData = this.state.data;
        newData.datasets[index].hidden = !newData.datasets[index].hidden;
        this.setState({
            data: newData
        });
    }

    render() {
        var uniqueId = Math.random();
        let { data, unit, yAxisLabel, chartPropUnit, dataWithoutChange } = this.state;

        let options = {
            responsive: true,
            maintainAspectRatio: false,
            hover: {
                mode: 'point'
            },
            legend: {
                display: false
            },
            layout: {
                padding: {
                    left: 42,
                    right: 62
                }
            },
            plugins: {
                datalabels: {
                    display: false
                }
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: true,
                            drawBorder: false,
                            drawTicks: false
                        },
                        ticks: {
                            drawTicks: false,
                            maxTicksLimit: 4,
                            fontColor: '#a9a9a9',
                            color: 'white',
                            padding: 7
                        }
                    }
                ],
                yAxes: [
                    {
                        gridLines: {
                            display: true,
                            drawBorder: true,
                            drawTicks: false
                        },
                        scaleLabel: {
                            display: false,
                            labelString: yAxisLabel
                        },
                        ticks: {
                            autoSkip: true, // TODO - ADDED NEWLY
                            maxTicksLimit: 9,
                            fontColor: '#a9a9a9',
                            crossAlign: 'far',
                            callback: function (value, index, values) {
                                if (unit && unit.toLowerCase() === 'time' && chartPropUnit.toLowerCase() === 'mixed') {
                                    return utilHelper.secondsToHmsForDynamicTabs(value) + '   ';
                                } else if (unit && unit.toLowerCase() === 'time') {
                                    return utilHelper.secondsToHmsForDynamicTabs(value) + '   ';
                                } else {
                                    return utilHelper.formatToUnitsForPillBox(value, 1) + '   ';
                                }
                            }
                        }
                    }
                ]
            },
            tooltips: {
                mode: 'nearest',
                enabled: false,
                custom: function (tooltipModel) {
                    // Tooltip Element
                    var tooltipEl = document.getElementById(uniqueId);

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        var bodyLines = tooltipModel.body.map(getBody);

                        var innerHtml = '';
                        innerHtml +=
                            '<th style="font-weight:bold;font-size:18px;  ">' +
                            bodyLines[0][0].split(': ')[0] +
                            '</th>';

                        bodyLines.forEach(function (body, i) {
                            var dataSplit = body[0].split(': ');
                            let activePosition = 0;
                            data.labels.forEach((label, i) => {
                                if (label === tooltipModel.title[0]) activePosition = i;
                            });
                            let labelData = bodyLines[0][0].split(': ')[0];
                            var tooltipLabels = ['First Phase: ', 'Middle Phase: ', 'Last Phase: '];
                            let currentData = '';
                            let currentDataWithoutChange = '';
                            if (data && data.datasets && labelData) {
                                for (let i = 0; i < data.datasets.length; i++) {
                                    if (
                                        data.datasets[i] &&
                                        data.datasets[i].label &&
                                        data.datasets[i].label === labelData
                                    ) {
                                        currentData = data.datasets[i].data;
                                        currentDataWithoutChange = dataWithoutChange[i];
                                        break;
                                    }
                                }
                            }

                            let val = '';
                            if (unit && unit.toLowerCase() === 'time') {
                                val = utilHelper.secondsToHmsForDynamicTabs(dataSplit[1]);
                                if (currentData) {
                                    const allData = currentData.toString().split(',');
                                    if (allData && allData.length > 0) {
                                        for (let i = 0; i < allData.length; i++) {
                                            innerHtml += `<tr><td style="font-weight: ${
                                                i === activePosition ? 'bold' : 'normal'
                                            };color: ${
                                                i !== activePosition ? 'gray' : ''
                                            }"><span style="float:left;padding-right:15px;">${
                                                tooltipLabels[i]
                                            }</span>  <span class="value" >${utilHelper.secondsToHmsForDynamicTabs(
                                                currentDataWithoutChange[i]
                                            )}</span> </td></tr>`;
                                        }
                                    } else {
                                        innerHtml += `<tr><td>${val}</td></tr>`;
                                    }
                                } else {
                                    innerHtml += `<tr><td>${val}</td></tr>`;
                                }
                            } else {
                                val = dataSplit[1];
                                if (currentData) {
                                    const allData = currentData.toString().split(',');
                                    if (allData && allData.length > 0) {
                                        for (let i = 0; i < allData.length; i++) {
                                            innerHtml += `<tr><td style="min-width:130px;font-weight: ${
                                                i === activePosition ? 'bold' : 'normal'
                                            };color: ${i !== activePosition ? 'gray' : ''}"><span class="description">${
                                                tooltipLabels[i]
                                            }</span>  <span class="value" >${utilHelper.formatToUnitsForPillBox(
                                                currentDataWithoutChange[i]
                                            )}</span> </td></tr>`;
                                        }
                                    } else {
                                        innerHtml += `<tr><td>${val}</td></tr>`;
                                    }
                                } else {
                                    innerHtml += `<tr><td>${val}</td></tr>`;
                                }
                            }
                        });
                        tooltipEl.innerHTML = '<table>' + innerHtml + '</table>';
                    }

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = tooltipModel.caretX + 'px';
                    if (tooltipModel.caretX > 800) {
                        tooltipEl.style.left = tooltipModel.caretX - 230 + 'px';
                    }
                    tooltipEl.style.top = 2 + tooltipModel.caretY + 'px';
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            }
        };

        if (data.datasets.length === 0) {
            return (
                <div style={{ paddingLeft: '14px', paddingRight: '5px' }}>
                    <div className="no-data" style={{ marginTop: '14%', fontSize: '32px' }}>
                        Data is not available
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <div className={'vertical-bar-yaxis-label'}>{yAxisLabel}</div>
                    <div className="vertical-bar-frame">
                        <Bar data={data} options={options} height={268} />
                        <div id={uniqueId} className="vertical-bar-chart-tooltip" />
                        <div className="vertical-bar-legend">
                            {data.datasets.map((dataset, i) => (
                                <div
                                    key={dataset.label}
                                    style={{ height: '24px' }}
                                    className={clsx({ unselected: dataset.hidden })}
                                >
                                    <div
                                        className="vertical-bar-chart-legend-color"
                                        style={{
                                            color:
                                                parseInt(dataset.hoverBackgroundColor.replace('#', ''), 16) >
                                                0xffffff / 2
                                                    ? '#000'
                                                    : '#fff',
                                            backgroundColor: dataset.hidden ? '#E5E5E5' : dataset.backgroundColor
                                        }}
                                        onClick={() => this.toggleData(i)}
                                        {...(!dataset.hidden && {
                                            onMouseOver: (e) =>
                                                (e.target.style.background = dataset.hoverBackgroundColor),
                                            onMouseLeave: (e) => (e.target.style.background = dataset.backgroundColor)
                                        })}
                                    >
                                        {dataset.hidden ? '' : '\u2713'}
                                    </div>
                                    <div className="vertical-bar-chart-legend-label">
                                        <span>{dataset.label}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default VerticalBarChart;
