import { Auth } from 'aws-amplify';

function getTenantManagementServiceAPIEndpoint() {
    return 'https://uhdg0ahs3d.execute-api.us-west-2.amazonaws.com/dev';
}

function getTenantManagementServiceAPIKey() {
    switch (process.env.REACT_APP_STAGE) {
        case 'prd':
            return 'LTtsBrHxHg3uZrlvli4no6Ybhj0fhUJP6KEOAh1Z';
        case 'stage':
            return 'Avk3tpxkq48nSnIRyzl7k5Smtuc6lX4z1t40Gtdr';
        case 'test':
            return 'dGm5SjOfPN3PNLUKeOo1281dGyXSIiVl3vWQ4LvM';
        case 'dev':
            return 'lkNpNf6hEL66vSlNfvO9J1QA2qTLJa2o4Xx7ugQ1';
        case 'cloud9':
            return 'lkNpNf6hEL66vSlNfvO9J1QA2qTLJa2o4Xx7ugQ1';
        default:
            throw new Error('Invalid Stage Environment');
    }
}

function getQuickSightEmbedUrlServiceAPIEndpoint() {
    switch (process.env.REACT_APP_STAGE) {
        case 'prd':
        case 'stage':
        case 'test':
        case 'dev':
        case 'cloud9':
            return 'https://tvc6dzqkg5.execute-api.us-west-2.amazonaws.com/dev/';
        default:
            throw new Error('Invalid Stage Environment');
    }
}

let applicationApiKey = getTenantManagementServiceAPIKey();

async function getAuthorizationHeader() {
    const currentSession = await Auth.currentSession();
    return currentSession.idToken.jwtToken;
}

let config = {
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
        mandatorySignIn: false,
        oauth: {
            domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: process.env.REACT_APP_HOST,
            redirectSignOut: process.env.REACT_APP_SIGNOUT_URL,
            responseType: 'CODE',
            options: {
                AdvancedSecurityDataCollectionFlag: true
            }
        }
    },
    API: {
        endpoints: [
            {
                name: process.env.REACT_APP_INSIGHTS_API_NAME,
                endpoint: process.env.REACT_APP_INSIGHTS_API_URL,
                region: process.env.REACT_APP_INSIGHTS_API_REGION,
                custom_header: async () => {
                    return {
                        Authorization: await getAuthorizationHeader()
                    };
                }
            },
            {
                name: 'TenantManagementServiceAPI',
                endpoint: getTenantManagementServiceAPIEndpoint(),
                region: 'us-west-2',
                custom_header: async () => {
                    return {
                        Authorization: await getAuthorizationHeader(),
                        'x-api-key': applicationApiKey
                    };
                }
            },
            {
                name: 'QuickSightEmbedUrlServiceAPI',
                endpoint: getQuickSightEmbedUrlServiceAPIEndpoint(),
                region: 'us-west-2',
                custom_header: async () => {
                    return {
                        Authorization: await getAuthorizationHeader()
                    };
                }
            }
        ]
    }
};

export default config;
